import React, { useEffect, useState } from 'react';
import SSRIcon from '@ingka/ssr-icon';
import { IconType } from '@nfw/nudge/types';
import { useLazyIcon } from '@nfw/ui/common';
import './index.scss';

export type EmojiRainProps = {
  emojis: IconType[];
  colors: string[];
};

const EmojiRain: React.FC<EmojiRainProps> = ({ emojis, colors }) => {
  const [combinedArray, setCombinedArray] = useState<string[]>([]);
  const [setIcons, getIcon] = useLazyIcon();

  const combineAndShuffle = (array1: string[], array2: string[]): string[] => {
    const combined = array1.concat(array2);

    for (let i = combined.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [combined[i], combined[j]] = [combined[j], combined[i]];
    }
    return combined;
  };

  useEffect(() => {
    if (emojis.length > 0) {
      setIcons(...emojis);
    }

    const emojisMultiplier = Math.ceil(30 / emojis.length);
    const colorsMultiplier = Math.ceil(20 / colors.length);

    const emojisRepeated = Array.from({ length: emojisMultiplier }, () => emojis).flat();
    const colorsRepeated = Array.from({ length: colorsMultiplier }, () => colors).flat();

    const shuffledArray = combineAndShuffle(
      emojisRepeated.slice(0, 50),
      colorsRepeated.slice(0, 50)
    );
    setCombinedArray(shuffledArray);
  }, [colors, emojis, setIcons]);

  return (
    <div className="emoji-and-confetti-rain-container">
      {combinedArray.map((item) => {
        const isEmoji = emojis.includes(item as IconType);
        const animationDuration = (Math.random() * 1 + 1.5).toFixed(1) + 's'; // Random duration between 1.5 and 2.5 seconds
        const animationDelay = (Math.random() * 0.5).toFixed(1) + 's'; // Random delay between 0 and 0.5 seconds
        const rotation = Math.floor(Math.random() * 91) - 45;

        const style = {
          animationDuration,
          animationDelay,
        };

        return (
          <div className={isEmoji ? 'emoji-div' : 'confetti-div'} style={style}>
            {isEmoji ? (
              <SSRIcon paths={getIcon(item as IconType)} className="emoji" />
            ) : (
              <div
                className="confetti"
                style={{ backgroundColor: item, rotate: rotation + 'deg' }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default EmojiRain;
