import { useCallback, useEffect } from 'react';
import { LocaleTranslations } from '@nfw/contracts/self-service';
import { useErrorLimitGuard } from '../api/hooks';
import { PuffaError } from '../api/puffa-error';
import {
  useGetTeamTranslationsForLocaleQuery,
  useGetTeamTranslationsQuery,
  useGetAllLocaleTranslationsQuery,
  useUpdateAllLocaleTranslationsMutation,
} from './api';

/**
 * Hook for fetching translations for a team.
 *
 * @param team - The team to fetch translations for.
 * @param skip - Flag for skipping the query.
 *
 * @returns the translations and loading state.
 */
export const useGetTeamTranslations = (team: string, skip?: boolean) => {
  const { data, isLoading, isError } = useGetTeamTranslationsQuery(
    {
      team,
    },
    { skip }
  );

  useEffect(() => {
    if (isError) {
      throw new PuffaError(`Failed fetching translations for team ${team}`);
    }
  }, [team, isError]);

  return { translations: data, isLoading };
};

/**
 * Hook for fetching translations for a team.
 *
 * @param team - The team to fetch translations for.
 * @param skip - Flag for skipping the query.
 *
 * @returns the translations and loading state.
 */
export const useGetTeamTranslationsForLocale = (team: string, locale: string, skip?: boolean) => {
  const { data, isLoading, isError } = useGetTeamTranslationsForLocaleQuery(
    {
      team,
      locale,
    },
    { skip }
  );

  useEffect(() => {
    if (isError) {
      throw new PuffaError(`Failed fetching translations for team ${team} in locale ${locale}`);
    }
  }, [team, isError, locale, data]);

  return { translations: data, isLoading };
};

/**
 * Hook for fetching all locale translations for a team.
 *
 * @param team - The team to fetch translations for.
 * @param skip - Flag for skipping the query.
 *
 * @returns the translations and loading state.
 */
export const useGetAllLocaleTranslations = (team: string, skip?: boolean) => {
  const { data, isLoading, isError } = useGetAllLocaleTranslationsQuery({ team }, { skip });

  useEffect(() => {
    if (isError) {
      throw new PuffaError(`Failed fetching translations for team ${team}.`);
    }
  }, [team, isError, data]);

  return { translations: data, isLoading };
};

/**
 * Hook for updating all locale translations for a team.
 *
 * @param team - The team to update translations for.
 *
 * @returns the save function, loading state and error state.
 */
export const useUpdateAllLocaleTranslations = (team: string) => {
  const [save, result] = useUpdateAllLocaleTranslationsMutation();
  useErrorLimitGuard(result.isError);

  const saveInternal = useCallback(
    (translations: LocaleTranslations) => {
      save({ team, translations });
    },
    [save, team]
  );

  return { save: saveInternal, isLoading: result.isLoading, isError: result.isError };
};
