import { isErrorBody as isBaseErrorBody } from '@nfw/contracts/base';
import { isRetailUnit } from '@nfw/ikea/retail';
import {
  isBoolean,
  isObject,
  isString,
  isArrayOfType,
  isNaturalNumber,
  isRegexpMatch,
  isOptionallyDefined,
} from '@nfw/utils';
import {
  ErrorBody,
  ErrorType,
  ErrorTypes,
  WidgetRequest,
  TeamConfiguration,
  ProductTeamRequest,
  TeamStatus,
  TeamStatuses,
  TeamType,
  EditableTeamConfiguration,
  Decision,
  MarketTeamRequest,
  TeamRequest,
} from './types';

/**
 * Check if the error type is a valid error type.
 *
 * @param type - the error type.
 *
 * @returns true if the error type is a valid error type.
 */
export const isErrorType = (type: unknown): type is ErrorType =>
  Object.values(ErrorTypes).includes(type as ErrorType);

/**
 * Check if the body is an error body.
 *
 * @param body - the body to test.
 *
 * @returns true if the body is an error body.
 */
export const isErrorBody = (body: unknown): body is ErrorBody =>
  isBaseErrorBody(body) && isErrorType(body.type);

/**
 * Check if the request is a valid widget request.
 *
 * @param req - the request to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isWidgetRequest = (req: unknown): req is WidgetRequest =>
  isObject(req) && isString(req.creator) && isString(req.team) && isString(req.figmaLink);

/**
 * Check if the type is a valid team type.
 *
 * @param type - the type to test.
 *
 * @returns true if type is valid, otherwise false.
 */
export const isTeamType = (type: unknown): type is TeamType =>
  isProductTeamType(type) || isMarketTeamType(type);

/**
 * Check if the type is a product team type.
 *
 * @param type - the type to test.
 *
 * @returns true if type is product, otherwise false.
 */
export const isProductTeamType = (type: unknown): type is 'product' => type === 'product';

/**
 * Check if the type is a market team type.
 *
 * @param type - the type to test.
 *
 * @returns true if type is market, otherwise false.
 */
export const isMarketTeamType = (type: unknown): type is 'market' => type === 'market';

/**
 * Check if team status is valid.
 *
 * @param status - the status to test.
 *
 * @returns true if status is valid, otherwise false.
 */
export const isTeamStatus = (status: unknown): status is TeamStatus =>
  Object.values(TeamStatuses).includes(status as TeamStatus);

/**
 * Check if the config is a valid edit team config.
 *
 * @param config - the config to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isEditableTeamConfiguration = (config: unknown): config is EditableTeamConfiguration =>
  isObject(config) &&
  isString(config.name) &&
  isString(config.description) &&
  isArrayOfType(config.retailUnits, isRetailUnit, 1);

/**
 * Check if the request is a valid product team request.
 *
 * @param req - the request to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isProductTeamRequest = (req: unknown): req is ProductTeamRequest =>
  isObject(req) &&
  isRegexpMatch(req.id, '^[a-z]{3,6}$') &&
  isProductTeamType(req.teamType) &&
  isString(req.phraseProjectId) &&
  isString(req.defaultLocale) &&
  isEditableTeamConfiguration(req);

/**
 * Check if the request is a valid market team request.
 *
 * @param req - the request to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isMarketTeamRequest = (req: unknown): req is MarketTeamRequest =>
  isObject(req) &&
  isRegexpMatch(req.id, '^[a-z]{3,6}$') &&
  isMarketTeamType(req.teamType) &&
  isString(req.defaultLocale) &&
  isEditableTeamConfiguration(req);

/**
 * Check if the request is a valid team request.
 *
 * @param req - the request to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isTeamRequest = (req: unknown): req is TeamRequest =>
  isProductTeamRequest(req) || isMarketTeamRequest(req);

/**
 * Check if the config is a valid team config.
 *
 * @param config - the config to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isTeamConfiguration = (config: unknown): config is TeamConfiguration =>
  isObject(config) &&
  isString(config.creator) &&
  isTeamStatus(config.status) &&
  isNaturalNumber(config.created) &&
  isNaturalNumber(config.modified) &&
  isTeamRequest(config);

/**
 * Check if the configs are valid team configurations.
 *
 * @param configs - the configs to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isTeamConfigurations = (configs: unknown): configs is TeamConfiguration[] =>
  isArrayOfType(configs, isTeamConfiguration);

/**
 * Check if an experiment decision is valid.
 *
 * @param d - the value to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isDecision = (d: unknown): d is Decision =>
  isObject(d) &&
  (isString(d.value) || isBoolean(d.value)) &&
  isOptionallyDefined(d.motivation, isString);
