import { useCallback, useEffect, useState } from 'react';
import SkapaToast from '@ingka/toast';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { acknowledgeToast } from '../../features/app/actions';
import { selectToast } from '../../features/app/selectors';
import { useAppDispatch } from '../../store';

const Toast: React.FC = () => {
  const toast = useSelector(selectToast);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const onAcknowledge = useCallback(() => {
    if (toast) {
      setOpen(false);
      // Hard-coded wait since there is an issue where transition sometimes is not called.
      setTimeout(() => {
        dispatch(acknowledgeToast());
      }, 500);
    }
  }, [dispatch, toast]);

  useEffect(() => {
    if (toast) {
      setOpen(true);

      /*
       * A home-made close of the toast because we cannot seem to get it to work.
       * Maybe we should talk to the Skapa team.
       */
      const wordcount = toast.msg.split(' ').length;
      const timeout = setTimeout(onAcknowledge, Math.min(10000, 5000 + wordcount * 500));
      return () => clearTimeout(timeout);
    }
  }, [toast, onAcknowledge]);

  return createPortal(
    <SkapaToast
      id="toast"
      text={toast?.msg ?? ''}
      isOpen={open}
      onCloseRequest={onAcknowledge}
      actionButtonText={toast?.action ? 'View' : undefined}
      actionClick={toast?.action ? () => window.open(toast?.action, '_blank') : undefined}
    />,
    document.body
  );
};

export default Toast;
