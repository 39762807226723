import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import NudgeOfFameTable from '../../components/NudgeOfFameTable';
import Page, { PageHeader } from '../../components/Page';
import { useGetNudgeOfFameMetrics } from '../../features/orchestrations/hooks';

const NudgeOfFamePage: React.FC = () => {
  const { metrics, isLoading } = useGetNudgeOfFameMetrics();
  const { t } = useTranslation();

  return (
    <Page
      loading={isLoading}
      padded
      header={<PageHeader title={t('page.nudge_of_fame.header')} />}
      crumbs={[{ name: t('global.overview'), to: '/?tab=features' }]}
    >
      <Text bodySize="l">{t('page.nudge_of_fame.description')}</Text>
      <NudgeOfFameTable nudgeMetrics={metrics} />
    </Page>
  );
};

export default NudgeOfFamePage;
