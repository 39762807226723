import { useErrorLimitGuard } from '../api/hooks';
import { useRequestLatestTranslationsMutation } from './api';

/**
 * Hook for requesting the latest translations to be fetched from the translation manager.
 *
 * @returns the request function.
 */
export const useRequestLatestTranslations = () => {
  const [requestLatestTranslations, { isError }] = useRequestLatestTranslationsMutation();

  useErrorLimitGuard(isError);

  return {
    requestLatestTranslations,
  };
};
