import React, { useEffect, useState } from 'react';
import Button from '@ingka/button';
import InlineMessage from '@ingka/inline-message';
import ListView, { ListViewItem } from '@ingka/list-view';
import archivebox from '@ingka/ssr-icon/paths/archive-box';
import code from '@ingka/ssr-icon/paths/chevron-left-slash-chevron-right';
import lightbulbIncandescent from '@ingka/ssr-icon/paths/lightbulb-incandescent';
import pencil from '@ingka/ssr-icon/paths/pencil';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Audits from '../../components/Audits';
import Breadcrumb from '../../components/Breadcrumb';
import CodeModal from '../../components/CodeModal';
import DesignPage from '../../components/DesignPage';
import { InUseModal } from '../../components/InUseModal';
import LinearLayout from '../../components/LinearLayout';
import PopUp from '../../components/PopUp';
import WidgetDescription from '../../components/WidgetDescription';
import WidgetPreview from '../../components/WidgetPreview';
import { selectInUseOrchestrations } from '../../features/app/selectors';
import { useGetTeamTranslations } from '../../features/i18n/hooks';
import { useArchiveWidget, useGetWidget } from '../../features/widgets/hooks';
import { InjectedViewPermissionsProps, withViewPermissions } from '../../hocs';
import { useListViewLinks, useNavigate } from '../../routes';
import { useMemoizedSelector } from '../../store';
import './index.scss';

const WidgetDetailsPage: React.FC<InjectedViewPermissionsProps> = ({ id, team, isEditAllowed }) => {
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [inUseVisible, setInUseVisible] = useState(false);
  const [codeVisible, setCodeVisible] = useState(false);
  const [inlineErrorVisible, setInlineErrorVisible] = useState(false);

  const orchestrations = useMemoizedSelector(selectInUseOrchestrations, id);

  const { data: widget, isLoading: isLoadingWidgets } = useGetWidget(id);
  const { translations, isLoading: isLoadingTranslations } = useGetTeamTranslations(team);
  const { archive, isError, reset } = useArchiveWidget(id);

  const { t } = useTranslation();

  const location = useLocation();
  const { navigate, toAppPath } = useNavigate();
  const { listViewOnClickHandler } = useListViewLinks();

  const archiveWidgetInternal = () => {
    if (widget) {
      archive(widget);
    }
  };

  useEffect(() => {
    if (isError) {
      setInlineErrorVisible(true);
      reset();
    }
  }, [t, isError, reset]);

  useEffect(() => {
    window.history.replaceState({}, '');
  }, []);

  return (
    <DesignPage
      loading={isLoadingWidgets || isLoadingTranslations}
      compact
      designComponent={<WidgetPreview widget={widget} translations={translations} />}
      designOnly
    >
      <PopUp
        visible={popUpVisible}
        close={() => setPopUpVisible(false)}
        title={t('widget.modal.archive.title')}
        body={t('widget.modal.archive.body')}
        actions={[
          {
            type: 'secondary',
            text: t('global.action.cancel'),
          },
          {
            type: 'primary',
            text: t('page.widget_details.archive.cta'),
            action: archiveWidgetInternal,
          },
        ]}
      />
      <InUseModal
        visible={inUseVisible}
        close={() => setInUseVisible(false)}
        features={orchestrations}
        title={t('page.widget_details.title')}
        text={t('page.widget_details.text')}
      />
      <CodeModal visible={codeVisible} close={() => setCodeVisible(false)} code={widget} />
      <LinearLayout gap="xl">
        {widget && (
          <>
            <Breadcrumb
              crumbs={[{ name: t('global.overview'), to: '/home' }, { name: `#${widget.id}` }]}
            />

            {location.state === 'created' && (
              <InlineMessage variant="positive" body={t('global.widget.saved')} dismissable />
            )}
            {location.state === 'updated' && (
              <InlineMessage variant="positive" body={t('global.widget.updated')} dismissable />
            )}

            <Text headingSize="m">{`${widget.name}`}</Text>
            <Audits id={id} modified={widget.modified} />
            <Button
              type="primary"
              disabled={!isEditAllowed || widget.isArchived}
              text={t('page.widget_details.apply_logic')}
              ssrIcon={lightbulbIncandescent}
              href={`/create/nudge/${team}/${widget.id}`}
              onClick={(event) => {
                event.preventDefault();
                navigate('/create/nudge/:team/:widgetId', { team, widgetId: widget.id });
              }}
              iconPosition="leading"
            />
            <WidgetDescription
              widget={widget}
              detailsHeader={t('global.details')}
              configurationHeader={t('global.configuration')}
            />
            {inlineErrorVisible && (
              <InlineMessage variant="negative" body={t('global.error.generic')} />
            )}
            <ListView id="actions" onClick={listViewOnClickHandler}>
              <ListViewItem
                id="edit"
                emphasised
                control="link"
                controlIcon="arrowRight"
                leadingIcon={pencil}
                title={t('global.edit')}
                href={toAppPath('/edit/design/:team/:id', { team: widget.team, id: widget.id })}
                disabled={!isEditAllowed || widget.isArchived}
              />
              <ListViewItem
                id="code"
                emphasised
                control="navigational"
                controlIcon="arrowRight"
                leadingIcon={code}
                title={t('global.view.code')}
                onClick={() => setCodeVisible(true)}
              />
              <ListViewItem
                id="archive"
                emphasised
                control="navigational"
                controlIcon="arrowRight"
                leadingIcon={archivebox}
                title={t('page.widget_details.archive.cta')}
                disabled={!isEditAllowed || widget.isArchived}
                onClick={() =>
                  orchestrations.length > 0 ? setInUseVisible(true) : setPopUpVisible(true)
                }
              />
            </ListView>
          </>
        )}
      </LinearLayout>
    </DesignPage>
  );
};

export default withViewPermissions(WidgetDetailsPage, false);
