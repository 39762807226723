/**
 * Time units dictionary.
 */
export const Units = {
  Second: 'sec',
  Minute: 'min',
  Hour: 'hour',
  Day: 'day',
  Week: 'week',
} as const;
/** Representation of a time unit. */
export type Unit = (typeof Units)[keyof typeof Units];

/**
 * Transform time to milliseconds.
 *
 * @param time - the time.
 * @param unit - the unit to transform to.
 *
 * @returns the milliseconds in the desired unit.
 */
export const toMs = (time: number, unit: Unit) => {
  const ms = Math.max(0, time) * 1000;
  switch (unit) {
    case 'sec':
      return ms;
    case 'min':
      return ms * 60;
    case 'hour':
      return ms * 60 * 60;
    case 'day':
      return ms * 60 * 60 * 24;
    case 'week':
    default:
      return ms * 60 * 60 * 24 * 7;
  }
};

/**
 * Get a date (and optionally time) string.
 *
 * @param time - the time.
 * @param withTime - flag for if the time should be displayed aswell.
 *
 * @returns a time string.
 */
export const toDateString = (time: number, withTime = false) => {
  const date = new Date(time);
  const { format } = Intl.DateTimeFormat(window.navigator.language, {
    dateStyle: 'short',
    timeStyle: withTime ? 'short' : undefined,
  });

  return format(date);
};

/**
 * Get a formatted time string in the format HH:mm.
 *
 * @param now - Optional date object to format. Defaults to the current date.
 *
 * @returns the formatted time string.
 */
export const getFormattedTime = (now = new Date()): string => {
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};
