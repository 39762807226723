import Hyperlink from '@ingka/hyperlink';
import Modal, { Theatre, ModalHeader } from '@ingka/modal';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import Step1 from '../../assets/join/Step1.png';
import Step2 from '../../assets/join/Step2.png';
import Step3 from '../../assets/join/Step3.png';
import Step4 from '../../assets/join/Step4.png';
import './index.scss';

export type JoinTeamTheatreProps = {
  open: boolean;
  onClose: () => void;
  myIdentityGroups?: string;
};

const JoinTeamTheatre: React.FC<JoinTeamTheatreProps> = ({ open, onClose, myIdentityGroups }) => {
  const onCloseInternal = () => {
    onClose();
  };
  const { t } = useTranslation();

  return (
    <Modal className="modal" visible={open} handleCloseBtn={onCloseInternal}>
      <Theatre header={<ModalHeader />}>
        <div className="container">
          <div className="header-div">
            <Text headingSize="m">{t('team.theatre.header')}</Text>
            <Text className="caption" bodySize="l">
              {t('team.theatre.caption')}
            </Text>
          </div>
          <div className="section">
            <div className="text">
              <span className="circle" key={'circle-1'}>
                <Text className="number">{1}</Text>
              </span>
              <Text bodySize="l" tagName="h3">
                {t('team.theatre.section1')}
                &nbsp;
                <Hyperlink url="https://myidentity.apps.ikea.com/" newWindow>
                  {t('global.myIdentity')}
                </Hyperlink>
              </Text>
            </div>
            <img className="img" alt="The myIdentity portal overview page" src={Step1} />
          </div>
          <div className="section">
            <div className="text">
              <span className="circle" key={'circle-2'}>
                <Text className="number">{2}</Text>
              </span>
              <Text bodySize="l" tagName="h3">
                {t('team.theatre.section2')}
              </Text>
            </div>
            <img className="img" alt="Select Azure AD Group" src={Step2} />
          </div>
          <div className="section">
            <div className="text">
              <span className="circle" key={'circle-3'}>
                <Text className="number">{3}</Text>
              </span>
              <Text bodySize="l" tagName="h3">
                {t('team.theatre.section3')}
              </Text>
            </div>
            <ol className="list">
              <li>
                <Text bodySize="l">{`${t('global.edit')}: ${t('team.theatre.edit')}`}</Text>
              </li>
              <li>
                <Text bodySize="l">{`${t('global.publish')}: ${t('team.theatre.publish')}`}</Text>
              </li>
              <li>
                <Text bodySize="l">{`${t('global.admin')}: ${t('team.theatre.admin')}`}</Text>
              </li>
              {myIdentityGroups && (
                <Text bodySize="l">
                  {t('team.theatre.myIdentity.teams', { groups: myIdentityGroups })}
                </Text>
              )}
            </ol>
            <img className="img" alt="Select the Azure AD group to be a member in" src={Step3} />
          </div>
          <div className="section">
            <div className="text">
              <span className="circle" key={'circle-4'}>
                <Text className="number">{4}</Text>
              </span>
              <Text bodySize="l" tagName="h3">
                {t('team.theatre.section4')}
              </Text>
            </div>
            <Text bodySize="l" className="explanation">
              {t('team.theatre.section4.caption')}
            </Text>
            <img className="img" alt="Submit the request for approval" src={Step4} />
          </div>
        </div>
      </Theatre>
    </Modal>
  );
};

export default JoinTeamTheatre;
