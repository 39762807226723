import { Routes, TeamPermissions } from '@nfw/contracts/self-service';
import { api } from '../api';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPermissions: builder.query<TeamPermissions, void>({
      query: () => Routes.Permissions,
      providesTags: ['permission'],
    }),
  }),
});

export const { useGetPermissionsQuery } = authApi;
