import { useEffect, useMemo, useState } from 'react';
import { RetailUnit } from '@nfw/ikea/retail';
import { UserPermission } from '@nfw/permissions';
import { nanoid } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from '../../routes';
import { RootState, useAppDispatch } from '../../store';
import { PuffaError } from '../api/puffa-error';
import { queueToast } from '../app/actions';
import { acquire, release } from './actions';
import { selectIsError, selectLock } from './selectors';

export type ResourceLockResult = {
  lockedPermissions: UserPermission[];
  isLoading: boolean;
  setResourceId: (resourceId: string) => void;
  setTeam: (team: string) => void;
  resourceId?: string;
  team?: string;
  retailUnits: RetailUnit[];
};

/**
 * Hook for using a resource lock.
 * The resource lock will try to lock the resource and disable the user from
 * not using any elevated user permissions if not lock can be acquired.
 *
 * @param isLockRequired - flag for if the user should go back if the lock is required.
 *
 * @returns An object containing the locked permissions, loading state, resource ID, team, retail units, and setter functions.
 */
export const useResourceLock = (isLockRequired = false): ResourceLockResult => {
  const [lockedPermissions, setLockedPermissions] = useState<UserPermission[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [team, setTeam] = useState<string>();
  const [resourceId, setResourceId] = useState<string>();
  const [retailUnits, setRetailUnits] = useState<RetailUnit[]>([]);

  const lockId = useMemo(() => nanoid(5), []);
  const lock = useSelector((state: RootState) => selectLock(state, lockId));
  const isError = useSelector((state: RootState) => selectIsError(state, lockId));

  const { back } = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (resourceId && team) {
      /*
       * User has elevated permissions.
       * This in turn means they can mutate the resource.
       * We should try locking it...
       */
      dispatch(acquire(resourceId, team, lockId));
      return () => {
        dispatch(release());
      };
    }
  }, [resourceId, lockId, dispatch, team]);

  useEffect(() => {
    if (isError) {
      throw new PuffaError();
    }
  }, [isError]);

  useEffect(() => {
    if (lock) {
      const { isAcquired, owner, userPermissions, retailUnits, isAuthenticated } = lock;

      if (isAcquired) {
        setLockedPermissions(userPermissions);
        setRetailUnits(retailUnits);
      } else {
        // If the lock is required to view page, go back.
        if (isLockRequired) {
          back();
        }
        // If the user is authenticated in the team context for this resource.
        if (isAuthenticated) {
          dispatch(queueToast({ msg: t('global.error.session_expired', { owner }) }));
        }
      }
      setIsLoading(false);
    }
  }, [lock, isError, isLockRequired, back, dispatch, t]);

  return {
    lockedPermissions,
    isLoading,
    setResourceId,
    setTeam,
    resourceId,
    team,
    retailUnits,
  };
};
