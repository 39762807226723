import { IconType, IconTypes } from '@nfw/nudge/types';

/**
 * Lazy import of icons.
 *
 * @param icon - the icon type.
 *
 * @returns the icon or undefined.
 */
const _lazyimport = (icon?: IconType) => {
  switch (icon) {
    case IconTypes.ArrowCounterclockwise:
      return import('@ingka/ssr-icon/paths/arrow-counterclockwise');
    case IconTypes.ArrowLeft:
      return import('@ingka/ssr-icon/paths/arrow-left');
    case IconTypes.ArrowUpFromBase:
      return import('@ingka/ssr-icon/paths/arrow-up-from-base');
    case IconTypes.BarChartDowntrend:
      return import('@ingka/ssr-icon/paths/bar-chart-downtrend');
    case IconTypes.BarChartUptrend:
      return import('@ingka/ssr-icon/paths/bar-chart-uptrend');
    case IconTypes.ShoppingBag:
      return import('@ingka/ssr-icon/paths/shopping-bag');
    case IconTypes.ShoppingBagActive:
      return import('@ingka/ssr-icon/paths/shopping-bag-active');
    case IconTypes.Chat:
      return import('@ingka/ssr-icon/paths/chat');
    case IconTypes.ChevronDown:
      return import('@ingka/ssr-icon/paths/chevron-down');
    case IconTypes.ChevronDownSmall:
      return import('@ingka/ssr-icon/paths/chevron-down-small');
    case IconTypes.ChevronUp:
      return import('@ingka/ssr-icon/paths/chevron-up');
    case IconTypes.ChevronUpSmall:
      return import('@ingka/ssr-icon/paths/chevron-up-small');
    case IconTypes.Cross:
      return import('@ingka/ssr-icon/paths/cross');
    case IconTypes.CircleFilled:
      return import('@ingka/ssr-icon/paths/circle-filled');
    case IconTypes.Circle:
      return import('@ingka/ssr-icon/paths/circle');
    case IconTypes.Globe:
      return import('@ingka/ssr-icon/paths/globe');
    case IconTypes.Link:
      return import('@ingka/ssr-icon/paths/link');
    case IconTypes.LinkOut:
      return import('@ingka/ssr-icon/paths/link-out');
    case IconTypes.FamilyGenderMixed:
      return import('@ingka/ssr-icon/paths/family-gender-mixed');
    case IconTypes.MagnifyingGlassList:
      return import('@ingka/ssr-icon/paths/magnifying-glass-list');
    case IconTypes.Pencil:
      return import('@ingka/ssr-icon/paths/pencil');
    case IconTypes.AllenKey:
      return import('@ingka/ssr-icon/paths/allen-key');
    case IconTypes.Checkmark:
      return import('@ingka/ssr-icon/paths/checkmark');
    case IconTypes.CheckmarkCircle:
      return import('@ingka/ssr-icon/paths/checkmark-circle');
    case IconTypes.Calendar:
      return import('@ingka/ssr-icon/paths/calendar');
    case IconTypes.Document:
      return import('@ingka/ssr-icon/paths/document');
    case IconTypes.MembershipCard:
      return import('@ingka/ssr-icon/paths/membership-card');
    case IconTypes.Heart:
      return import('@ingka/ssr-icon/paths/heart');
    case IconTypes.IceCream:
      return import('@ingka/ssr-icon/paths/ice-cream');
    case IconTypes.InfoCircle:
      return import('@ingka/ssr-icon/paths/information-circle');
    case IconTypes.FeedbackDissatisfied:
      return import('@ingka/ssr-icon/paths/feedback-dissatisfied');
    case IconTypes.FeedbackSatisfied:
      return import('@ingka/ssr-icon/paths/feedback-satisfied');
    case IconTypes.FeedbackHappy:
      return import('@ingka/ssr-icon/paths/feedback-happy');
    case IconTypes.FeedbackNeutral:
      return import('@ingka/ssr-icon/paths/feedback-neutral');
    case IconTypes.HotDrink:
      return import('@ingka/ssr-icon/paths/hot-drink');
    case IconTypes.Flame:
      return import('@ingka/ssr-icon/paths/flame');
    case IconTypes.Flower:
      return import('@ingka/ssr-icon/paths/flower');
    case IconTypes.Reward:
      return import('@ingka/ssr-icon/paths/reward');
    case IconTypes.Phone:
      return import('@ingka/ssr-icon/paths/phone');
    case IconTypes.FeedbackSad:
      return import('@ingka/ssr-icon/paths/feedback-sad');
    case IconTypes.Star:
      return import('@ingka/ssr-icon/paths/star');
    case IconTypes.ThumbsUp:
      return import('@ingka/ssr-icon/paths/thumbs-up');
    case IconTypes.ThumbsDown:
      return import('@ingka/ssr-icon/paths/thumbs-down');
    case IconTypes.People:
      return import('@ingka/ssr-icon/paths/people');
    case IconTypes.Plus:
      return import('@ingka/ssr-icon/paths/plus');
    case IconTypes.Person:
      return import('@ingka/ssr-icon/paths/person');
    case IconTypes.PersonActive:
      return import('@ingka/ssr-icon/paths/person-active');
    case IconTypes.Power:
      return import('@ingka/ssr-icon/paths/power');
    case IconTypes.QuestionMark:
      return import('@ingka/ssr-icon/paths/question-mark');
    case IconTypes.TrashCan:
      return import('@ingka/ssr-icon/paths/trash-can');
    case IconTypes.ClipboardCheckmark:
      return import('@ingka/ssr-icon/paths/clipboard-checkmark');
    case IconTypes.Lock:
      return import('@ingka/ssr-icon/paths/lock');
    case IconTypes.Pairing:
      return import('@ingka/ssr-icon/paths/pairing');
    case IconTypes.ChevronUpChevronDownSmall:
      return import('@ingka/ssr-icon/paths/chevron-up-chevron-down-small');
    case IconTypes.Store:
      return import('@ingka/ssr-icon/paths/store');
    case IconTypes.MailArrowIn:
      return import('@ingka/ssr-icon/paths/mail-arrow-in');
    default:
      throw new Error('Unknown icon');
  }
};

/**
 * The ssr function for getting an icon.
 */
export type SSRIconFunction = (prefix?: string | undefined) => React.SVGProps<SVGElement>[];

/**
 * Factory method for getting the icon.
 *
 * @param icon - the icon type.
 *
 * @returns the icon.
 */
const tryGetIconFn = async (icon?: IconType) => {
  if (icon) {
    return _lazyimport(icon).then(({ default: icon }) => icon);
  }
  return undefined;
};

/**
 * A record of icon types and their ssr function.
 */
export type IconRecord = Partial<Record<IconType, SSRIconFunction>>;

/**
 * Get the icon record.
 *
 * @param icons - the icons to add in record.
 *
 * @returns the icon record.
 */
export const getIconRecord = async (icons: IconType[]): Promise<IconRecord> => {
  let record: IconRecord = {};

  for (const icon of icons) {
    try {
      const fn = await tryGetIconFn(icon);

      if (fn) {
        record = {
          ...record,
          [icon]: fn,
        };
      }
    } catch (error) {
      // nop - worst case scenario we do not show the icon.
    }
  }
  return record;
};
