import { useEffect, useState } from 'react';
import Button from '@ingka/button';
import Text from '@ingka/text';
import { TeamConfiguration } from '@nfw/contracts/self-service';
import { useTranslation } from 'react-i18next';
import JoinTeamTheatre from '../../components/JoinTeamTheatre';
import Page, { PageHeader } from '../../components/Page';
import TeamDescription from '../../components/TeamDescription';
import { useTeamPermission } from '../../features/auth/hooks';
import { useGetTeam } from '../../features/teams/hooks';
import { useNavigate } from '../../routes';
import { Routes } from '../../routes/types';
import EditTeam from '../TeamPage/Teams/EditTeam';
import './index.scss';

const TeamDetailsPage: React.FC = () => {
  const { params, navigate } = useNavigate();
  const [editedTeam, setEditedTeam] = useState<TeamConfiguration>();
  const [joinTeam, setJoinTeam] = useState(false);
  const { setTeam, isInitialised, permissions } = useTeamPermission();
  const { data: teamData, isLoading } = useGetTeam(params.team!);
  const { t } = useTranslation();

  useEffect(() => {
    if (teamData) {
      setTeam(teamData.id);
    }
  }, [teamData, setTeam]);

  let myIdentityGroups: string | undefined = undefined;
  if (teamData) {
    myIdentityGroups =
      `NFW_${teamData.id}_ADMIN, NFW_${teamData.id}_PUBLISH, NFW_${teamData.id}_EDIT`.toUpperCase();
  }

  return (
    <>
      <JoinTeamTheatre
        open={joinTeam}
        onClose={() => setJoinTeam(false)}
        myIdentityGroups={myIdentityGroups}
      />
      <EditTeam team={editedTeam} close={() => setEditedTeam(undefined)} />
      <Page
        padded
        loading={isLoading || !isInitialised}
        crumbs={[
          { name: t('global.overview'), to: '/?tab=features' },
          { name: t('global.teams.all'), to: Routes.ViewTeams },
          { name: teamData?.name ?? '' },
        ]}
        header={
          <PageHeader
            title={teamData?.name ?? 'unknown'}
            caption={teamData?.description}
            actions={[
              <Button
                key="edit"
                text={t('global.edit_team')}
                type="secondary"
                onClick={() => setEditedTeam(teamData)}
                disabled={!permissions?.includes('MANAGE_TEAM')}
              />,
              <Button
                key="join"
                type="primary"
                text={t('global.join.team')}
                onClick={() => setJoinTeam(true)}
              />,
            ]}
          />
        }
      >
        <Button
          text={t('page.team_details.view_translations')}
          key="translations"
          disabled={!permissions?.includes('EDIT')}
          size="small"
          className="translations"
          onClick={() =>
            navigate(
              teamData?.teamType === 'product'
                ? Routes.ProductTeamTranslations
                : Routes.MarketTeamTranslations,
              { team: params.team! }
            )
          }
        />
        {teamData && (
          <div className="team-details-container">
            <div className="header">
              <Text headingSize="m" tagName="h1">
                {t('global.details')}
              </Text>
            </div>
            <TeamDescription team={teamData} />
          </div>
        )}
      </Page>
    </>
  );
};

export default TeamDetailsPage;
