import { createReducer } from '@reduxjs/toolkit';
import { setPublishing } from './actions';
import { OrchestrationState } from './types';

const initialState: OrchestrationState = {
  isPublishing: [],
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setPublishing, (state, action) => {
    const { meta, payload } = action;
    const newState = state.isPublishing.filter((id) => id !== payload);

    if (meta) {
      state.isPublishing = newState.concat(payload);
    } else {
      state.isPublishing = newState;
    }
  });
});

export default reducer;
