import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '@ingka/button';
import InlineMessage from '@ingka/inline-message';
import ListView, { ListViewItem } from '@ingka/list-view';
import checkmarkCircle from '@ingka/ssr-icon/paths/checkmark-circle';
import createNew from '@ingka/ssr-icon/paths/create-new';
import cube from '@ingka/ssr-icon/paths/cube-3d';
import document from '@ingka/ssr-icon/paths/document';
import documentAdd from '@ingka/ssr-icon/paths/document-add';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import play from '@ingka/ssr-icon/paths/play';
import stop from '@ingka/ssr-icon/paths/stop';
import { EnvironmentState } from '@nfw/deployment-types';
import { StoredFeatureOrchestration } from '@nfw/orchestration-types';
import { UserPermission } from '@nfw/permissions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ConcludePopup from '../../../components/ConcludePopup';
import EmojiRain from '../../../components/EmojiRain';
import LinearLayout from '../../../components/LinearLayout';
import RedirectModal from '../../../components/RedirectModal';
import SetupExperimentPopup from '../../../components/SetupExperimentPopup';
import StartExperimentPopup from '../../../components/StartExperimentPopUp';
import StateIndicator from '../../../components/StateIndicator';
import { dismissExperimentIterationMessage } from '../../../features/app/actions';
import { selectExperimentIterationMessageDismissed } from '../../../features/app/selectors';
import {
  useDeleteExperiment,
  useGetDefaultMetrics,
  useGetExperimentResults,
  useReport,
} from '../../../features/experiments/hooks';
import { useDeploy } from '../../../features/orchestrations/hooks';
import { useNavigate } from '../../../routes';
import { useAppDispatch } from '../../../store';
import ReleaseLog from '../ReleaseLog';
import './index.scss';

export type ActionProps = {
  orchestration: StoredFeatureOrchestration;
  permissions: UserPermission[];
  children: React.ReactElement | React.ReactElement[];
  onStateChange: (state: EnvironmentState) => void;
};

const OrchestrationActions: React.FC<ActionProps> = ({
  orchestration,
  permissions,
  onStateChange,
  children,
}) => {
  const [concludePopupVisible, setConcludePopupVisible] = useState(false);
  const [startExperimentPopupVisible, setStartExperimentPopupVisible] = useState(false);
  const [iterationExperimentPopupVisible, setIterationExperimentPopupVisible] = useState(false);
  const [redirectPopVisible, setRedirectPopupVisible] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showStartExperimentError, setShowStartExperimentError] = useState(false);
  const isMessageDismissed = useSelector(selectExperimentIterationMessageDismissed);

  const isPublishAllowed = useMemo(() => permissions.includes('PUBLISH'), [permissions]);

  const { deployment, isRunning, state, publish } = useDeploy(orchestration);
  const { gotoReport, isLoading: isLoadingReport } = useReport(orchestration.id);
  const { metrics: defaultMetrics = [] } = useGetDefaultMetrics(orchestration.namespace);
  const { deleteExperiment } = useDeleteExperiment();
  const { result } = useGetExperimentResults(orchestration.id);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { navigateWithSearch } = useNavigate();

  const wasRunning = useRef(isRunning);
  const location = useLocation();

  useEffect(() => {
    if (state) {
      onStateChange(state);
    }
  }, [state, onStateChange]);

  useEffect(() => {
    if (isRunning) {
      wasRunning.current = true;
    } else if (wasRunning.current) {
      location.state = undefined;
    }
  }, [isRunning, location]);

  useEffect(() => {
    if (state?.prod === 'published' && !isRunning && wasRunning.current) {
      setShowConfetti(true);
    }
  }, [isRunning, state?.prod]);

  const startExperiment = useCallback(() => {
    if (state?.next?.action === 'experiment' && defaultMetrics.length > 0) {
      publish();
    } else {
      setShowStartExperimentError(true);
    }
  }, [defaultMetrics.length, publish, state?.next?.action]);

  const cancelExperiment = useCallback(() => {
    deleteExperiment({ id: orchestration.id, team: orchestration.team });
  }, [deleteExperiment, orchestration.id, orchestration.team]);

  return (
    state &&
    deployment && (
      <>
        <ConcludePopup
          id={orchestration.id}
          team={orchestration.team}
          visible={concludePopupVisible}
          onClose={() => setConcludePopupVisible(false)}
          publish={publish}
        />
        <StartExperimentPopup
          visible={startExperimentPopupVisible}
          onClose={() => setStartExperimentPopupVisible(false)}
          startExperiment={startExperiment}
          orchestration={orchestration}
          cancelExperiment={cancelExperiment}
          isIteration={result && result?.length > 0}
        />
        <SetupExperimentPopup
          visible={iterationExperimentPopupVisible}
          onClose={() => setIterationExperimentPopupVisible(false)}
          orchestration={orchestration}
        />
        <RedirectModal
          visible={redirectPopVisible}
          close={() => setRedirectPopupVisible(false)}
          deployment={deployment}
        />
        <LinearLayout gap="xl">
          <>
            {children}
            <StateIndicator
              isArchived={orchestration.isArchived}
              consolidatedStatus={state.consolidated}
              detailed
            />
            <ReleaseLog deployment={deployment} />
            {!orchestration.isArchived && (
              <>
                <ListView id="publish-actions" showDivider className="secondary-actions">
                  <ListViewItem
                    inset
                    emphasised
                    control="navigational"
                    controlIcon="arrowRight"
                    showDivider
                    title={t('publish.action.view_nudge')}
                    disabled={state.cte === 'draft' || orchestration.isArchived}
                    leadingIcon={cube}
                    onClick={() => setRedirectPopupVisible(true)}
                  />
                  <ListViewItem
                    inset
                    emphasised
                    control="navigational"
                    controlIcon="arrowRight"
                    size="large"
                    showDivider={false}
                    title={t('publish.action.view_report')}
                    disabled={state.prod !== 'experimenting' || isLoadingReport}
                    leadingIcon={document}
                    onClick={gotoReport}
                  />
                </ListView>
                {!isPublishAllowed && (
                  <InlineMessage
                    variant="cautionary"
                    body={t('page.nudge_details.publish_information')}
                  />
                )}
                {showStartExperimentError && (
                  <InlineMessage variant="cautionary" body={t('publish.experiment.action.error')} />
                )}
                {state.next?.action === 'conclude_experiment' && (
                  <Button
                    type="primary"
                    text={t('publish.conclude.action.text')}
                    loading={isRunning}
                    ssrIcon={stop}
                    disabled={!isPublishAllowed}
                    onClick={() => setConcludePopupVisible(true)}
                  />
                )}
                {state.cte === 'draft' && (
                  <Button
                    type="primary"
                    text={t('global.publish_cte')}
                    loading={isRunning}
                    disabled={!isPublishAllowed}
                    ssrIcon={createNew}
                    onClick={publish}
                  />
                )}
                {state.next?.env === 'prod' && state.next.action === 'publish' && (
                  <Button
                    type="primary"
                    text={t('global.publish_production')}
                    loading={isRunning}
                    disabled={!isPublishAllowed}
                    ssrIcon={checkmarkCircle}
                    onClick={publish}
                  />
                )}
                {state.next?.action === 'create_experiment' &&
                  (state.prod === 'draft' ? (
                    <>
                      {location.state === 'updated' && isRunning && (
                        <InlineMessage
                          variant="informative"
                          body={t('page.widget_details.republish_info')}
                          dismissable
                        />
                      )}
                      <Button
                        type="primary"
                        text={t('global.experiment.create')}
                        loading={isRunning}
                        disabled={!isPublishAllowed}
                        onClick={() => {
                          const searchParams = new URLSearchParams();
                          searchParams.set('type', 'visibility');

                          navigateWithSearch(
                            '/create/experiment/:team/:id',
                            {
                              team: orchestration.team,
                              id: orchestration.id,
                            },
                            searchParams
                          );
                        }}
                        ssrIcon={documentAdd}
                      />
                    </>
                  ) : (
                    <>
                      {!isMessageDismissed && (
                        <InlineMessage
                          ssrIcon={informationCircle}
                          variant="informative"
                          title={t('page.nudge_details.experiment_iteration_title')}
                          body={t('page.nudge_details.experiment_iteration_body')}
                          dismissable
                          onDismissClick={() => dispatch(dismissExperimentIterationMessage())}
                        />
                      )}
                      <Button
                        type="primary"
                        text={t('global.experiment.create')}
                        loading={isRunning}
                        disabled={!isPublishAllowed}
                        onClick={() => setIterationExperimentPopupVisible(true)}
                        ssrIcon={documentAdd}
                      />
                    </>
                  ))}
                {state.next?.action === 'experiment' && (
                  <>
                    {location.state === 'updated' && isRunning && (
                      <InlineMessage
                        variant="informative"
                        body={t('page.widget_details.republish_info')}
                        dismissable
                      />
                    )}
                    <Button
                      type="primary"
                      text={t('page.nudge_details.start_review')}
                      loading={isRunning}
                      ssrIcon={play}
                      disabled={!isPublishAllowed}
                      onClick={() => setStartExperimentPopupVisible(true)}
                    />
                  </>
                )}
                {showConfetti && (
                  <EmojiRain
                    emojis={['heart', 'allen-key']}
                    colors={['red', 'pink', 'purple', 'green', 'blue']}
                  />
                )}
              </>
            )}
          </>
        </LinearLayout>
      </>
    )
  );
};

export default OrchestrationActions;
