import React from 'react';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import ErrorImage from '../../assets/error/puffa-error.png';
import LinearLayout from '../../components/LinearLayout';
import { environment } from '../../environment';
import './index.scss';

export type ErrorPageProps = {
  message?: string;
};

const ErrorPage: React.FC<ErrorPageProps> = ({ message }) => {
  const { t } = useTranslation();

  return (
    <LinearLayout className="error-container">
      <img src={ErrorImage} alt="error_image" className="img" />
      <Text headingSize="m" tagName="h1" className="text">
        {t('page.error.title')}
      </Text>
      {message && message !== '' ? (
        <Text headingSize="s" tagName="h1" className="error-msg">
          {t('page.error.msg', { msg: message })}
        </Text>
      ) : null}
      <Text headingSize="xs" tagName="h2" className="text">
        {t('page.error.reload', { email: environment.supportEmail })}
      </Text>
    </LinearLayout>
  );
};

export default ErrorPage;
