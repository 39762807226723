import { useEffect, useRef, useState } from 'react';
import Text from '@ingka/text';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './index.scss';

export type ExpandableTextWrapperProps = {
  children: React.ReactNode;
};

export const ExpandableTextWrapper: React.FC<ExpandableTextWrapperProps> = ({ children }) => {
  const [showFullText, setShowFullText] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (textRef.current) {
      const lineHeight =
        parseInt(window.getComputedStyle(textRef.current).getPropertyValue('line-height')) || 0;
      setIsTruncated(textRef.current.scrollHeight > lineHeight);
    }
  }, [children]);

  return (
    <>
      <div ref={textRef}>
        <Text
          bodySize="l"
          className={classNames('text-wrapper', {
            'show-full-text': showFullText,
          })}
        >
          {children}
        </Text>
      </div>
      {!showFullText && isTruncated && (
        <Text bodySize="l" className="text-action" onClick={() => setShowFullText(true)}>
          {t('global.read_more')}
        </Text>
      )}
      {showFullText && (
        <Text bodySize="l" className="text-action" onClick={() => setShowFullText(false)}>
          {t('global.read_less')}
        </Text>
      )}
    </>
  );
};

export default ExpandableTextWrapper;
