import { Component, ErrorInfo, ReactNode } from 'react';
import { PuffaError } from '../../features/api/puffa-error';
import ErrorPage from '../../pages/ErrorPage';

type ErrorProps = {
  children?: ReactNode;
};

type ErrorState = {
  hasError: boolean;
  error?: Error;
};

class ErrorBoundary extends Component<ErrorProps, ErrorState> {
  public state: ErrorState = {
    hasError: false,
  };

  public static getDerivedStateFromError(error: Error): ErrorState {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    const { hasError, error } = this.state;
    if (hasError) {
      if (error instanceof PuffaError) {
        return <ErrorPage message={error.message} />;
      } else {
        return <ErrorPage />;
      }
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
