import classNames from 'classnames';
import { Header } from '../Header';
import './index.scss';

export type PageLayoutProps = {
  children: React.ReactElement | React.ReactElement[];
  focusPortal?: boolean;
};

const PageLayout: React.FC<PageLayoutProps> = ({ children, focusPortal }) => {
  return (
    <div className={classNames({ 'skapa-focus-portal': focusPortal })}>
      <Header />
      <div className="page-layout">{children}</div>
    </div>
  );
};

export default PageLayout;
