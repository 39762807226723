import Pill from '@ingka/pill';
import arrow from '@ingka/ssr-icon/paths/arrow-right';
import Text from '@ingka/text';
import { toDateString } from '@nfw/utils';
import { Route } from '../../routes/types';
import LinearLayout from '../LinearLayout';
import PuffaHyperlink from '../PuffaHyperlink';
import { StateIndicatorProps } from '../StateIndicator';
import './index.scss';

export type DetailCardProps = {
  id: string;
  status?: React.ReactElement<StateIndicatorProps>;
  title: string;
  team: string;
  info: string;
  creator: string;
  created: number;
  navigateTo: Route;
  navigateToParams?: Record<string, string | number>;
};

const DetailCard: React.FC<DetailCardProps> = ({
  id,
  status,
  title,
  team,
  info,
  creator,
  created,
  navigateTo,
  navigateToParams,
}) => (
  <PuffaHyperlink route={navigateTo} params={navigateToParams} subtle>
    <LinearLayout border padded className="detail-card" gap="xl">
      <LinearLayout horizontal className="space-between">
        {status || <div />}
        {
          <Text tagName="span" bodySize="s">
            #{id}
          </Text>
        }
      </LinearLayout>
      <Text headingSize="s" className="title">
        {title}
      </Text>
      <LinearLayout horizontal className="space-between">
        <LinearLayout>
          <Text headingSize="xs" className="nowrap">
            {team}
          </Text>
          <Text tagName="span" bodySize="s" className="nowrap">
            {creator}
          </Text>
          <Text tagName="span" bodySize="s" className="nowrap">
            {info}
          </Text>
          <Text tagName="span" bodySize="s" className="nowrap">
            {toDateString(created)}
          </Text>
        </LinearLayout>
        <div className="action-container">
          <Pill className="action" iconOnly ssrIcon={arrow} />
        </div>
      </LinearLayout>
    </LinearLayout>
  </PuffaHyperlink>
);

export default DetailCard;
