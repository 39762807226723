import { TeamConfiguration } from '@nfw/contracts/self-service';
import { useTranslation } from 'react-i18next';
import DescribedItem from '../DescribedItem';
import LinearLayout from '../LinearLayout';

export type TeamDescriptionProps = {
  team: TeamConfiguration;
};

const TeamDescription: React.FC<TeamDescriptionProps> = ({ team }) => {
  const { t } = useTranslation();
  const retailUnits = team.retailUnits.map((ru) => t(`global.country.${ru}`)).join(', ');
  const myIdentityGroups = [
    `NFW_${team.id}_ADMIN`.toUpperCase(),
    `NFW_${team.id}_PUBLISH`.toUpperCase(),
    `NFW_${team.id}_EDIT`.toUpperCase(),
  ];

  return (
    <LinearLayout className="details" gap="l">
      <DescribedItem title={t('global.administrator')} text={team.creator} />
      <DescribedItem title={t('global.markets')} text={retailUnits} />
      {team.teamType === 'product' && (
        <DescribedItem title={t('form.team.phrase.label')} text={team.phraseProjectId} />
      )}
      <DescribedItem title={t('global.myIdentity_groups')} textGroup={myIdentityGroups} />
    </LinearLayout>
  );
};

export default TeamDescription;
