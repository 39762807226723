import { DefaultMetric, MeasureType } from '@nfw/contracts/experiment';
import { InputTypes, LayoutConfiguration, RankingItem, ValidationTypes } from '@nfw/form-types';
import { IconTypes, NudgeExperimentType, NudgeExperimentTypes, Widget } from '@nfw/nudge/types';
import { Scope, WinningDirection } from '@nfw/optimizely/types';
import { StoredFeatureOrchestration, StoredWidget } from '@nfw/orchestration-types';
import { TFunction } from 'i18next';
import { NamedMetric } from '../types';

// //////////////////////////////
// Metric texts.
// //////////////////////////////

const getMetricText = (metric: DefaultMetric, t: TFunction) => {
  const { id, direction, measure, scope = 'visitor' } = metric;

  return t('metric.description', {
    id,
    direction: getDirectionText(direction, t),
    measure: getMeasureText(measure, t),
    scope: getScopeText(scope, t),
  });
};

const getMeasureText = (measure: MeasureType, t: TFunction) => {
  switch (measure) {
    case 'total_conversions':
      return t('metric.measure.total_conversions');
    case 'total_revenue':
      return t('metric.measure.total_revenue');
    case 'total_value':
      return t('metric.measure.total_value');
    case 'unique_conversions':
    default:
      return t('metric.measure.unique_conversions');
  }
};

const getDirectionText = (direction: WinningDirection, t: TFunction) => {
  switch (direction) {
    case 'increasing':
      return t('metric.direction.increase');
    case 'decreasing':
    default:
      return t('metric.direction.decrease');
  }
};

const getScopeText = (scope: Scope = 'visitor', t: TFunction) => {
  switch (scope) {
    case 'visitor':
      return t('metric.scope.visitor');
    case 'event':
    default:
      return t('metric.scope.event');
  }
};

// //////////////////////////////
// Experiment layouts.
// //////////////////////////////

/**
 * Get a setup experiment layout.
 *
 * @param widgets - the available widgets.
 * @param t - the translate function.
 *
 * @returns the layout configuration.
 */
export const getSetupExperiment = (
  t: TFunction,
  widgets: StoredWidget[] = []
): LayoutConfiguration => {
  return [
    {
      inputType: InputTypes.PillListbox,
      includePlaceholderOption: false,
      label: t('form.experiment.iteration.type.label'),
      btnLabel: t('form.experiment.iteration.type.button'),
      includeSearch: false,
      name: 'type',
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.general.field.error'),
        },
      ],
      options: [
        {
          name: t('form.experiment.iteration.visibility.text'),
          value: NudgeExperimentTypes.Visibility,
          description: t('form.experiment.iteration.visibility.description'),
        },
        {
          name: t('form.experiment.iteration.design.text'),
          value: NudgeExperimentTypes.Design,
          description: t('form.experiment.iteration.design.description'),
        },
      ],
    },
    {
      inputType: InputTypes.PillListbox,
      includePlaceholderOption: false,
      name: 'widgetId',
      label: t('form.experiment.iteration.widget.label'),
      btnLabel: t('form.experiment.iteration.widget.button'),
      includeSearch: false,
      dependsOn: 'type',
      requiresValueOf: [NudgeExperimentTypes.Design],
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.general.field.error'),
        },
      ],
      options: widgets.map(({ id, name, description }) => ({ name, value: id, description })),
    },
  ];
};

/**
 * The default metrics layout.
 *
 * @param metrics - the default metrics.
 * @param t - the translator function.
 *
 * @returns the layout configuration.
 */
export const getDefaultMetricLayout = (
  metrics: NamedMetric[],
  t: TFunction
): LayoutConfiguration => [
  {
    inputType: InputTypes.FixedText,
    text: 'Organize the default metrics',
    textHeadingSize: 'm',
  },
  {
    inputType: InputTypes.Ranking,
    name: 'metrics',
    label: 'Default metrics',
    items: metrics.map((metric) => ({
      icon: IconTypes.BarChartDowntrend,
      label: metric.name,
      description: getMetricText(metric, t),
      value: { ...metric, isGoverned: true },
      dismissable: true,
    })),
  },
];

/**
 * Get the mettrics configuration layout.
 *
 * @param t - the translation function.
 *
 * @returns the layout configuration.
 */
export const getMetricConfigurationLayout = (t: TFunction): LayoutConfiguration => [
  {
    inputType: InputTypes.Combobox,
    name: 'direction',
    label: t('form.experiment.direction'),
    placeholderText: t('global.choose_option'),
    validations: [
      {
        type: ValidationTypes.Required,
        errorMessage: t('form.general.field.error'),
      },
    ],
    options: [
      {
        name: t('form.experiment.increase'),
        value: 'increasing',
      },
      {
        name: t('form.experiment.decrease'),
        value: 'decreasing',
      },
    ],
  },
  {
    inputType: InputTypes.Combobox,
    name: 'measure',
    label: t('form.experiment.measure'),
    placeholderText: t('global.choose_option'),
    validations: [
      {
        type: ValidationTypes.Required,
        errorMessage: t('form.general.field.error'),
      },
    ],
    options: [
      {
        name: t('form.experiment.unique_conversions'),
        value: 'unique_conversions',
      },
      {
        name: t('form.experiment.total_conversions'),
        value: 'total_conversions',
      },
      {
        name: t('form.experiment.total_revenue'),
        value: 'total_revenue',
      },
      {
        name: t('form.experiment.total_value'),
        value: 'total_value',
      },
    ],
  },
  {
    inputType: InputTypes.Combobox,
    name: 'scope',
    label: t('form.experiment.scope'),
    dependsOn: 'measure',
    requiresValueOf: ['total_revenue', 'total_value'],
    placeholderText: t('global.choose_option'),
    validations: [
      {
        type: ValidationTypes.Required,
        errorMessage: t('form.general.field.error'),
      },
    ],
    options: [
      {
        name: t('form.experiment.per_visitor'),
        value: 'visitor',
      },
      {
        name: t('form.experiment.per_event'),
        value: 'event',
      },
    ],
  },
];

export const getExperimentLayout = (
  type: NudgeExperimentType,
  orchestration: StoredFeatureOrchestration,
  metrics: NamedMetric[],
  variants: Widget[],
  t: TFunction
): LayoutConfiguration => {
  const { id, featureNudge } = orchestration;

  return [
    {
      inputType: InputTypes.HiddenInput,
      name: 'type',
      value: type,
    },
    {
      inputType: InputTypes.HiddenInput,
      name: 'variants',
      dependsOn: 'type',
      requiresValueOf: [NudgeExperimentTypes.Design],
      value: variants,
    },
    {
      inputType: InputTypes.HiddenInput,
      name: 'orchestrationId',
      value: id,
    },
    {
      inputType: InputTypes.HiddenInput,
      name: 'widgetId',
      value: featureNudge.widgetRef,
    },
    {
      inputType: InputTypes.FixedText,
      text: t('metric.ranking.title'),
      textHeadingSize: 'm',
      helpMessage: t('metric.ranking.info'),
    },
    {
      inputType: InputTypes.Ranking,
      name: 'metrics',
      label: t('metric.ranking.label'),
      leadLabel: t('metric.ranking.primary_label'),
      helpMessage: t('metric.ranking.help_message'),
      countString: t('page.experiment.events_selected', { count: metrics.length }),
      enableMoveToTop: true,
      moveToTopLabel: t('metric.ranking.make_primary_label'),
      items: metrics.map((metric) => ({
        icon: IconTypes.ChevronUpChevronDownSmall,
        label: metric.name,
        description: getMetricText(metric, t),
        value: metric,
        tag: metric.isGoverned ? t('metric.ranking.preloaded') : undefined,
        dismissable: !metric.isGoverned,
      })),
    },
  ];
};

export const getHealthMetricsItems = (id: string, t: TFunction) => [
  {
    icon: IconTypes.BarChartUptrend,
    label: t('metric.health.cls_label'),
    description: t('metric.health.cls_description'),
    dismissable: false,
    value: 'cls',
    tag: t('metric.ranking.preloaded'),
  },
  {
    icon: IconTypes.BarChartUptrend,
    label: t('metric.health.fcp_label'),
    description: t('metric.health.fcp_description'),
    dismissable: false,
    value: 'fcp',
    tag: t('metric.ranking.preloaded'),
  },
  {
    icon: IconTypes.BarChartUptrend,
    label: t('metric.health.lcp_label'),
    description: t('metric.health.lcp_description'),
    dismissable: false,
    value: 'lcp',
    tag: t('metric.ranking.preloaded'),
  },
  {
    icon: IconTypes.BarChartUptrend,
    label: t('metric.health.inp_label'),
    description: t('metric.health.inp_description'),
    dismissable: false,
    value: 'inp',
    tag: t('metric.ranking.preloaded'),
  },
  {
    icon: IconTypes.BarChartUptrend,
    label: `cweb_nudge_${id}_click`,
    description: t('metric.health.click_description'),
    dismissable: false,
    value: 'fid',
    tag: t('metric.ranking.preloaded'),
    noOverflow: false,
  },
  {
    icon: IconTypes.BarChartUptrend,
    label: `cweb_nudge_${id}_dismiss`,
    description: t('metric.health.dismiss_description'),
    dismissable: false,
    value: 'fid',
    tag: t('metric.ranking.preloaded'),
    noOverflow: false,
  },
  {
    icon: IconTypes.BarChartUptrend,
    label: `cweb_nudge_${id}_show`,
    description: t('metric.health.show_description'),
    dismissable: false,
    value: 'fid',
    tag: t('metric.ranking.preloaded'),
    noOverflow: false,
  },
];

export const getHealthMetricsLayout = (id: string, t: TFunction): LayoutConfiguration => [
  {
    inputType: InputTypes.Ranking,
    name: 'healthMetrics',
    label: t('metric.health.label'),
    helpMessage: t('metric.health.help'),
    draggable: false,
    items: getHealthMetricsItems(id, t),
  },
];

export const getMetricsPreviewLayout = (
  t: TFunction,
  metrics: NamedMetric[],
  id: string
): LayoutConfiguration => {
  const allMetrics: RankingItem[] = [];
  if (metrics) {
    allMetrics.push(
      ...metrics.map((metric) => ({
        icon: IconTypes.BarChartUptrend,
        label: metric.name,
        description: getMetricText(metric, t),
        value: metric,
        dismissable: false,
        tag: metric.isGoverned ? t('metric.ranking.preloaded') : undefined,
      }))
    );
  }

  allMetrics.push(...getHealthMetricsItems(id, t));

  return [
    {
      inputType: InputTypes.Ranking,
      name: 'healthMetrics',
      label: t('metric.preview.label'),
      draggable: false,
      leadLabel: t('metric.ranking.primary_label'),
      items: allMetrics,
      showEventsSelected: false,
    },
  ];
};
