import { useMemo } from 'react';
import { Environment, PublishState } from '@nfw/deployment-types';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { OverviewOrchestration } from '../../../features/app/types';
import GenericFilter, {
  MarketFilter,
  NamedFilter,
  SearchFilterFunction,
  Sort,
  SwitchFilter,
} from '../GenericFilter';

const translatedStatuses = (env: Environment, status: PublishState, t: TFunction) => {
  switch (status) {
    case 'published':
      return env === 'prod' ? t('global.state.prod.live') : t('global.state.cte.live');
    case 'experiment_created':
      return t('global.state.prod.experiment_created');
    case 'experimenting':
      return t('global.state.prod.experimenting');
    case 'draft':
    default:
      return t('global.state.draft');
  }
};

const searchFilter: SearchFilterFunction<OverviewOrchestration> = (searchValue, value) =>
  value
    ? value.id.toLowerCase().includes(searchValue) || value.name.toLowerCase().includes(searchValue)
    : false;

const getNamedFilters = (t: TFunction): NamedFilter<OverviewOrchestration>[] => [
  {
    id: 'status',
    label: t('global.status'),
    distinctValues: (value: OverviewOrchestration) => {
      const { env, status } = value.consolidatedStatus;
      return translatedStatuses(env, status, t);
    },
    filter: (values: string[], value: OverviewOrchestration) => {
      const {
        isArchived,
        consolidatedStatus: { env, status },
      } = value;

      return isArchived
        ? values.includes(t('global.state.archived'))
        : values.includes(translatedStatuses(env, status, t));
    },
  },
  {
    id: 'creator',
    label: t('global.created_by'),
    distinctValues: (value: OverviewOrchestration) => value.creatorName,
    filter: (values: string[], value: OverviewOrchestration) => values.includes(value.creatorName),
  },
  {
    id: 'team',
    label: t('global.team'),
    distinctValues: (value: OverviewOrchestration) => value.teamName,
    filter: (values: string[], value: OverviewOrchestration) => values.includes(value.teamName),
  },
  {
    id: 'namespace',
    label: t('global.page'),
    distinctValues: (value: OverviewOrchestration) => value.namespace,
    filter: (values: string[], value: OverviewOrchestration) => values.includes(value.namespace),
  },
];

const getSwitchFilters = (t: TFunction): SwitchFilter<OverviewOrchestration>[] => [
  {
    id: 'showArchived',
    label: t('page.home.filter.include_archived'),
    filter: (values: string[], value: OverviewOrchestration) =>
      values.includes('false') ? !value.isArchived : true,
    value: 'false',
  },
];

const getMarketFilter = (t: TFunction): MarketFilter<OverviewOrchestration> => ({
  id: 'market',
  label: t('global.markets'),
  distinctValues: (value: OverviewOrchestration) => value.retailUnits,
  filter: (values: string[], value: OverviewOrchestration) =>
    value.retailUnits.some((ru) => values.includes(ru)),
});

const getSort = (t: TFunction): Sort<OverviewOrchestration>[] => [
  {
    id: 'name',
    label: t('global.name'),
    sort: (values: OverviewOrchestration[]) =>
      values.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
  },
  {
    id: 'newest',
    label: t('global.sort.newest'),
    sort: (values: OverviewOrchestration[]) =>
      values.sort((a, b) => (a.created < b.created ? 1 : -1)),
  },
  {
    id: 'oldest',
    label: t('global.sort.oldest'),
    sort: (values: OverviewOrchestration[]) =>
      values.sort((a, b) => (a.created > b.created ? 1 : -1)),
  },
  {
    id: 'recently_edited',
    label: t('global.last_edited'),
    sort: (values: OverviewOrchestration[]) =>
      values.sort((a, b) => (a.modified < b.modified ? 1 : -1)),
  },
];

const OrchestrationFilter: React.FC<{
  data: OverviewOrchestration[];
  onFilterChange: (data: OverviewOrchestration[]) => void;
  layoutIndex: number;
}> = ({ data, onFilterChange }) => {
  const { t } = useTranslation();
  const namedFilters = useMemo(() => getNamedFilters(t), [t]);
  const marketFilter = useMemo(() => getMarketFilter(t), [t]);
  const switchFilters = useMemo(() => getSwitchFilters(t), [t]);
  const sort = useMemo(() => getSort(t), [t]);

  return (
    <GenericFilter
      type="feature"
      data={data}
      onFilterChange={onFilterChange}
      searchFilter={searchFilter}
      namedFilters={namedFilters}
      marketFilter={marketFilter}
      switchFilters={switchFilters}
      sort={sort}
      searchLabel={t('global.filter.placeholder')}
    />
  );
};

export default OrchestrationFilter;
