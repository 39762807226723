/**
 * Regexp used when replacing text in a string.
 */
const TextReplaceRegexp = /{{(\w+)}}/g;

/**
 * Regexp used to replace values in path.
 */
const UrlReplaceRegexp = /:(\w+)/g;

/**
 * Replace text in a template string.
 *
 * @param template - the text template.
 * @param replacements - a dictionary of the replacements to do in the text.
 * @param regexp - the regular expression.
 *
 * @returns the text with replaced texts.
 */
export const replace = (
  template: string,
  replacements: Record<string, string | number>,
  regexp: RegExp
) =>
  template.replace(regexp, (sub, args) =>
    Object.prototype.hasOwnProperty.call(replacements, args) ? replacements[args].toString() : sub
  );

/**
 * Populate a templated text \{\{value\}\} with values.
 *
 * @param template - the text template.
 * @param values - the values to insert.
 *
 * @returns the populated path with the values.
 */
export const toText = (template: string, values: Record<string, string | number>) =>
  replace(template, values, TextReplaceRegexp);

/**
 * Populate a templated path /:a/:b with values.
 *
 * @param path - the path base.
 * @param values - the values to insert.
 *
 * @returns the populated path with the values.
 */
export const toPath = (path: string, values: Record<string, string | number>) =>
  replace(path, values, UrlReplaceRegexp);
