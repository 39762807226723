/**
 * PuffaError is a custom error class that extends the built-in `Error` class.
 * It provides a `message` property and a `getMessage` method.
 */
export class PuffaError extends Error {
  /**
   * The error message.
   */
  public readonly message: string;

  /**
   * Constructor.
   *
   * @param message - the message.
   */
  public constructor(message = '') {
    super(message);
    this.message = message;
  }

  /**
   * Get the error message.
   *
   * @returns the message.
   */
  public getMessage(): string {
    return this.message;
  }
}
