import { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultEvent, DefaultMetric, Metrics } from '@nfw/contracts/experiment';
import { Namespace } from '@nfw/ikea/utils';
import { Widget } from '@nfw/nudge/types';
import { StoredFeatureOrchestration, StoredWidget } from '@nfw/orchestration-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import { PuffaError } from '../api/puffa-error';
import { queueToast } from '../app/actions';
import { selectWidgets } from '../widgets/selectors';
import {
  experimentApi,
  useCreateExperimentMutation,
  useGetDefaultEventsQuery,
  useGetExperimentQuery,
  useCreateMetricsMutation,
  useLazyGetMetricsQuery,
  useGetDefaultMetricsQuery,
  useGetExperimentResultRecordsQuery,
  useDeleteExperimentMutation,
} from './api';
import {
  getDefaultMetricLayout,
  getExperimentLayout,
  getHealthMetricsLayout,
  getMetricsPreviewLayout,
  getMetricConfigurationLayout,
  getSetupExperiment,
} from './forms';
import { ExperimentMeta, NamedMetric } from './types';

/**
 * Hook for getting a named metric.
 *
 * @param events - the events.
 * @param metrics - the metrics to name.
 *
 * @returns the named metrics.
 */
const useNamedMetric = (events: DefaultEvent[], metrics: DefaultMetric[]): NamedMetric[] =>
  useMemo(
    () =>
      Array.isArray(metrics)
        ? metrics.map((metric) => {
            const event = events.find((event) => event.id === metric.id);
            const name = event?.name ?? `${metric.id}`;

            return { ...metric, name };
          })
        : [],
    [events, metrics]
  );

/**
 * Get the setup iteration experiment layout.
 *
 * @param widgets - the candidate widgets.
 *
 * @returns the layout.
 */
export const useSetupIterationExperimentLayout = (widgets?: StoredWidget[]) => {
  const { t } = useTranslation();

  return useMemo(() => getSetupExperiment(t, widgets), [t, widgets]);
};

/**
 * Hook for getting the experiment layout.
 *
 * @param meta - the experiment meta.
 * @param orchestration - the orchestration.
 * @param events - the default events.
 * @param metrics - the experiment metrics.
 *
 * @returns the experiment layout.
 */
export const useExperimentLayout = (
  meta: ExperimentMeta,
  orchestration: StoredFeatureOrchestration,
  events: DefaultEvent[],
  metrics: DefaultMetric[]
) => {
  const { type, widgetId } = meta;
  const { t } = useTranslation();
  const namedMetrics = useNamedMetric(events, metrics);
  const widgets = useSelector(selectWidgets);
  const widgetVariants = useMemo(() => {
    let variants: Widget[] = [];
    if (type === 'design') {
      variants = widgets.filter(({ id }) => id === widgetId);
    }
    return variants;
  }, [type, widgets, widgetId]);

  return useMemo(
    () => getExperimentLayout(type, orchestration, namedMetrics, widgetVariants, t),
    [type, orchestration, namedMetrics, widgetVariants, t]
  );
};

/**
 * Hook for getting the health metrics placeholders.
 *
 * @param id - the id of the nudge.
 *
 * @returns the health metrics layout.
 */
export const useHealthMetricsLayout = (id: string) => {
  const { t } = useTranslation();

  return useMemo(() => getHealthMetricsLayout(id, t), [id, t]);
};

/**
 * Hook for getting the health metrics placeholders.
 *
 * @param metrics - the metrics.
 * @param id - the id of the nudge.
 *
 * @returns the health metrics layout.
 */
export const useMetricsPreviewLayout = (metrics, id: string) => {
  const { t } = useTranslation();

  return useMemo(() => getMetricsPreviewLayout(t, metrics, id), [t, metrics, id]);
};

/**
 * Hook for getting the default metrics layout.
 *
 * @param metrics - the metrics.
 *
 * @returns the default metrics layout.
 */
export const useDefaultMetricsLayout = (events: DefaultEvent[], metrics: DefaultMetric[]) => {
  const { t } = useTranslation();
  const namedMetrics = useNamedMetric(events, metrics);

  return useMemo(() => getDefaultMetricLayout(namedMetrics, t), [namedMetrics, t]);
};

/**
 * Hook for getting the metric configuration.
 *
 * @returns the metric configuration layout.
 */
export const useMetricConfigurationLayout = () => {
  const { t } = useTranslation();

  return useMemo(() => getMetricConfigurationLayout(t), [t]);
};

/**
 * The report result.
 */
export type ReportResult = {
  isLoading: boolean;
  gotoReport: () => void;
};

/**
 * Hook for redirecting to the report.
 *
 * @param id - the orchestration id.
 *
 * @returns the report result.
 */
export const useReport = (id: string): ReportResult => {
  const [isLoading, setLoading] = useState(false);
  const [getUrl] = experimentApi.endpoints.getExperimentReportUrl.useLazyQuery();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const gotoReport = useCallback(() => {
    setLoading(true);
    getUrl({ id })
      .unwrap()
      .then(({ url }) => window.open(url, '_blank'))
      .catch(() => {
        dispatch(queueToast({ msg: t('global.error.generic') }));
      })
      .finally(() => setLoading(false));
  }, [id, getUrl, dispatch, t]);

  return { isLoading, gotoReport };
};

/**
 * Hook for fetching the default metrics events and saving an experiment.
 *
 * @param load - flag for if the events should load.
 * @param onSave - a save callback.
 */
export const useExperimentation = (onSaved: (success: boolean) => void) => {
  const {
    data: events,
    isError: isMetricsError,
    isLoading: isLoadingMetrics,
    refetch,
  } = useGetDefaultEventsQuery();
  const [save, saveResult] = useCreateExperimentMutation();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { isSuccess, isError, reset } = saveResult;

    if (isSuccess || isError) {
      if (isError) {
        dispatch(queueToast({ msg: t('global.error.generic') }));
      }
      onSaved(isSuccess);
      reset();
    }
  }, [saveResult, onSaved, dispatch, t]);

  return {
    events,
    isMetricsError,
    isLoadingMetrics,
    refetch,
    save,
    isSaving: saveResult.isLoading,
  };
};

/**
 * Hook for deleting experiment.
 */
export const useDeleteExperiment = () => {
  const [deleteExperiment, deleteExperimentResults] = useDeleteExperimentMutation();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const { isError } = deleteExperimentResults;

    if (isError) {
      dispatch(queueToast({ msg: t('global.error.generic') }));
    }
  }, [deleteExperimentResults, dispatch, t]);

  return { deleteExperiment };
};

/**
 * Hook for getting an experiment.
 *
 * @param id - the experiment id.
 * @param team - the team id.
 * @param skip - flag for if the query should start.
 *
 * @returns the experiment and loading state.
 *
 * @throws error when there is an error fetching the metrics.
 */
export const useGetExperiment = (id: string, team: string, skip = false) => {
  const { data: experiment, isError, isLoading } = useGetExperimentQuery({ id, team }, { skip });

  useEffect(() => {
    if (isError) {
      throw new PuffaError();
    }
  }, [isError]);

  return { experiment, isLoading };
};

/**
 * Hook for getting the default metrics.
 *
 * @param namespace - the namespace of the experiment.
 * @param skip - flag for if the query should be skipped.
 *
 * @returns the metrics and loading state.
 *
 * @throws error when there is an error fetching the metrics.
 */
export const useGetDefaultMetrics = (namespace?: string, skip = false) => {
  const { data, isLoading, isError } = useGetDefaultMetricsQuery(
    { namespace: namespace as Namespace },
    { skip: skip || !namespace }
  );

  useEffect(() => {
    if (isError) {
      throw new PuffaError();
    }
  }, [isError]);

  const metrics = data ? data['metrics'] : [];

  return { isLoading, metrics };
};

/**
 * Hook for getting the default metrics.
 *
 * @param namespace - the namespace to get default metrics for.
 *
 * @returns the default metrics and loading state.
 *
 * @throws error when there is an error fetching the metrics.
 */
export const useDefaultMetrics = (namespace?: Namespace) => {
  const [query, result] = useLazyGetMetricsQuery();

  useEffect(() => {
    if (namespace) {
      query({ namespace });
    }
  }, [query, namespace]);

  useEffect(() => {
    if (result.isError) {
      throw new PuffaError();
    }
  }, [result]);

  return { isLoading: result.isLoading, defaultMetrics: result.data?.metrics };
};

/**
 * Hook for updating the default optimizely events.
 *
 * @param namespace - the namespace to save metrics for.
 */
export const useUpdateDefaultMetrics = (namespace?: Namespace) => {
  const {
    data: events,
    isError: isMetricsError,
    isLoading: isLoadingEvents,
    refetch,
  } = useGetDefaultEventsQuery();
  const [save, saveResult] = useCreateMetricsMutation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const { isSuccess, isError, reset } = saveResult;

    if (isSuccess || isError) {
      if (isSuccess) {
        dispatch(queueToast({ msg: t('page.events.success') }));
      } else {
        dispatch(queueToast({ msg: t('page.events.error') }));
      }
      reset();
    }
  }, [saveResult, dispatch, t]);

  const internalSave = useCallback(
    (metrics: Metrics) => {
      if (namespace) {
        save({ namespace, ...metrics });
      }
    },
    [save, namespace]
  );

  return {
    events,
    isMetricsError,
    isLoadingEvents,
    refetch,
    save: internalSave,
    isSaving: saveResult.isLoading,
  };
};

/**
 * Hook for getting the experiment results.
 *
 * @param id - the id of the feature to get the results for, omit to fetch for all features.
 * @param skip - flag for if the query should be skipped.
 *
 * @returns the experiment results and loading state.
 *
 * @throws error when there is an error fetching the metrics.
 */
export const useGetExperimentResults = (id?: string, skip?: boolean) => {
  const { data: result, isError, isLoading } = useGetExperimentResultRecordsQuery({ id }, { skip });

  useEffect(() => {
    if (isError) {
      throw new PuffaError();
    }
  }, [isError]);

  return { result, isLoading };
};
