import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { resourcelockApi } from './api';

const createLockResponseSelector = createSelector(
  (lockId: string) => lockId,
  (lockId) => resourcelockApi.endpoints.acquire.select(lockId)
);

const selectLockResponse = createSelector(
  [(state: RootState) => state, (_, lockId: string) => createLockResponseSelector(lockId)],
  (state, selector) => selector(state)
);

export const selectIsError = createSelector(selectLockResponse, (response) => response.isError);
export const selectLock = createSelector(selectLockResponse, (response) => response.data);
