import { useEffect, useState } from 'react';
import AspectRatioBox, { AspectRatioBoxRatio } from '@ingka/aspect-ratio-box';
import Image from '@ingka/image';
import classNames from 'classnames';
import './index.scss';

export type ImageLoaderProps = {
  /** The skapa prefix. */
  prefix?: string;
  /** The class name applied to the image. */
  className?: string;
  /** The aspect ratio of the image. */
  ratio?: AspectRatioBoxRatio;
  /** The image source. */
  src: string;
  /** The alt text. Default empty string for decorative images */
  altText?: string;
  /** The tag type to use. */
  tagType?: 'div' | 'span';
  /** Callback when image is loaded. */
  onLoaded?: () => void;
};

type State = 'loading' | 'ready' | 'error';

export const ImageLoader: React.FC<ImageLoaderProps> = ({
  prefix,
  className,
  ratio = 'standard',
  tagType = 'span',
  src,
  altText = '',
  onLoaded,
}) => {
  const [state, setState] = useState<State>('loading');
  const [imageSrc, setImageSrc] = useState<string | undefined>();

  useEffect(() => {
    if (src) {
      setState('loading');
    } else {
      setState('error');
    }
    setImageSrc(src);
  }, [src]);

  useEffect(() => {
    if (state === 'ready') {
      onLoaded?.();
    }
  }, [state, onLoaded]);

  return (
    <AspectRatioBox prefix={prefix} ratio={ratio} className="nfw-imageloader" tagType={tagType}>
      {imageSrc && (
        <Image
          prefix={prefix}
          src={imageSrc}
          className={classNames(className, 'child', {
            show: state !== 'loading',
          })}
          fallback={state === 'error'}
          onLoad={() => setState('ready')}
          onError={() => setState('error')}
          alt={altText}
        />
      )}
    </AspectRatioBox>
  );
};
