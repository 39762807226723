import { useEffect, useState } from 'react';
import { ErrorType, ErrorTypes, isErrorBody } from '@nfw/contracts/self-service';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { PuffaError } from './puffa-error';

/**
 * Hook for using an api error.
 *
 * @param error - the error received.
 *
 * @returns the error type and the status when a proper error is received.
 */
export const useApiError = (error?: FetchBaseQueryError | SerializedError) => {
  const [type, setType] = useState<ErrorType>();
  const [status, setStatus] = useState<number>();

  useEffect(() => {
    if (error) {
      let errorType: ErrorType = ErrorTypes.App;
      let statusCode = 500;

      if ('status' in error) {
        const data = error.data;

        if (isErrorBody(data)) {
          const { type, status } = data;

          errorType = type;
          statusCode = status;
        }
      }
      setStatus(statusCode);
      setType(errorType);
    }
  }, [error]);

  return { type, status };
};

/**
 * Hook for using an error limit guard.
 *
 * @param isError - wether or not the request generated an error.
 * @param limit - the limit of the allowed error count.
 */
export const useErrorLimitGuard = (isError: boolean, limit = 3) => {
  const [failCount, setFailCount] = useState(0);

  useEffect(() => {
    if (isError) {
      setFailCount((prev) => prev + 1);
    }
  }, [isError]);

  useEffect(() => {
    if (failCount >= limit) {
      throw new PuffaError();
    }
  }, [failCount, limit]);
};
