import {
  InputTypes,
  LayoutConfiguration,
  PillListboxOption,
  ValidationTypes,
} from '@nfw/form-types';
import { IconTypes } from '@nfw/nudge/types';
import { TFunction } from 'i18next';

export const getValueStatementLayout = (
  t: TFunction,
  translationOptions: PillListboxOption[]
): LayoutConfiguration => [
  {
    inputType: InputTypes.PillListbox,
    name: 'statement',
    btnLabel: t('form.widget.select_key'),
    options: translationOptions,
    label: t('form.widget.statement.label'),
    includeSearch: true,
    validations: [
      { type: ValidationTypes.Required, errorMessage: t('form.widget.statement.error') },
    ],
  },
  {
    inputType: InputTypes.PillListbox,
    name: 'icon',
    label: t('form.widget.icon.label_optional'),
    options: Object.values(IconTypes).map((value) => ({ name: value, value, icon: value })),
    includeSearch: true,
    btnLabel: t('form.widget.icon.btn_label'),
  },
  {
    inputType: InputTypes.PillListbox,
    name: 'mood',
    label: t('form.widget.mood.label'),
    includeSearch: false,
    options: [
      { name: t('form.widget.mood.positive'), value: 'positive' },
      { name: t('form.widget.mood.negative'), value: 'negative' },
    ],
    btnLabel: t('form.widget.mood.btn_label'),
    includePlaceholderOption: true,
    placeholderOptionName: t('form.widget.mood.neutral'),
  },
];
