import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import resources from './resources/en-XY.json';
import { LanguageOption } from './types';

export const SupportedLngs: LanguageOption[] = [
  { locale: 'en', text: 'English' },
  { locale: 'es', text: 'Español' },
  { locale: 'fr', text: 'Français' },
  { locale: 'de', text: 'Deutsch' },
  { locale: 'sv', text: 'Svenska' },
  { locale: 'nl', text: 'Nederlands' },
];

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(ChainedBackend)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en-XY',
    load: 'languageOnly',
    backend: {
      backends: [HttpBackend, resourcesToBackend({ 'en-XY': { translation: resources } })],
      backendOptions: [
        {
          loadPath: '/api/i18n/{{ns}}/{{lng}}',
        },
      ],
    },
  });

export default i18next;
