import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ExperimentRequest } from '@nfw/contracts/experiment';
import { getTeamFeatures } from '@nfw/contracts/self-service';
import { Deployment, EnvironmentState, getEnvironmentState } from '@nfw/deployment-types';
import { HierarchyNode, LayoutConfiguration } from '@nfw/form-types';
import { RetailUnit } from '@nfw/ikea/retail';
import { IkeaStore } from '@nfw/ikea/store';
import {
  FeatureOrchestration,
  StoredFeatureOrchestration,
  StoredNudgeGroup,
  StoredWidget,
} from '@nfw/orchestration-types';
import { toMs } from '@nfw/utils';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '../../routes';
import { useAppDispatch, useMemoizedSelector } from '../../store';
import { useErrorLimitGuard } from '../api/hooks';
import { PuffaError } from '../api/puffa-error';
import { queueToast } from '../app/actions';
import { useCreateExperimentMutation } from '../experiments/api';
import { setPublishing } from '../orchestrations/actions';
import { selectPublishing, selectTeamOrchestrations } from '../orchestrations/selectors';
import { selectTeam } from '../teams/selectors';
import {
  orchestrationApi,
  useArchiveOrchestrationMutation,
  useCreateOrchestrationMutation,
  useGetCollisionsQuery,
  useGetDeploymentQuery,
  useGetDeploymentsQuery,
  useGetNudgeGroupMetricQuery,
  useGetNudgeGroupOrchestrationsQuery,
  useGetNudgeMetricQuery,
  useGetNudgeOfFameMetricsQuery,
  useGetOrchestrationQuery,
  useGetOrchestrationsQuery,
  useGetRootNodeQuery,
  useGetStoresQuery,
  usePublishOrchestrationMutation,
  useRepublishOrchestrationMutation,
  useUpdateOrchestrationMutation,
} from './api';
import {
  getOrchestrationLayout,
  getMarketConfigurationLayout,
  getWidgetRefLayout,
  getFrameworkConfigurationLayout,
} from './forms';
import { getNudgeLayoutConfiguration } from './forms/Nudge';

/**
 * Get the market configuration layout.
 *
 * @returns the layout configuration.
 */
export const useMarketConfigurationLayout = () => {
  const { t } = useTranslation();

  return useMemo(() => getMarketConfigurationLayout(t), [t]);
};

/**
 * Get the framework configuration layout.
 *
 * @returns the layout configuration.
 */
export const useFrameworkConfigurationLayout = () => {
  const { t } = useTranslation();

  return useMemo(() => getFrameworkConfigurationLayout(t), [t]);
};

/**
 * Get the layout configuration for creating a nudge.
 *
 * @param widget - the widget part of the nudge.
 * @param widgets - the team widgets.
 * @param team - the team.
 * @param defaultId - the default orchestration id, if any.
 *
 * @returns the layout or undefined.
 */
export const useNudgeLayout = (
  widget: StoredWidget,
  widgets: StoredWidget[],
  team: string,
  defaultId?: string
) => {
  const { t } = useTranslation();
  const orchestrations = useMemoizedSelector(selectTeamOrchestrations, team);
  const teamConfig = useMemoizedSelector(selectTeam, team);

  const { triggers, repeat } = getTeamFeatures(teamConfig);

  return useMemo(
    () =>
      getNudgeLayoutConfiguration(widget, widgets, orchestrations, triggers, repeat, t, defaultId),
    [widget, widgets, orchestrations, triggers, repeat, t, defaultId]
  );
};

/**
 * Get the create feature configuration.
 *
 * @param team - the team.
 * @param retailUnits - the retail units.
 * @param editMode - flag for if the layout is in edit mode.
 * @param stores - the IKEA stores.
 * @param nudgeGroups - the nudge groups.
 * @param rootNode - the root node of the ikea catalogue structure.
 * @param isLoading - flag for if the data is loading.
 *
 * @returns the create feature layout configuration.
 */
export const useOrchestrationLayout = (
  team: string,
  retailUnits: RetailUnit[],
  editMode: boolean,
  stores: IkeaStore[],
  nudgeGroups?: StoredNudgeGroup[],
  rootNode?: HierarchyNode,
  isLoading?: boolean
): LayoutConfiguration => {
  const { t } = useTranslation();
  const teamConfig = useMemoizedSelector(selectTeam, team);

  const { conditions, namespaces } = getTeamFeatures(teamConfig);

  return useMemo(
    () =>
      getOrchestrationLayout(
        retailUnits,
        editMode,
        t,
        conditions,
        stores,
        namespaces,
        nudgeGroups,
        rootNode,
        isLoading
      ),
    [retailUnits, editMode, t, stores, conditions, namespaces, nudgeGroups, rootNode, isLoading]
  );
};

/**
 * Get the root node of the ikea catalog.
 *
 * @param sspLanguage - the language to show the root node in.
 * @param retailUnits - the retail units to get the root node for.
 *
 * @returns the root node.
 */
export const useGetRootNode = (
  sspLanguage: string,
  retailUnits: string[] = [],
  exceptionType?: string
) => {
  const [rootCopy, setRootCopy] = useState<HierarchyNode>();

  const { data, isError, isLoading } = useGetRootNodeQuery(
    { retailUnits: retailUnits, sspLanguage },
    { skip: retailUnits.length === 0 || !(exceptionType === 'breadcrumb') }
  );

  useEffect(() => {
    if (isError) {
      throw new PuffaError();
    }
  }, [isError]);

  useEffect(() => {
    if (data) {
      // RTK Query gives immutable data so we need to clone it to be able to modify it.
      setRootCopy(cloneDeep(data));
    }
  }, [data]);

  return { rootCopy, isLoading };
};

/**
 * Get the Ikea stores.
 *
 * @param retailUnits - the retail units to filter the stores by.
 *
 * @returns an object with the stores and loading state.
 */
export const useGetStores = (retailUnits: string[] = []) => {
  const { data, isError, isLoading } = useGetStoresQuery({ retailUnits });

  useEffect(() => {
    if (isError) {
      throw new PuffaError();
    }
  }, [isError]);

  return { stores: data, isLoading };
};

/**
 * Get the widget ref layout.
 *
 * @param widgets - the widgets.
 * @param preSetWidgetId - the widget id to pre set.
 *
 * @returns the widget ref layout.
 */
export const useWidgetRefLayout = (widgets: StoredWidget[], preSetWidgetId?: string) => {
  const { t } = useTranslation();

  return useMemo(
    () => getWidgetRefLayout(widgets, t, preSetWidgetId),
    [widgets, t, preSetWidgetId]
  );
};

export type OrchestrationResult = {
  orchestration: StoredFeatureOrchestration | undefined;
  isRunning: boolean;
  archive: () => void;
};

/**
 * Hook for fetching and archiving an orchestration feature.
 *
 * @param id - the id of the feature.
 * @param team - the team the feature belongs to.
 */
export const useOrchestration = (id: string, team: string): OrchestrationResult => {
  const isRunning = useMemoizedSelector(selectPublishing, id);
  const [isArchiving, setArchiving] = useState(false);

  const { data, isError, refetch } = useGetOrchestrationQuery({ id });
  const [archive, archiveResult] = useArchiveOrchestrationMutation();

  const dispatch = useAppDispatch();
  const { navigate } = useNavigate();
  const { t } = useTranslation();

  const archiveInternal = useCallback(() => {
    archive({ id, team });
    setArchiving(true);
    dispatch(setPublishing(true, id));
  }, [id, team, archive, dispatch]);

  useEffect(() => {
    if (isArchiving && !isRunning) {
      setArchiving(false);
      refetch();
    }
  }, [isArchiving, isRunning, refetch]);

  useEffect(() => {
    const { isError, isSuccess, reset } = archiveResult;

    if (isError || isSuccess) {
      if (isError) {
        dispatch(queueToast({ msg: t('global.error.generic') }));
        dispatch(setPublishing(false, id));
      }
      reset();
    }
  }, [id, archiveResult, dispatch, t]);

  useEffect(() => {
    if (isError) {
      navigate('/home');
      dispatch(queueToast({ msg: t('global.error.generic') }));
    }
  }, [isError, dispatch, navigate, t]);

  return {
    orchestration: data,
    archive: archiveInternal,
    isRunning,
  };
};

export type DeployResult = {
  /**
   * The current deployment.
   */
  deployment: Deployment | undefined;
  /**
   * A deployment is running.
   */
  isRunning: boolean;
  /**
   * The environment state.
   */
  state: EnvironmentState | undefined;
  /**
   * Publish function.
   */
  publish: () => void;
  /**
   * Refresh the environment state.
   */
  refresh: () => void;
};

/**
 * Hook for publishing a nudge.
 *
 * @param orchestration - the orchestration.
 *
 * @returns the deploy result.
 */
export const useDeploy = (orchestration: StoredFeatureOrchestration): DeployResult => {
  const isRunning = useMemoizedSelector(selectPublishing, orchestration.id);
  const prevIsRunning = useRef<boolean>(isRunning);
  const [state, setState] = useState<EnvironmentState>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [publish, publishResult] = usePublishOrchestrationMutation();
  useErrorLimitGuard(publishResult.isError);

  const {
    data: deployment,
    refetch,
    isError: isGetDeploymentError,
  } = useGetDeploymentQuery(
    { id: orchestration.id },
    { pollingInterval: isRunning ? toMs(10, 'sec') : 0 }
  );

  useEffect(() => {
    if (prevIsRunning.current && !isRunning) {
      dispatch(
        orchestrationApi.util.invalidateTags([
          { type: 'deployment', id: orchestration.id },
          { type: 'feature', id: orchestration.id },
          { type: 'experiment-results' },
        ])
      );
    }
    prevIsRunning.current = isRunning;
  }, [dispatch, isRunning, orchestration.id]);

  useEffect(() => {
    if (deployment) {
      const { id, isPublishing } = deployment;
      dispatch(setPublishing(isPublishing, id));
      setState(getEnvironmentState(deployment));
    }
  }, [deployment, dispatch]);

  const publishInternal = useCallback(() => {
    const { id, team, isArchived } = orchestration;

    if (!isArchived) {
      publish({ id, team });
      dispatch(setPublishing(true, id));
    }
  }, [orchestration, publish, dispatch]);

  useEffect(() => {
    const { isError, isSuccess, reset } = publishResult;

    if (isError || isSuccess) {
      if (isError) {
        dispatch(queueToast({ msg: t('global.error.publish') }));
        dispatch(setPublishing(false, orchestration.id));
      }
      reset();
    }
  }, [orchestration, publishResult, dispatch, t]);

  useEffect(() => {
    if (isGetDeploymentError) {
      throw new PuffaError();
    }
  }, [isGetDeploymentError, dispatch, t]);

  return {
    isRunning,
    deployment,
    state,
    publish: publishInternal,
    refresh: refetch,
  };
};

/**
 * Hook for creating an orchestration.
 * User is navigated to details page when a successful save is done.
 *
 * If an id is added in the route a default nudge will be loaded. This will
 * be treated as a copy of a nudge.
 *
 * @param team - the team for orchestration is created for.
 *
 * @throws PuffaError if the update fails more than 2 times.
 */
export const useCreate = (team: string) => {
  const [save, result] = useCreateOrchestrationMutation();
  const { navigate, back, params } = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useErrorLimitGuard(result.isError);

  const {
    data: defaultOrchestration,
    isLoading: isLoadingOrchestration,
    isError,
  } = useGetOrchestrationQuery({ id: params['id']! }, { skip: !params['id'] });

  const saveInternal = useCallback(
    (orchestration: FeatureOrchestration) => {
      save({ team, orchestration });
    },
    [save, team]
  );

  useEffect(() => {
    if (isError) {
      // We could not load the nudge to copy.
      dispatch(queueToast({ msg: t('global.error.generic') }));
      // Navigate back.
      back();
    }
  }, [isError, dispatch, back, t]);

  useEffect(() => {
    const { data, isError, reset } = result;

    if (data || isError) {
      if (data) {
        navigate('/nudge/:team/:id', { id: data, team }, { state: 'created' });
      } else {
        dispatch(queueToast({ msg: t('global.error.generic') }));
      }
      reset();
    }
  }, [team, result, dispatch, navigate, t]);

  return {
    save: saveInternal,
    isLoadingOrchestration,
    defaultOrchestration,
    isSaving: result.isLoading,
  };
};

/**
 * Hook for updating an orchestration.
 *
 * @param team - the team to update the orchestration for.
 * @param id - the id of the orchestration to update.
 *
 * @returns an object with the update function and loading state.
 * @throws PuffaError if the update fails more than 2 times.
 */
export const useUpdate = (team: string, id: string) => {
  const [update, result] = useUpdateOrchestrationMutation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useErrorLimitGuard(result.isError);
  const { navigate } = useNavigate();

  const updateInternal = useCallback(
    (orchestration: FeatureOrchestration) => {
      update({ team, id, orchestration });
    },
    [update, id, team]
  );

  useEffect(() => {
    const { isSuccess, isError, reset } = result;

    if (isSuccess || isError) {
      if (isSuccess) {
        navigate('/nudge/:team/:id', { team, id }, { state: 'updated' });
      } else {
        dispatch(queueToast({ msg: t('global.error.generic') }));
      }
      reset();
    }
  }, [team, result, dispatch, t, id, navigate]);

  return {
    update: updateInternal,
    isLoading: result.isLoading,
  };
};

/**
 * Hook for republishing an orchestration.
 *
 * @returns an object with the republish function and loading state.
 *
 * @throws PuffaError if the request fails more than 2 times.
 */
export const useRepublishOrchestration = () => {
  const [republish, result] = useRepublishOrchestrationMutation();
  useErrorLimitGuard(result.isError);

  return { republish, isLoading: result.isLoading };
};

export type ReportResult = {
  isLoading: boolean;
  gotoReport: () => void;
};

/**
 * Hook for fetching an orchestration.
 *
 * @param id - the orchestration id.
 *
 * @returns an object with the orchestration and loading state.
 */
export const useGetOrchestration = (id: string) => {
  const { data, isLoading, isError } = useGetOrchestrationQuery({ id });

  useEffect(() => {
    if (isError) {
      throw new PuffaError();
    }
  }, [isError]);

  return { orchestration: data, isLoading };
};

/**
 * Hook for fetching all orchestrations.
 *
 * @returns an object with the orchestrations and loading state.
 */
export const useGetOrchestrations = () => {
  const { data, isLoading, isError } = useGetOrchestrationsQuery();

  useEffect(() => {
    if (isError) {
      throw new PuffaError();
    }
  }, [isError]);

  return { orchestrations: data, isLoading };
};

/**
 * Hook for fetching all orchestrations connected to a nudge group.
 *
 * @param id - the id of the nudge group.
 *
 * @returns an object with the orchestrations and loading state.
 */
export const useGetNudgeGroupOrchestrations = (id: string) => {
  const { data, isLoading, isError } = useGetNudgeGroupOrchestrationsQuery({ id });

  useEffect(() => {
    if (isError) {
      throw new PuffaError();
    }
  }, [isError]);

  return { orchestrations: data, isLoading };
};

/**
 * Hook for creating an experiment.
 *
 * @returns an object with the create function and loading state.
 */
export const useCreateExperiment = () => {
  const [create, createResult] = useCreateExperimentMutation();

  const createInternal = useCallback(
    (id: string, team: string, experiment: ExperimentRequest) => {
      create({ id, team, experiment });
    },
    [create]
  );
  // refetch

  return {
    create: createInternal,
    isSuccess: createResult.isSuccess,
    isLoading: createResult.isLoading,
    isError: createResult.isError,
    reset: createResult.reset,
  };
};

/**
 * Hook for getting all deployments.
 *
 * @returns an object with the deployments and loading state.
 *
 * @throws PuffaError if the request fails.
 */
export const useGetDeployments = () => {
  const { data, isLoading, isError } = useGetDeploymentsQuery();

  useEffect(() => {
    if (isError) {
      throw new PuffaError();
    }
  }, [isError]);

  return { deployments: data, isLoading };
};

/**
 * Hook for getting a deployment.
 *
 * @param id - the id of the deployment to get.
 *
 * @returns An object with the deployment and loading state.
 *
 * @throws PuffaError if the request fails.
 */
export const useGetDeployment = (id: string) => {
  const { data, isLoading, isError } = useGetDeploymentQuery({ id });

  useEffect(() => {
    if (isError) {
      throw new PuffaError();
    }
  }, [isError]);

  return { deployment: data, isLoading };
};

/**
 * Hook for getting a nudge metric.
 *
 * @param id - the id of the nudge to get the metric for.
 * @param skip - if we should skip fetching the data.
 *
 * @returns the metric and loading state.
 */
export const useGetNudgeMetric = (id: string, skip: boolean) => {
  const { data, isLoading, isError } = useGetNudgeMetricQuery({ id }, { skip });

  useEffect(() => {
    if (isError) {
      throw new PuffaError(`Metrics for nudge #${id} possibly not defined`);
    }
  }, [id, isError]);

  return { metric: data, isLoading };
};

/**
 * Hook for getting a nudge group metric.
 *
 * @param id - the id of the nudge group to get the metric for.
 * @param skip - if we should skip fetching the data.
 *
 * @returns the metric and loading state.
 */
export const useGetNudgeGroupMetric = (id: string, skip: boolean) => {
  const { data, isLoading, isError } = useGetNudgeGroupMetricQuery({ id }, { skip });

  useEffect(() => {
    if (isError) {
      throw new PuffaError(`Metrics for nudge group #${id} possibly not defined`);
    }
  }, [id, isError]);

  return { metric: data, isLoading };
};

/**
 * Hook for getting the nudge of fame metrics.
 *
 * @returns the metrics and loading state.
 */
export const useGetNudgeOfFameMetrics = () => {
  const { data, isLoading, isError } = useGetNudgeOfFameMetricsQuery();

  useEffect(() => {
    if (isError) {
      throw new PuffaError('Nudge of fame metrics possibly not available');
    }
  }, [isError]);

  return { metrics: data, isLoading };
};

/**
 * Hook for getting all the collisions for the existing orchestrations.
 *
 * @param skip - if we should skip fetching the data.
 *
 * @returns the collisions.
 *
 * @throws PuffaError if the request fails.
 */
export const useGetCollisions = (skip?: boolean) => {
  const { data, isLoading, isError } = useGetCollisionsQuery(undefined, { skip });

  useEffect(() => {
    if (isError) {
      throw new PuffaError();
    }
  }, [isError]);

  return { collisions: data, isLoading };
};
