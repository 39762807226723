import { createReducer, nanoid } from '@reduxjs/toolkit';
import {
  acknowledgeToast,
  showToast,
  setTab,
  updateFilter,
  clearFilter,
  enableFocusPortal,
  updateSort,
  dismissExperimentIterationMessage,
} from './actions';
import { AppState } from './types';

const initialState: AppState = {
  toast: undefined,
  focusPortalEnabled: false,
  isPublishing: [],
  filterSelections: [],
  sort: undefined,
  sessionId: nanoid(12),
  overviewlayout: {
    widgets: 0,
    orchestrations: 0,
    nudge_groups: 0,
  },
  experimentIterationMessageDismissed: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(acknowledgeToast, (state) => {
      state.toast = undefined;
    })
    .addCase(showToast, (state, action) => {
      state.toast = action.payload;
    })
    .addCase(setTab, (state, action) => {
      state.activeTab = action.payload;
    })
    .addCase(enableFocusPortal, (state, action) => {
      state.focusPortalEnabled = action.payload;
    })
    .addCase(updateFilter, (state, action) => {
      const {
        meta: add,
        payload: { which, id, value },
      } = action;

      let selection = state.filterSelections.find(
        (selection) => selection.which === which && selection.id === id
      );

      let values: string[] = [];
      if (selection) {
        values = selection.values.filter((v) => v !== value);
        // We have a selection
        if (add) {
          values.push(value);
        }

        selection = {
          ...selection,
          values,
        };
      } else {
        selection = { which, id, values: [value] };
      }

      state.filterSelections = state.filterSelections
        .filter(({ which, id }) => which !== action.payload.which || id !== action.payload.id)
        .concat(selection);
    })
    .addCase(clearFilter, (state) => {
      state.filterSelections = [];
    })
    .addCase(updateSort, (state, action) => {
      state.sort = action.payload;
    })
    .addCase(dismissExperimentIterationMessage, (state) => {
      state.experimentIterationMessageDismissed = true;
    });
});

export default reducer;
