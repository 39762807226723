import { useEffect, useState } from 'react';
import Button from '@ingka/button';
import InlineMessage from '@ingka/inline-message';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import JoinTeamTheatre from '../../components/JoinTeamTheatre';
import TeamRequestModal from '../../components/TeamRequestModal';
import { usePermission } from '../../features/auth/hooks';
import { useNavigate } from '../../routes';
import './index.scss';

const WelcomePage: React.FC = () => {
  const { navigate } = useNavigate();
  const { isAllowed: hasTeam, isLoading } = usePermission('EDIT');
  const { t } = useTranslation();

  const [joinTeamTheatreOpen, setJoinTeamTheatreOpen] = useState(false);
  const [requestTeamModalVisible, setRequestTeamModalVisible] = useState(false);
  const [requestReceived, setRequestReceived] = useState(false);

  useEffect(() => {
    if (!isLoading && hasTeam) {
      navigate('/home');
    }
  }, [hasTeam, isLoading, navigate]);

  const onTeamRequested = (isRequested: boolean) => {
    setRequestTeamModalVisible(false);
    setRequestReceived(isRequested);
  };

  return (
    !(isLoading || hasTeam) && (
      <>
        <JoinTeamTheatre open={joinTeamTheatreOpen} onClose={() => setJoinTeamTheatreOpen(false)} />
        <TeamRequestModal open={requestTeamModalVisible} onClose={onTeamRequested} />
        <div className="landing-container">
          <div>
            <Text tagName="p" headingSize="s" className="header">
              {t('global.app.system')}
            </Text>
          </div>
          <Text tagName="h1" headingSize="l">
            {t('global.app.name')}
          </Text>

          {requestReceived ? (
            <div className="inline-message">
              <InlineMessage
                body={t('page.welcome.request_submitted_body')}
                title={t('page.welcome.request_submitted_title')}
                variant="positive"
              />
            </div>
          ) : (
            <Text tagName="p" bodySize="xl" className="information">
              {t('team.onboard.text')}
            </Text>
          )}
          <div className="button-container">
            <Button
              type="primary"
              text={t('team.onboard.action.join.text')}
              onClick={() => setJoinTeamTheatreOpen(true)}
            />
            <Button
              type="emphasised"
              text={t('team.onboard.action.request.text')}
              onClick={() => setRequestTeamModalVisible(true)}
            />
          </div>
          <div className="skip">
            <Button
              size="small"
              text={t('page.welcome.skip')}
              type="secondary"
              onClick={() => navigate('/home')}
            />
          </div>
        </div>
      </>
    )
  );
};

export default WelcomePage;
