import { all } from 'redux-saga/effects';
import appSagas from './app/sagas';
import resourcelockSagas from './resourcelocks/sagas';

/**
 * The root saga defining all middleware style sagas.
 */
export default function* rootSaga() {
  yield all([...appSagas, ...resourcelockSagas]);
}
