import { Routes } from '@nfw/contracts/self-service';
import { FrameworkConfiguration, MarketConfiguration } from '@nfw/nudge/types';
import { toPath } from '@nfw/utils';
import { api } from '../api';
import { RetailUnitArg } from '../api/types';

export const orchestrationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMarketConfiguration: build.query<MarketConfiguration, RetailUnitArg>({
      query: ({ retailUnit }) => toPath(Routes.Configuration, { retailUnit }),
      providesTags: (_, __, { retailUnit }) => [{ type: 'market_configuration', id: retailUnit }],
    }),
    getFrameworkConfiguration: build.query<FrameworkConfiguration, void>({
      query: () => Routes.FrameworkConfiguration,
      providesTags: [{ type: 'framework_configuration', id: 'all' }],
    }),
    updateMarketConfiguration: build.mutation<
      void,
      RetailUnitArg & { configuration: MarketConfiguration }
    >({
      query: ({ retailUnit, configuration }) => ({
        url: toPath(Routes.Configuration, { retailUnit }),
        method: 'PUT',
        body: configuration,
      }),
      invalidatesTags: (_, __, { retailUnit }) => [
        { type: 'market_configuration', id: retailUnit },
      ],
    }),
    updateFrameworkConfiguration: build.mutation<void, { configuration: FrameworkConfiguration }>({
      query: ({ configuration }) => ({
        url: Routes.FrameworkConfiguration,
        method: 'PUT',
        body: configuration,
      }),
      invalidatesTags: [{ type: 'framework_configuration', id: 'all' }],
    }),
  }),
});

export const {
  useGetMarketConfigurationQuery,
  useGetFrameworkConfigurationQuery,
  useUpdateMarketConfigurationMutation,
  useUpdateFrameworkConfigurationMutation,
} = orchestrationApi;
