import { RefObject, createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import missing from '@ingka/ssr-icon/paths/missing';
import { IconType } from '@nfw/nudge/types';
import { isDefined, randomize } from '@nfw/utils';
import { isHTMLElement, isInViewport } from './dom';
import { IconRecord, SSRIconFunction, getIconRecord } from './icons';

/**
 * Hook for refreshing value changes.
 *
 * @param value - the current value.
 * @param timeout - the refresh timeout.
 *
 * @returns the refreshed value.
 */
export const useRefresh = <T,>(value: T, timeout: number): T | undefined => {
  const [refreshedValue, setRefreshedValue] = useState<T>();

  useEffect(() => {
    setRefreshedValue(undefined);
    const timeoutFn = setTimeout(() => {
      setRefreshedValue(value);
    }, timeout);
    return () => clearTimeout(timeoutFn);
  }, [value, timeout]);

  return refreshedValue;
};

/**
 * Hook for debounce value changes.
 *
 * @param value - the current value.
 * @param timeout - the debounce timeout.
 *
 * @returns the debounced value.
 */
export const useDebounce = <T,>(value: T, timeout = 200): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeoutFn = setTimeout(() => {
      setDebouncedValue(value);
    }, timeout);
    return () => clearTimeout(timeoutFn);
  }, [value, timeout]);

  return debouncedValue;
};

/**
 * Hook for retrieving a previous value.
 *
 * @param current - the current value.
 *
 * @returns the previous value.
 */
export function usePrevious<T>(current: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = current;
  }, [current]);

  return ref.current;
}

/**
 * Setter and getter for the icons.
 */
export type IconRegistry = [
  (...types: (IconType | undefined)[]) => void,
  (icon?: IconType) => SSRIconFunction | undefined
];

/**
 * Hook for getting a skapa icon.
 *
 * @param type - the type(s) to create.
 *
 * @returns the icon registry.
 */
export const useLazyIcon = (): IconRegistry => {
  const [iconTypes, setIconTypes] = useState<IconType[]>();
  const [record, setRecord] = useState<IconRecord>({});

  useEffect(() => {
    if (iconTypes) {
      getIconRecord(iconTypes).then(setRecord);
    }
  }, [iconTypes]);

  const setIcons = useCallback(
    (...types: (IconType | undefined)[]) => setIconTypes(types.filter(isDefined)),
    []
  );
  const getIcon = useCallback(
    (type?: IconType) => (type ? record[type] ?? missing : undefined),
    [record]
  );

  return [setIcons, getIcon];
};

/**
 * Hook for acquiring multiple refs.
 *
 * @param numRefs - the number of references to add.
 */
export const useRefs = <T,>(numRefs: number) => {
  const ref = useRef<RefObject<T>[]>([]);

  return useMemo(() => {
    for (let i = 0; i < numRefs; i++) {
      ref.current.push(createRef<T>());
    }
    return ref;
  }, [numRefs, ref]);
};

/**
 * Debug hook used for checking if the instance has changed.
 *
 * @returns a random (memoized) instance id.
 */
export const useDebugInstance = () => useMemo(() => randomize(1, 1000), []);

/**
 * Hook for using drag and drop on a container containing draggable items.
 *
 * @param ref - the container ref.
 * @param onDrop - callback when an element has changed positions.
 * @param onDragOver - callback when an element is hovered by the dragged item.
 */
export const useDragNDrop = <T extends Element>(
  ref: RefObject<T>,
  onDrop: (from: number, to: number) => void,
  onDragOver?: (target: HTMLElement) => void,
  onDragIndex?: (index: number | null) => void,
  onDragOverIndex?: (index: number | null) => void
) => {
  const [from, setFrom] = useState<number>(-1);
  const [children, setChildren] = useState<HTMLElement[]>([]);

  useEffect(() => {
    const list = ref.current;
    if (list) {
      setChildren(
        Array.from(list.childNodes)
          .filter(isHTMLElement)
          .filter(({ draggable }) => draggable)
      );
    }
  }, [ref]);

  useEffect(() => {
    const list = ref.current;
    if (list) {
      const callback = (_: MutationRecord[]) => {
        setChildren(
          Array.from(list.childNodes)
            .filter(isHTMLElement)
            .filter(({ draggable }) => draggable)
        );
      };
      const observer = new MutationObserver(callback);

      observer.observe(list, { childList: true });
      return () => observer.disconnect();
    }
  }, [ref]);

  useEffect(() => {
    const container = ref.current;

    if (container) {
      const dragStart = (event: DragEvent) => {
        // The element dragged.
        const element = event.target as HTMLElement;
        const index = children.indexOf(element);

        if (index >= 0) {
          onDragIndex?.(index);
          setFrom(index);
        }
      };

      const dragEnd = () => {
        onDragOverIndex?.(null);
        onDragIndex?.(null);
      };

      const dragEnter = (event: DragEvent) => {
        event.preventDefault();
        const element = event.target as HTMLElement;
        const index = children.indexOf(element);

        if (index >= 0) {
          onDragOverIndex?.(index);
        }
      };

      const dragOver = (event: DragEvent) => {
        event.preventDefault();
        const element = event.target as HTMLElement;
        const index = children.indexOf(element);

        if (index !== from) {
          onDragOver?.(event.target as HTMLElement);
        }
      };

      const drop = (event: DragEvent) => {
        // The element to drop over.
        const element = event.currentTarget as HTMLElement;

        if (container.contains(element)) {
          const to = children.indexOf(element);

          if (to >= 0 && to !== from) {
            onDrop(from, to);
          }
        }
        // Reset we are not dragging anymore.
        setFrom(-1);
      };

      children.forEach((child) => {
        child.addEventListener('dragstart', dragStart);
        child.addEventListener('dragover', dragOver);
        child.addEventListener('drop', drop);
        child.addEventListener('dragend', dragEnd);
        child.addEventListener('dragenter', dragEnter);
      });

      return () => {
        children.forEach((child) => {
          child.removeEventListener('dragstart', dragStart);
          child.removeEventListener('dragover', dragOver);
          child.removeEventListener('drop', drop);
          child.removeEventListener('dragend', dragEnd);
          child.removeEventListener('dragenter', dragEnter);
        });
      };
    }
  }, [ref, from, children, onDrop, onDragOver, onDragIndex, onDragOverIndex]);
};

/**
 * Hook for determin if an element is in viewport.
 *
 * @param once - if true will always report visible when once visible.
 *
 * @returns the element ref callback and whether the referenced element is visible.
 */
export const useInViewport = <T extends HTMLElement>(): [(elemenet: T | null) => void, boolean] => {
  const [element, setElement] = useState<T | null>(null);
  const [visible, setVisible] = useState(false);

  const refCallback = useCallback((node: T | null) => {
    setElement(node);
  }, []);

  const onVisibilityChange = useCallback(() => {
    if (element) {
      const rect = element.getBoundingClientRect();

      setVisible(isInViewport(rect));
    }
  }, [element]);

  useEffect(onVisibilityChange, [onVisibilityChange]);

  useEffect(() => {
    document.addEventListener('scroll', onVisibilityChange, { passive: true });
    document.addEventListener('resize', onVisibilityChange, { passive: true });

    return () => {
      document.removeEventListener('scroll', onVisibilityChange);
      document.removeEventListener('resize', onVisibilityChange);
    };
  }, [onVisibilityChange]);

  return [refCallback, visible];
};
