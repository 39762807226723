import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import LinearLayout from '../../components/LinearLayout';
import NudgeGroupForm from '../../components/NudgeGroupForm';
import Page from '../../components/Page';
import ProgressIndicator from '../../components/ProgressIndicator';
import { useGetNudgeGroup, useUpdateNudgeGroup } from '../../features/nudgeGroups/hooks';
import { InjectedViewPermissionsProps, withViewPermissions } from '../../hocs';

const EditNudgeGroupPage: React.FC<InjectedViewPermissionsProps> = ({ id, team }) => {
  const { t } = useTranslation();

  const { nudgeGroup: defaultGroup } = useGetNudgeGroup(id);
  const { update, isLoading } = useUpdateNudgeGroup(id, team);

  return (
    <Page crumbs={[{ name: t('global.overview'), to: '/?tab=features' }]} singleColumn>
      {defaultGroup && (
        <LinearLayout gap="l">
          <Text headingSize="m">{t('page.edit_nudge_group.title')}</Text>
          <ProgressIndicator currentPage={1} steps={[t('page.nudge_group.breadcrumb')]} />
          <NudgeGroupForm onSave={update} saving={isLoading} defaultNudgeGroup={defaultGroup} />
        </LinearLayout>
      )}
    </Page>
  );
};

export default withViewPermissions(EditNudgeGroupPage, true, 'EDIT');
