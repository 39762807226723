import Button from '@ingka/button';
import Loading from '@ingka/loading';
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal';
import Text from '@ingka/text';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useGetCollisions } from '../../features/orchestrations/hooks';
import { useNavigate } from '../../routes';
import ExpandableTextWrapper from '../TextWrapper';
import './index.scss';

export type NudgeCollisionModalProps = {
  visible: boolean;
  close: () => void;
  id: string;
};

const NudgeCollisionModal: React.FC<NudgeCollisionModalProps> = ({ visible, close, id }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigate();
  const { collisions, isLoading } = useGetCollisions(!visible);

  const handleClick = (orchestration) => {
    if (orchestration.id && orchestration.team) {
      close();
      navigate('/nudge/:team/:id', {
        id: orchestration.id,
        team: orchestration.team,
      });
      // Reload the page to make it clear that the user has navigated to the new nudge details page and is at the top of the page
      window.location.reload();
    }
  };

  return createPortal(
    <Modal className="collision-theatre" visible={visible} handleCloseBtn={close}>
      <Sheets
        header={<ModalHeader />}
        footer={
          <ModalFooter>
            <Button type="primary" text={t('global.close')} onClick={close} />
          </ModalFooter>
        }
      >
        <ModalBody className="modal-body">
          <Text headingSize="m">{t('component.collision_table.colliding_nudges')}</Text>
          <div className="intro">
            <ExpandableTextWrapper>{t('page.nudge_collisions.description')}</ExpandableTextWrapper>
          </div>
          {isLoading ? (
            <Loading />
          ) : collisions && collisions[id] ? (
            collisions[id].map((collision) => (
              <div className="collision" onClick={() => handleClick(collision)}>
                <Text bodySize="l">{collision.id}</Text>
                <Text bodySize="s">{collision.name}</Text>
              </div>
            ))
          ) : (
            <Text bodySize="l">{t('component.collision_table.no_collisions')}</Text>
          )}
        </ModalBody>
      </Sheets>
    </Modal>,
    document.body
  );
};

export default NudgeCollisionModal;
