import Text from '@ingka/text';
import { ConsolidatedStatus } from '@nfw/deployment-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './index.scss';

export type StateIndicatorProps = {
  isArchived: boolean;
  consolidatedStatus?: ConsolidatedStatus;
  detailed?: boolean;
};

const StateIndicator: React.FC<StateIndicatorProps> = ({
  isArchived,
  consolidatedStatus = { env: 'cte', status: 'draft' },
  detailed,
}) => {
  const { env, status } = consolidatedStatus;
  const { t } = useTranslation();

  const getStatusDetails = () => {
    if (isArchived) {
      return {
        title: t('global.state.archived'),
        content: t('component.nudge_state.archived_content'),
        styling: 'archived',
      };
    }

    const statusMap = {
      draft: {
        title: t('global.state.draft'),
        content: t('component.nudge_state.draft_content'),
        styling: 'draft',
      },
      experiment_created: {
        title: t('global.state.prod.experiment_created'),
        content: t('component.nudge_state.experiment_created_content'),
        styling: 'experiment-created',
      },
      experimenting: {
        title: t('global.state.prod.experimenting'),
        content: t('component.nudge_state.experimenting_content'),
        styling: 'experimenting',
      },
      published: {
        title: env === 'prod' ? t('global.state.prod.live') : t('component.nudge_state.test'),
        content:
          env === 'prod'
            ? t('component.nudge_state.published_content')
            : t('component.nudge_state.test_content'),
        styling: env === 'prod' ? 'prod' : 'cte',
      },
    };

    return statusMap[status] || {};
  };

  const { title, content, styling } = getStatusDetails();

  return (
    <div className={classNames('status-content', { detailed }, styling)}>
      <div className={classNames('circle', styling)} />
      <div className="text-div">
        <Text bodySize="m" className={classNames('title', { detailed })}>
          {title}
        </Text>
        {detailed && <Text bodySize="m">{content}</Text>}
      </div>
    </div>
  );
};

export default StateIndicator;
