import { useCallback } from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import { isFrameworkConfiguration } from '@nfw/nudge/typeguard';
import { FrameworkConfiguration } from '@nfw/nudge/types';
import { Form, FormCallback } from '@nfw/ui/form';
import DOMPurify from 'dompurify';
import { useGetFrameworkConfiguration } from '../../../features/configurations/hooks';
import { useFrameworkConfigurationLayout } from '../../../features/orchestrations/hooks';

export type FrameworkConfigurationFormProps = {
  onConfigurationChange: (configuration: FrameworkConfiguration) => void;
};

const FrameworkConfigurationForm: React.FC<FrameworkConfigurationFormProps> = ({
  onConfigurationChange,
}) => {
  const { data: configuration, isLoading } = useGetFrameworkConfiguration();
  const configLayout = useFrameworkConfigurationLayout();

  const onChange = useCallback<FormCallback<FrameworkConfiguration>>(
    ({ value }) => {
      if (value) {
        onConfigurationChange(value);
      }
    },
    [onConfigurationChange]
  );

  return isLoading ? (
    <Loading text="">
      <LoadingBall size="large" />
    </Loading>
  ) : (
    <Form
      config={configLayout}
      defaultFormValue={configuration}
      onChange={onChange}
      validationFn={isFrameworkConfiguration}
      purify={DOMPurify.sanitize}
    />
  );
};

export default FrameworkConfigurationForm;
