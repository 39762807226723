import { InputTypes, LayoutConfiguration } from '@nfw/form-types';
import { VisibilityExceptionTypes } from '@nfw/nudge/types';
import { TFunction } from 'i18next';

export const VisibilityExceptionLayout = (t: TFunction): LayoutConfiguration => [
  {
    inputType: InputTypes.PillListbox,
    name: 'type',
    label: t('form.feature.visibility_exception.type'),
    btnLabel: t('global.choose_option'),
    includeSearch: false,
    options: Object.entries(VisibilityExceptionTypes).map(([name, value]) => ({
      name: value,
      value: name,
    })),
  },
  {
    inputType: InputTypes.Checkbox,
    name: 'show',
    label: t('form.feature.visibility_exception.show_triggered'),
  },
  {
    inputType: InputTypes.Slider,
    name: 'distance',
    label: t('form.feature.visibility_exception.distance'),
    dependsOn: 'type',
    requiresValueOf: [
      VisibilityExceptionTypes.DistanceGreaterThan,
      VisibilityExceptionTypes.DistanceLessThan,
    ],
    defaultValue: 0,
    min: 0,
    max: 5000,
    step: 10,
    unit: 'px',
  },
  {
    inputType: InputTypes.Slider,
    name: 'range',
    label: t('form.feature.visibility_exception.range'),
    dependsOn: 'type',
    requiresValueOf: [
      VisibilityExceptionTypes.HorizontallyAligned,
      VisibilityExceptionTypes.VerticallyAligned,
    ],
    defaultValue: 100,
    min: 100,
    max: 5000,
    step: 10,
    unit: 'px',
  },
  {
    inputType: InputTypes.Checkbox,
    name: 'intersecting',
    label: t('form.feature.visibility_exception.label'),
    defaultValue: true,
    dependsOn: 'type',
    requiresValueOf: [VisibilityExceptionTypes.Intersecting],
  },
];
