import { Schedule } from '@nfw/nudge/types';
import { isRegexpMatch, isObject } from '@nfw/utils';

/**
 * Check if a schedule is valid.
 *
 * @param c - the schedule to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isSchedule = (c: unknown): c is Schedule =>
  isObject(c) &&
  isRegexpMatch(c.start, /^\d{4}-\d{2}-\d{2}$/) &&
  isRegexpMatch(c.end, /^\d{4}-\d{2}-\d{2}$/) &&
  isRegexpMatch(c.timezone, /^(?:Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])$/);
