import React, { useEffect, useState } from 'react';
import InlineMessage from '@ingka/inline-message';
import ListView, { ListViewItem } from '@ingka/list-view';
import archivebox from '@ingka/ssr-icon/paths/archive-box';
import arrowClockwise from '@ingka/ssr-icon/paths/arrow-clockwise';
import code from '@ingka/ssr-icon/paths/chevron-left-slash-chevron-right';
import clipboardCheckmark from '@ingka/ssr-icon/paths/clipboard-checkmark';
import contract from '@ingka/ssr-icon/paths/contract';
import copy from '@ingka/ssr-icon/paths/copy';
import history from '@ingka/ssr-icon/paths/history';
import pencil from '@ingka/ssr-icon/paths/pencil';
import Text from '@ingka/text';
import { EnvironmentState } from '@nfw/deployment-types';
import { StoredWidget } from '@nfw/orchestration-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Audits from '../../components/Audits';
import Breadcrumb from '../../components/Breadcrumb';
import CodeModal from '../../components/CodeModal';
import DesignPage from '../../components/DesignPage';
import ExperimentResultTheatre from '../../components/ExperimentResultTheatre';
import LinearLayout from '../../components/LinearLayout';
import NudgeCollisionModal from '../../components/NudgeCollisionModal';
import NudgeMetricModal from '../../components/NudgeMetricModal';
import OrchestrationDescription from '../../components/OrchestrationDescription';
import PopUp from '../../components/PopUp';
import WidgetPreview from '../../components/WidgetPreview';
import { environment } from '../../environment';
import { useGetTeamTranslations } from '../../features/i18n/hooks';
import { useOrchestration, useRepublishOrchestration } from '../../features/orchestrations/hooks';
import { selectTeamWidgets } from '../../features/widgets/selectors';
import { InjectedViewPermissionsProps, withViewPermissions } from '../../hocs';
import { useListViewLinks, useNavigate } from '../../routes';
import { useMemoizedSelector } from '../../store';
import OrchestrationActions from './OrchestrationActions';
import './index.scss';

const OrchestrationDetailsPage: React.FC<InjectedViewPermissionsProps> = ({
  id,
  team,
  permissions,
  isEditAllowed,
}) => {
  const [isDeploymentInit, setDeploymentInit] = useState(false);
  const [state, setState] = useState<EnvironmentState>();
  const [widget, setWidget] = useState<StoredWidget>();
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [republishPopUpVisible, setRepublishPopUpVisible] = useState(false);
  const [codeVisible, setCodeVisible] = useState(false);
  const [experimentResultVisible, setExperimentResultVisible] = useState(false);
  const [nudgeMetricVisible, setNudgeMetricVisible] = useState(false);
  const [nudgeCollisionsVisible, setNudgeCollisionsVisible] = useState(false);

  const teamWidgets = useMemoizedSelector(selectTeamWidgets, team);

  const { republish, isLoading: isRepublishing } = useRepublishOrchestration();
  const { orchestration, isRunning, archive } = useOrchestration(id, team);
  const { translations, isLoading: isTranslationsLoading } = useGetTeamTranslations(team);

  const { t } = useTranslation();
  const location = useLocation();
  const { toAppPath } = useNavigate();
  const { listViewOnClickHandler } = useListViewLinks();

  useEffect(() => {
    if (orchestration) {
      setWidget(teamWidgets.find(({ id }) => orchestration.featureNudge.widgetRef === id));
    }
  }, [teamWidgets, orchestration]);

  useEffect(() => {
    window.history.replaceState({}, '');
  }, []);

  return (
    <DesignPage
      compact
      loading={!widget || !isDeploymentInit || isTranslationsLoading}
      designComponent={<WidgetPreview widget={widget} translations={translations} />}
      designOnly
    >
      <PopUp
        visible={popUpVisible}
        close={() => setPopUpVisible(false)}
        title={t('page.nudge_details.archive.title')}
        body={t('page.nudge_details.archive.description')}
        actions={[
          {
            type: 'secondary',
            text: t('global.action.cancel'),
          },
          { type: 'primary', text: t('page.nudge_details.archive.cta'), action: archive },
        ]}
      />
      <PopUp
        visible={republishPopUpVisible}
        close={() => setRepublishPopUpVisible(false)}
        title={t('page.nudge_details.republish.title')}
        body={t('page.nudge_details.republish.description')}
        actions={[
          {
            type: 'secondary',
            text: t('global.action.cancel'),
          },
          {
            type: 'primary',
            text: t('page.nudge_details.republish.cta'),
            action: () => republish({ id, team }),
          },
        ]}
      />
      <CodeModal close={() => setCodeVisible(false)} visible={codeVisible} code={orchestration} />
      <ExperimentResultTheatre
        visible={experimentResultVisible}
        close={() => setExperimentResultVisible(false)}
        id={id}
        skip={!orchestration?.isArchived && state?.prod !== 'published'}
      />
      <NudgeMetricModal
        close={() => setNudgeMetricVisible(false)}
        visible={nudgeMetricVisible}
        orchestrationId={
          orchestration?.featureNudge.widgetRef
            ? `${id}-${orchestration?.featureNudge.widgetRef}`
            : undefined
        }
      />
      <NudgeCollisionModal
        close={() => setNudgeCollisionsVisible(false)}
        visible={nudgeCollisionsVisible}
        id={id}
      />
      <LinearLayout gap="xl">
        {orchestration && (
          <>
            <Breadcrumb
              crumbs={[{ name: t('global.overview'), to: '/home' }, { name: `#${id}` }]}
            />
            {location.state === 'created' && (
              <InlineMessage variant="positive" body={t('global.nudge.saved')} dismissable />
            )}
            {location.state === 'updated' && (
              <InlineMessage variant="positive" body={t('global.nudge.updated')} dismissable />
            )}

            <OrchestrationActions
              orchestration={orchestration}
              permissions={permissions}
              onStateChange={(state) => {
                setDeploymentInit(true);
                setState(state);
              }}
            >
              <Text headingSize="m">{orchestration.name}</Text>
              <Audits id={id} modified={orchestration.modified} />
            </OrchestrationActions>
            <OrchestrationDescription
              orchestration={orchestration}
              detailsHeader={t('global.details')}
              configurationHeader={t('global.configuration')}
            />
            <ListView id="actions" onClick={listViewOnClickHandler}>
              <ListViewItem
                id="edit"
                emphasised
                control="link"
                controlIcon="arrowRight"
                leadingIcon={pencil}
                title={t('global.edit')}
                href={toAppPath('/edit/nudge/:team/:id', { team, id })}
                disabled={
                  isRunning || (state && !state.isEditable(permissions)) || orchestration.isArchived
                }
              />
              <ListViewItem
                id="republish"
                emphasised
                control="navigational"
                controlIcon="arrowRight"
                leadingIcon={arrowClockwise}
                title={t('page.nudge_details.republish.cta')}
                onClick={() => setRepublishPopUpVisible(true)}
                disabled={
                  isRunning ||
                  isRepublishing ||
                  !state ||
                  !state.isRepublishable(permissions) ||
                  orchestration.isArchived
                }
              />
              <ListViewItem
                id="copy"
                emphasised
                control="link"
                controlIcon="arrowRight"
                leadingIcon={copy}
                title={t('page.nudge_details.copy_nudge')}
                href={toAppPath('/copy/nudge/:team/:id', { team, id })}
                disabled={!isEditAllowed}
              />
              <ListViewItem
                id="code"
                emphasised
                control="navigational"
                controlIcon="arrowRight"
                leadingIcon={code}
                title={t('global.view.code')}
                onClick={() => setCodeVisible(true)}
              />
              <ListViewItem
                id="experiment-result"
                emphasised
                control="navigational"
                controlIcon="arrowRight"
                leadingIcon={history}
                title={'View experiment results'}
                disabled={!orchestration?.isArchived && state?.prod !== 'published'}
                onClick={() => setExperimentResultVisible(true)}
              />
              <ListViewItem
                id="nudge-metric"
                emphasised
                control="navigational"
                controlIcon="arrowRight"
                leadingIcon={clipboardCheckmark}
                title={t('page.nudge_details.view_metrics')}
                disabled={
                  !(state?.prod === 'published' || state?.prod === 'experimenting') ||
                  environment.inDev
                }
                onClick={() => setNudgeMetricVisible(true)}
              />
              <ListViewItem
                id="nudge-collisions"
                emphasised
                control="navigational"
                controlIcon="arrowRight"
                leadingIcon={contract}
                title={t('page.nudge_collisions.header')}
                onClick={() => setNudgeCollisionsVisible(true)}
                disabled={orchestration.isArchived}
              />
              <ListViewItem
                id="archive"
                emphasised
                control="navigational"
                controlIcon="arrowRight"
                leadingIcon={archivebox}
                title={t('page.nudge_details.archive.cta')}
                disabled={
                  orchestration.isArchived || isRunning || !state?.isArchivable(permissions)
                }
                onClick={() => setPopUpVisible(true)}
              />
            </ListView>
          </>
        )}
      </LinearLayout>
    </DesignPage>
  );
};

export default withViewPermissions(OrchestrationDetailsPage, false);
