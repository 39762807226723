import { useMemo } from 'react';
import List from '@ingka/list';
import Text from '@ingka/text';
import { StoredFeatureOrchestration } from '@nfw/orchestration-types';
import { toDateString } from '@nfw/utils';
import { useTranslation } from 'react-i18next';
import { selectTeamName } from '../../features/teams/selectors';
import { useMemoizedSelector } from '../../store';
import DescribedItem from '../DescribedItem';
import LinearLayout from '../LinearLayout';
import './index.scss';

export type OrchestrationDescriptionProps = {
  orchestration: StoredFeatureOrchestration;
  detailsHeader: string;
  configurationHeader: string;
};

const OrchestrationDescription: React.FC<OrchestrationDescriptionProps> = ({
  orchestration: {
    id,
    conditions,
    created,
    creatorName,
    featureNudge,
    matcher,
    namespace,
    retailUnits,
    team,
    hypothesis,
    name,
    ticket,
  },
  detailsHeader,
  configurationHeader,
}) => {
  const { t } = useTranslation();
  const teamName = useMemoizedSelector(selectTeamName, team);
  const markets = retailUnits.map((ru) => t(`global.country.${ru}`)).join(', ');

  const repeatConfig = useMemo(() => {
    const { repeat } = featureNudge;
    switch (repeat.type) {
      case 'always':
        return repeat.type;
      case 'page':
        return `${repeat.type}: ${repeat.count}`;
      default: {
        const text = `${repeat.type}: ${repeat.count}`;
        if (repeat.page) {
          return `${text} - ${t('feature.description.repeat.page', repeat.page)}`;
        }
        return text;
      }
    }
  }, [featureNudge, t]);

  const triggerConfig = useMemo(
    () =>
      featureNudge.triggers.map(({ delay, type }) => {
        if (delay) {
          return t('global.trigger.type.delay', {
            triggerType: type,
            triggerDelay: delay,
          });
        } else {
          return t('global.trigger.type', { triggerType: type });
        }
      }),
    [featureNudge, t]
  );

  const conditionConfig = conditions.map((condition) => {
    switch (condition.type) {
      case 'authenticated':
        if (condition.userTarget) {
          return `${condition.type}: ${condition.value} - ${condition.userTarget}`;
        }
      // eslint-disable-next-line no-fallthrough
      default:
        return 'limit' in condition && 'value' in condition
          ? `${condition.type}: ${condition.value} - ${condition.limit?.value}`
          : 'value' in condition
          ? `${condition.type}: ${condition.value}`
          : condition.type;
    }
  });

  const featureMatcherElements: string[] = [];
  let exceptions: string[] = [];
  if (matcher) {
    const { includeChildPages, includeNamespacePage, searchparams, exception } = matcher;
    featureMatcherElements.push(
      `${t('form.feature.pagematcher.childpage.label')}: ${includeChildPages}`
    );
    featureMatcherElements.push(
      `${t('form.feature.pagematcher.namespace.label')}: ${includeNamespacePage}`
    );

    if (searchparams && searchparams.length > 0) {
      const query = searchparams.map(({ key, value }) => `${key}=${value}`).join('&');
      featureMatcherElements.push(t('feature.description.pagematcher.queryparams', { query }));
    }

    if (exception?.type === 'breadcrumb') {
      featureMatcherElements.push(`${t('page.nudge_details.breadcrumb_exceptions')}`);
      exceptions = exception.exceptions;
    } else if (exception?.type === 'string') {
      featureMatcherElements.push(`${t('page.nudge_details.string_exceptions')}`);
      exception.startsWith.length > 0 && exceptions.push(`Starts with: ${exception.startsWith}`);
      exception.endsWith.length > 0 && exceptions.push(`Ends with: ${exception.endsWith}`);
      exception.includes.length > 0 && exceptions.push(`Includes: ${exception.includes}`);
    }
  }

  return (
    <>
      <LinearLayout gap="l" border padded>
        <Text headingSize="xs">{detailsHeader}</Text>
        <DescribedItem title={t('global.created_by')} text={creatorName} />
        <DescribedItem title={t('global.team')} text={teamName} />
        <DescribedItem
          title={t('global.nudge_design')}
          text={`#${featureNudge.widgetRef}`}
          navigateTo="/design/:team/:id"
          navigateToParams={{ team: team, id: featureNudge.widgetRef }}
        />
        <DescribedItem title={t('form.feature.hypothesis.label')} text={hypothesis} />
        {ticket && (
          <DescribedItem
            title={t('form.feature.ticket_label')}
            text={ticket}
            externalLink={ticket}
          />
        )}
        <DescribedItem title={t('global.markets')} text={markets} />
        <DescribedItem title={t('global.created')} text={toDateString(created)} />
      </LinearLayout>
      <LinearLayout gap="l" border padded>
        <Text headingSize="xs">{configurationHeader}</Text>
        <DescribedItem title={t('form.feature.name.label')} text={name} />
        <DescribedItem
          title={t('feature.description.tracking')}
          text={`${id}-${featureNudge.widgetRef}`}
        />
        <DescribedItem
          title={t('global.conditions')}
          text={conditionConfig.length > 0 ? conditionConfig.join(', ') : 'N/A'}
        />
        <DescribedItem title={t('global.page')} text={namespace} />
        <DescribedItem
          title={t('form.feature.trigger.type.label')}
          text={triggerConfig.join(', ')}
        />
        <DescribedItem title={t('global.repeat')} text={repeatConfig ? repeatConfig : 'N/A'} />
        {featureMatcherElements.length > 0 && (
          <>
            <DescribedItem
              title={t('page.nudge_details.page_matcher')}
              textGroup={featureMatcherElements}
            />
            <List variation="small" className="exceptions-group">
              {exceptions.map((t) => (
                <li key={t}>{t}</li>
              ))}
            </List>
          </>
        )}
        {featureNudge.blacklist && (
          <DescribedItem
            title={t('form.feature.blacklist.label')}
            text={featureNudge.blacklist.join(', ')}
          />
        )}
      </LinearLayout>
    </>
  );
};

export default OrchestrationDescription;
