import { JiraData, Routes, WidgetRequest } from '@nfw/contracts/self-service';
import { DescribedWidget, StoredWidget } from '@nfw/orchestration-types';
import { toPath } from '@nfw/utils';
import { api } from '../api';
import { IdArg, TeamArg, TeamIdArg } from '../api/types';

export const widgetApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getWidget: builder.query<StoredWidget, IdArg>({
      query: ({ id }) => toPath(Routes.Widget, { id }),
      providesTags: (_, __, { id }) => [{ type: 'widget', id }],
    }),
    getWidgets: builder.query<StoredWidget[], void>({
      query: () => Routes.Widgets,
      providesTags: (widgets) =>
        widgets
          ? [
              ...widgets.map(({ id }) => ({ type: 'widget' as const, id })),
              { type: 'widget', id: 'all' },
            ]
          : [{ type: 'widget', id: 'all' }],
    }),
    createWidget: builder.mutation<string, TeamArg & { widget: DescribedWidget }>({
      query: ({ team, widget }) => ({
        url: toPath(Routes.TeamWidgets, { team }),
        method: 'POST',
        body: widget,
      }),
      invalidatesTags: [{ type: 'widget', id: 'all' }],
    }),
    updateWidget: builder.mutation<void, TeamIdArg & { widget: DescribedWidget }>({
      query: ({ id, team, widget }) => ({
        url: toPath(Routes.TeamWidget, { id, team }),
        method: 'PUT',
        body: widget,
      }),
      invalidatesTags: (_, __, { id }) => [{ type: 'widget', id }],
    }),
    archiveWidget: builder.mutation<void, TeamIdArg>({
      query: ({ id, team }) => ({
        url: toPath(Routes.ArchiveTeamWidget, { id, team }),
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'widget', id: 'all' }],
    }),
    requestWidget: builder.mutation<JiraData, WidgetRequest>({
      query: (request) => ({
        url: Routes.WidgetRequest,
        method: 'POST',
        body: request,
      }),
    }),
  }),
});

export const {
  useGetWidgetQuery,
  useGetWidgetsQuery,
  useCreateWidgetMutation,
  useUpdateWidgetMutation,
  useArchiveWidgetMutation,
  useRequestWidgetMutation,
} = widgetApi;
