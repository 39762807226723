import { useEffect, useState } from 'react';
import Table, { TableBody, TableHeader } from '@ingka/table';
import Text from '@ingka/text';
import { OrchestrationCollision } from '@nfw/nudge-collision';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import { CollisionFilter } from '../filters';
import PuffaHyperlink from '../PuffaHyperlink';

export type CollisionTableProps = {
  t: TFunction;
  collisions: OrchestrationCollision[];
};

const CollisionTable: React.FC<CollisionTableProps> = ({ collisions, t }) => {
  const [filteredCollisions, setFilteredCollisions] =
    useState<OrchestrationCollision[]>(collisions);

  useEffect(() => {
    setFilteredCollisions(collisions);
  }, [collisions]);

  return (
    <>
      <CollisionFilter collisions={collisions} onFilterChange={setFilteredCollisions} />
      <Table fullWidth inset>
        <TableHeader>
          <tr>
            <td>
              <Text headingSize="s">{t('global.nudges')}</Text>
            </td>
            <td>
              <Text headingSize="s">{t('component.collision_table.colliding_nudges')}</Text>
            </td>
            <td>
              <Text headingSize="s">{t('component.collision_table.where')}</Text>
            </td>
          </tr>
        </TableHeader>
        <TableBody striped>
          {filteredCollisions.map(
            ({ id, team, collidingNudges, retailUnits, page, displayName }) => (
              <tr key={`collision-${id}`}>
                <td className={classNames({ clickable: id && team })}>
                  <PuffaHyperlink route="/nudge/:team/:id" params={{ team, id }}>
                    <Text>{displayName}</Text>
                  </PuffaHyperlink>
                </td>
                <td className={classNames({ clickable: id && team })}>
                  {collidingNudges.map(({ id, name }) => (
                    <PuffaHyperlink route="/nudge/:team/:id" params={{ team, id }} key={id}>
                      <Text>{`${name} - ${id}`}</Text>
                    </PuffaHyperlink>
                  ))}
                </td>
                <td>
                  <Text>
                    {t('component.collision_table.market', {
                      market: retailUnits.join(', '),
                      interpolation: { escapeValue: false },
                    })}
                  </Text>
                  <Text>
                    {t('component.collision_table.page', {
                      page,
                      interpolation: { escapeValue: false },
                    })}
                  </Text>
                </td>
              </tr>
            )
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default CollisionTable;
