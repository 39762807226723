import React, { useState } from 'react';
import Button from '@ingka/button';
import plus from '@ingka/ssr-icon/paths/plus';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NudgePicker from '../../components/NudgePicker';
import Page, { PageHeader } from '../../components/Page';
import { setTab } from '../../features/app/actions';
import {
  selectActiveTab,
  selectOverviewOrchestrations,
  selectOverviewWidgets,
  selectOverviewNudgeGroups,
} from '../../features/app/selectors';
import { usePermission } from '../../features/auth/hooks';
import { useAppDispatch } from '../../store';
import NudgeGroupOverview from './NudgeGroupOverview';
import OrchestrationOverview from './OrchestrationOverview';
import { TabNames } from './types';
import WidgetOverview from './WidgetOverview';

const OverviewPage: React.FC = () => {
  const [nudgePickerOpen, setNudgePickerOpen] = useState(false);
  const orchestrations = useSelector(selectOverviewOrchestrations);
  const widgets = useSelector(selectOverviewWidgets);
  const nudgeGroups = useSelector(selectOverviewNudgeGroups);
  const activeTab = useSelector(selectActiveTab);
  const { teams, isLoading, isAllowed } = usePermission('EDIT');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <>
      <NudgePicker
        open={nudgePickerOpen}
        close={() => setNudgePickerOpen(false)}
        isAllowed={isAllowed}
        teams={teams}
      />
      <Page
        loading={isLoading || !orchestrations || !widgets || !nudgeGroups}
        padded
        header={
          <PageHeader
            title={t('global.overview')}
            actions={[
              <Button
                key="create"
                type="primary"
                text={t('global.create')}
                ssrIcon={plus}
                disabled={isLoading || !isAllowed}
                onClick={() => setNudgePickerOpen(true)}
              />,
            ]}
          />
        }
      >
        <Tabs
          activeTab={activeTab}
          onTabChanged={(tabName) => dispatch(setTab(tabName))}
          tabs={[
            <Tab key="features" text={t('global.nudges')} tabPanelId={TabNames.Features} />,
            <Tab key="widgets" text={t('global.nudge_designs')} tabPanelId={TabNames.Widgets} />,
            <Tab
              key="nudge_groups"
              text={t('global.nudge_groups')}
              tabPanelId={TabNames.NudgeGroups}
            />,
          ]}
          tabPanels={[
            <TabPanel key="features" tabPanelId={TabNames.Features}>
              <OrchestrationOverview orchestrations={orchestrations} />
            </TabPanel>,
            <TabPanel key="widgets" tabPanelId={TabNames.Widgets}>
              <WidgetOverview widgets={widgets} />
            </TabPanel>,
            <TabPanel key="nudge_groups" tabPanelId={TabNames.NudgeGroups}>
              {nudgeGroups && (
                <NudgeGroupOverview nudgeGroups={nudgeGroups} orchestrations={orchestrations} />
              )}
            </TabPanel>,
          ]}
        />
      </Page>
    </>
  );
};

export default OverviewPage;
