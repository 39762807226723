import { useCallback, useEffect, useState } from 'react';
import Text from '@ingka/text';
import { FormValue, Input, LayoutConfiguration } from '@nfw/form-types';
import { usePrevious } from '@nfw/ui/common';
import { trimFn } from '@nfw/utils';
import { FormLayout } from './FormLayout';
import './index.scss';

type InferredInput<T> = T extends { [key: string]: Input } ? Partial<T> : Input;

export type FormCallback<T> = (data: {
  input?: InferredInput<T>;
  value?: T;
  previous?: T;
  valid: boolean;
}) => void;

export type FormProps<T> = {
  prefix?: string;
  disabled?: boolean;
  config: LayoutConfiguration;
  purify?: (value: string) => string;
  header?: string;
  /**
   * If validationFn is successful: returns the typed value.
   * If form is invalid or validationFn is invalid, returns valid === false.
   */
  validationFn: (value: unknown) => value is T;
  onChange: FormCallback<T>;
  defaultFormValue?: Partial<T>;
};

export function Form<T>({
  prefix,
  disabled,
  config,
  header,
  onChange,
  purify = trimFn,
  validationFn,
  defaultFormValue,
}: FormProps<T>) {
  const [input, setInput] = useState<InferredInput<T>>();
  const [isFormValid, setFormValid] = useState(false);
  const previousInput = usePrevious(input);

  const onChangeInternal = useCallback((_: string, valid: boolean, value?: Input) => {
    setInput(value as InferredInput<T>);
    setFormValid(valid);
  }, []);

  useEffect(() => {
    let value;
    const valid = isFormValid && validationFn(input);
    if (valid) {
      value = input;
    }

    let previous;
    if (validationFn(previousInput)) {
      previous = previousInput;
    }

    onChange({ input, value, previous, valid });
  }, [isFormValid, input, previousInput, validationFn, onChange]);

  return (
    <>
      {header && (
        <Text headingSize="xs" className="nfw-header">
          {header}
        </Text>
      )}
      <form
        onSubmit={(form) => {
          form.preventDefault();
          return false;
        }}
      >
        <fieldset disabled={disabled}>
          <FormLayout
            prefix={prefix}
            config={config}
            onChange={onChangeInternal}
            purify={purify}
            defaultFormValue={defaultFormValue as FormValue}
            context={{ input: input as Input | undefined }}
          />
        </fieldset>
      </form>
    </>
  );
}
