import {
  AlignVisibilityException,
  DistanceVisibilityException,
  IntersectingVisibilityException,
  NudgeVisibilityRule,
  VisibilityException,
  VisibilityExceptionType,
  VisibilityExceptionTypes,
  VisibilityType,
  VisibilityTypes,
} from '@nfw/nudge/types';
import {
  isArrayOfType,
  isBoolean,
  isNaturalNumber,
  isObject,
  isOptionallyDefined,
} from '@nfw/utils';

/**
 * Check if a visibility type is valid.
 *
 * @param type - the type to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isVisibilityType = (type: unknown): type is VisibilityType =>
  Object.values(VisibilityTypes).includes(type as VisibilityType);

/**
 * Check if a visibility exception type is valid.
 *
 * @param type - the type to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isVisibilityExceptionType = (type: unknown): type is VisibilityExceptionType =>
  Object.values(VisibilityExceptionTypes).includes(type as VisibilityExceptionType);

/**
 * Check if a distance visibility exception is valid.
 *
 * @param e - the exception to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isDistanceVisibilityException = (e: unknown): e is DistanceVisibilityException =>
  isObject(e) &&
  (e.type === VisibilityExceptionTypes.DistanceGreaterThan ||
    e.type === VisibilityExceptionTypes.DistanceLessThan) &&
  isNaturalNumber(e.distance) &&
  isBoolean(e.show);

/**
 * Check if an align visibility exception is valid.
 *
 * @param e - the exception to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isAlignVisibilityException = (e: unknown): e is AlignVisibilityException =>
  isObject(e) &&
  (e.type === VisibilityExceptionTypes.VerticallyAligned ||
    e.type === VisibilityExceptionTypes.HorizontallyAligned) &&
  isNaturalNumber(e.range) &&
  isBoolean(e.show);

/**
 * Check if an intersecting visibility exception is valid.
 *
 * @param e - the exception to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isIntersectingVisibilityException = (
  e: unknown
): e is IntersectingVisibilityException =>
  isObject(e) &&
  e.type === VisibilityExceptionTypes.Intersecting &&
  isBoolean(e.intersecting) &&
  isBoolean(e.show);

/**
 * Check if an exception is a visibility exception.
 *
 * @param e - the exception to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isVisibilityException = (e: unknown): e is VisibilityException =>
  isDistanceVisibilityException(e) ||
  isAlignVisibilityException(e) ||
  isIntersectingVisibilityException(e);

/**
 * Check if the rule is valid.
 *
 * @param rule - the rule to check.
 *
 * @returns true if the rule is a valid, otherwise false.
 */
export const isNudgeVisibilityRule = (rule: unknown): rule is NudgeVisibilityRule =>
  isObject(rule) &&
  isVisibilityType(rule.when) &&
  isOptionallyDefined(rule.exceptions, (exceptions) =>
    isArrayOfType(exceptions, isVisibilityException)
  );
