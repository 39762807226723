import React, { useCallback } from 'react';
import Text from '@ingka/text';
import { FeatureNudge, StoredWidget, isFeatureNudge } from '@nfw/orchestration-types';
import { Form, FormCallback } from '@nfw/ui/form';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { useNudgeLayout } from '../../../features/orchestrations/hooks';
import './index.scss';

export type NudgeFormProps = {
  widget: StoredWidget;
  widgets: StoredWidget[];
  team: string;
  onValid: (nudge: FeatureNudge) => void;
  onInvalid: (id: string) => void;
  defaultFeatureNudge?: FeatureNudge;
  defaultId?: string;
};

export const NudgeForm: React.FC<NudgeFormProps> = ({
  widget,
  widgets,
  team,
  onValid,
  onInvalid,
  defaultFeatureNudge,
  defaultId,
}) => {
  const layout = useNudgeLayout(widget, widgets, team, defaultId);
  const { t } = useTranslation();

  const onChangeInternal = useCallback<FormCallback<FeatureNudge>>(
    ({ value, previous, valid }) => {
      if (value) {
        onValid(value);
      }

      if (!valid && previous) {
        onInvalid(previous.widgetRef);
      }
    },
    [onValid, onInvalid]
  );

  return (
    <div className="nudge-forms">
      <Text headingSize="s" className="title">
        {t('form.feature.customize_header')}
      </Text>
      <Form
        config={layout}
        purify={DOMPurify.sanitize}
        onChange={onChangeInternal}
        validationFn={isFeatureNudge}
        defaultFormValue={defaultFeatureNudge}
      />
    </div>
  );
};

export default NudgeForm;
