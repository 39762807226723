import { toPath } from '@nfw/utils';

/**
 * The base path.
 */
export const BasePath = '/api';

/**
 * Available routes.
 */
export const Routes = {
  Audits: '/audits/:id',

  Configuration: '/configuration/:retailUnit',
  FrameworkConfiguration: '/framework-configuration',

  Experiment: '/experiments/teams/:team/orchestrations/:id',
  ExperimentDecision: '/experiments/team/:team/orchestrations/:id/decision',
  ExperimentReport: '/experiments/reports/orchestrations/:id',
  ExperimentEvents: '/experiments/events',
  ExperimentMetrics: '/experiments/orchestrations/:id/metrics',
  ExperimentResults: '/experiment/results',
  DefaultMetrics: '/experiments/metrics/:namespace',

  Features: '/features',
  Feature: '/features/:id',
  FeatureNudgeGroups: '/features/nudge-groups/:id',
  FeatureCollisions: '/collisions',
  TeamFeatures: '/teams/:team/features',
  TeamFeature: '/teams/:team/features/:id',
  RepublishTeamFeature: '/teams/:team/features/:id/republish',

  Deployments: '/deployments',
  Deployment: '/deployments/:id',

  NudgeMetric: '/metrics/:id',
  NudgeGroupMetric: '/nudge-groups/metrics/:id',
  NudgeOfFameMetrics: '/nudge-of-fame',

  NudgeGroups: '/nudge-groups',
  NudgeGroup: '/nudge-groups/:id',
  ArchiveNudgeGroup: '/nudge-groups/teams/:team/:id/archive',
  TeamNudgeGroups: '/teams/:team/nudge-groups',
  TeamNudgeGroup: '/teams/:team/nudge-groups/:id',

  Publish: '/teams/:team/deployments/:id/publish',
  Archive: '/teams/:team/deployments/:id/archive',

  IkeaWebStructure: '/ikea-web-structure',
  IkeaStores: '/ikea-stores',

  Widgets: '/widgets',
  Widget: '/widgets/:id',
  WidgetRequest: '/widgets/requests',
  TeamWidgets: '/widgets/teams/:team',
  TeamWidget: '/widgets/teams/:team/:id',
  ArchiveTeamWidget: '/widgets/teams/:team/:id/archive',
  WidgetInUse: '/widgets/:id/teams/:team/orchestrations',

  TeamConfigurations: '/teams',
  TeamConfiguration: '/teams/:team',
  TeamStatus: '/teams/:team/status/:status',

  Permissions: '/permissions',

  Translations: '/i18n/teams/:team',
  TranslationsByLocale: '/i18n/teams/:team/:locale',
  AppTranslations: '/i18n/translation/:locale',
  TranslationManager: '/translation-manager',
  MarketTranslations: '/market/teams/:team/translations',

  LockResource: '/lock/acquire',
  ReleaseResource: '/lock/release',
} as const;

export type Route = (typeof Routes)[keyof typeof Routes];

/**
 * Get the full path parameterized.
 *
 * @param route - the route path.
 * @param params - the route params.
 *
 * @returns the full path.
 */
export const getPath = (route: Route, params?: Record<string, string>): string =>
  params ? toPath(BasePath.concat(route), params) : BasePath.concat(route);
