import {
  ListValue,
  MessageWidget,
  ModalWidget,
  ReactionWidget,
  TabWidget,
  TipWidget,
  ToastWidget,
  ValueStatement,
  Widget,
  WidgetType,
  WidgetTypes,
  PromptWidget,
  AspectRatio,
  AspectRatios,
  HorizontalAlign,
  HorizontalAligns,
  Image,
  EmbeddedWidget,
  OverlayWidget,
  TeaserWidget,
  InlineMessageWidget,
} from '@nfw/nudge/types';
import {
  isArrayOfType,
  isBoolean,
  isObject,
  isOptionallyDefined,
  isString,
  isStringArray,
} from '@nfw/utils';
import { isAction, isButtonAction } from './action';
import { isIconType } from './icon';

/**
 * Check if the type is a valid widget type.
 *
 * @param type - the type to check.
 *
 * @returns true if the type is a valid widget type.
 */
export const isWidgetType = (type: unknown): type is WidgetType =>
  Object.values(WidgetTypes).includes(type as WidgetType);

/**
 * Check if a value statement is valid.
 *
 * @param s - the value statement to test.
 *
 * @returns true if value statement is valid.
 */
export const isValueStatement = (s: unknown): s is ValueStatement =>
  isObject(s) &&
  isOptionallyDefined(s.mood, isString) &&
  isOptionallyDefined(s.icon, isIconType) &&
  isString(s.statement);

/**
 * Check if an aspect ratio is valid.
 *
 * @param a - the aspect ratio to check.
 *
 * @returns true if the aspect ratio is valid.
 */
export const isAspectRatio = (a: unknown): a is AspectRatio =>
  Object.values(AspectRatios).includes(a as AspectRatio);

/**
 * Check if an image is valid.
 *
 * @param i - the image to test.
 *
 * @returns true if valid.
 */
export const isImage = (i: unknown): i is Image =>
  isObject(i) &&
  isString(i.imageSrc) &&
  isAspectRatio(i.aspectRatio) &&
  isOptionallyDefined(i.altText, isString);

/**
 * Check if the a is a valid modal alignment.
 *
 * @param a - the alignment to check.
 *
 * @returns true if the alignment is a valid modal alignment.
 */
export const isHorizontalAlign = (a: unknown): a is HorizontalAlign =>
  Object.values(HorizontalAligns).includes(a as HorizontalAlign);

/**
 * Check if a list value is valid.
 *
 * @param li - the list value to test.
 *
 * @returns true if list value is valid.
 */
export const isListValue = (li: unknown): li is ListValue =>
  isObject(li) && isOptionallyDefined(li.ordered, isBoolean) && isStringArray(li.statements);

/**
 * Check if a widget has a certain widget type.
 *
 * @param widget - the widget to test.
 * @param type - the widget type.
 *
 * @returns true if the nudge is a base nudge.
 */
const isWidgetOfType = (widget: unknown, type: WidgetType): widget is Record<string, unknown> =>
  isObject(widget) && widget.type === type && isString(widget.text);

/**
 * Check if a widget is a valid teaser widget.
 *
 * @param widget - the widget to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isTeaserWidget = (widget: unknown): widget is TeaserWidget =>
  isWidgetOfType(widget, WidgetTypes.Teaser) &&
  isString(widget.title) &&
  isImage(widget.image) &&
  isButtonAction(widget.action);

/**
 * Check if a widget is a valid inline message widget.
 *
 * @param widget - the widget to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isInlineMessageWidget = (widget: unknown): widget is InlineMessageWidget =>
  isWidgetOfType(widget, WidgetTypes.InlineMessage) &&
  isString(widget.title) &&
  isString(widget.variant) &&
  isOptionallyDefined(widget.subtle, isBoolean) &&
  isOptionallyDefined(
    widget.link,
    (value) => isObject(value) && isString(value.href) && isString(value.label)
  ) &&
  isAction(widget.action);

/**
 * Check if a widget is a valid tab widget.
 *
 * @param widget - the widget to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isTabWidget = (widget: unknown): widget is TabWidget =>
  isWidgetOfType(widget, WidgetTypes.Tab) &&
  isButtonAction(widget.action) &&
  isOptionallyDefined(widget.accentuate, isBoolean);

/**
 * Check if widget is a valid reaction widget.
 *
 * @param widget - the widget to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isReactionWidget = (widget: unknown): widget is ReactionWidget =>
  isWidgetOfType(widget, WidgetTypes.Reaction) && isArrayOfType(widget.reactions, isIconType);

/**
 * Check if a widget is a valid tip widget.
 *
 * @param widget - the widget to test.
 *
 * @returns true if the nudge is a tip nudge.
 */
export const isTipWidget = (widget: unknown): widget is TipWidget =>
  isWidgetOfType(widget, WidgetTypes.Tip);

/**
 * Check if the widget is a valid toast widget.
 *
 * @param widget - the widget to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isToastWidget = (widget: unknown): widget is ToastWidget =>
  isWidgetOfType(widget, WidgetTypes.Toast) && isAction(widget.action);

/**
 * Check if the widget is a valid message widget.
 *
 * @param widget - the widget to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isMessageWidget = (widget: unknown): widget is MessageWidget =>
  isWidgetOfType(widget, WidgetTypes.Message) && isOptionallyDefined(widget.action, isAction);

/**
 * Check if the widget is a modal widget.
 *
 * @param widget - the widget to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isModalWidget = (widget: unknown): widget is ModalWidget =>
  isWidgetOfType(widget, WidgetTypes.Modal) &&
  isArrayOfType(widget.actions, isButtonAction, 1) &&
  isString(widget.title) &&
  isOptionallyDefined(widget.ctaText, (value) => isArrayOfType(value, isString)) &&
  isOptionallyDefined(widget.valueStatements, (value) => isArrayOfType(value, isValueStatement)) &&
  isOptionallyDefined(widget.list, isListValue) &&
  isOptionallyDefined(widget.backgroundColor, isString) &&
  isOptionallyDefined(widget.horizontalAlignment, isHorizontalAlign) &&
  isOptionallyDefined(widget.size, isString) &&
  isOptionallyDefined(widget.header, isImage) &&
  isOptionallyDefined(widget.footerDivider, isBoolean);

/**
 * Check if the widget is a prompt widget.
 *
 * @param widget - the widget to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isPromptWidget = (widget: unknown): widget is PromptWidget =>
  isWidgetOfType(widget, WidgetTypes.Prompt) &&
  isString(widget.title) &&
  isOptionallyDefined(widget.valueStatements, (value) => isArrayOfType(value, isValueStatement)) &&
  isArrayOfType(widget.actions, isButtonAction, 1) &&
  isOptionallyDefined(widget.footerDivider, isBoolean);

/**
 * Check if a widget is a valid widget.
 *
 * @param widget - the widget to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isWidget = (widget: unknown): widget is Widget =>
  isTeaserWidget(widget) ||
  isTabWidget(widget) ||
  isReactionWidget(widget) ||
  isTipWidget(widget) ||
  isMessageWidget(widget) ||
  isToastWidget(widget) ||
  isModalWidget(widget) ||
  isPromptWidget(widget);

/**
 * Check if a widget is a valid embedded widget.
 *
 * @param widget - the widget to check.
 *
 * @returns true if valid, otherwise false.
 */
export const isEmbeddedwidget = (widget: unknown): widget is EmbeddedWidget =>
  isTeaserWidget(widget) || isInlineMessageWidget(widget);

/**
 * Check if a widget is a valid overlay widget.
 *
 * @param widget - the widget to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isOverlayWidget = (widget: unknown): widget is OverlayWidget =>
  isEmbeddedwidget(widget) ? false : isWidget(widget);

export /**
 * Check if an array of widgets is valid.
 *
 * @param widgets - the widgets to test.
 *
 * @returns true if valid, otherwise false.
 */
const isWidgets = (widgets: unknown): widgets is Widget[] => isArrayOfType(widgets, isWidget);
