import { useMemo } from 'react';
import { OrchestrationCollision } from '@nfw/nudge-collision';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import GenericFilter, { MarketFilter, NamedFilter, SearchFilterFunction } from '../GenericFilter';

const searchFilter: SearchFilterFunction<OrchestrationCollision> = (searchValue, value) =>
  value ? value.displayName.toLowerCase().includes(searchValue) : true;

const getNamedFilters = (t: TFunction): NamedFilter<OrchestrationCollision>[] => [
  {
    id: 'displayName',
    label: t('global.nudges'),
    distinctValues: (value: OrchestrationCollision) => value.displayName,
    filter: (values: string[], value: OrchestrationCollision) => values.includes(value.displayName),
  },
  {
    id: 'pages',
    label: t('global.page'),
    distinctValues: (value: OrchestrationCollision) => value.page,
    filter: (values: string[], value: OrchestrationCollision) => values.includes(value.page),
  },
];

const getMarketFilter = (t: TFunction): MarketFilter<OrchestrationCollision> => ({
  id: 'market',
  label: t('global.markets'),
  distinctValues: (value: OrchestrationCollision) => value.retailUnits,
  filter: (values: string[], value: OrchestrationCollision) =>
    value.retailUnits.some((market) => values.includes(market)),
});

const CollisionFilter: React.FC<{
  collisions: OrchestrationCollision[];
  onFilterChange: (data: OrchestrationCollision[]) => void;
}> = ({ collisions, onFilterChange }) => {
  const { t } = useTranslation();
  const namedFilters = useMemo(() => getNamedFilters(t), [t]);
  const marketFilter = useMemo(() => getMarketFilter(t), [t]);

  return (
    <GenericFilter
      type="collision"
      data={collisions}
      onFilterChange={onFilterChange}
      searchFilter={searchFilter}
      marketFilter={marketFilter}
      namedFilters={namedFilters}
      searchLabel={t('global.filter.placeholder')}
      compact
    />
  );
};

export default CollisionFilter;
