import { ButtonProps } from '@ingka/button';
import classNames from 'classnames';
import './index.scss';

export type FormFooterProps = {
  children: React.ReactElement<ButtonProps> | React.ReactElement<ButtonProps>[];
  singleColumn?: boolean;
};

const FormFooter: React.FC<FormFooterProps> = ({ children, singleColumn }) => {
  return (
    <div className={classNames('form-footer-container', { 'single-column': singleColumn })}>
      {children}
    </div>
  );
};

export default FormFooter;
