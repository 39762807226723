import { ReactElement, useEffect, useRef, useState } from 'react';
import Switch from '@ingka/switch';
import Toggle from '@ingka/toggle';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import JSONPretty from 'react-json-pretty';
import { appendStyleTags } from './utils';
import './index.scss';

export interface CanvasProps {
  canShowDesign: boolean;
  designOnly?: boolean;
  className?: string;
  component?: ReactElement;
  json?: object;
}

const Canvas: React.FC<CanvasProps> = ({
  canShowDesign,
  component,
  className,
  json,
  designOnly,
}) => {
  const ref = useRef<HTMLIFrameElement>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (ref.current?.contentDocument?.head) {
      const unsubscribe = appendStyleTags(ref.current.contentDocument.head);
      const newStyleElement = document.createElement('style');
      newStyleElement.textContent = `
      body {
        background: transparent;
      }
    `;
      ref.current.contentDocument.head.appendChild(newStyleElement);
      return unsubscribe;
    }
  }, [ref]);

  useEffect(() => {
    if (!canShowDesign) {
      setActiveIndex(0);
    }
  }, [canShowDesign]);

  return designOnly ? (
    <>
      <iframe
        ref={ref}
        className={classNames('iframe', className, { mobile: activeIndex === 1 })}
        id="canvas"
        title="Design canvas"
      >
        {ref.current?.contentDocument?.body &&
          createPortal(component, ref.current.contentDocument.body)}
      </iframe>
      <div className="mobile-switch">
        <Switch
          id="mobile-switch"
          className="switch"
          value={activeIndex === 1 ? 'true' : 'false'}
          onChange={() => setActiveIndex((prevIndex) => (prevIndex === 0 ? 1 : 0))}
          label={t('global.preview.mobile')}
        />
      </div>
    </>
  ) : (
    <>
      <Toggle
        className="preview-toggle"
        activeIndex={activeIndex}
        buttons={[
          {
            text: t('global.code'),
            disabled: !json,
          },
          {
            text: t('component.progress_indicator.design_third'),
            disabled: !component || !canShowDesign,
          },
        ]}
        onClick={(_, index) => setActiveIndex(index)}
      />

      <iframe
        ref={ref}
        className={classNames('iframe', className)}
        id="canvas"
        title="Design canvas"
      >
        {ref.current?.contentDocument?.body &&
          createPortal(
            activeIndex === 0 ? (
              <JSONPretty silent className="json-pretty" data={json} />
            ) : (
              component
            ),
            ref.current.contentDocument.body
          )}
      </iframe>
    </>
  );
};

export default Canvas;
