import { isLanguage, isRetailUnit, Language, RetailUnit } from '@nfw/ikea/retail';
import { getPathDirectories } from '@nfw/utils';

/**
 * The valid namespaces. This should be updated when a new team is onboarded.
 * The namespace is constructed as <market>/<language>/<namespace> ex: se/sv/p/ for pip pages.
 */
export const Namespaces = {
  Appointment: 'appointment',
  BusinessStore: 'business-store',
  Catalogue: 'cat',
  CustomerService: 'customer-service',
  Favourites: 'favourites',
  Home: 'home',
  LoyaltyHub: 'loyalty-hub',
  Offers: 'offers',
  Pip: 'p',
  Purchases: 'purchases',
  Rooms: 'rooms',
  Planners: 'planners',
  Profile: 'profile',
  ShoppingCart: 'shoppingcart',
  Search: 'search',
  New: 'new',
  Campaigns: 'campaigns',
} as const;
/**
 * The namespace of the page is the path directory after the ru/lang part.
 *
 * Example: www.ikea.com/se/sv/:namespace/../
 */
export type Namespace = (typeof Namespaces)[keyof typeof Namespaces];

/**
 * Check if a namespace candidate is a namespace.
 *
 * @param ns - the namespace to test.
 *
 * @returns true if the namespace is indeed a namespace.
 */
export const isNamespace = (ns: unknown): ns is Namespace =>
  Object.values(Namespaces).includes(ns as Namespace);

/**
 * Check if a namespace is a namespace that is under the catalogue structure.
 *
 * @param namespace - the namespace to check.
 *
 * @returns true when catalogue page, otherwise false.
 */
export const isCataloguePage = (namespace: unknown): namespace is Namespace =>
  (['cat', 'p', 'rooms'] as Namespace[]).includes(namespace as Namespace);

/**
 * Conventionally a page on ikea.com has a name followed by an id.
 *
 * Examples:
 * - https://www.ikea.com/xx/yy/p/some-name-id
 * - https://www.ikea.com/xx/yy/cat/name-name-id
 *
 * @param dirs - the dirs after market, language and namespace.
 *
 * @returns the id of the page or undefined.
 */
export const getPageId = (dirs: string[]) => [...dirs].pop()?.split('-').pop();

export type PathInfo = {
  retailUnit: RetailUnit;
  language: Language;
  namespace: Namespace;
  subDirectories: string[];
  subPath: string;
  pageId: string | undefined;
};

/**
 * Get the path info of the page loaded.
 *
 * Use with care this will include the entire Namespaces object.
 *
 * @param path - the request path.
 *
 * @returns the page info or undefined if no match.
 */
export const getPathInfo = (path: string): PathInfo | undefined => {
  const [retailUnit, language, ns, ...subDirectories] = getPathDirectories(path);

  const namespace = ns ?? Namespaces.Home;
  let info: PathInfo | undefined;
  if (isRetailUnit(retailUnit) && isLanguage(language) && isNamespace(namespace)) {
    info = {
      retailUnit,
      language,
      namespace,
      subDirectories,
      subPath: subDirectories.join('/'),
      pageId: getPageId(subDirectories),
    };
  }
  return info;
};
