import { getEnvironmentState } from '@nfw/deployment-types';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { selectNudgeGroups } from '../nudgeGroups/selectors';
import { selectDeployments, selectOrchestrations } from '../orchestrations/selectors';
import { selectTeams } from '../teams/selectors';
import { selectWidgets } from '../widgets/selectors';
import {
  OverviewOrchestration,
  OverviewWidget,
  OverviewLayoutType,
  OverviewNudgeGroup,
} from './types';

const selectLocalState = (state: RootState) => state.app;

export const selectSessionId = createSelector(selectLocalState, (state) => state.sessionId);

export const selectToast = createSelector(selectLocalState, (state) => state.toast);
export const selectActiveTab = createSelector(selectLocalState, (state) => state.activeTab);

export const selectFocusPortalEnabled = createSelector(
  selectLocalState,
  (state) => state.focusPortalEnabled
);

export const selectOverviewOrchestrations = createSelector(
  selectOrchestrations,
  selectTeams,
  selectDeployments,
  (orchestrations, teams, deployments) =>
    orchestrations.reduce<OverviewOrchestration[]>((acc, current) => {
      const { id, team } = current;
      const deployment = deployments.find((deployment) => deployment.id === id);
      const teamconfig = teams.find((config) => config.id === team);

      if (deployment && teamconfig) {
        const { consolidated } = getEnvironmentState(deployment);

        acc.push({
          ...current,
          consolidatedStatus: consolidated,
          teamName: teamconfig.name,
        });
      }
      return acc;
    }, [])
);

export const selectNudgeGroupOrchestrations = createSelector(
  [selectOverviewOrchestrations, (_: RootState, groupId: string) => groupId],
  (orchestrations, groupId) => orchestrations.filter((o) => o.groupId === groupId)
);

export const selectOverviewWidgets = createSelector(selectWidgets, selectTeams, (widgets, teams) =>
  widgets.reduce<OverviewWidget[]>((acc, current) => {
    const teamconfig = teams?.find((config) => config.id === current.team);
    if (teamconfig) {
      acc.push({ ...current, teamName: teamconfig.name });
    }
    return acc;
  }, [])
);

export const selectOverviewNudgeGroups = createSelector(
  selectNudgeGroups,
  selectTeams,
  (nudgeGroups, teams) =>
    nudgeGroups.reduce<OverviewNudgeGroup[]>((acc, current) => {
      const teamconfig = teams?.find((config) => config.id === current.team);
      if (teamconfig) {
        acc.push({ ...current, teamName: teamconfig.name });
      }
      return acc;
    }, [])
);

export const selectInUseOrchestrations = createSelector(
  [selectOverviewOrchestrations, (_, id: string) => id],
  (orchestrations, id) =>
    orchestrations.filter(
      ({ featureNudge, isArchived }) => !isArchived && featureNudge.widgetRef === id
    )
);

export const selectWidgetPublished = createSelector([selectInUseOrchestrations], (orchestrations) =>
  orchestrations.some(({ consolidatedStatus }) => consolidatedStatus.status !== 'draft')
);

export const selectFilterSelections = createSelector(
  selectLocalState,
  (state) => state.filterSelections
);

export const selectWhichFilterSelections = createSelector(
  [selectFilterSelections, (_, which: string) => which],
  (selections, which) => selections.filter((selection) => selection.which === which)
);

export const selectFilterValues = createSelector(
  [
    selectFilterSelections,
    (_, which: string, id: string) => ({
      which,
      id,
    }),
  ],
  (selections, args) => selections.filter(({ id, which }) => id === args.id && which === args.which)
);
export const selectOverviewLayouts = createSelector(
  selectLocalState,
  (state) => state.overviewlayout
);
export const selectOverviewLayout = createSelector(
  [selectOverviewLayouts, (_, which: OverviewLayoutType) => which],
  (layout, which) => layout[which]
);
export const selectSortValue = createSelector(selectLocalState, (state) => state.sort);
export const selectExperimentIterationMessageDismissed = createSelector(
  selectLocalState,
  (state) => state.experimentIterationMessageDismissed
);
