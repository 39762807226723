import React, { useState } from 'react';
import DetailCard from '../../../components/DetailCard';
import { WidgetFilter } from '../../../components/filters';
import LinearLayout from '../../../components/LinearLayout';
import StateIndicator from '../../../components/StateIndicator';
import { selectOverviewLayout } from '../../../features/app/selectors';
import { OverviewWidget } from '../../../features/app/types';
import { useMemoizedSelector } from '../../../store';

export type WidgetOverviewProps = {
  widgets?: OverviewWidget[];
};

const WidgetOverview: React.FC<WidgetOverviewProps> = ({ widgets = [] }) => {
  const layoutIndex = useMemoizedSelector(selectOverviewLayout, 'widgets');
  const [filteredWidgets, setFilteredWidgets] = useState(widgets);

  return (
    <>
      <WidgetFilter data={widgets} onFilterChange={setFilteredWidgets} layoutIndex={layoutIndex} />
      <LinearLayout horizontal gap="xl" wrap>
        {filteredWidgets.map(
          ({ id, isArchived, name, creatorName, team, teamName, type, created }) => (
            <DetailCard
              key={id}
              id={id}
              status={isArchived ? <StateIndicator isArchived /> : undefined}
              title={name}
              team={teamName}
              info={type}
              creator={creatorName}
              created={created}
              navigateTo="/design/:team/:id"
              navigateToParams={{ team, id }}
            />
          )
        )}
      </LinearLayout>
    </>
  );
};

export default WidgetOverview;
