import {
  CartAddTrigger,
  CartRemoveTrigger,
  DOMEventName,
  DOMEventNames,
  DOMEventTrigger,
  ElementInViewportTrigger,
  EpisodEventTrigger,
  ExitIntentTrigger,
  FavouriteAddTrigger,
  FavouriteRemoveTrigger,
  IdleTrigger,
  InitTrigger,
  MaxDelayTime,
  NativeTrigger,
  RegExpMatcherTrigger,
  SearchParamTrigger,
  StoreChangeTrigger,
  Trigger,
  TriggerType,
  TriggerTypes,
} from '@nfw/nudge/types';
import {
  isArrayOfType,
  isNaturalNumber,
  isObject,
  isOptionallyDefined,
  isString,
} from '@nfw/utils';

/**
 * Check if the trigger type is a valid trigger type.
 *
 * @param type - the type to test.
 *
 * @returns true if the event is a trigger event, otherwise false.
 */
export const isTriggerType = (type: unknown): type is TriggerType =>
  Object.values(TriggerTypes).includes(type as TriggerType);

/**
 * Check if the event is a DOM event name.
 *
 * @param event - the event to test.
 *
 * @returns true if the event is a DOM event name, otherwise false.
 */
export const isDOMEventName = (event: unknown): event is DOMEventName =>
  Object.values(DOMEventNames).includes(event as DOMEventName);

/**
 * Check if the trigger is a certain type.
 *
 * @param trigger - the trigger to test.
 * @param type - the type of the trigger.
 *
 * @returns true if the trigger is of a certain type.
 */
const isTriggerOfType = (trigger: unknown, type: TriggerType): trigger is Record<string, unknown> =>
  isObject(trigger) &&
  trigger.type == type &&
  isOptionallyDefined(trigger.delay, (d) => isNaturalNumber(d, MaxDelayTime));

/**
 * Check if a trigger is a valid episod event trigger.
 *
 * @param trigger - the trigger to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isEpisodEventTrigger = (trigger: unknown): trigger is EpisodEventTrigger =>
  isTriggerOfType(trigger, TriggerTypes.EpisodEvent) &&
  isString(trigger.category) &&
  isString(trigger.action);

/**
 * Check if the trigger is a valid init trigger.
 *
 * @param trigger - the trigger to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isInitTrigger = (trigger: unknown): trigger is InitTrigger =>
  isTriggerOfType(trigger, TriggerTypes.Init);

/**
 * Check if the trigger is a valid regexp matcher trigger.
 *
 * @param trigger - the trigger to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isRegExpMatcherTrigger = (trigger: unknown): trigger is RegExpMatcherTrigger =>
  isTriggerOfType(trigger, TriggerTypes.RegExpMatcher) && isArrayOfType(trigger.regExps, isString);

/**
 * Check if the trigger is a valid exit intent trigger.
 *
 * @param trigger - the trigger to use.
 *
 * @returns true when valid, otherwise false.
 */
export const isExitIntentTrigger = (trigger: unknown): trigger is ExitIntentTrigger =>
  isTriggerOfType(trigger, TriggerTypes.ExitIntent);

/**
 * Check if the trigger is a dom event trigger.
 *
 * @param trigger - the trigger to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isDOMEventTriggerOfType = <T extends DOMEventName>(
  trigger: unknown,
  eventName: T
): trigger is DOMEventTrigger<T> =>
  isTriggerOfType(trigger, TriggerTypes.DomEvent) && trigger.domEventName === eventName;

/**
 * Check if the trigger is a valid dom event trigger.
 *
 * @param trigger - the trigger to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isDOMEventTrigger = (trigger: unknown): trigger is DOMEventTrigger<DOMEventName> =>
  Object.values(DOMEventNames).some((name) => isDOMEventTriggerOfType(trigger, name));

/**
 * Check if the trigger is a valid idle trigger.
 *
 * @param trigger - the trigger to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isIdleTrigger = (trigger: unknown): trigger is IdleTrigger =>
  isTriggerOfType(trigger, TriggerTypes.Idle);

/**
 * Check if a the trigger is a valid native trigger.
 *
 * @param trigger - the trigger to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isNativeTrigger = (trigger: unknown): trigger is NativeTrigger =>
  isTriggerOfType(trigger, TriggerTypes.Native);

/**
 * Check if a trigger is a valid search param trigger.
 *
 * @param trigger - the trigger to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isSearchParamTrigger = (trigger: unknown): trigger is SearchParamTrigger =>
  isTriggerOfType(trigger, TriggerTypes.SearchParam);

/**
 * Check if a trigger is a valid cart added trigger.
 *
 * @param trigger - the trigger to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isCartAddTrigger = (trigger: unknown): trigger is CartAddTrigger =>
  isTriggerOfType(trigger, TriggerTypes.CartItemsAdded);

/**
 * Check if a trigger is a valid cart remove trigger.
 *
 * @param trigger - the trigger to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isCartRemoveTrigger = (trigger: unknown): trigger is CartRemoveTrigger =>
  isTriggerOfType(trigger, TriggerTypes.CartItemsRemoved);

/**
 * Check if a trigger is a valid favourites add trigger.
 *
 * @param trigger - the trigger to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isFavouritesAddTrigger = (trigger: unknown): trigger is FavouriteAddTrigger =>
  isTriggerOfType(trigger, TriggerTypes.FavouritesAdded);

/**
 * Check if a trigger is a valid favourites remove trigger.
 *
 * @param trigger - the trigger to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isFavouritesRemoveTrigger = (trigger: unknown): trigger is FavouriteRemoveTrigger =>
  isTriggerOfType(trigger, TriggerTypes.FavouritesRemoved);

/**
 * Check if a trigger is a valid store change trigger.
 *
 * @param trigger - the trigger to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isStoreChangeTrigger = (trigger: unknown): trigger is StoreChangeTrigger =>
  isTriggerOfType(trigger, TriggerTypes.StoreChange);

/**
 * Check if a trigger is a valid element in viewport trigger.
 *
 * @param trigger - the trigger to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isElementInViewportTrigger = (trigger: unknown): trigger is ElementInViewportTrigger =>
  isTriggerOfType(trigger, TriggerTypes.ElementInViewport) &&
  isString(trigger.value) &&
  isString(trigger.targetType);

/**
 * Check if the trigger is a valid trigger.
 *
 * @param trigger - the trigger to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isTrigger = (trigger: unknown): trigger is Trigger =>
  isInitTrigger(trigger) ||
  isDOMEventTrigger(trigger) ||
  isEpisodEventTrigger(trigger) ||
  isExitIntentTrigger(trigger) ||
  isRegExpMatcherTrigger(trigger) ||
  isIdleTrigger(trigger) ||
  isNativeTrigger(trigger) ||
  isSearchParamTrigger(trigger) ||
  isCartAddTrigger(trigger) ||
  isCartRemoveTrigger(trigger) ||
  isFavouritesAddTrigger(trigger) ||
  isFavouritesRemoveTrigger(trigger) ||
  isStoreChangeTrigger(trigger) ||
  isElementInViewportTrigger(trigger);
