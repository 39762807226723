import { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import { FormContext, FormValue, Input, InputTypes, LayoutConfiguration } from '@nfw/form-types';
import { isDefined, trimFn } from '@nfw/utils';
import { CheckboxGroupField } from '../fields/CheckboxGroupField';

export type FormProps = {
  config: LayoutConfiguration;
  onChange: (name: string, valid: boolean, value?: Input) => void;
  purify?: (value: string) => string;
  defaultFormValue?: FormValue;
  fieldPrefix?: string;
  prefix?: string;
  show?: boolean;
  disabled?: boolean;
  context: FormContext;
};

type ErrorDictionary = Record<string, boolean>;

const CheckboxField = lazy(() => import('../fields/CheckboxField'));
const FixedTextField = lazy(() => import('../fields/FixedTextField'));
const HiddenField = lazy(() => import('../fields/HiddenField'));
const InlineFormControlField = lazy(() => import('../fields/InlineFormControlField'));
const ManyOfManyField = lazy(() => import('../fields/ManyOfManyField'));
const ModalControlField = lazy(() => import('../fields/ModalControlField'));
const ModalFormControlField = lazy(() => import('../fields/ModalFormControlField'));
const QuantityField = lazy(() => import('../fields/QuantityField'));
const RadioButtonGroupField = lazy(() => import('../fields/RadioButtonGroupField'));
const BooleanRadioButtonGroupField = lazy(() => import('../fields/BooleanRadioGroupField'));
const SelectField = lazy(() => import('../fields/SelectField'));
const SliderField = lazy(() => import('../fields/SliderField'));
const SwitchField = lazy(() => import('../fields/SwitchField'));
const TextAreaField = lazy(() => import('../fields/TextAreaField'));
const TextField = lazy(() => import('../fields/TextField'));
const ChoiceField = lazy(() => import('../fields/ChoiceField'));
const PillListbox = lazy(() => import('../fields/PillListboxField'));
const Divider = lazy(() => import('../fields/Divider'));
const InlineMessageField = lazy(() => import('../fields/InlineMessageField'));
const TextFieldGroup = lazy(() => import('../fields/TextFieldGroup'));
const NumberField = lazy(() => import('../fields/NumberField'));
const RankingField = lazy(() => import('../fields/RankingField'));
const HierarchySelectField = lazy(() => import('../fields/HierarchySelectField'));
const ComboboxField = lazy(() => import('../fields/ComboboxField'));
const DatePicker = lazy(() => import('../fields/DatePicker'));

/**
 * The form layout component.
 */
export const FormLayout: React.FC<FormProps> = ({
  show = true,
  disabled = false,
  config,
  onChange,
  purify = trimFn,
  prefix,
  fieldPrefix = 'data',
  defaultFormValue = {},
  context,
}) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState<ErrorDictionary>({});

  const onChangeInternal = useCallback((name: string, value?: Input) => {
    setValues((v) => {
      if (isDefined(value)) {
        return { ...v, [name]: value };
      } else {
        delete v[name];
        return { ...v };
      }
    });
  }, []);

  const onValidChangeInternal = useCallback((name: string, valid: boolean) => {
    setErrors((errors) => ({ ...errors, [name]: valid }));
  }, []);

  useEffect(() => {
    const valid = Object.values(errors).every((valid) => valid);
    onChange(fieldPrefix, valid, show ? values : undefined);
  }, [values, errors, fieldPrefix, show, onChange]);

  return show ? (
    <>
      {config.map((field, index) => {
        const { requiresValueOf, dependsOn } = field;
        field.disabled = field.disabled || disabled;

        let hidden = false;
        if (Array.isArray(dependsOn)) {
          hidden = dependsOn.reduce((acc, dep) => {
            const dependantValue = values[dep.name];
            return acc || Array.isArray(dep.requiresValueOf)
              ? !(dep.requiresValueOf as Input[]).includes(dependantValue)
              : !dep.requiresValueOf(dependantValue, context.input);
          }, false);
        } else if (dependsOn && requiresValueOf) {
          const dependantValue = values[dependsOn];
          hidden = !requiresValueOf.includes(dependantValue);
        }

        switch (field.inputType) {
          case InputTypes.HiddenInput:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <HiddenField config={field} onChange={onChangeInternal} />
              </Suspense>
            );
          case InputTypes.TextInput:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <TextField
                  prefix={prefix}
                  config={field}
                  onChange={onChangeInternal}
                  purify={purify}
                  onValid={onValidChangeInternal}
                  hidden={hidden}
                  defaultValue={defaultFormValue[field.name] as string}
                />
              </Suspense>
            );
          case InputTypes.NumberInput:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <NumberField
                  prefix={prefix}
                  config={field}
                  onChange={onChangeInternal}
                  onValid={onValidChangeInternal}
                  hidden={hidden}
                  defaultValue={defaultFormValue[field.name] as number}
                />
              </Suspense>
            );
          case InputTypes.TextArea:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <TextAreaField
                  prefix={prefix}
                  config={field}
                  onChange={onChangeInternal}
                  purify={purify}
                  onValid={onValidChangeInternal}
                  hidden={hidden}
                  defaultValue={defaultFormValue[field.name] as string}
                />
              </Suspense>
            );
          case InputTypes.Checkbox:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <CheckboxField
                  prefix={prefix}
                  config={field}
                  onChange={onChangeInternal}
                  onValid={onValidChangeInternal}
                  hidden={hidden}
                  defaultValue={defaultFormValue[field.name] as boolean}
                />
              </Suspense>
            );
          case InputTypes.CheckboxGroup:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <CheckboxGroupField
                  prefix={prefix}
                  config={field}
                  onChange={onChangeInternal}
                  onValid={onValidChangeInternal}
                  hidden={hidden}
                  defaultValue={defaultFormValue[field.name] as string[]}
                />
              </Suspense>
            );
          case InputTypes.RadioGroup:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <RadioButtonGroupField
                  config={field}
                  hidden={hidden}
                  prefix={prefix}
                  onChange={onChangeInternal}
                  defaultValue={defaultFormValue[field.name] as string}
                />
              </Suspense>
            );
          case InputTypes.BooleanRadioGroup:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <BooleanRadioButtonGroupField
                  config={field}
                  hidden={hidden}
                  prefix={prefix}
                  onChange={onChangeInternal}
                  defaultValue={defaultFormValue[field.name] as boolean}
                />
              </Suspense>
            );
          case InputTypes.Choice:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <ChoiceField
                  prefix={prefix}
                  config={field}
                  hidden={hidden}
                  onChange={onChangeInternal}
                  defaultValue={defaultFormValue[field.name] as string}
                />
              </Suspense>
            );
          case InputTypes.Quantity:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <QuantityField
                  prefix={prefix}
                  config={field}
                  onChange={onChangeInternal}
                  onValid={onValidChangeInternal}
                  hidden={hidden}
                  defaultValue={defaultFormValue[field.name] as number}
                />
              </Suspense>
            );
          case InputTypes.Ranking:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <RankingField
                  prefix={prefix}
                  config={field}
                  onChange={onChangeInternal}
                  onValid={onValidChangeInternal}
                  hidden={hidden}
                />
              </Suspense>
            );
          case InputTypes.Slider:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <SliderField
                  prefix={prefix}
                  config={field}
                  onChange={onChangeInternal}
                  onValid={onValidChangeInternal}
                  hidden={hidden}
                  defaultValue={defaultFormValue[field.name] as number}
                />
              </Suspense>
            );
          case InputTypes.Select:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <SelectField
                  prefix={prefix}
                  config={field}
                  onChange={onChangeInternal}
                  onValid={onValidChangeInternal}
                  hidden={hidden}
                  defaultValue={defaultFormValue[field.name] as string}
                />
              </Suspense>
            );
          case InputTypes.Combobox:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <ComboboxField
                  prefix={prefix}
                  config={field}
                  onChange={onChangeInternal}
                  onValid={onValidChangeInternal}
                  hidden={hidden}
                  defaultValue={defaultFormValue[field.name] as string}
                />
              </Suspense>
            );
          case InputTypes.Switch:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <SwitchField
                  prefix={prefix}
                  config={field}
                  onChange={onChangeInternal}
                  onValid={onValidChangeInternal}
                  hidden={hidden}
                  defaultValue={defaultFormValue[field.name] as boolean}
                />
              </Suspense>
            );
          case InputTypes.ManyOfMany:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <ManyOfManyField
                  config={field}
                  hidden={hidden}
                  prefix={prefix}
                  onChange={onChangeInternal}
                  onValid={onValidChangeInternal}
                  defaultValues={defaultFormValue[field.name] as string[]}
                />
              </Suspense>
            );
          case InputTypes.TextInputGroup:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <TextFieldGroup
                  config={field}
                  hidden={hidden}
                  prefix={prefix}
                  onChange={onChangeInternal}
                  onValid={onValidChangeInternal}
                  defaultValue={defaultFormValue[field.name] as string[]}
                />
              </Suspense>
            );
          case InputTypes.FixedText:
            return (
              <Suspense key={`${fieldPrefix}.${index}`}>
                <FixedTextField config={field} hidden={hidden} prefix={prefix} />
              </Suspense>
            );
          case InputTypes.InlineFormControl:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <InlineFormControlField
                  prefix={prefix}
                  config={field}
                  hidden={hidden}
                  required={field.required}
                  onChange={onChangeInternal}
                  purify={purify}
                  onValid={onValidChangeInternal}
                  defaultValue={defaultFormValue[field.name] as FormValue}
                  context={context}
                />
              </Suspense>
            );
          case InputTypes.ModalControl:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <ModalControlField
                  prefix={prefix}
                  config={field}
                  hidden={hidden}
                  onChange={onChangeInternal}
                  purify={purify}
                  defaultValue={defaultFormValue[field.name] as string[]}
                  context={context}
                />
              </Suspense>
            );
          case InputTypes.ModalFormControl:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <ModalFormControlField
                  prefix={prefix}
                  config={field}
                  hidden={hidden}
                  onChange={onChangeInternal}
                  defaultValue={defaultFormValue[field.name] as Input[]}
                  purify={purify}
                  context={context}
                />
              </Suspense>
            );
          case InputTypes.PillListbox:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <PillListbox
                  prefix={prefix}
                  config={field}
                  hidden={hidden}
                  onChange={onChangeInternal}
                  onValid={onValidChangeInternal}
                  defaultValue={defaultFormValue[field.name] as string}
                />
              </Suspense>
            );
          case InputTypes.Divider:
            return (
              <Suspense key={`${fieldPrefix}.${index}`}>
                <Divider key={`${fieldPrefix}.${index}`} config={field} />
              </Suspense>
            );
          case InputTypes.InlineMessage:
            return (
              <Suspense key={`${fieldPrefix}.${index}`}>
                <InlineMessageField
                  key={`${fieldPrefix}.${index}`}
                  prefix={prefix}
                  config={field}
                  hidden={hidden}
                />
              </Suspense>
            );
          case InputTypes.HierarchySelect:
            return (
              <Suspense key={`${fieldPrefix}.${index}`}>
                <HierarchySelectField
                  key={`${fieldPrefix}.${index}`}
                  prefix={prefix}
                  config={field}
                  hidden={hidden}
                  onChange={onChangeInternal}
                  onValid={onValidChangeInternal}
                  defaultValues={defaultFormValue[field.name] as string[]}
                />
              </Suspense>
            );
          case InputTypes.DatePicker:
            return (
              <Suspense key={`${fieldPrefix}.${field.name}.${index}`}>
                <DatePicker
                  prefix={prefix}
                  config={field}
                  onChange={onChangeInternal}
                  hidden={hidden}
                  defaultValue={defaultFormValue[field.name] as string}
                />
              </Suspense>
            );
          default:
            return null;
        }
      })}
    </>
  ) : null;
};
