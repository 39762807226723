import { useCallback, useState } from 'react';
import Button from '@ingka/button';
import Combobox from '@ingka/combobox';
import { MenuItem } from '@ingka/list-box';
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal';
import { RetailUnit, getRetailUnits, isRetailUnit } from '@nfw/ikea/retail';
import { MarketConfiguration } from '@nfw/nudge/types';
import { useTranslation } from 'react-i18next';
import { queueToast } from '../../features/app/actions';
import { useUpdateMarketConfiguration } from '../../features/configurations/hooks';
import { useAppDispatch } from '../../store';
import LinearLayout from '../LinearLayout';
import MarketConfigurationForm from './ConfigurationForm';

export type UpdateMarketConfigModalProps = {
  open: boolean;
  onClose: (isUpdated: boolean) => void;
};

const UpdateMarketConfigModal: React.FC<UpdateMarketConfigModalProps> = ({ open, onClose }) => {
  const [configuration, setConfiguration] = useState<MarketConfiguration>();
  const [retailUnit, setRetailUnit] = useState<RetailUnit>();
  const { update, isLoading, isSuccess } = useUpdateMarketConfiguration();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onCloseInternal = () => {
    setRetailUnit(undefined);
    setConfiguration(undefined);
    onClose(false);
  };

  const onSubmit = useCallback(() => {
    if (configuration && retailUnit) {
      update(configuration, retailUnit);
      if (isSuccess) {
        setRetailUnit(undefined);
        setConfiguration(undefined);
        dispatch(queueToast({ msg: t('form.configuration.saved') }));
      }
    }
    onClose(true);
  }, [configuration, retailUnit, update, isSuccess, onClose, dispatch, t]);

  return (
    <Modal visible={open} handleCloseBtn={onCloseInternal}>
      <Sheets
        header={<ModalHeader title={t('global.update_config')} />}
        footer={
          <ModalFooter>
            <Button type="secondary" text={t('global.action.cancel')} onClick={onCloseInternal} />
            <Button
              type="primary"
              loading={isLoading}
              text={t('global.action.save')}
              onClick={onSubmit}
              disabled={!configuration || !retailUnit}
            />
          </ModalFooter>
        }
      >
        <ModalBody>
          <LinearLayout gap="s">
            <Combobox
              listboxId="retailSelect"
              key="retailSelect"
              labelId="retailSelect"
              label={t('component.update_config.select')}
              className="select"
              defaultValue={retailUnit || ''}
              placeholder={t('component.nudge_picker.select_nudge')}
            >
              {getRetailUnits().map((value) => (
                <MenuItem
                  key={`global.country.${value}`}
                  title={t(`global.country.${value}`)}
                  onClick={() => setRetailUnit(value as RetailUnit)}
                  id={value}
                />
              ))}
            </Combobox>
          </LinearLayout>
          {isRetailUnit(retailUnit) && (
            <>
              <hr />
              <MarketConfigurationForm
                retailUnit={retailUnit}
                onConfigurationChange={setConfiguration}
              />
            </>
          )}
        </ModalBody>
      </Sheets>
    </Modal>
  );
};

export default UpdateMarketConfigModal;
