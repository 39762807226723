import { isNudge } from '@nfw/nudge/typeguard';
import { GenericNudge, Nudge, Widget } from '@nfw/nudge/types';
import { StoredFeatureOrchestration, StoredWidget, TeamOrchestration } from './types';

/**
 * Transform a stored widget to a regular widget.
 *
 * @param storedWidget - the stored widget.
 *
 * @returns the widget.
 */
export const toWidget = (storedWidget: StoredWidget): Widget => {
  const {
    id: _,
    description: __,
    creator: ___,
    creatorName: ____,
    created: _____,
    modified: ______,
    name: _______,
    team: ________,
    isArchived: _________,
    ...widget
  } = storedWidget;

  return widget;
};

/**
 * Create a nudge from a stored feature orchestration and a stored widget.
 *
 * @param orchestration - the orchestration.
 * @param storedWidget - the stored widget.
 *
 * @returns the nudge or undefined if the combination is invalid.
 */
export const toNudge = (
  orchestration: StoredFeatureOrchestration,
  storedWidget: StoredWidget
): Nudge | undefined => {
  const {
    featureNudge: { triggers, repeat, mandatory, experiment },
    matcher,
    conditions,
    id: featureId,
  } = orchestration;
  const { id: widgetId } = storedWidget;

  let nudge: Nudge | undefined = undefined;
  const widget = toWidget(storedWidget);

  const candidate: GenericNudge = {
    id: `${featureId}-${widgetId}`,
    matcher,
    conditions,
    triggers,
    repeat,
    mandatory,
    experiment,
    ...widget,
  };

  if (isNudge(candidate)) {
    nudge = candidate;
  }

  return nudge;
};

/**
 * Transform the available features to feature orchestrations.
 *
 * @param widgets - the widgets.
 * @param orchestrations - the orchestration features.
 *
 * @returns the team orchestrations.
 */
export const toTeamOrchestrations = (
  storedWidgets: StoredWidget[],
  orchestrations: StoredFeatureOrchestration[]
): TeamOrchestration[] =>
  orchestrations.map((feature) => {
    const { team, retailUnits, namespace, featureNudge } = feature;
    const { widgetRef } = featureNudge;
    const nudges: Nudge[] = [];
    const storedWidget = storedWidgets.find(({ id }) => id === widgetRef);

    if (storedWidget) {
      const nudge = toNudge(feature, storedWidget);
      if (nudge) {
        nudges.push(nudge);
      }
    }

    return {
      namespace,
      team,
      retailUnits,
      nudges,
    };
  });
