import { useCallback, useEffect, useState } from 'react';
import Choice, { ChoiceItem } from '@ingka/choice';
import TextArea from '@ingka/text-area';
import { NudgeExperimentTypes } from '@nfw/nudge/types';
import { isDefined } from '@nfw/utils';
import { useTranslation } from 'react-i18next';
import { PuffaError } from '../../features/api/puffa-error';
import { useTakeDecisionMutation } from '../../features/experiments/api';
import { useGetExperiment } from '../../features/experiments/hooks';
import PopUp from '../PopUp';
import './index.scss';

export type ConcludePopupProps = {
  id: string;
  team: string;
  visible: boolean;
  onClose: () => void;
  publish: () => void;
};

const ConcludePopup: React.FC<ConcludePopupProps> = ({ id, team, visible, onClose, publish }) => {
  const [decision, setDecision] = useState<string | boolean | null>();
  const [motivation, setMotivation] = useState<string>();
  const { experiment } = useGetExperiment(id, team, !visible);
  const [decide] = useTakeDecisionMutation();
  const { t } = useTranslation();

  useEffect(() => {
    if (!visible) {
      setDecision(null);
      setMotivation(undefined);
    }
  }, [visible]);

  const conclude = useCallback(() => {
    if (isDefined(decision)) {
      decide({ id, team, value: decision, motivation })
        .then(() => publish())
        .catch((_) => {
          throw new PuffaError();
        });
    }
  }, [id, team, decision, publish, decide, motivation]);

  if (experiment) {
    const { type } = experiment;

    switch (type) {
      case NudgeExperimentTypes.Visibility:
        return (
          <PopUp
            visible={visible}
            close={onClose}
            title={t('publish.conclude.popup.title')}
            body={
              <div className="popup-body">
                <Choice onSelect={(id) => setDecision(id === 'publish')}>
                  <ChoiceItem
                    id="archive"
                    title={t('page.nudge_details.archive.cta')}
                    caption={t('publish.conclude.reject.text')}
                  />
                  <ChoiceItem
                    id="publish"
                    title={t('publish.conclude.action.accept.text')}
                    caption={t('publish.conclude.accept.text')}
                  />
                </Choice>
                <TextArea
                  id="visibility-motivation"
                  className="motivation"
                  onChange={(e) => setMotivation(e.target.value)}
                  label={t('publish.conclude.popup.motivation')}
                />
              </div>
            }
            actions={[
              { type: 'secondary', text: t('global.action.cancel') },
              {
                type: 'primary',
                text: t('global.action.continue'),
                disabled: !isDefined(decision),
                action: conclude,
              },
            ]}
          />
        );
      case NudgeExperimentTypes.Design:
        return (
          <PopUp
            visible={visible}
            close={onClose}
            title={t('publish.conclude.popup.title')}
            body={
              <div className="popup-body">
                <Choice onSelect={setDecision}>
                  {[
                    <ChoiceItem
                      id={experiment.widgetId}
                      title={t('publish.conclude.basevariant.text')}
                      caption={t('publish.conclude.basevariant.description')}
                    />,
                    ...experiment.variants.map((variant) => (
                      <ChoiceItem
                        key={variant.id}
                        id={variant.id}
                        title={t('publish.conclude.designvariant.text', { name: variant.name })}
                        caption={t('publish.conclude.designvariant.description', {
                          id: `${experiment.orchestrationId}-${variant.id}`,
                        })}
                      />
                    )),
                  ]}
                </Choice>
                <TextArea
                  id="design-motivation"
                  className="motivation"
                  onChange={(e) => setMotivation(e.target.value)}
                  label={t('publish.conclude.popup.motivation')}
                />
              </div>
            }
            actions={[
              { type: 'secondary', text: t('global.action.cancel') },
              {
                type: 'primary',
                text: t('global.action.continue'),
                disabled: !isDefined(decision),
                action: conclude,
              },
            ]}
          />
        );

      default:
        throw new PuffaError();
    }
  }
  return null;
};

export default ConcludePopup;
