import { useMemo } from 'react';
import InlineMessage from '@ingka/inline-message';
import { Environment, FailureReason } from '@nfw/deployment-types';
import { Trans, useTranslation } from 'react-i18next';

export type LogEntryProps = {
  env?: Environment;
  reason: FailureReason;
  locales: string[];
};

const LogEntry: React.FC<LogEntryProps> = ({ reason, locales }) => {
  const { t } = useTranslation();
  const content = useMemo(() => {
    switch (reason) {
      case 'unknown_error':
        return [
          t('publish.error.unknown.title'),
          <Trans i18nKey="publish.error.unknown.text" values={{ locales: locales.join(', ') }} />,
        ];
      case 'untranslated':
        return [
          t('publish.error.translations.title'),
          <Trans
            i18nKey="publish.error.translations.text"
            values={{ locales: locales.join(', ') }}
          />,
        ];
      default:
        return null;
    }
  }, [reason, locales, t]);

  return content && <InlineMessage title={content[0]} body={content[1]} variant="cautionary" />;
};

export default LogEntry;
