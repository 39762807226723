import { InputTypes, LayoutConfiguration, ValidationTypes } from '@nfw/form-types';
import { DOMEventNames, TriggerTypes, MaxDelayTime, TriggerType } from '@nfw/nudge/types';
import { getNudgeDOMEventNames, getNudgeTriggerTypes } from '@nfw/nudge-typemap';
import { StoredWidget } from '@nfw/orchestration-types';
import { TFunction } from 'i18next';

export const getTriggerLayout = (
  widget: StoredWidget,
  widgets: StoredWidget[],
  triggers: string[],
  t: TFunction
): LayoutConfiguration => {
  const optionHelpMessage: Record<TriggerType, string> = {
    dom_event: t('form.feature.trigger.dom_explanation'),
    exit_intent: t('form.feature.trigger.exit_explanation'),
    idle: t('form.feature.trigger.idle_explanation'),
    init: t('form.feature.trigger.init_explanation'),
    native: t('form.feature.trigger.native_explanation'),
    regexp_matcher: t('form.feature.trigger.regexp.explanation'),
    search_param: t('form.feature.trigger.searchparam.explanation'),
    cart_add: t('form.feature.trigger.cart_add_explanation'),
    cart_remove: t('form.feature.trigger.cart_remove_explanation'),
    favourite_add: t('form.feature.trigger.favourite_add_explanation'),
    favourite_remove: t('form.feature.trigger.favourite_remove_explanation'),
    store_change: t('form.feature.trigger.store_change_explanation'),
    episod_event: t('form.feature.trigger.episod_explanation'),
    element_in_viewport: t('form.feature.trigger.element_in_viewport_explanation'),
  };

  const availableTriggers = getNudgeTriggerTypes(widget.type, triggers).map((value) => ({
    name: value,
    description: optionHelpMessage[value],
    value,
  }));

  return [
    {
      inputType: InputTypes.PillListbox,
      name: 'type',
      label: t('form.feature.trigger.type.label'),
      btnLabel: t('global.choose_option'),
      includeSearch: false,
      options: availableTriggers,
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.feature.trigger.type.error'),
        },
      ],
    },
    {
      inputType: InputTypes.PillListbox,
      name: 'domEventName',
      label: t('form.feature.trigger.event.label'),
      dependsOn: 'type',
      requiresValueOf: [TriggerTypes.DomEvent],
      btnLabel: t('global.choose_option'),
      includeSearch: false,
      options: Object.entries(DOMEventNames)
        .filter(([_, value]) => getNudgeDOMEventNames(widget.type).includes(value))
        .map(([name, value]) => ({ name, value })),
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.feature.trigger.event.error'),
        },
      ],
    },
    {
      inputType: InputTypes.PillListbox,
      name: 'nudgeId',
      label: t('form.feature.trigger.nudge.label'),
      dependsOn: 'type',
      requiresValueOf: [TriggerTypes.Native],
      btnLabel: t('global.choose_option'),
      includeSearch: false,
      options: widgets
        .filter(({ id }) => widget.id !== id)
        .map(({ id }) => ({ name: id, value: id })),
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.feature.trigger.nudge.error'),
        },
      ],
    },
    {
      inputType: InputTypes.TextInputGroup,
      name: 'regExps',
      label: t('form.feature.trigger.regexp.label'),
      helpMessage: t('form.feature.trigger.regexp.explanation'),
      clearAllText: t('global.clear_all'),
      requiresValueOf: [TriggerTypes.RegExpMatcher],
      dependsOn: 'type',
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.feature.trigger.regexp.error'),
        },
      ],
    },
    {
      inputType: InputTypes.TextInput,
      name: 'category',
      label: t('form.feature.trigger.episod.category'),
      requiresValueOf: [TriggerTypes.EpisodEvent],
      dependsOn: 'type',
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.feature.trigger.episod.error'),
        },
      ],
    },
    {
      inputType: InputTypes.TextInput,
      name: 'action',
      label: t('form.feature.trigger.episod.action'),
      requiresValueOf: [TriggerTypes.EpisodEvent],
      dependsOn: 'type',
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.feature.trigger.episod.error'),
        },
      ],
    },
    {
      inputType: InputTypes.TextInput,
      name: 'value',
      label: t('form.feature.trigger.element.label'),
      helpMessage: t('form.feature.trigger.element.explanation'),
      dependsOn: 'type',
      requiresValueOf: [TriggerTypes.ElementInViewport],
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.feature.trigger.element.error'),
        },
      ],
    },
    {
      inputType: InputTypes.Combobox,
      name: 'targetType',
      label: t('form.feature.trigger.element_type.label'),
      helpMessage: t('form.feature.trigger.element_type.explanation'),
      dependsOn: 'type',
      requiresValueOf: [TriggerTypes.ElementInViewport],
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.feature.trigger.element.error'),
        },
      ],
      options: [
        { name: t('form.feature.trigger.element.type.class'), value: 'class_name' },
        { name: t('global.id'), value: 'element_id' },
        { name: t('form.feature.trigger.element.type.data'), value: 'data_nudge' },
      ],
      placeholderText: t('global.choose_option'),
    },
    {
      inputType: InputTypes.Slider,
      name: 'delay',
      label: t('form.feature.trigger.delay.label'),
      dependsOn: 'type',
      requiresValueOf: Object.values(TriggerTypes).filter((value) => value !== TriggerTypes.Idle),
      unit: 's',
      step: 1,
      defaultValue: 0,
      min: 0,
      max: MaxDelayTime,
    },
  ];
};
