import { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '@ingka/button';
import Text from '@ingka/text';
import { RetailUnit } from '@nfw/ikea/retail';
import { Widget } from '@nfw/nudge/types';
import {
  Feature,
  FeatureNudge,
  FeatureOrchestration,
  StoredNudgeGroup,
  StoredWidget,
  isFeature,
  isFeatureOrchestration,
} from '@nfw/orchestration-types';
import { Form, FormCallback } from '@nfw/ui/form';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import {
  useGetRootNode,
  useGetStores,
  useOrchestrationLayout,
} from '../../features/orchestrations/hooks';
import FormFooter from '../FormFooter';
import NudgeForm from './NudgeForm';
import WidgetPicker from './WidgetPicker';
import './index.scss';

export type OrchestrationFormProps = {
  team: string;
  retailUnits: RetailUnit[];
  widgets: StoredWidget[];
  nudgeGroups: StoredNudgeGroup[];
  saving?: boolean;
  onSave: (featureOrchestration: FeatureOrchestration) => void;
  onProgress: (page: number) => void;
  onWidgetSelected?: (widget: Widget) => void;
  isCopy?: boolean;
  defaultFeatureOrchestration?: FeatureOrchestration;
  defaultOrchestrationId?: string;
  presetWidgetId?: string;
};

const OrchestrationForm: React.FC<OrchestrationFormProps> = ({
  team,
  retailUnits,
  widgets,
  nudgeGroups,
  saving,
  onSave,
  onProgress,
  onWidgetSelected,
  defaultFeatureOrchestration,
  defaultOrchestrationId,
  presetWidgetId,
}) => {
  const defaultWidget = useMemo(
    () => widgets.find(({ id }) => defaultFeatureOrchestration?.featureNudge.widgetRef === id),
    [defaultFeatureOrchestration, widgets]
  );
  const isEditMode = useMemo(() => !!defaultFeatureOrchestration, [defaultFeatureOrchestration]);
  const [featureNudge, setFeatureNudge] = useState<FeatureNudge>();
  const [selectedWidget, setSelectedWidget] = useState(defaultWidget);
  const [input, setInput] = useState<Partial<Feature>>();
  const [value, setValue] = useState<Partial<FeatureOrchestration>>();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [validDescription, setValidDescription] = useState(false);
  const { t, i18n } = useTranslation();

  const { rootCopy: rootNode, isLoading } = useGetRootNode(
    i18n.language,
    input?.retailUnits,
    input?.matcher?.exception?.type
  );

  const { stores = [], isLoading: isStoresLoading } = useGetStores(input?.retailUnits);

  const layout = useOrchestrationLayout(
    team,
    retailUnits,
    isEditMode,
    stores,
    nudgeGroups,
    rootNode,
    isLoading || isStoresLoading
  );

  const handleBack = () => {
    setSelectedIndex(selectedIndex - 1);
  };

  const handleContinue = () => {
    setSelectedIndex(selectedIndex + 1);
  };

  const onChangeInternal = useCallback<FormCallback<Feature>>(
    ({ input, value, valid }) => {
      setValidDescription(valid);
      setInput(input);
      setValue({
        ...value,
        featureNudge,
      });
    },
    [featureNudge]
  );

  const save = useCallback(() => {
    if (isFeatureOrchestration(value)) {
      onSave(value);
    }
  }, [value, onSave]);

  useEffect(() => {
    if (selectedWidget) {
      onWidgetSelected?.(selectedWidget);
    }
  }, [selectedWidget, onWidgetSelected]);

  useEffect(() => {
    onProgress(selectedIndex);
  }, [onProgress, selectedIndex]);

  return (
    <div>
      <div className="form-div" style={{ display: selectedIndex === 0 ? 'block' : 'none' }}>
        <div className="header-div">
          <Text className="title" headingSize="s">
            {t('form.feature.title')}
          </Text>
          <hr className="title-hr" />
        </div>
        <WidgetPicker
          widgets={widgets}
          onWidgetChange={setSelectedWidget}
          presetWidgetId={defaultWidget?.id || presetWidgetId}
        />
        <Form
          config={layout}
          onChange={onChangeInternal}
          validationFn={isFeature}
          purify={DOMPurify.sanitize}
          defaultFormValue={defaultFeatureOrchestration}
        />
        <FormFooter>
          <Button
            type="primary"
            text={t('global.action.next')}
            disabled={!isFeature(input) || !validDescription || !selectedWidget}
            onClick={handleContinue}
          />
        </FormFooter>
      </div>
      <div className="form-div" style={{ display: selectedIndex === 1 ? 'block' : 'none' }}>
        {selectedWidget && (
          <NudgeForm
            widget={selectedWidget}
            widgets={widgets}
            team={team}
            onValid={setFeatureNudge}
            onInvalid={() => setFeatureNudge(undefined)}
            defaultFeatureNudge={defaultFeatureOrchestration?.featureNudge}
            defaultId={defaultOrchestrationId}
          />
        )}
        <FormFooter>
          <Button
            type="secondary"
            style={{
              backgroundColor: '#fff',
            }}
            text={t('global.action.back')}
            onClick={handleBack}
          />
          <Button
            type="primary"
            text={defaultFeatureOrchestration ? t('global.update') : t('global.nudge.create')}
            loading={saving}
            disabled={!isFeatureOrchestration(value)}
            onClick={save}
          />
        </FormFooter>
      </div>
    </div>
  );
};

export default OrchestrationForm;
