import { memo } from 'react';
import Text from '@ingka/text';
import classNames from 'classnames';
import './index.scss';

export type ProgressIndicatorProps = {
  currentPage: number;
  steps: string[];
};

const createLine = (stepNumber: number, currentPage: number, lineWidth: string) => (
  <span
    key={stepNumber}
    className={classNames('line', {
      'line--highlight': stepNumber <= currentPage,
    })}
    style={{ width: lineWidth }}
  />
);

const createCircle = (stepNumber: number, currentPage: number, title: string) => (
  <div key={`circle-${stepNumber}`} className="circle-container">
    <span
      key={`circle-${stepNumber}`}
      className={classNames('circle', {
        'circle--highlight': stepNumber <= currentPage,
      })}
    >
      <Text className="number">{stepNumber}</Text>
    </span>
    <Text
      className={classNames('title', {
        'title--highlight': stepNumber <= currentPage,
      })}
    >
      {title}
    </Text>
  </div>
);

/**
 * ,
 * Components to indicate which step you are on for some number of pages
 */
export const ProgressIndicator: React.FC<ProgressIndicatorProps> = memo(
  ({ currentPage, steps }) => {
    const nbrOfPages = steps.length;
    const lineWidth = nbrOfPages > 1 ? `${100 / nbrOfPages}%` : '80%'; // Calculate the dynamic width of each line

    const indicatorComponents: JSX.Element[] = [];
    for (let index = 0; index < nbrOfPages; index++) {
      const stepNumber = index + 1;
      indicatorComponents.push(createLine(stepNumber, currentPage, lineWidth));
      indicatorComponents.push(createCircle(stepNumber, currentPage, steps[index]));
    }

    indicatorComponents.push(
      // Adding a final line and completing the design
      <span
        key={`trailing-line`}
        className={classNames('line', {
          'line--highlight': nbrOfPages <= currentPage,
        })}
        style={{ width: lineWidth }}
      />
    );

    return (
      <div
        className="component-container"
        role="progressbar"
        aria-valuemin={1}
        aria-valuemax={nbrOfPages}
        aria-valuenow={currentPage}
      >
        <div className="indicator-container">{indicatorComponents}</div>
      </div>
    );
  }
);

export default ProgressIndicator;
