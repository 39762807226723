import { useCallback, useEffect } from 'react';
import { StoredWidget } from '@nfw/orchestration-types';
import { Form, FormCallback } from '@nfw/ui/form';
import { isObject, isString } from '@nfw/utils';
import DOMPurify from 'dompurify';
import { useWidgetRefLayout } from '../../../features/orchestrations/hooks';

type WithWidgetRef = {
  widgetRef: string;
};

const isWithWidgetRef = (w: unknown): w is WithWidgetRef => isObject(w) && isString(w.widgetRef);

export type WidgetPickerProps = {
  widgets: StoredWidget[];
  onWidgetChange: (widget: StoredWidget) => void;
  presetWidgetId?: string;
};

/**
 * Take note only one widget can be selected. FeatureOrchestration supports many widgets
 * and this component should be easy to extend to support that use case.
 */
export const WidgetPicker: React.FC<WidgetPickerProps> = ({
  widgets,
  onWidgetChange,
  presetWidgetId,
}) => {
  const widgetRefLayout = useWidgetRefLayout(widgets, presetWidgetId);

  const onChangeInternal = useCallback<FormCallback<WithWidgetRef>>(
    ({ value }) => {
      if (value) {
        const selectedWidget = widgets.find(({ id }) => value.widgetRef === id);
        if (selectedWidget) {
          onWidgetChange(selectedWidget);
        }
      }
    },
    [onWidgetChange, widgets]
  );

  useEffect(() => {
    if (presetWidgetId) {
      const presetWidget = widgets.find(({ id }) => id === presetWidgetId);
      if (presetWidget) {
        onWidgetChange(presetWidget);
      }
    }
  }, [onWidgetChange, presetWidgetId, widgets]);

  return (
    <Form
      config={widgetRefLayout}
      onChange={onChangeInternal}
      validationFn={isWithWidgetRef}
      purify={DOMPurify.sanitize}
    />
  );
};

export default WidgetPicker;
