import { useEffect, useState } from 'react';
import Accordion, { AccordionItem } from '@ingka/accordion';
import Button from '@ingka/button';
import Loading, { LoadingBall } from '@ingka/loading';
import Text from '@ingka/text';
import { toPath } from '@nfw/utils';
import { useTranslation } from 'react-i18next';
import MarketTranslationModal from '../../../components/MarketTranslationModal';
import Page, { PageHeader } from '../../../components/Page';
import { useTeamPermission } from '../../../features/auth/hooks';
import { useGetAllLocaleTranslations } from '../../../features/i18n/hooks';
import { useGetTeam } from '../../../features/teams/hooks';
import { InjectedTeamProps, withRoutedTeamPermissions } from '../../../hocs';
import { useNavigate } from '../../../routes';
import { Routes } from '../../../routes/types';
import './index.scss';

const MarketTeamTranslationPage: React.FC<InjectedTeamProps> = ({ team }) => {
  const { permissions, isInitialised, setTeam } = useTeamPermission();
  const { params } = useNavigate();
  const { data, isLoading: isTeamLoading } = useGetTeam(team);
  const { t } = useTranslation();
  const { translations, isLoading: isTranslationsLoading } = useGetAllLocaleTranslations(team);
  const [teamName, setTeamName] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isUpdatingTranslations, setIsUpdateTranslations] = useState(false);
  const [existingKey, setExistingKey] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (data) {
      setTeam(data.id);
      setTeamName(data.name);
    }
  }, [data, setTeam]);

  const getAvailableKeysAndLocales = () => {
    if (!translations) return { keys: [], locales: [] };

    const locales = Object.keys(translations);
    const allKeys =
      locales.length > 0
        ? Object.keys(translations[locales[0]]).filter((key) =>
            locales.every((locale) => key in translations[locale])
          )
        : [];

    return { keys: allKeys, locales };
  };

  const { keys: availableKeys, locales: availableLocales } = getAvailableKeysAndLocales();

  const handleAddNewKey = () => {
    setExistingKey(undefined);
    setIsModalOpen(true);
  };

  const handleEditKey = (key: string) => {
    setExistingKey(key);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setExistingKey(undefined);
  };

  return (
    translations && (
      <Page
        padded
        loading={isTeamLoading || !isInitialised || isTranslationsLoading}
        crumbs={[
          { name: t('global.overview'), to: '/?tab=features' },
          { name: t('global.teams.all'), to: Routes.ViewTeams },
          { name: teamName, to: toPath(Routes.ViewTeam, { team: params.team! }) },
          { name: t('page.translations.translations') },
        ]}
        header={
          <PageHeader
            title={t('page.market_translation.title', { team: teamName })}
            audit={{ id: team }}
            actions={[
              <Button
                key={'add-new-translation'}
                text={t('page.market_translation.add_translation')}
                onClick={handleAddNewKey}
                type="primary"
              />,
            ]}
          />
        }
      >
        <Text bodySize="l">{t('page.market_translation.caption')}</Text>
        <MarketTranslationModal
          open={isModalOpen}
          onClose={handleCloseModal}
          setIsUpdatingTranslations={setIsUpdateTranslations}
          teamName={teamName}
          team={team}
          translations={translations}
          existingKey={existingKey}
        />
        {isUpdatingTranslations ? (
          <div className="loading">
            <Loading text={t('page.market_translation.loading')}>
              <LoadingBall size="large" />
            </Loading>
          </div>
        ) : availableKeys.length > 0 ? (
          <Accordion collapsible={false}>
            {availableKeys.map((key) => (
              <AccordionItem id={key} key={key} title={key}>
                <pre>
                  {availableLocales.map((locale) => (
                    <div className="translation" key={locale}>
                      <Text headingSize="xs">{locale}: </Text>
                      <Text>{translations[locale][key]}</Text>
                    </div>
                  ))}
                </pre>
                <Button
                  text={t('global.edit')}
                  size="small"
                  disabled={!permissions?.includes('PUBLISH')}
                  type="secondary"
                  key={`edit-${key}`}
                  className="edit-button"
                  onClick={() => handleEditKey(key)}
                />
              </AccordionItem>
            ))}
          </Accordion>
        ) : (
          <Text headingSize="m">{t('page.market_translation.no_translations')}</Text>
        )}
      </Page>
    )
  );
};

export default withRoutedTeamPermissions(MarketTeamTranslationPage, 'EDIT');
