import {
  Action,
  ActionType,
  ActionTypes,
  ButtonAction,
  ButtonType,
  ButtonTypes,
  CallAction,
  DismissAction,
  GotoAction,
  IconPosition,
  IconPositions,
  LoginAction,
  NegativeAction,
  NudgeAction,
  PositiveAction,
  WithIcon,
} from '@nfw/nudge/types';
import { isObject, isOptionallyDefined, isString } from '@nfw/utils';
import { isIconType } from './icon';

/**
 * Check if button type is valid.
 *
 * @param type - the button type.
 *
 * @returns true if valid, otherwise false.
 */
export const isButtonType = (type: unknown): type is ButtonType =>
  Object.values(ButtonTypes).includes(type as ButtonType);

/**
 * Check if icon position is valid.
 *
 * @param p - the icon position.
 *
 * @returns true if valid, otherwise false.
 */
export const isIconPosition = (p: unknown): p is IconPosition =>
  Object.values(IconPositions).includes(p as IconPosition);

/**
 * Check if the action type is valid.
 *
 * @param type - the type to check.
 *
 * @returns true if the type is a valid, otherwise false.
 */
export const isActionType = (type: unknown): type is ActionType =>
  Object.values(ActionTypes).includes(type as ActionType);

/**
 * Check if a valid with icon type.
 *
 * @param w - the value to check.
 *
 * @returns true when valid, otherwise false.
 */
export const isWithIcon = (w: unknown): w is WithIcon =>
  isObject(w) && isIconType(w.iconType) && isIconPosition(w.iconPosition);

/**
 * Check if the action base is valid.
 *
 * @param action - the action to check.
 *
 * @returns true if the action is an action base object, otherwise false.
 */
const isActionOfType = (action: unknown, type: ActionType): action is Record<string, unknown> =>
  isObject(action) && action.type === type && isOptionallyDefined(action.text, isString);

/**
 * Check if the action is a dismiss action.
 *
 * @param action - the action to test.
 *
 * @returns true if action is valid, otherwise false.
 */
export const isDismissAction = (action: unknown): action is DismissAction =>
  isActionOfType(action, ActionTypes.Dismiss);

/**
 * Check if the action is a positive dismiss action.
 *
 * @param action - the action to test.
 *
 * @returns true if action is valid, otherwise false.
 */
export const isPositiveAction = (action: unknown): action is PositiveAction =>
  isActionOfType(action, ActionTypes.Positive);

/**
 * Check if the action is a negative dismiss action.
 *
 * @param action - the action to test.
 *
 * @returns true if action is valid, otherwise false.
 * */
export const isNegativeAction = (action: unknown): action is NegativeAction =>
  isActionOfType(action, ActionTypes.Negative);

/**
 * Check if the action is a goto action.
 *
 * @param action - the action to test.
 *
 * @returns true if the action is a valid goto action, otherwise false.
 */
export const isGoToAction = (action: unknown): action is GotoAction =>
  (isActionOfType(action, ActionTypes.GoTo) || isActionOfType(action, ActionTypes.GoToExternal)) &&
  isString(action.path);

/**
 * Check if the action is a login action.
 *
 * @param action - the action to test.
 *
 * @returns true if the action is a valid login action, otherwise false.
 */
export const isLoginAction = (action: unknown): action is LoginAction =>
  isActionOfType(action, ActionTypes.Login) && isOptionallyDefined(action.path, isString);

/**
 * Check if the action is a call action.
 *
 * @param action - the action to test.
 *
 * @returns true if the action is valid, otherwise false.
 */
export const isCallAction = (action: unknown): action is CallAction =>
  isActionOfType(action, ActionTypes.Call) && isString(action.number);

/**
 * Check if the action is a nudge action.
 *
 * @param action - the action to test.
 *
 * @returns true if the action is valid, otherwise false.
 */
export const isNudgeAction = (action: unknown): action is NudgeAction =>
  isActionOfType(action, ActionTypes.Nudge) && isString(action.nudgeId);

/**
 * Check if an action is an action.
 *
 * @param action - the action to test.
 *
 * @returns true if the action is a valid action, otherwise false.
 */
export const isAction = (action: unknown): action is Action =>
  isCallAction(action) ||
  isDismissAction(action) ||
  isGoToAction(action) ||
  isLoginAction(action) ||
  isNegativeAction(action) ||
  isNudgeAction(action) ||
  isPositiveAction(action);

/**
 * Check if the action is a valid button action.
 *
 * @param action - the action to test.
 *
 * @returns true when action is valid, otherwise false.
 */
export const isButtonAction = (action: unknown): action is ButtonAction =>
  isObject(action) &&
  isButtonType(action.buttonType) &&
  isOptionallyDefined(action.icon, isWithIcon) &&
  isAction(action);
