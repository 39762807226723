import { useMemo } from 'react';
import { DefaultEvent } from '@nfw/contracts/experiment';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import GenericFilter, { NamedFilter, SearchFilterFunction } from '../GenericFilter';

const searchFilter: SearchFilterFunction<DefaultEvent> = (searchValue, value) =>
  value
    ? value.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      value.project_name.toLowerCase().includes(searchValue.toLowerCase()) ||
      value.id.toString().includes(searchValue)
    : false;

const getNamedFilters = (t: TFunction): NamedFilter<DefaultEvent>[] => [
  {
    id: 'projects',
    label: t('global.projects'),
    distinctValues: (value: DefaultEvent) => value.project_name,
    filter: (values: string[], value: DefaultEvent) => values.includes(value.project_name),
  },
];

const EventsFilter: React.FC<{
  data: DefaultEvent[];
  onFilterChange: (data: DefaultEvent[]) => void;
}> = ({ data, onFilterChange }) => {
  const { t } = useTranslation();
  const namedFilter = useMemo(() => getNamedFilters(t), [t]);

  return (
    <GenericFilter
      type="project"
      data={data}
      onFilterChange={onFilterChange}
      searchFilter={searchFilter}
      namedFilters={namedFilter}
      searchLabel={t('global.filter.events_placeholder')}
      compact
    />
  );
};

export default EventsFilter;
