import React, { useEffect, useState } from 'react';
import Button from '@ingka/button';
import InlineMessage from '@ingka/inline-message';
import ListView, { ListViewItem } from '@ingka/list-view';
import Modal, { ModalFooter, ModalHeader, Sheets, ModalBody } from '@ingka/modal';
import Text from '@ingka/text';
import { Deployment, getPublishedDeployments } from '@nfw/deployment-types';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import LinearLayout from '../LinearLayout';

const IkeaCTEDomain = 'https://www.cte.ikeadt.com';
const IkeaProdDomain = 'https://www.ikea.com';

export type RedirectModalProps = {
  visible: boolean;
  close: () => void;
  deployment: Deployment;
};

const RedirectModal: React.FC<RedirectModalProps> = ({ visible, close, deployment }) => {
  const [prodUrls, setProdUrls] = useState<string[]>([]);
  const [cteUrls, setCteUrls] = useState<string[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setCteUrls(
      Object.entries(getPublishedDeployments(deployment, 'cte')).map(([locale]) => {
        const [lang, ru] = locale.split('-');

        return `${IkeaCTEDomain}/${ru.toLowerCase()}/${lang}`;
      })
    );
    setProdUrls(
      Object.entries(getPublishedDeployments(deployment, 'prod')).map(([locale]) => {
        const [lang, ru] = locale.split('-');

        return `${IkeaProdDomain}/${ru.toLowerCase()}/${lang}`;
      })
    );
  }, [deployment]);

  return createPortal(
    <Modal visible={visible} handleCloseBtn={close}>
      <Sheets
        title={'Test nudge'}
        header={<ModalHeader />}
        size="small"
        footer={
          <ModalFooter>
            <Button type="primary" text={t('global.action.cancel')} />
          </ModalFooter>
        }
      >
        <ModalBody>
          <LinearLayout gap="l">
            <Text tagName="h1" bodySize="xl">
              {t('view.modal.title')}
            </Text>

            {prodUrls.length > 0 && (
              <>
                <Text tagName="h3">{t('global.view.production')}</Text>
                <InlineMessage variant="informative" body={t('view.modal.info')} />
                <ListView id="redirect_prod">
                  {prodUrls?.map((url, index) => (
                    <ListViewItem
                      key={index}
                      title={url}
                      onClick={() => {
                        window.open(url, '_blank');
                        close();
                      }}
                      control="navigational"
                    />
                  ))}
                </ListView>
              </>
            )}

            {cteUrls.length > 0 && (
              <>
                <Text tagName="h1">{t('global.view.cte')}</Text>
                <ListView id="redirect_cte">
                  {cteUrls.map((url, index) => (
                    <ListViewItem
                      key={index}
                      title={url}
                      onClick={() => {
                        window.open(url, '_blank');
                        close();
                      }}
                      control="navigational"
                    />
                  ))}
                </ListView>
              </>
            )}
          </LinearLayout>
        </ModalBody>
      </Sheets>
    </Modal>,
    document.body
  );
};

export default RedirectModal;
