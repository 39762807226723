import { SagaIterator } from '@redux-saga/types';
import { actionChannel, delay, fork, put, take } from 'redux-saga/effects';
import { ACTION_QUEUE_TOAST, ACTION_ACKNOWLEDGE_TOAST, showToast } from './actions';

function* onToast(): SagaIterator {
  const channel = yield actionChannel(ACTION_QUEUE_TOAST);
  while (true) {
    const action = yield take(channel);
    yield put(showToast(action.payload));
    yield take(ACTION_ACKNOWLEDGE_TOAST);
    yield delay(200);
  }
}

const sagas = [fork(onToast)];
export default sagas;
