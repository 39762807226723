import { useEffect, useState } from 'react';
import CheckboxGroup from '@ingka/checkbox-group';
import FormField from '@ingka/form-field';
import { CheckboxGroupFieldConfiguration } from '@nfw/form-types';
import { useValidation } from '../hooks';

export type CheckboxGroupFieldProps = {
  hidden: boolean;
  config: CheckboxGroupFieldConfiguration;
  onChange: (name: string, value?: string[]) => void;
  onValid?: (name: string, valid: boolean) => void;
  defaultValue?: string[];
  prefix?: string;
};

export const CheckboxGroupField: React.FC<CheckboxGroupFieldProps> = ({
  hidden,
  config,
  onChange,
  onValid,
  defaultValue,
  prefix,
}) => {
  const { name, label, disabled, helpMessage, options } = config;
  const [value, setValue] = useState(defaultValue ?? config.defaultValue ?? []);
  const [valid, shouldValidate, msg] = useValidation(config, hidden, value);

  useEffect(() => {
    onChange(name, hidden ? undefined : value);
  }, [hidden, name, onChange, value]);

  useEffect(() => {
    onValid?.(name, valid);
  }, [name, valid, onValid]);

  const handleCheckboxChange = (event: React.FormEvent<HTMLFieldSetElement>) => {
    const target = event.target as HTMLInputElement;
    const newValue = target.value;
    if (target.checked) {
      setValue((prevValue) => [...prevValue, newValue]);
    } else {
      setValue((prevValue) => prevValue.filter((value) => value !== newValue));
    }
  };

  return hidden ? null : (
    <FormField
      prefix={prefix}
      disabled={disabled}
      fieldHelper={{ msg: helpMessage }}
      validation={{ msg }}
      shouldValidate={shouldValidate}
      valid={valid}
    >
      <CheckboxGroup
        list={options.map((option) => ({
          ...option,
          defaultChecked: value.includes(option.value),
        }))}
        name={label}
        onChange={handleCheckboxChange}
      />
    </FormField>
  );
};
