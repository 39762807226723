import { createSelector } from '@reduxjs/toolkit';
import { teamApi } from './api';

export const selectTeams = createSelector(
  teamApi.endpoints.getTeams.select(),
  ({ data }) => data ?? []
);
export const selectTeam = createSelector([selectTeams, (_, team: string) => team], (teams, team) =>
  teams.find(({ id }) => id === team)
);

export const selectTeamName = createSelector([selectTeam], (team) =>
  team ? team.name : 'unknown'
);
export const selectIdentifiedTeams = createSelector(
  [selectTeams, (_, teamIds: string[]) => teamIds],
  (teams, teamIds) => teams.filter(({ id }) => teamIds.includes(id))
);
