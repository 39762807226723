import { environment as defaultEnvironment } from './environment.default';
import { Environment } from './types';

/**
 * The dev environment.
 */
export const environment: Environment = {
  ...defaultEnvironment,
  auth0Redirect: 'https://nfw.cte.ingkadt.com',
};
