import { InputTypes, LayoutConfiguration } from '@nfw/form-types';
import { RepeatType, TriggerType, WidgetTypes } from '@nfw/nudge/types';
import { StoredFeatureOrchestration, StoredWidget } from '@nfw/orchestration-types';
import { TFunction } from 'i18next';
import { getTriggerLayout } from '../Trigger';
import { AnchorLayout } from './Anchor';
import { getRepeatLayout } from './Repeat';

export const getNudgeLayoutConfiguration = (
  widget: StoredWidget,
  widgets: StoredWidget[],
  orchestrations: StoredFeatureOrchestration[],
  triggers: TriggerType[],
  repeat: RepeatType[],
  t: TFunction,
  defaultId?: string
): LayoutConfiguration => {
  const configuration: LayoutConfiguration = [
    {
      inputType: InputTypes.FixedText,
      text: t('form.feature.triggers.text'),
      textHeadingSize: 'xs',
    },
    {
      inputType: InputTypes.FixedText,
      label: t('form.feature.triggers.label'),
      text: t('form.feature.triggers.description'),
      labelBodySize: 'm',
      textBodySize: 's',
    },
    {
      inputType: InputTypes.ModalFormControl,
      editable: true,
      disabledFieldsWhenEditable: ['type'],
      name: 'triggers',
      label: t('form.feature.triggers.add'),
      valueIdentifier: 'type',
      configuration: getTriggerLayout(widget, widgets, triggers, t),
      modalPrimaryActionText: t('form.general.select'),
      modalSecondaryActionText: t('global.action.cancel'),
      modalEditPrimaryActionText: t('global.action.save'),
      modalEditSecondaryActionText: t('global.delete'),
    },
    {
      inputType: InputTypes.InlineFormControl,
      name: 'repeat',
      type: 'switch',
      required: true,
      label: t('form.feature.repeat.header'),
      configuration: getRepeatLayout(repeat, t),
    },
    { inputType: InputTypes.HiddenInput, name: 'widgetRef', value: widget.id },
  ];

  const filteredOrchestration = orchestrations.filter(
    (orchestration) => defaultId !== `${orchestration.id}-${orchestration.featureNudge.widgetRef}`
  );

  configuration.push({
    inputType: InputTypes.ManyOfMany,
    name: 'blacklist',
    label: t('form.feature.blacklist.label'),
    header: t('form.feature.blacklist.header'),
    helpMessage: t('form.feature.blacklist.hint'),
    selectAllText: t('form.general.select_all'),
    selectOneText: t('global.add'),
    clearAllText: t('global.clear_all'),
    values: filteredOrchestration.map((value) => ({
      name: value.name,
      value: `${value.id}-${value.featureNudge.widgetRef}`,
      extra: `${value.id}-${value.featureNudge.widgetRef}`,
    })),
    disabled: filteredOrchestration.length === 0,
  });

  if (
    widget.type === WidgetTypes.Tip ||
    widget.type === WidgetTypes.Message ||
    widget.type === WidgetTypes.Reaction ||
    widget.type === WidgetTypes.Toast
  ) {
    configuration.push({
      inputType: InputTypes.InlineFormControl,
      name: 'target',
      type: 'switch',
      label: t('form.feature.target.label'),
      configuration: AnchorLayout(t),
    });
  }

  return configuration;
};
