import { Routes } from '@nfw/contracts/self-service';
import { NudgeGroup } from '@nfw/nudge/types';
import { StoredNudgeGroup } from '@nfw/orchestration-types';
import { toPath } from '@nfw/utils';
import { api } from '../api';
import { IdArg, TeamArg, TeamIdArg } from '../api/types';

export const nudgeGroupApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getNudgeGroups: builder.query<StoredNudgeGroup[], void>({
      query: () => Routes.NudgeGroups,
      providesTags: (nudgeGroups) =>
        nudgeGroups
          ? [
              ...nudgeGroups.map(({ id }) => ({ type: 'nudge-group' as const, id })),
              { type: 'nudge-group', id: 'all' },
            ]
          : [{ type: 'nudge-group', id: 'all' }],
    }),
    getNudgeGroup: builder.query<StoredNudgeGroup, IdArg>({
      query: ({ id }) => toPath(Routes.NudgeGroup, { id }),
      providesTags: (_, __, { id }) => [{ type: 'nudge-group', id }],
    }),
    createNudgeGroup: builder.mutation<string, TeamArg & { nudgeGroup: NudgeGroup }>({
      query: ({ team, nudgeGroup }) => ({
        url: toPath(Routes.TeamNudgeGroups, { team }),
        method: 'POST',
        body: nudgeGroup,
      }),
      invalidatesTags: [{ type: 'nudge-group', id: 'all' }],
    }),
    updateNudgeGroup: builder.mutation<void, TeamIdArg & { nudgeGroup: NudgeGroup }>({
      query: ({ id, team, nudgeGroup }) => ({
        url: toPath(Routes.TeamNudgeGroup, { id, team }),
        method: 'PUT',
        body: nudgeGroup,
      }),
      invalidatesTags: (_, __, { id }) => [{ type: 'nudge-group', id }],
    }),
    archiveNudgeGroup: builder.mutation<void, TeamIdArg>({
      query: ({ id, team }) => ({
        url: toPath(Routes.ArchiveNudgeGroup, { id, team }),
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'nudge-group', id: 'all' }],
    }),
  }),
});

export const {
  useGetNudgeGroupsQuery,
  useGetNudgeGroupQuery,
  useCreateNudgeGroupMutation,
  useUpdateNudgeGroupMutation,
  useArchiveNudgeGroupMutation,
} = nudgeGroupApi;
