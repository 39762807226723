import {
  AuthenticatedCondition,
  B2BCustomerCondition,
  CartCountCondition,
  CartItemAddedCondition,
  CartLargeSumCondition,
  CartQuantityCondition,
  Condition,
  ConditionType,
  ConditionTypes,
  Limit,
  DeviceTypeCondition,
  Operand,
  Operands,
  RewardBalanceCondition,
  RewardCountCondition,
  RewardRedeemableCondition,
  DeviceType,
  DeviceTypes,
  StoreCondition,
  PostCodeCondition,
  TimeCondition,
  IdentifiedUserTarget,
  IdentifiedUserTargets,
  TrafficCondition,
} from '@nfw/nudge/types';
import {
  isArrayOfType,
  isBoolean,
  isInteger,
  isNaturalNumber,
  isObject,
  isOptionallyDefined,
  isRegexpMatch,
  isString,
} from '@nfw/utils';

/**
 * Check if the limit is valid.
 *
 * @param l - the limit to check.
 *
 * @returns true if valid, otherwise false.
 */
const isLimit = (l: unknown): l is Limit => isObject(l) && isNaturalNumber(l.value);

/**
 * Check if the condition is of a certain condition type.
 *
 * @param condition - the condition to test.
 * @param type - the condition type.
 *
 * @returns true if the condition is of the expected type, otherwise false.
 */
const isBaseConditionOfType = (
  condition: unknown,
  type: ConditionType
): condition is Record<string, unknown> => isObject(condition) && condition.type === type;

/**
 * Check if the operand is a valid operation type.
 *
 * @param type - the type to check.
 *
 * @returns true if valid, otherwise false.
 */
const isOperand = (operand: unknown): operand is Operand =>
  Object.values(Operands).includes(operand as Operand);

/**
 * Check if the device is a valid device type.
 *
 * @param type - the type to check.
 *
 * @returns true if valid, otherwise false.
 */
const isDeviceType = (device: unknown): device is DeviceType =>
  Object.values(DeviceTypes).includes(device as DeviceType);

/**
 * Check if the time is in the format 'HH:mm'.
 *
 * @param t - the time to check.
 *
 * @returns true if valid, otherwise false.
 */
const isValidTimeFormat = (t: unknown): boolean =>
  isString(t) && isRegexpMatch(t, /^([01]\d|2[0-3]):([0-5]\d)$/);

/**
 * Check if user is identified user target.
 *
 * @param i - the identified user target to test.
 *
 * @returns true when valid, otherwise false.
 */
const isIdentifiedUserTarger = (i: unknown): i is IdentifiedUserTarget =>
  Object.values(IdentifiedUserTargets).includes(i as IdentifiedUserTarget);

/**
 * Check if the type is a valid  condition type.
 *
 * @param type - the type to check.
 *
 * @returns true if valid, otherwise false.
 */
export const isConditionType = (type: unknown): type is ConditionType =>
  Object.values(ConditionTypes).includes(type as ConditionType);

/**
 * Check if a condition is a authenticated condition.
 *
 * @param c - the condition.
 *
 * @returns true if the condition is an authenticated condition, otherwise false.
 */
export const isAuthenticatedCondition = (c: unknown): c is AuthenticatedCondition =>
  isBaseConditionOfType(c, ConditionTypes.Authenticated) &&
  isBoolean(c.value) &&
  isOptionallyDefined(c.userTarget, isIdentifiedUserTarger);

/**
 * Check if a condition is a b2b customer condition.
 *
 * @param c - the condition.
 *
 * @returns true when valid, otherwise false.
 */
export const isB2BCustomerCondition = (c: unknown): c is B2BCustomerCondition =>
  isBaseConditionOfType(c, ConditionTypes.B2BCustomer);

/**
 * Check if a condition is a valid reward count condition.
 *
 * @param c - the condition.
 *
 * @returns true if the valid, otherwise false.
 */
export const isRewardCountCondition = (c: unknown): c is RewardCountCondition =>
  isBaseConditionOfType(c, ConditionTypes.RewardCount) &&
  isNaturalNumber(c.value) &&
  isOptionallyDefined(c.limit, isLimit);

/**
 * Check if a condition is a valid reward balance condition.
 *
 * @param c - the condition.
 *
 * @returns true if the valid, otherwise false.
 */
export const isRewardBalanceCondition = (c: unknown): c is RewardBalanceCondition =>
  isBaseConditionOfType(c, ConditionTypes.RewardBalance) &&
  isNaturalNumber(c.value) &&
  isOptionallyDefined(c.limit, isLimit);

/**
 * Check if condition is a valid redeemable condition.
 *
 * @param c - the condition.
 *
 * @returns true if valid, otherwise false.
 */
export const isRewardRedeemableCondition = (c: unknown): c is RewardRedeemableCondition =>
  isBaseConditionOfType(c, ConditionTypes.RewardRedeemable) && isString(c.value);

/**
 * Check if condition is a valid cart count condition.
 *
 * @param c - the condition.
 *
 * @returns true when valid, otherwise false.
 */
export const isCartCountCondition = (c: unknown): c is CartCountCondition =>
  isBaseConditionOfType(c, ConditionTypes.CartCount) && isNaturalNumber(c.value);

/**
 * Check if condition is a valid cart quantity condition.
 *
 * @param c - the condition.
 *
 * @returns true when valid, otherwise false.
 */
export const isCartQuantityCondition = (c: unknown): c is CartQuantityCondition =>
  isBaseConditionOfType(c, ConditionTypes.CartQuantity) && isNaturalNumber(c.value);

/**
 * Check if the condition is a valid cart item added condition.
 *
 * @param c - the condition.
 *
 * @returns true when valid, otherwise false.
 */
export const isCartItemAddedCondition = (c: unknown): c is CartItemAddedCondition =>
  isBaseConditionOfType(c, ConditionTypes.CartItemAdded) &&
  isArrayOfType(c.values, isString) &&
  isOperand(c.op);

/**
 * Check if the condition is a valid cart large sum condition.
 *
 * @param c - the condition.
 *
 * @returns true when valid, otherwise false.
 */
export const isCartLargeSumCondition = (c: unknown): c is CartLargeSumCondition =>
  isBaseConditionOfType(c, ConditionTypes.CartLargeSum);

/**
 * Check if the condition is a valid device type condition.
 *
 * @param c - the condition.
 *
 * @returns true when valid, otherwise false.
 */
export const isDeviceTypeCondition = (c: unknown): c is DeviceTypeCondition =>
  isBaseConditionOfType(c, ConditionTypes.DeviceType) && isDeviceType(c.value);

/**
 * Check if the condition is a valid store condition.
 *
 * @param c - the condition.
 *
 * @returns true when valid, otherwise false.
 */
export const isStoreCondition = (c: unknown): c is StoreCondition =>
  isBaseConditionOfType(c, ConditionTypes.Store) && isArrayOfType(c.values, isString);

/**
 * Check if the condition is a valid post code condition.
 *
 * @param c - the condition.
 *
 * @returns true when valid, otherwise false.
 */
export const isPostCodeCondition = (c: unknown): c is PostCodeCondition =>
  isBaseConditionOfType(c, ConditionTypes.PostCode) && isArrayOfType(c.values, isString);

/**
 * Check if the condition is a valid time condition.
 *
 * @param c - the condition.
 *
 * @returns true when valid, otherwise false.
 */
export const isTimeCondition = (c: unknown): c is TimeCondition =>
  isBaseConditionOfType(c, ConditionTypes.Time) &&
  isValidTimeFormat(c.startTime) &&
  isValidTimeFormat(c.endTime);

/**
 * Check if the condition is a valid traffic condition.
 *
 * @param c - the condition.
 *
 * @returns - true when valid, otherwise false.
 */
export const isTrafficCondition = (c: unknown): c is TrafficCondition =>
  isBaseConditionOfType(c, ConditionTypes.Traffic) && isInteger(c.value, { min: 1, max: 99 });

/**
 * Check if condition is a valid condition.
 *
 * @param c - the condition.
 *
 * @returns true if valid, otherwise false.
 */
export const isCondition = (c: unknown): c is Condition =>
  isAuthenticatedCondition(c) ||
  isB2BCustomerCondition(c) ||
  isCartCountCondition(c) ||
  isCartItemAddedCondition(c) ||
  isCartLargeSumCondition(c) ||
  isCartQuantityCondition(c) ||
  isDeviceTypeCondition(c) ||
  isRewardCountCondition(c) ||
  isRewardBalanceCondition(c) ||
  isRewardRedeemableCondition(c) ||
  isStoreCondition(c) ||
  isPostCodeCondition(c) ||
  isTimeCondition(c) ||
  isTrafficCondition(c);
