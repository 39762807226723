import React, { useCallback, useEffect } from 'react';
import Button from '@ingka/button';
import Text from '@ingka/text';
import { MessageWidget, TipWidget, WidgetTypes } from '@nfw/nudge/types';
import { useLazyIcon } from '@nfw/ui/common';
import classNames from 'classnames';
import './index.scss';

export interface MessageProps {
  /** The widget id */
  id: string;
  /** Flag for if the message should show */
  show: boolean;
  /** The skapa prefix */
  prefix?: string;
  /** The max width of the message */
  maxWidth?: number;
  /** The min height of the message */
  minHeight?: number;
  /** The top position */
  top?: number;
  /** The left position */
  left?: number;
  /** The widget */
  widget: MessageWidget | TipWidget;
  /** The widget text when overlayed. */
  text?: string;
  /** The hierarchy index */
  hierarchyIndex?: number;
  /** The closing function */
  onClose: () => void;
  /** Callback when the message is closed */
  onClosed?: () => void;
  /** The submit function */
  onAction?: () => void;
}

export const Message = React.forwardRef<HTMLDivElement, MessageProps>(
  (
    {
      id,
      show,
      prefix,
      widget,
      text,
      hierarchyIndex = 0,
      minHeight,
      maxWidth,
      top,
      left,
      onAction,
      onClose,
      onClosed,
    },
    ref
  ) => {
    const [setIcon, getIcon] = useLazyIcon();
    const onTransitionEnd = useCallback(
      ({ target }) => {
        if (!show && target.id === id) {
          onClosed?.();
        }
      },
      [show, onClosed, id]
    );

    useEffect(() => {
      setIcon('cross');
    }, [setIcon]);

    return (
      <div
        id={id}
        ref={ref}
        className={classNames('nfw-message', {
          show,
          disabled: hierarchyIndex > 0,
        })}
        onTransitionEnd={onTransitionEnd}
        style={{ minHeight, maxWidth, top, left }}
      >
        <div className={classNames('triangle', 'top')} />
        <div className="contents">
          <Text
            prefix={prefix}
            bodySize="s"
            tagName="p"
            className={classNames('text', {
              visible: hierarchyIndex === 0,
            })}
          >
            {text ?? widget.text}
          </Text>
          {widget.type === WidgetTypes.Message && (
            <div className="actions">
              {widget.action && (
                <Button
                  type="tertiary"
                  prefix={prefix}
                  onClick={onAction}
                  size="small"
                  text={widget.action.text}
                  inverseTheme
                />
              )}
              <Button
                type="tertiary"
                prefix={prefix}
                onClick={onClose}
                size="small"
                ssrIcon={getIcon('cross')}
                iconOnly
                inverseTheme
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default Message;
