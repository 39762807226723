import React, { useEffect, useMemo, useRef, useState } from 'react';
import SSRIcon from '@ingka/ssr-icon';
import { IconType } from '@nfw/nudge/types';
import { useLazyIcon } from '@nfw/ui/common';
import { randomize } from '@nfw/utils';
import './index.scss';

export type ReactionProps = {
  /** The id of the reaction. */
  id: string;
  /** The skapa prefix. */
  prefix?: string;
  /** The reactions to use. */
  reactions: IconType[];
  /** The finished callback. */
  onFinished?: () => void;
};

export const Reaction: React.FC<ReactionProps> = ({ id, prefix, reactions, onFinished }) => {
  const animationState = useRef<Set<number>>(new Set());
  const [animate, setAnimate] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(false);
  const positions = useMemo(() => reactions.map(() => randomize(10, 90)), [reactions]);

  const [setIcons, getIcon] = useLazyIcon();

  useEffect(() => {
    setIcons(...reactions);
  }, [reactions, setIcons]);

  useEffect(() => {
    setAnimate(true);
  }, []);

  useEffect(() => {
    if (animationFinished) {
      onFinished?.();
    }
  }, [animationFinished, onFinished]);

  const onAnimationFinished = (index: number) => {
    animationState.current.add(index);
    setAnimationFinished(animationState.current.size === reactions.length);
  };

  return animationFinished ? null : (
    <div id={id} className="nfw-reaction-overlay">
      {reactions.map((reaction, index) => {
        const icon = getIcon(reaction);

        if (icon) {
          return (
            <div
              key={`reaction${index}`}
              onTransitionEnd={() => onAnimationFinished(index)}
              className="item"
              style={
                animate
                  ? {
                      bottom: '100%',
                      opacity: 0,
                      left: `${positions[index]}%`,
                      transitionDelay: `${300 * index}ms`,
                    }
                  : {
                      left: `${positions[index]}%`,
                    }
              }
            >
              <div>
                <SSRIcon paths={icon} prefix={prefix} className="icon" />
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default Reaction;
