import React, { useMemo } from 'react';
import { TeamConfiguration } from '@nfw/contracts/self-service';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import GenericFilter, { NamedFilter, SearchFilterFunction } from '../GenericFilter';

const searchFilter: SearchFilterFunction<TeamConfiguration> = (searchValue, value) =>
  value
    ? value.id.toLowerCase().includes(searchValue) || value.name.toLowerCase().includes(searchValue)
    : false;

const getNamedFilters = (t: TFunction): NamedFilter<TeamConfiguration>[] => [
  {
    id: 'status',
    label: t('global.status'),
    distinctValues: (value: TeamConfiguration) => value.status,
    filter: (values: string[], value: TeamConfiguration) => values.includes(value.status),
  },
];

const TeamFilter: React.FC<{
  data: TeamConfiguration[];
  onFilterChange: (data: TeamConfiguration[]) => void;
}> = ({ data, onFilterChange }) => {
  const { t } = useTranslation();
  const namedFilters = useMemo(() => getNamedFilters(t), [t]);

  return (
    <GenericFilter
      data={data}
      onFilterChange={onFilterChange}
      searchFilter={searchFilter}
      namedFilters={namedFilters}
      type="request"
      searchLabel={t('global.filter.placeholder')}
    />
  );
};

export default TeamFilter;
