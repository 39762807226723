import Text from '@ingka/text';
import classNames from 'classnames';
import { Route } from '../../routes/types';
import LinearLayout from '../LinearLayout';
import PuffaHyperlink from '../PuffaHyperlink';
import './index.scss';

export type DescribedItemProps = {
  title: string;
  text?: string;
  textGroup?: string[];
  navigateTo?: Route;
  navigateToParams?: Record<string, string | number>;
  externalLink?: string;
};

const DescribedItem: React.FC<DescribedItemProps> = ({
  title,
  text,
  textGroup,
  navigateTo,
  navigateToParams,
  externalLink,
}) => {
  const tableText = (
    <Text
      className={classNames('table-text', { clickable: navigateTo || externalLink })}
      bodySize="m"
    >
      {text}
    </Text>
  );

  return (
    <>
      <LinearLayout>
        <Text bodySize="s">{title}</Text>
        <Text className={classNames('table-text', { clickable: navigateTo })} bodySize="m">
          {navigateTo ? (
            <PuffaHyperlink route={navigateTo} params={navigateToParams} subtle>
              {tableText}
            </PuffaHyperlink>
          ) : externalLink ? (
            <a href={externalLink} target="_blank" rel="noreferrer">
              {tableText}
            </a>
          ) : (
            tableText
          )}
        </Text>
      </LinearLayout>
      {textGroup &&
        textGroup.map((t) => (
          <Text bodySize="m" key={t} className="group">
            {t}
          </Text>
        ))}
    </>
  );
};

export default DescribedItem;
