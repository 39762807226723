import Text from '@ingka/text';
import { OrchestrationCollision } from '@nfw/nudge-collision';
import { useTranslation } from 'react-i18next';
import CollisionTable from '../../components/CollisionTable';
import Page, { PageHeader } from '../../components/Page';
import { useGetCollisions, useGetOrchestrations } from '../../features/orchestrations/hooks';

const CollisionPage: React.FC = () => {
  const { t } = useTranslation();

  const { collisions, isLoading: isCollisionsLoading } = useGetCollisions();
  const { orchestrations, isLoading: isOrchestrationLoading } = useGetOrchestrations();

  let orchestrationCollisions: OrchestrationCollision[] = [];

  if (collisions && orchestrations) {
    orchestrationCollisions = Object.keys(collisions).reduce<OrchestrationCollision[]>(
      (acc, key) => {
        const orchestration = orchestrations.find(({ id }) => id === key);
        if (!orchestration) return acc;

        const collisionRetailUnits = new Set(
          collisions[key].flatMap((collision) => collision.retailUnits)
        );
        const retailUnits = orchestration.retailUnits.filter((retailUnit) =>
          collisionRetailUnits.has(retailUnit)
        );

        const collidingNudges = collisions[key].map(({ id, name }) => ({ id, name }));

        const page = [...new Set(collisions[key].map((collision) => collision.namespace))].join(
          ', '
        );

        return [
          ...acc,
          {
            id: orchestration.id,
            displayName: `${orchestration.name} - ${orchestration.id}`,
            team: orchestration.team,
            retailUnits,
            collidingNudges,
            page,
          },
        ];
      },
      []
    );
  }

  return (
    <Page
      loading={isCollisionsLoading || isOrchestrationLoading}
      padded
      header={<PageHeader title={t('page.nudge_collisions.header')} />}
      crumbs={[{ name: t('global.overview'), to: '/?tab=features' }]}
    >
      <Text bodySize="l">{t('page.nudge_collisions.description')}</Text>
      {orchestrationCollisions.length > 0 ? (
        <CollisionTable collisions={orchestrationCollisions} t={t} />
      ) : (
        <Text headingSize="m">{t('component.collision_table.no_collisions')}</Text>
      )}
    </Page>
  );
};

export default CollisionPage;
