import { CatalogueBaseNode } from '@nfw/ikea/catalogue';
import { IconType } from '@nfw/nudge/types';

export type Input = number | string | boolean | Input[] | { [key: string]: Input };

export type FormValue = { [key: string]: Input };

export const ValidationTypes = {
  Required: 'required',
  Regexp: 'regexp',
} as const;
export type ValidationType = (typeof ValidationTypes)[keyof typeof ValidationTypes];

type ValidationBase<T extends ValidationType> = {
  type: T;
  errorMessage: string;
};

export type RegexpValidation = ValidationBase<'regexp'> & {
  regexp: RegExp | string;
};

export type RequiredValidation = ValidationBase<'required'>;

export type Validation = RegexpValidation | RequiredValidation;

export const InputTypes = {
  HiddenInput: 'hidden_input',
  Select: 'select',
  TextInput: 'text_input',
  TextInputGroup: 'text_input_group',
  TextArea: 'text_area',
  NumberInput: 'number_input',
  Checkbox: 'checkbox',
  Slider: 'slider',
  Switch: 'switch',
  Quantity: 'quantity',
  InlineFormControl: 'inline_form_control',
  ManyOfMany: 'many_of_many',
  ModalFormControl: 'modal_form_control',
  ModalControl: 'modal_control',
  PillListbox: 'pill_listbox',
  Radio: 'radio',
  BooleanRadioGroup: 'boolean_radio_group',
  RadioGroup: 'radio_group',
  FixedText: 'fixed_text',
  HierarchySelect: 'hierarchy_select',
  Choice: 'choice',
  Divider: 'divider',
  InlineMessage: 'inline_message',
  Ranking: 'ranking',
  Combobox: 'combobox',
  CheckboxGroup: 'checkbox_group',
  DatePicker: 'date_picker',
} as const;
export type InputType = (typeof InputTypes)[keyof typeof InputTypes];

export type FieldConfiguration<S extends InputType, T extends Input> = {
  inputType: S;
  name: string;
  label: string;
  helpMessage?: string;
  disabled?: boolean;
  /**
   * Either the field name (in conjunction with the requiresValueOf-prop), or or a list of objects
   * with field names and their respective requiresValueOf-props. In the later case the
   * requiresValueOf can be a function that takes the value and form context and returns whether
   * the field should be enabled or not.
   */
  dependsOn?:
    | string
    | {
        name: string;
        requiresValueOf: Input[] | ((value: Input, context: Input | undefined) => boolean);
      }[];
  requiresValueOf?: Input[];
  defaultValue?: T;
};

export type HiddenFieldConfiguration = Omit<
  FieldConfiguration<'hidden_input', Input>,
  'defaultValue' | 'label' | 'helpMessage'
> & {
  value: Input;
};

export type ValidationFieldConfiguration<S extends InputType, T extends Input> = FieldConfiguration<
  S,
  T
> & {
  validations?: Validation[];
};

export type NumberedFieldConfiguration<T extends 'slider' | 'quantity'> = FieldConfiguration<
  T,
  number
> & {
  min: number;
  max: number;
};

export type OptionValue = {
  name: string;
  value: string;
  extra?: Input;
  icon?: IconType;
};

export type HierarchyNode = CatalogueBaseNode<{
  displayName: string;
  disabled?: boolean;
  /** Indicates that a node can be selected. */
  selectable?: boolean;
  /** Indicates that it is possible to navigate to a node's children. */
  navigable?: boolean;
  /** Indicates if the node is selected. */
  selected?: boolean;
}>;

/**
 * A select field format.
 */
export type SelectFieldConfiguration = ValidationFieldConfiguration<'select', string> & {
  options: OptionValue[];
  selectedHelpMessage?: Record<string, string>;
  hintText?: string;
  optionHelpMessage?: Record<string, string>;
};

export type ComboboxFieldConfiguration = ValidationFieldConfiguration<'combobox', string> & {
  options: OptionValue[];
  placeholderText: string;
  labelId?: string;
  listboxId?: string;
  hintText?: string;
};

/**
 * The number field configuration.
 */
export type NumberFieldConfiguration = ValidationFieldConfiguration<'number_input', number> & {
  limit?: number;
};

/**
 * The text field configuration.
 */
export type TextFieldConfiguration = ValidationFieldConfiguration<'text_input', string> & {
  inputMode?: 'search' | 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal';
  limit?: number;
};

/**
 * The fixed text field configuration.
 */
export type FixedTextFieldConfiguration = Omit<
  FieldConfiguration<'fixed_text', string>,
  'name' | 'defaultValue' | 'label'
> & {
  label?: string;
  labelHeadingSize?: 'xs' | 's' | 'm' | 'l' | 'xl';
  labelBodySize?: 's' | 'm' | 'l' | 'xl';
  text: string;
  textHeadingSize?: 'xs' | 's' | 'm' | 'l' | 'xl';
  textBodySize?: 's' | 'm' | 'l' | 'xl';
  sectionGap?: boolean;
  helpMessage?: string;
};

export type TextAreaFieldConfiguration = ValidationFieldConfiguration<'text_area', string> & {
  limit?: number;
};

export type InlineMessageConfiguration = Omit<
  FieldConfiguration<'inline_message', FormValue>,
  'defaultValue' | 'label' | 'helpMessage' | 'name'
> & {
  title?: string;
  headingLevel?: '1' | '2' | '3' | '4' | '5' | '6';
  body?: string;
  variant: 'informative' | 'positive' | 'cautionary' | 'negative';
  subtle?: boolean;
};

/**
 * The checkbox field configuration.
 */
export type CheckboxFieldConfiguration = FieldConfiguration<'checkbox', boolean> & {
  reverse?: boolean;
};

/**
 * The checkbox group field configuration.
 */
export type CheckboxGroupFieldConfiguration = FieldConfiguration<'checkbox_group', string[]> & {
  options: CheckboxGroupOption[];
};

export type CheckboxGroupOption = {
  value: string;
  label: string;
  id: string;
  disabled?: boolean;
  caption?: string;
  defaultChecked?: boolean;
};

export type PillListboxOption = {
  name: string;
  value: string;
  description?: string;
  icon?: IconType;
};

export type PillListboxFieldConfiguration = ValidationFieldConfiguration<'pill_listbox', string> & {
  includePlaceholderOption?: boolean;
  placeholderOptionName?: string;
  options: PillListboxOption[];
  label: string;
  btnLabel: string;
  includeSearch: boolean;
  defaultValue?: string;
};

/**
 * The switch field configuration.
 */
export type SwitchFieldConfiguration = ValidationFieldConfiguration<'switch', boolean> & {
  subtle?: boolean;
  reverse?: boolean;
};

/**
 * The slider field configuration.
 */
export type SliderFieldConfiguration = NumberedFieldConfiguration<'slider'> & {
  unitPrefix?: string;
  unit: string;
  step: number;
  defaultValue: number; // Required for slider since it cannot display 'undefined'.
};

/**
 * The quantity field configuration.
 */
export type QuantityFieldConfiguration = NumberedFieldConfiguration<'quantity'>;

/**
 * The inline presenter is a checkbox controlling show/hide form.
 */
export type InlineFormPresenterConfiguration = Omit<
  FieldConfiguration<'inline_form_control', FormValue>,
  'defaultValue'
> & {
  configuration: LayoutConfiguration;
  type: 'switch' | 'checkbox';
  required?: boolean;
  caption?: string;
  subtle?: boolean;
};

/**
 * The modal form presenter is a picker style component.
 */
export type ModalFormControlConfiguration = Omit<
  FieldConfiguration<'modal_form_control', Input[]>,
  'defaultValue'
> & {
  configuration: LayoutConfiguration;
  editable: boolean;
  disabledFieldsWhenEditable?: string[];
  valueIdentifier: string;
  modalPrimaryActionText: string;
  modalSecondaryActionText: string;
  modalEditPrimaryActionText?: string;
  modalEditSecondaryActionText?: string;
  sectionLabel?: string;
  defaultValue?: Input[];
  modalTitle?: string;
  modalHeader?: string;
  modalBodyText?: string;
  draggable?: boolean;
  modalAlignment?: 'left' | 'right';
};

/**
 * A modal control presenter is a variant where exactly one value is picked or created.
 */
export type ModalControlConfiguration = Omit<
  FieldConfiguration<'modal_control', string[]>,
  'defaultValue'
> & {
  sectionHeader?: string;
  control: Omit<TextFieldConfiguration, 'name'>;
  modalPrimaryActionText: string;
  modalSecondaryActionText: string;
  draggable?: boolean;
  modalAlignment?: 'left' | 'right';
};

/**
 * Many of many configuration.
 */
export type ManyOfManyConfiguration = Omit<
  ValidationFieldConfiguration<'many_of_many', string[]>,
  'validations'
> & {
  values: OptionValue[];
  header?: string;
  hintText?: string;
  selectAllText?: string;
  selectOneText?: string;
  clearAllText?: string;
  validations?: [RequiredValidation];
  draggable?: boolean;
};

/**
 * Text group configuration.
 */
export type TextInputGroupConfiguration = Omit<
  ValidationFieldConfiguration<'text_input_group', string[]>,
  'validations'
> & {
  header?: string;
  hintText?: string;
  clearAllText?: string;
  validations?: [RequiredValidation];
  draggable?: boolean;
};

/**
 * Hierarchy select configuration.
 */
export type HierarchySelectConfiguration = Omit<
  ValidationFieldConfiguration<'hierarchy_select', string[]>,
  'validations'
> & {
  backButtonLabel: string;
  traceLabel: string;
  rootNode?: HierarchyNode;
  errorMessage?: string;
  isLoading?: boolean;
};

/**
 * The date picker configuration.
 */
export type DatePickerConfiguration = ValidationFieldConfiguration<'date_picker', string>;

export type RadioButtonOption = {
  id: string;
  label: string;
  value: string;
  disabled?: boolean;
};

export type BooleanRadioButtonGroupConfiguration = FieldConfiguration<
  'boolean_radio_group',
  boolean
> & {
  trueLabel: string;
  falseLabel: string;
};

export type RadioButtonGroupConfiguration = FieldConfiguration<'radio_group', string> & {
  options: RadioButtonOption[];
  selectedHelpMessage?: Record<string, string>;
};

export type ChoiceItem = {
  title: string;
  caption: string;
  value: string;
  id: string;
  selected?: boolean;
};

export type ChoiceConfiguration = Omit<FieldConfiguration<'choice', Input>, 'defaultValue'> & {
  choiceItems: ChoiceItem[];
  defaultValue: string;
};

export type DividerConfiguration = Omit<
  FieldConfiguration<'divider', string>,
  'name' | 'defaultValue' | 'label' | 'hidden' | 'helpMessage'
>;

export type RankingItem = {
  icon: IconType;
  label: string;
  description: string;
  dismissable: boolean;
  value: Input;
  tag?: string;
  noOverflow?: boolean;
  highlight?: boolean;
};

export type RankingConfiguration = FieldConfiguration<'ranking', Input[]> & {
  items: RankingItem[];
  enumerate?: boolean;
  leadLabel?: string;
  draggable?: boolean;
  showEventsSelected?: boolean;
  countString?: string;
  enableMoveToTop?: boolean;
  moveToTopLabel?: string;
};

export type ComponentConfiguration =
  | HiddenFieldConfiguration
  | SelectFieldConfiguration
  | TextFieldConfiguration
  | TextAreaFieldConfiguration
  | NumberFieldConfiguration
  | CheckboxFieldConfiguration
  | SliderFieldConfiguration
  | SwitchFieldConfiguration
  | QuantityFieldConfiguration
  | InlineFormPresenterConfiguration
  | ModalFormControlConfiguration
  | ModalControlConfiguration
  | ManyOfManyConfiguration
  | RadioButtonGroupConfiguration
  | FixedTextFieldConfiguration
  | ChoiceConfiguration
  | PillListboxFieldConfiguration
  | DividerConfiguration
  | InlineMessageConfiguration
  | TextInputGroupConfiguration
  | RankingConfiguration
  | HierarchySelectConfiguration
  | ComboboxFieldConfiguration
  | BooleanRadioButtonGroupConfiguration
  | CheckboxGroupFieldConfiguration
  | DatePickerConfiguration;

/**
 * The layout configuration.
 */
export type LayoutConfiguration = ComponentConfiguration[];

export type FormContext = {
  input?: Input;
};
