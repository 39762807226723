import { useEffect, useState } from 'react';
import { nudgeGroupApi } from '../nudgeGroups/api';
import { orchestrationApi } from '../orchestrations/api';
import { teamApi } from '../teams/api';
import { widgetApi } from '../widgets/api';

/**
 * Hook for fetching overview data.
 *
 * @param skip - flag for when query should start when false the query will start.
 */
export const useOverviewEntities = (skip: boolean) => {
  const [isInitialised, setInitialised] = useState(false);
  const { isError: isOrchestrationError, data: orchestrations } =
    orchestrationApi.endpoints.getOrchestrations.useQuery(undefined, { skip });

  const { isError: isDeploymentError, data: deployments } =
    orchestrationApi.endpoints.getDeployments.useQuery(undefined, {
      skip,
    });
  const { isError: isTeamError, data: teams } = teamApi.endpoints.getTeams.useQuery(undefined, {
    skip,
  });
  const { isError: isWidgetsError, data: widgets } = widgetApi.endpoints.getWidgets.useQuery(
    undefined,
    {
      skip,
    }
  );
  const { isError: isNudgeGroupsError, data: nudgeGroups } =
    nudgeGroupApi.endpoints.getNudgeGroups.useQuery(undefined, {
      skip,
    });

  useEffect(() => {
    setInitialised(!!orchestrations && !!deployments && !!widgets && !!teams && !!nudgeGroups);
  }, [skip, orchestrations, deployments, widgets, teams, nudgeGroups]);

  return {
    isInitialised,
    isError:
      isOrchestrationError ||
      isDeploymentError ||
      isTeamError ||
      isWidgetsError ||
      isNudgeGroupsError,
  };
};
