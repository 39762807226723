import React, { useMemo } from 'react';
import { Deployment, DeploymentState, FailureReason } from '@nfw/deployment-types';
import LinearLayout from '../../../components/LinearLayout';
import LogEntry from './LogEntry';
import { LocaleState } from './types';
import './index.scss';

export type ReleaseLogProps = {
  deployment: Deployment;
};

/**
 * Utility function to map all deployment states into a log entries.
 *
 * @param entry - a deployment entry.
 *
 * @returns the locale state.
 */
const toLocaleState = (entry: [string, DeploymentState][]): LocaleState =>
  entry
    .map(([locale, state]) => ({ state, locale }))
    .reduce<LocaleState>(
      (acc, { locale, state }) => ({
        ...acc,
        [state.failureReason]: [...(acc[state.failureReason] || []), locale],
      }),
      {}
    );

const ReleaseLog: React.FC<ReleaseLogProps> = ({ deployment }) => {
  const [cte, prod] = useMemo(
    () => [
      toLocaleState(Object.entries(deployment.cte)),
      toLocaleState(Object.entries(deployment.prod)),
    ],
    [deployment]
  );

  return (
    (Object.keys(cte).length > 0 || Object.keys(prod).length > 0) && (
      <LinearLayout gap="m" className="log">
        {Object.entries(cte).map(
          ([reason, locales]) =>
            reason !== 'none' && (
              <LogEntry key={reason} reason={reason as FailureReason} locales={locales} />
            )
        )}

        {Object.entries(prod).map(
          ([reason, locales]) =>
            reason !== 'none' && (
              <LogEntry key={reason} reason={reason as FailureReason} locales={locales} />
            )
        )}
      </LinearLayout>
    )
  );
};

export default ReleaseLog;
