import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { nudgeGroupApi } from './api';

export const selectNudgeGroups = createSelector(
  nudgeGroupApi.endpoints.getNudgeGroups.select(),
  ({ data }) => data ?? []
);

export const selectTeamNudgeGroups = createSelector(
  [selectNudgeGroups, (_: RootState, team?: string) => team],
  (nudgeGroups, team) => nudgeGroups.filter((nudgeGroup) => nudgeGroup.team === team)
);

export const selectActiveTeamNudgeGroups = createSelector(
  [selectNudgeGroups, (_: RootState, team?: string) => team],
  (nudgeGroups, team) =>
    nudgeGroups.filter((nudgeGroup) => nudgeGroup.team === team && !nudgeGroup.isArchived)
);
