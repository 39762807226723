import { DOMAnchor, DOMTarget, DOMTargetType, DOMTargetTypes } from '@nfw/nudge/types';
import { isArrayOfType, isObject, isOptionallyDefined, isString } from '@nfw/utils';
import { isNudgeVisibilityRule } from './visibility';

/**
 * Check if the type is a valid DOM target type.
 *
 * @param type - the type to check.
 *
 * @returns true if the type is a valid, otherwise false.
 */
export const isDOMTargetType = (type: unknown): type is DOMTargetType =>
  Object.values(DOMTargetTypes).includes(type as DOMTargetType);

/**
 * Check if a target is a valid DOM target.
 *
 * @param target - the anchor to test.
 *
 * @returns true if the target is a valid, otherwise false.
 */
export const isDOMTarget = (target: unknown): target is DOMTarget =>
  isObject(target) && isDOMTargetType(target.type) && isString(target.id);

/**
 * Check if an anchor is a valid DOM anchor.
 *
 * @param anchor - the anchor to test.
 *
 * @returns true if the anchor is valid, otherwise false.
 */
export const isDOMAnchor = (anchor: unknown): anchor is DOMAnchor =>
  isObject(anchor) &&
  isDOMTargetType(anchor.type) &&
  isString(anchor.id) &&
  isOptionallyDefined(anchor.visibilityRule, isNudgeVisibilityRule);

/**
 * Check the anchors are valid DOM anchors.
 *
 * @param anchors - the anchors to test.
 *
 * @returns true if the anchors are valid, otherwise false.
 */
export const isDOMAnchors = (anchors: unknown): anchors is DOMAnchor[] =>
  isArrayOfType(anchors, isDOMAnchor);
