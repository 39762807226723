import { isNudgeExperimentType, isWidget } from '@nfw/nudge/typeguard';
import { NudgeExperimentType } from '@nfw/nudge/types';
import { isEvent, isScope, isWinningDirection } from '@nfw/optimizely/types';
import {
  isArrayOfType,
  isBoolean,
  isNaturalNumber,
  isObject,
  isOptionallyDefined,
  isString,
} from '@nfw/utils';
import {
  CampaignExperiment,
  CampaignExperimentRequest,
  DefaultEvent,
  DefaultMetric,
  DesignExperiment,
  DesignExperimentRequest,
  Experiment,
  ExperimentBase,
  ExperimentRequest,
  ExperimentResult,
  ExperimentResultRecord,
  MeasureType,
  MeasureTypes,
  MetricConfiguration,
  Metrics,
  ProjectEvents,
  VisibilityExperiment,
  VisibilityExperimentRequest,
} from './types';

/**
 * Check if the request is of a certain type.
 *
 * @param e - the request to test.
 * @param type - the type that should match.
 *
 * @returns true if valid, otherwise false.
 */
const isExperimentRequestOfType = (
  r: unknown,
  type: NudgeExperimentType
): r is Record<string, unknown> =>
  isObject(r) &&
  r.type === type &&
  isString(r.orchestrationId) &&
  isString(r.widgetId) &&
  isMetrics(r);

/**
 * Check if the request is a text variant request.
 *
 * @param r - the request to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isDesignExperimentRequest = (r: unknown): r is DesignExperimentRequest =>
  isExperimentRequestOfType(r, 'design') && isArrayOfType(r.variants, isWidget);

/**
 * Check if the request is a visibility request.
 *
 * @param r - the request to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isVisibilityExperimentRequets = (r: unknown): r is VisibilityExperimentRequest =>
  isExperimentRequestOfType(r, 'visibility');

/**
 * Check if the request is a campaign request.
 *
 * @param r - the request to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isCampaignExperimentRequest = (r: unknown): r is CampaignExperimentRequest =>
  isExperimentRequestOfType(r, 'campaign');

/**
 * Check if an experiment request is valid.
 *
 * @param e - the experiment request to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isExperimentRequest = (r: unknown): r is ExperimentRequest =>
  isVisibilityExperimentRequets(r) ||
  isDesignExperimentRequest(r) ||
  isCampaignExperimentRequest(r);

/**
 * Check if the experiment base is valid.
 *
 * @param e - the experiment base to test.
 *
 * @returns true if valid, otherwise false.
 */
const isExperimentBase = (e: unknown): e is ExperimentBase =>
  isObject(e) &&
  isString(e.team) &&
  isString(e.flagKey) &&
  isString(e.ruleKey) &&
  isBoolean(e.isRunning) &&
  isOptionallyDefined(e.creator, isString) &&
  isOptionallyDefined(e.isReminderEmailSent, isBoolean) &&
  isOptionallyDefined(e.startDate, isNaturalNumber) &&
  isOptionallyDefined(e.cwvEventIds, (value) => isArrayOfType(value, isNaturalNumber)) &&
  isOptionallyDefined(e.reportUrl, isString) &&
  isOptionallyDefined(e.motivation, isString);

/**
 * Check if the experiment is a text variant experiment.
 *
 * @param e - the experiment to test.
 *
 * @returns true if the experiment is a valid text variant experiment, otherwise false.
 */
export const isDesignExperiment = (e: unknown): e is DesignExperiment =>
  isDesignExperimentRequest(e) && isExperimentBase(e) && isOptionallyDefined(e.decision, isString);

/**
 * Check if the experiment is a visibility experiment.
 *
 * @param e - the experiment to test.
 *
 * @returns true if the experiment is a valid visibility experiment, otherwise false.
 */
export const isVisibilityExperiment = (e: unknown): e is VisibilityExperiment =>
  isVisibilityExperimentRequets(e) &&
  isExperimentBase(e) &&
  isOptionallyDefined(e.decision, isBoolean);

/**
 * Check if the experiment is a campaign experiment.
 *
 * @param e - the experiment to test.
 *
 * @returns true if the experiment is a valid campaign experiment, otherwise false.
 */
export const isCampaignExperiment = (e: unknown): e is CampaignExperiment =>
  isCampaignExperimentRequest(e) && isExperimentBase(e);

/**
 * Check if the experiment is an experiment.
 *
 * @param experiment - the experiment.
 *
 * @returns true if the experiment is a valid experiment, otherwise false.
 */
export const isExperiment = (experiment: unknown): experiment is Experiment =>
  isVisibilityExperiment(experiment) ||
  isDesignExperiment(experiment) ||
  isCampaignExperiment(experiment);

/**
 * Check if the experiments is an array of valid experiments.
 *
 * @param experiments - the experiments to test.
 *
 * @returns true if the experiments is an valid array of experiments, otherwise false.
 */
export const isExperiments = (experiments: unknown): experiments is Experiment[] =>
  isArrayOfType(experiments, isExperiment);

/**
 * Check if type is a MeasureType.
 *
 * @param type - the type.
 *
 * @returns true if type is a MeasureType, otherwise false.
 */
export const isMeasureType = (type: unknown): type is MeasureType =>
  Object.values(MeasureTypes).includes(type as MeasureType);

/**
 * Check if a metric configuration is valid.
 *
 * @param c - the metric configuration to check.
 *
 * @returns true when valid, otherwise false.
 */
export const isMetricConfiguration = (c: unknown): c is MetricConfiguration =>
  isObject(c) &&
  isWinningDirection(c.direction) &&
  isMeasureType(c.measure) &&
  isOptionallyDefined(c.scope, isScope) &&
  isOptionallyDefined(c.isGoverned, isBoolean);

/**
 * Check if default metric is valid.
 *
 * @param m - the default metric to check.
 *
 * @returns true when valid, otherwise false.
 */
export const isDefaultMetric = (m: unknown): m is DefaultMetric =>
  isObject(m) &&
  isNaturalNumber(m.id) &&
  isOptionallyDefined(m.isGoverned, isBoolean) &&
  isMetricConfiguration(m);

/**
 * Check if metrics are valid.
 *
 * @param m - the metrics to check.
 *
 * @returns true when valid, otherwise false.
 */
export const isMetrics = (m: unknown): m is Metrics =>
  isObject(m) && isArrayOfType(m.metrics, isDefaultMetric);

/**
 * Check if a default event is valid.
 *
 * @param e - the default event to check.
 *
 * @returns true when valid, otherwise false.
 */
export const isDefaultEvent = (e: unknown): e is DefaultEvent =>
  isObject(e) && isString(e.project_name) && isEvent(e);

/**
 * Check if project events are valid.
 *
 * @param p - the project events to check.
 *
 * @returns true when valid, otherwise false.
 */
export const isProjectEvents = (p: unknown): p is ProjectEvents =>
  isObject(p) && isString(p.name) && isArrayOfType(p.events, isEvent);

/**
 * Check if an experiment result is valid.
 *
 * @param r - the experiment result to check.
 *
 * @returns true when valid, otherwise false.
 */
const isExperimentResult = (r: unknown): r is ExperimentResult =>
  isObject(r) &&
  isBoolean(r.successful) &&
  isNaturalNumber(r.concludedTimestamp) &&
  isNudgeExperimentType(r.type) &&
  isString(r.reportUrl) &&
  isOptionallyDefined(r.decisionMotivation, isString);

/**
 * Check if an experiment result record is valid.
 *
 * @param r - the experiment result record to check.
 *
 * @returns returns true when valid, otherwise false.
 */
export const isExperimentResultRecord = (r: unknown): r is ExperimentResultRecord =>
  isObject(r) && isString(r.id) && isString(r.team) && isArrayOfType(r.results, isExperimentResult);

/**
 * Check if an array of experiment result records is valid.
 *
 * @param r - the records to check.
 *
 * @returns returns true when valid, otherwise false.
 */
export const isExperimentResultRecords = (r: unknown): r is ExperimentResultRecord[] =>
  isArrayOfType(r, isExperimentResultRecord);
