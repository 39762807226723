import { Deployment, Environment, LocalizedDeploymentState, PublishState } from '../types';
import { EnvironmentNode } from './EnvironmentNode';

const DRAFT = new EnvironmentNode({
  cte: 'draft',
  prod: 'draft',
  consolidated: { env: 'cte', status: 'draft' },
  isEditable: (permissions) => permissions.includes('EDIT'),
  isRepublishable: () => false,
  isArchivable: (permissions) => permissions.includes('EDIT'),
  next: { env: 'cte', action: 'publish' },
});

const PUBLISHED_CTE = new EnvironmentNode({
  cte: 'published',
  prod: 'draft',
  isEditable: (permissions) => permissions.includes('PUBLISH'),
  isRepublishable: (permissions) => permissions.includes('PUBLISH'),
  isArchivable: (permissions) => permissions.includes('PUBLISH'),
  consolidated: { env: 'cte', status: 'published' },
  next: { env: 'prod', action: 'create_experiment' },
});

const EXPERIMENT_CREATED = new EnvironmentNode({
  cte: 'published',
  prod: 'experiment_created',
  isEditable: (permissions) => permissions.includes('PUBLISH'),
  isRepublishable: (permissions) => permissions.includes('PUBLISH'),
  isArchivable: (permissions) => permissions.includes('PUBLISH'),
  consolidated: { env: 'prod', status: 'experiment_created' },
  next: { env: 'prod', action: 'experiment' },
});

const EXPERIMENTING = new EnvironmentNode({
  cte: 'published',
  prod: 'experimenting',
  isEditable: () => false,
  isRepublishable: () => false,
  isArchivable: (permissions) => permissions.includes('PUBLISH'),
  consolidated: { env: 'prod', status: 'experimenting' },
  next: { env: 'prod', action: 'conclude_experiment' },
});

const PUBLISHED_PROD = new EnvironmentNode({
  cte: 'published',
  prod: 'published',
  isEditable: () => false,
  isRepublishable: () => false,
  isArchivable: (permissions) => permissions.includes('PUBLISH'),
  consolidated: { env: 'prod', status: 'published' },
  next: { env: 'prod', action: 'create_experiment' },
});

/**
 * The root of the published state.
 */
const ROOT = (() => {
  DRAFT.setNext(PUBLISHED_CTE)
    .setNext(EXPERIMENT_CREATED)
    .setNext(EXPERIMENTING)
    .setNext(PUBLISHED_PROD)
    .setNext(EXPERIMENT_CREATED);
  return DRAFT;
})();

/**
 * Get the current environment node.
 *
 * @param deployment - the deployment.
 * @param withMostProgress - optional flag for using the deployment with most progress.
 *
 * @returns the current environmnet node.
 */
export const getCurrent = (deployment: Deployment, withMostProgress = false) =>
  ROOT.findNextWithState(deployment, withMostProgress) ?? ROOT;

/**
 * Get the current environment state.
 *
 * @param deployment - the deployment record.
 * @param withMostProgress - optional flag for using the deployment with most progress.
 *
 * @returns the current environment state.
 */
export const getEnvironmentState = (deployment: Deployment, withMostProgress = false) =>
  getCurrent(deployment, withMostProgress).getValue();

/**
 * Get the next actions to perform.
 *
 * @param deployment - the deployment record.
 *
 * @returns the next prop of the current environment state.
 */
export const getNext = (deployment: Deployment) => getEnvironmentState(deployment).next;

/**
 * Get the published deployments.
 *
 * @param deployment - the deployment record.
 * @param env - the environments.
 *
 * @returns the localized deployments where the nudge is either published or is experimenting.
 */
export const getPublishedDeployments = (deployment: Deployment, env: Environment) =>
  Object.entries(deployment[env])
    .filter(
      ([, { state }]) =>
        state &&
        (state === 'experimenting' || state === 'published' || state === 'experiment_created')
    )
    .reduce<LocalizedDeploymentState>((acc, current) => {
      const [locale, deploymentState] = current;

      return { ...acc, [locale]: deploymentState };
    }, {});

/**
 * Get the deployment state for a certain locale and environment. If the deployment is not found
 * return draft state and none failureReason.
 *
 * @param env - the environment.
 * @param locale - the locale to check.
 * @param deployment - the deployment.
 *
 * @returns the deployment state.
 */
export const getDeploymentState = (env: Environment, locale: string, deployment: Deployment) => {
  const { state = 'draft', failureReason = 'none' } = deployment[env][locale] || {};

  return {
    state,
    failureReason,
  };
};

/**
 * Check if a locale is published.
 *
 * @param env - the environment to check.
 * @param deployment - the deployment record.
 * @param locale - the locale to check.
 * @param includeAttempts - if true any attempt will count as published.
 *
 * @returns true when published (or attempted to publish), otherwise false.
 */
export const isPublished = (
  env: Environment,
  deployment: Deployment,
  locale: string,
  includeAttempts = false
): boolean => {
  const { state, failureReason } = getDeploymentState(env, locale, deployment);
  return (
    state === 'experiment_created' ||
    state === 'experimenting' ||
    state === 'published' ||
    (includeAttempts && failureReason !== 'none')
  );
};

/**
 * Check if the deployment is published in any locale for a certain environment.
 *
 * @param env - the environment to check.
 * @param deployment - the deployment record.
 *
 * @returns true when published in any locale, otherwise false.
 */
export const isPublishedAnywhere = (env: Environment, deployment: Deployment): boolean =>
  Object.keys(deployment[env]).some((locale) => isPublished(env, deployment, locale));

/**
 * Get the published state for a certain locale and environment.
 *
 * @param env - the environment.
 * @param locale - the locale to check.
 * @param deployment - the deployment.
 *
 * @returns a tuple of the published state and if an attempt of publish is made.
 */
export const getPublishedState = (
  env: Environment,
  locale: string,
  deployment: Deployment
): [PublishState, boolean] => {
  const deploymentState = getDeploymentState(env, locale, deployment);
  let isPublishAttemptMade = false;

  const { state, failureReason } = deploymentState;
  isPublishAttemptMade = state !== 'draft' || failureReason !== 'none';

  return [state, isPublishAttemptMade];
};
