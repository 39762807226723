import { useCallback } from 'react';
import Table, { TableBody, TableHeader } from '@ingka/table';
import Text from '@ingka/text';
import { NudgeOfFameMetrics } from '@nfw/nudge-evaluate';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useGetOrchestrations } from '../../features/orchestrations/hooks';
import './index.scss';
import PuffaHyperlink from '../PuffaHyperlink';

export type NudgeOfFameTableProps = {
  nudgeMetrics?: NudgeOfFameMetrics[];
};

const NudgeOfFameTable: React.FC<NudgeOfFameTableProps> = ({ nudgeMetrics }) => {
  const { t } = useTranslation();
  const { orchestrations } = useGetOrchestrations();

  const extractOrchestrationDetails = useCallback(
    (metricId: string) => {
      const orchestrationId = metricId.split('-')[0]; // Only want the feature id part.
      return orchestrations?.find((orchestration) => orchestration.id === orchestrationId);
    },
    [orchestrations]
  );

  return nudgeMetrics && nudgeMetrics.length > 0 ? (
    <Table fullWidth inset>
      <TableHeader>
        <tr key={'nudge-of-fame-header'}>
          <td>
            <Text headingSize="s">{t('form.widget.action.nudge.label')}</Text>
          </td>
          <td>
            <Text headingSize="s">{t('global.name')}</Text>
          </td>
          <td>
            <Text headingSize="s">{t('global.team')}</Text>
          </td>
          <td>
            <Text headingSize="s">{t('component.nudge_of_fame_table.ctr')}</Text>
          </td>
          <td>
            <Text headingSize="s">{t('component.metrics_modal.shows')}</Text>
          </td>
          <td>
            <Text headingSize="s">{t('component.metrics_modal.clicks')}</Text>
          </td>
          <td>
            <Text headingSize="s">{t('component.metrics_modal.dismisses')}</Text>
          </td>
        </tr>
      </TableHeader>
      <TableBody striped>
        {nudgeMetrics.map(({ shows, clicks, dismisses, id }, index) => {
          const orchestration = extractOrchestrationDetails(id);

          return (
            <tr key={`nudge-of-fame-${index}`}>
              {orchestration?.id && orchestration?.team ? (
                <td className={classNames({ clickable: orchestration?.id && orchestration?.team })}>
                  <PuffaHyperlink
                    route="/nudge/:team/:id"
                    params={{ team: orchestration.team, id: orchestration.id }}
                  >
                    {`#${id}`}
                  </PuffaHyperlink>
                </td>
              ) : (
                <td className={classNames({ clickable: false })}>{`#${id}`}</td>
              )}
              <td>{orchestration?.name || 'N/A'}</td>
              <td>{orchestration?.team || 'N/A'}</td>
              <td>{(clicks / shows).toFixed(3)}</td>
              <td>{shows}</td>
              <td>{clicks}</td>
              <td>{dismisses}</td>
            </tr>
          );
        })}
      </TableBody>
    </Table>
  ) : (
    <Text headingSize="m">{t('component.nudge_of_fame_table.no_data')}</Text>
  );
};

export default NudgeOfFameTable;
