import { isObject, isString, unique } from '@nfw/utils';

/**
 * The supported languages for each retail unit,
 */
const SupportedLanguages = {
  at: ['de', 'en'],
  au: ['en'],
  be: ['en', 'fr', 'nl'],
  ca: ['en', 'fr'],
  ch: ['de', 'fr', 'it', 'en'],
  cz: ['en', 'cs'],
  de: ['de', 'en'],
  dk: ['da'],
  es: ['es', 'en', 'ca', 'eu', 'gl'],
  fi: ['fi', 'en'],
  fr: ['fr'],
  gb: ['en'],
  hr: ['hr'],
  hu: ['hu'],
  ie: ['en'],
  in: ['en'],
  it: ['it'],
  jp: ['ja', 'en'],
  kr: ['ko', 'en'],
  nl: ['nl', 'en'],
  no: ['no'],
  pl: ['pl'],
  pt: ['pt', 'en'],
  ro: ['ro'],
  rs: ['sr'],
  se: ['sv', 'en'],
  si: ['sl'],
  sk: ['sk'],
  us: ['en', 'es'],
} as const;

export type RetailUnit = keyof typeof SupportedLanguages;
export type Language = (typeof SupportedLanguages)[RetailUnit][number];
export type Locale = {
  retailUnit: RetailUnit;
  language: Language;
};

/**
 * Get all retail units.
 *
 * @returns the retail units.
 */
export const getRetailUnits = () => Object.keys(SupportedLanguages) as RetailUnit[];

/**
 * Get all available languages.
 *
 * @returns the languages.
 */
export const getLanguages = () => unique(Object.values(SupportedLanguages).flat()) as Language[];

/**
 * Get the supported languages for a certain retail unit.
 *
 * @param retailUnit - the retail unit.
 *
 * @returns the list of languages supported.
 */
export const getSupportedLanguages = (retailUnit: RetailUnit) =>
  SupportedLanguages[retailUnit] as unknown as Language[];

/**
 * Get locales for retail units.
 *
 * @param retailUnits - the retail units to get locales from.
 *
 * @returns the locales for the retail unit.
 */
export const getLocales = (retailUnits: RetailUnit[]) =>
  retailUnits.reduce<Locale[]>((locales, retailUnit) => {
    getSupportedLanguages(retailUnit).forEach((language) => locales.push({ language, retailUnit }));
    return locales;
  }, []);

/**
 * Get locale strings for retail units.
 *
 * @param retailUnits - the retail units to get locales from.
 *
 * @returns the locale strings.
 */
export const getLocaleStrings = (retailUnits: RetailUnit[]) =>
  getLocales(retailUnits).map(({ language, retailUnit }) => toLocaleString(retailUnit, language));

/**
 * Check if the retail unit is supported.
 *
 * @param unit - the retail unit to check.
 *
 * @returns true if the retail unit is a supported retail unit.
 */
export const isRetailUnit = (unit: unknown): unit is RetailUnit =>
  getRetailUnits().includes(unit as RetailUnit);

/**
 * Check if language is a supported language.
 *
 * @param language - the language to check.
 *
 * @returns true if language is a supported language.
 */
export const isLanguage = (language: unknown): language is Language =>
  getLanguages().includes(language as Language);

/**
 * Check if the locale is supported.
 *
 * @param locale - the locale to test.
 *
 * @returns true if the combination unit and language is valid, otherwise false.
 */
export const isSupportedLocale = (locale: unknown): locale is Locale =>
  isObject(locale) &&
  isString(locale.language) &&
  isString(locale.retailUnit) &&
  SupportedLanguages[locale.retailUnit]?.includes(locale.language);

/**
 * Create a locale string of format xx-YY.
 *
 * @param retailUnit - the retail unit.
 * @param language - the language.
 *
 * @returns the locale string.
 */
export const toLocaleString = (retailUnit: RetailUnit, language: Language) =>
  `${language}-${retailUnit.toUpperCase()}`;

/**
 * The locale callback.
 */
export type LocaleCallback<T> = (data: {
  language: Language;
  retailUnit: RetailUnit;
  locale: string;
}) => T;

/**
 * Iterate all locales for retail units.
 *
 * @param cb - the callback function.
 * @param retailUnits - the optional retail units to iterate,
 *                    if omitted then all retail units are used.
 *
 * @returns the results of the callback.
 */
export const forEachLocale = <T = void>(cb: LocaleCallback<T>, retailUnits = getRetailUnits()) => {
  const results: T[] = [];

  for (const retailUnit of retailUnits) {
    for (const language of SupportedLanguages[retailUnit]) {
      const locale = toLocaleString(retailUnit, language);
      results.push(cb({ retailUnit, language, locale }));
    }
  }
  return results;
};

/**
 * Ikea different article types.
 */
export const ItemTypes = {
  Art: 'ART',
  Spr: 'SPR',
  Vtp: 'VTP',
} as const;
export type ItemType = (typeof ItemTypes)[keyof typeof ItemTypes];

/**
 * Check if an item type is valid.
 *
 * @param t - the type to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isItemType = (t: unknown): t is ItemType =>
  Object.values(ItemTypes).includes(t as ItemType);
