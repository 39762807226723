export const Routes = {
  Collision: '/nudge-collisions',

  CreateWidget: '/create/design/:team',
  CreateExperiment: '/create/experiment/:team/:id',
  CreateNudgeGroup: '/create/nudge-group/:team',
  CreateOrchestration: '/create/nudge/:team',
  CreateOrchestrationWithWidget: '/create/nudge/:team/:widgetId',

  EditWidget: '/edit/design/:team/:id',
  EditOrchestration: '/edit/nudge/:team/:id',
  CopyOrchestration: '/copy/nudge/:team/:id',
  EditNudgeGroupPage: '/edit/nudge-group/:team/:id',

  HandleEvents: '/handle-events',

  NudgeOfFame: '/nudge-of-fame',

  ViewWidget: '/design/:team/:id',
  ViewOrchestration: '/nudge/:team/:id',
  ViewNudgeGroup: '/nudge-group/:team/:id',

  Home: '/home',
  ViewTeams: '/team',
  ViewTeam: '/team/:team',
  ProductTeamTranslations: '/team/:team/product-translations',
  MarketTeamTranslations: '/team/:team/market-translations',

  ViewRequests: '/requests',
} as const;
export type Route = (typeof Routes)[keyof typeof Routes];
