import React from 'react';
import Button, { ButtonType } from '@ingka/button';
import Modal, { ModalFooter, Prompt, ModalHeader } from '@ingka/modal';
import classNames from 'classnames';
import PopupBody from './PopupBody';
import './index.scss';

export type ActionProps = {
  type: ButtonType;
  text: string;
  action?: () => void;
  href?: string;
  disabled?: boolean;
  isLoading?: boolean;
};

export type PopUpProps = {
  title?: string;
  visible: boolean;
  close: () => void;
  actions: ActionProps[];
  body: string | React.ReactElement;
};

const PopUp: React.FC<PopUpProps> = ({ title, visible, body, actions, close }) => (
  <Modal visible={visible} handleCloseBtn={close}>
    <Prompt
      footer={
        <ModalFooter
          renderBorder
          className={classNames('modal-footer', { stack: actions.length > 2 })}
        >
          {actions.map(({ type, text, isLoading, disabled, action, href }, index) =>
            href ? (
              <Button
                text={text}
                type={type}
                loading={isLoading}
                disabled={disabled}
                href={href}
                key={`action-${index}`}
                newWindow
              />
            ) : (
              <Button
                key={`action-${index}`}
                text={text}
                type={type}
                loading={isLoading}
                disabled={disabled}
                onClick={(event) => {
                  event.preventDefault();
                  action?.();
                  close();
                }}
              />
            )
          )}
        </ModalFooter>
      }
      header={<ModalHeader />}
      title={title}
      titleId="id"
    >
      <PopupBody body={body} />
    </Prompt>
  </Modal>
);

export default PopUp;
