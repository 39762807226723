import React, { useEffect, useState } from 'react';
import Text from '@ingka/text';
import { Widget } from '@nfw/nudge/types';
import { toPath } from '@nfw/utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Breadcrumb from '../../components/Breadcrumb';
import DesignPage from '../../components/DesignPage';
import LinearLayout from '../../components/LinearLayout';
import PopUp from '../../components/PopUp';
import ProgressIndicator from '../../components/ProgressIndicator';
import WidgetForm from '../../components/WidgetForm';
import WidgetPreview from '../../components/WidgetPreview';
import { selectWidgetPublished } from '../../features/app/selectors';
import { useGetTeamTranslations } from '../../features/i18n/hooks';
import { useGetWidget, useUpdateWidget } from '../../features/widgets/hooks';
import { InjectedViewPermissionsProps, withViewPermissions } from '../../hocs';
import { useNavigate } from '../../routes';
import { Routes } from '../../routes/types';

const EditWidgetPage: React.FC<InjectedViewPermissionsProps> = ({ id, team }) => {
  const isPublished = useSelector((state) => selectWidgetPublished(state, id));

  const [input, setInput] = useState({});
  const [widget, setWidget] = useState<Widget>();
  const [progress, setProgress] = useState(0);
  const [showWarning, setShowWarning] = useState(isPublished);
  const [errorPopUpVisible, setErrorPopUpVisible] = useState(false);

  const { data: defaultWidget } = useGetWidget(id);
  const { translations, isLoading: isTranslationsLoading } = useGetTeamTranslations(team);
  const { update, isLoading, isError } = useUpdateWidget(team, id);

  const { back } = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (isError) {
      setErrorPopUpVisible(true);
    }
  }, [isError]);

  return (
    <DesignPage
      loading={!defaultWidget || isTranslationsLoading}
      designComponent={<WidgetPreview widget={widget} translations={translations} />}
      designOnly
    >
      <>
        <PopUp
          visible={showWarning}
          close={() => setShowWarning(false)}
          title={t('widget.edit.popup.inuse.title')}
          body={t('widget.edit.popup.inuse.text')}
          actions={[
            { type: 'secondary', text: t('global.action.back'), action: back },
            { type: 'primary', text: t('global.action.continue') },
          ]}
        />
        <PopUp
          visible={errorPopUpVisible}
          title={t('page.error.title')}
          body={t('form.widget.error')}
          close={() => setErrorPopUpVisible(false)}
          actions={[
            { type: 'primary', text: t('global.action.retry'), action: () => update(input) },
          ]}
        />
        {defaultWidget && translations && (
          <LinearLayout gap="l">
            <Breadcrumb
              crumbs={[
                { name: t('global.overview'), to: '/home' },
                { name: `#${id}`, to: toPath(Routes.ViewWidget, { id, team }) },
              ]}
            />
            <Text headingSize="m">{t('global.design.edit')}</Text>
            <ProgressIndicator
              currentPage={progress + 1}
              steps={[
                t('component.progress_indicator.design_first'),
                t('component.progress_indicator.design_second'),
                t('component.progress_indicator.design_third'),
              ]}
            />
            <WidgetForm
              loading={isLoading}
              team={team}
              submit={update}
              onInput={setInput}
              onWidget={setWidget}
              onProgress={setProgress}
              translations={translations}
              defaultWidget={defaultWidget}
            />
          </LinearLayout>
        )}
      </>
    </DesignPage>
  );
};

export default withViewPermissions(EditWidgetPage, true, 'EDIT');
