import { Environment } from './types';

/**
 * Our default environment used locally.
 */
export const environment: Environment = {
  auth0Domain: 'https://ingka-icow-stag.eu.auth0.com',
  auth0ClientId: '474W6XHQD2FUKMOxecrihETBRynU2oWX',
  auth0Audience: 'https://ingka-icow-stag.eu.auth0.com/api/v2/',
  auth0Redirect: 'http://localhost:3000/',
  supportEmail: 'ce.puffa.se@ingka.ikea.com',
  inDev: true,
};
