// ///////////////////////////////////////////
// Nudge Actions that can be taken
// ///////////////////////////////////////////

import { IconType } from './icon';

/**
 * The supported action types.
 */
export const ActionTypes = {
  Call: 'call',
  Dismiss: 'dismiss',
  GoTo: 'goto',
  GoToExternal: 'goto_external',
  Login: 'login',
  Negative: 'negative',
  Nudge: 'nudge',
  Positive: 'positive',
} as const;
export type ActionType = (typeof ActionTypes)[keyof typeof ActionTypes];

export const ButtonTypes = {
  Primary: 'primary',
  Emphasised: 'emphasised',
  Secondary: 'secondary',
  Tertiary: 'tertiary',
  Plain: 'plain',
  Danger: 'danger',
} as const;
export type ButtonType = (typeof ButtonTypes)[keyof typeof ButtonTypes];

export const IconPositions = {
  Leading: 'leading',
  Trailing: 'trailing',
} as const;
export type IconPosition = (typeof IconPositions)[keyof typeof IconPositions];

export type WithIcon = {
  iconType: IconType;
  iconPosition: IconPosition;
};

type WithPath = {
  path: string;
};

type WithOptionalPath = Partial<WithPath>;

type ActionBase<T extends ActionType> = {
  type: T;
  text?: string;
};

export type LoginAction = ActionBase<'login'> & WithOptionalPath;

export type DismissAction = ActionBase<'dismiss'>;

export type PositiveAction = ActionBase<'positive'>;

export type NegativeAction = ActionBase<'negative'>;

export type CallAction = ActionBase<'call'> & {
  number: string;
};

export type GotoAction = ActionBase<'goto' | 'goto_external'> & WithPath;

export type NudgeAction = ActionBase<'nudge'> & {
  nudgeId: string;
};

export type Action =
  | CallAction
  | DismissAction
  | LoginAction
  | GotoAction
  | NegativeAction
  | NudgeAction
  | PositiveAction;

export type ButtonAction = Action & {
  buttonType: ButtonType;
  icon?: WithIcon;
};
