import { Suspense, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { RouterProvider } from 'react-router';
import Toast from './components/Toast';
import { useOverviewEntities } from './features/app/hooks';
import { setCredentials } from './features/auth/actions';
import { selectCredentials } from './features/auth/selectors';
import { router } from './routes';
import { useAppDispatch } from './store';

const AuthenticatedApp: React.FC = () => {
  const credentials = useSelector(selectCredentials);
  const { isInitialised } = useOverviewEntities(credentials === undefined);

  const { loginWithRedirect, isLoading, isAuthenticated, user, getAccessTokenSilently } =
    useAuth0();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user) {
      const setToken = async () => {
        const token = await getAccessTokenSilently();

        if (token) {
          const { email, name, given_name, picture } = user;

          dispatch(setCredentials({ token, email, name, given_name, picture }));
        }
      };
      setToken();
    }
  }, [getAccessTokenSilently, user, dispatch]);

  useEffect(() => {
    if (isAuthenticated || isLoading) {
      return;
    }

    loginWithRedirect({
      appState: { targetUrl: window.location.pathname },
    });
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return (
    <Suspense>
      <Toast />
      {credentials && isInitialised && <RouterProvider router={router} />}
    </Suspense>
  );
};

export default AuthenticatedApp;
