import {
  BreadcrumbMatcherException,
  ExceptionType,
  ExceptionTypes,
  PageMatcher,
  PageMatcherException,
  SearchParam,
  StringMatcherException,
} from '@nfw/nudge/types';
import {
  isArrayOfType,
  isBoolean,
  isObject,
  isOptionallyDefined,
  isString,
  isStringArray,
} from '@nfw/utils';

const isExceptionOfType = (e: unknown, type: ExceptionType): e is Record<string, unknown> =>
  isObject(e) && e.type === type;

/**
 * Check if the exception is valid.
 *
 * @param e - the exception to test.
 *
 * @returns true when exception is valid, otherwise false.
 */
export const isStringMatcherException = (e: unknown): e is StringMatcherException =>
  isExceptionOfType(e, ExceptionTypes.StringMatch) &&
  isArrayOfType(e.endsWith, isString) &&
  isArrayOfType(e.startsWith, isString) &&
  isArrayOfType(e.includes, isString);

/**
 * Check if the exception is a valid breadcrumb matcher exception.
 *
 * @param e - the exception to test.
 *
 * @returns true when exception is valid, otherwise false.
 */
export const isBreadcrumbMatcherException = (e: unknown): e is BreadcrumbMatcherException =>
  isExceptionOfType(e, ExceptionTypes.Breadcrumb) && isStringArray(e.exceptions);

/**
 * Check if the exception is a valid page matcher exception.
 *
 * @param e - the exception to test.
 *
 * @returns true when exception is valid, otherwise false.
 */
export const isPageMatcherException = (e: unknown): e is PageMatcherException =>
  isStringMatcherException(e) || isBreadcrumbMatcherException(e);

/**
 * Check if a search param is valid.
 *
 * @param s - the search param to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isSearchParam = (s: unknown): s is SearchParam =>
  isObject(s) && isString(s.key) && isString(s.value);

/**
 * Check if a matcher is valid.
 *
 * @param m - the matcher to test.
 *
 * @returns true when matcher is valid, otherwise false.
 */
export const isPageMatcher = (m: unknown): m is PageMatcher =>
  isObject(m) &&
  isBoolean(m.includeChildPages) &&
  isBoolean(m.includeNamespacePage) &&
  isOptionallyDefined(m.searchparams, (value) => isArrayOfType(value, isSearchParam)) &&
  isOptionallyDefined(m.exception, isPageMatcherException);
