import { LayoutConfiguration, InputTypes, ValidationTypes } from '@nfw/form-types';
import { TFunction } from 'i18next';

export const getGroupNudgeLayout = (t: TFunction): LayoutConfiguration => [
  {
    inputType: InputTypes.TextInput,
    name: 'name',
    label: t('form.nudge_group.name'),
    limit: 50,
    validations: [
      {
        type: ValidationTypes.Required,
        errorMessage: t('form.general.name.error'),
      },
    ],
  },
  {
    inputType: InputTypes.TextArea,
    name: 'description',
    limit: 300,
    label: t('global.description'),
    helpMessage: t('form.nudge_group.description_help'),
    validations: [
      {
        type: ValidationTypes.Required,
        errorMessage: t('form.widget.description.error'),
      },
    ],
  },
];
