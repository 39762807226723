import { InputTypes, LayoutConfiguration } from '@nfw/form-types';
import { RepeatTypes } from '@nfw/nudge/types';
import { TFunction } from 'i18next';

export const getRepeatLayout = (repeat: string[], t: TFunction): LayoutConfiguration => {
  const repeatExtras = {
    always: t('form.feature.repeat.always'),
    page: t('form.feature.repeat.page'),
    session: t('form.feature.repeat.session'),
    lifetime: t('form.feature.repeat.lifetime'),
  };

  const options = repeat.map((value) => ({
    name: value === 'session' ? `${value} (${t('form.feature.repeat.recommended')})` : value,
    value,
    description: repeatExtras[value],
  }));

  return [
    {
      inputType: InputTypes.PillListbox,
      btnLabel: t('form.general.select_type'),
      name: 'type',
      label: t('form.feature.repeat.select'),
      includeSearch: false,
      options,
    },
    {
      inputType: InputTypes.Quantity,
      name: 'count',
      label: t('form.feature.repeat.quantity'),
      dependsOn: 'type',
      requiresValueOf: [RepeatTypes.Lifetime, RepeatTypes.Page, RepeatTypes.Session],
      min: 1,
      max: 1000,
      defaultValue: 1,
    },
    {
      inputType: InputTypes.InlineFormControl,
      name: 'page',
      label: t('form.feature.repeat.page.label'),
      dependsOn: 'type',
      type: 'switch',
      requiresValueOf: [RepeatTypes.Lifetime, RepeatTypes.Session],
      configuration: [
        {
          inputType: InputTypes.HiddenInput,
          name: 'max',
          value: 1,
        },
        {
          inputType: InputTypes.Slider,
          name: 'interval',
          label: t('form.feature.repeat.page.interval'),
          defaultValue: 1,
          min: 1,
          max: 10,
          step: 1,
          unitPrefix: t('form.feature.repeat.page.intervalunit.prefix'),
          unit: t('form.feature.repeat.page.intervalunit.suffix'),
        },
        {
          inputType: InputTypes.Checkbox,
          name: 'initShow',
          label: t('form.feature.repeat.page.initshow'),
          defaultValue: false,
        },
      ],
    },
  ];
};
