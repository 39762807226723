import { useCallback, useEffect, useMemo } from 'react';
import { getTeamFeatures, Translations } from '@nfw/contracts/self-service';
import { StoredWidget, WithWidgetType } from '@nfw/orchestration-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '../../routes';
import { useAppDispatch, useMemoizedSelector } from '../../store';
import { useErrorLimitGuard } from '../api/hooks';
import { PuffaError } from '../api/puffa-error';
import { queueToast } from '../app/actions';
import { selectTeam } from '../teams/selectors';
import {
  useArchiveWidgetMutation,
  useCreateWidgetMutation,
  useGetWidgetQuery,
  useUpdateWidgetMutation,
} from './api';
import {
  getWidgetLayout,
  getWidgetDescriptionLayout,
  getWidgetRequestLayout,
  getWidgetTypeLayout,
} from './forms';

/**
 * Hook for creating a widget.
 * Will redirect to the widget detail page on success and
 * redirect to error page after 3 retries.
 *
 * @param team - that will own the widget.
 *
 * @returns create widget function, loading state and error state
 */
export const useCreateWidget = (team: string) => {
  const [save, result] = useCreateWidgetMutation();
  const { t } = useTranslation();
  useErrorLimitGuard(result.isError);
  const { navigate } = useNavigate();

  const updateInternal = useCallback(
    (widget) => {
      save({ widget, team });
    },
    [save, team]
  );

  useEffect(() => {
    const { isSuccess, isError, data } = result;

    if (isSuccess || isError) {
      if (isSuccess) {
        navigate('/design/:team/:id', { id: data, team }, { state: 'created' });
      }
    }
  }, [result, team, navigate, t]);

  return {
    create: updateInternal,
    isLoading: result.isLoading,
    isError: result.isError,
  };
};

/**
 * Hook for updating a widget.
 * Will redirect to the widget detail page on success and
 * redirect to error page after 3 retries.
 *
 * @param id - the id of the widget.
 * @param team - the team that owns the widget.
 *
 * @returns update widget function, loading state and error state
 */
export const useUpdateWidget = (team: string, id: string) => {
  const [update, result] = useUpdateWidgetMutation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useErrorLimitGuard(result.isError);
  const { navigate } = useNavigate();

  const updateInternal = useCallback(
    (widget) => {
      update({ id, team, widget });
    },
    [id, team, update]
  );

  useEffect(() => {
    const { isSuccess, isError } = result;
    if (isSuccess || isError) {
      if (isSuccess) {
        navigate('/design/:team/:id', { id, team }, { state: 'updated' });
      }
    }
  }, [dispatch, id, navigate, result, t, team]);

  return {
    update: updateInternal,
    isLoading: result.isLoading,
    isError: result.isError,
  };
};

/**
 * Hook for getting a widget.
 *
 * @param id - the id of the widget.
 *
 * @returns the widget data.
 */
export const useGetWidget = (id: string) => {
  const { data, isError, isLoading } = useGetWidgetQuery({ id });

  useEffect(() => {
    if (isError) {
      throw new PuffaError();
    }
  }, [isError]);

  return { data, isLoading };
};

/**
 * Hook for archiving a widget.
 *
 * @returns the archive function, error state and the reset function.
 */
export const useArchiveWidget = (id: string) => {
  const [archive, result] = useArchiveWidgetMutation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { navigate } = useNavigate();
  const { isSuccess, isError, reset } = result;

  useEffect(() => {
    if (isSuccess) {
      dispatch(queueToast({ msg: t('global.widget.archived', { id }) }));
      navigate('/home');
      reset();
    }
  }, [id, isSuccess, reset, navigate, dispatch, t]);

  const archiveInternal = useCallback(
    (widget: StoredWidget) => {
      archive(widget);
    },
    [archive]
  );

  return { archive: archiveInternal, isError: isError, reset: result.reset };
};

/**
 * Hook for using the widget description.
 *
 * @returns the widget description layout.
 */
export const useWidgetDescriptionLayout = () => {
  const { t } = useTranslation();

  return useMemo(() => getWidgetDescriptionLayout(t), [t]);
};

/**
 * Get the layout configuration for creating a widget.
 *
 * @param translation - the team translations.
 * @param widgetType - the type of widget to fetch configurations of.
 * @param team - the team of the widget. TEMPORARY solution for offering HRI team pos/neg actions.
 *
 * @returns the layout or undefined.
 */
export const useWidgetLayout = (
  translations: Translations,
  widgetType: WithWidgetType | undefined,
  team: string
) => {
  const { t } = useTranslation();
  const teamConfig = useMemoizedSelector(selectTeam, team);

  const { actions } = getTeamFeatures(teamConfig);

  return useMemo(
    () => getWidgetLayout(translations, t, widgetType, actions),
    [translations, t, widgetType, actions]
  );
};

/**
 * Get the type layout for creating a widget.
 *
 * @returns the type layout configuration for a widget.
 */
export const useWidgetTypeLayout = (team: string) => {
  const { t } = useTranslation();
  const teamConfig = useMemoizedSelector(selectTeam, team);
  const { widgets } = getTeamFeatures(teamConfig);

  return useMemo(() => getWidgetTypeLayout(widgets, t), [t, widgets]);
};

/**
 * The layout configuration for requesting a new widget.
 *
 * @param team - the team of the request.
 * @param name - the name of the person requesting a new widget.
 *
 * @returns the layout configuration for requesting a widget.
 */
export const useWidgetRequestLayout = (team: string, name = '') => {
  const { t } = useTranslation();

  return useMemo(() => getWidgetRequestLayout(name, team, t), [team, name, t]);
};
