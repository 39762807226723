import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import LinearLayout from '../../components/LinearLayout';
import NudgeGroupForm from '../../components/NudgeGroupForm';
import Page from '../../components/Page';
import ProgressIndicator from '../../components/ProgressIndicator';
import { useCreateNudgeGroup } from '../../features/nudgeGroups/hooks';
import { InjectedTeamProps, withRoutedTeamPermissions } from '../../hocs';

const CreateNudgeGroupPage: React.FC<InjectedTeamProps> = ({ team }) => {
  const { create, isLoading } = useCreateNudgeGroup(team);
  const { t } = useTranslation();

  return (
    <Page crumbs={[{ name: t('global.overview'), to: '/?tab=features' }]} singleColumn>
      <LinearLayout gap="l">
        <Text headingSize="m">{t('page.nudge_group.title')}</Text>
        <ProgressIndicator currentPage={1} steps={[t('page.nudge_group.breadcrumb')]} />
        <NudgeGroupForm onSave={create} saving={isLoading} />
      </LinearLayout>
    </Page>
  );
};

export default withRoutedTeamPermissions(CreateNudgeGroupPage, 'EDIT');
