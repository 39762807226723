import { useEffect, useState } from 'react';
import Button from '@ingka/button';
import Combobox from '@ingka/combobox';
import { MenuItem } from '@ingka/list-box';
import { getLocaleStrings } from '@nfw/ikea/retail';
import { toPath } from '@nfw/utils';
import { useTranslation } from 'react-i18next';
import JSONPretty from 'react-json-pretty';
import Page, { PageHeader } from '../../../components/Page';
import { useTeamPermission } from '../../../features/auth/hooks';
import { useGetTeamTranslationsForLocale } from '../../../features/i18n/hooks';
import { useGetTeam } from '../../../features/teams/hooks';
import { useRequestLatestTranslations } from '../../../features/translationManager/hooks';
import { useNavigate } from '../../../routes';
import { Routes } from '../../../routes/types';
import './index.scss';

const ProductTeamTranslationPage: React.FC = () => {
  const [selectedLocale, setSelectedLocale] = useState('');
  const { permissions, isInitialised, setTeam } = useTeamPermission();
  const { requestLatestTranslations } = useRequestLatestTranslations();
  const { params } = useNavigate();
  const { data, isLoading: isTeamLoading } = useGetTeam(params.team!);
  const { translations, isLoading: isTranslationsLoading } = useGetTeamTranslationsForLocale(
    params.team ?? '',
    selectedLocale,
    !params.team
  );

  const [loadingTranslations, setLoadingTranslations] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      setTeam(data.id);
    }
  }, [data, setTeam]);

  const handleGetTranslations = () => {
    setLoadingTranslations(true);
    requestLatestTranslations();
    // Simulate loading
    setTimeout(() => {
      setLoadingTranslations(false);
    }, 15000);
  };

  return (
    data &&
    translations && (
      <Page
        padded
        loading={isTeamLoading || !isInitialised || isTranslationsLoading}
        crumbs={[
          { name: t('global.overview'), to: '/?tab=features' },
          { name: t('global.teams.all'), to: Routes.ViewTeams },
          { name: data.name, to: toPath(Routes.ViewTeam, { team: params.team! }) },
          { name: t('page.translations.translations') },
        ]}
        header={
          <PageHeader
            title={t('page.translations.title', { team: data.name })}
            caption={
              data.teamType === 'product'
                ? t('page.translations.caption', {
                    id: data?.phraseProjectId,
                  })
                : undefined
            }
            actions={[
              <Button
                text={t('page.team_details.get_translations')}
                key="fetch-translations"
                type="primary"
                size="small"
                loading={loadingTranslations}
                className="translations"
                disabled={!permissions?.includes('PUBLISH')}
                onClick={handleGetTranslations}
              />,
            ]}
          />
        }
      >
        <Combobox
          labelId="select-locale"
          listboxId="select-locale-listbox"
          placeholder={t('page.team_details.select_locale')}
          label={t('page.team_details.combobox_labels')}
        >
          {[data.defaultLocale].concat(getLocaleStrings(data.retailUnits)).map((locale) => (
            <MenuItem
              key={locale}
              id={locale}
              title={locale}
              onClick={() => setSelectedLocale(locale)}
            />
          ))}
        </Combobox>
        <div className="json-translations">
          <JSONPretty silent className="json-pretty" data={translations} />
        </div>
      </Page>
    )
  );
};

export default ProductTeamTranslationPage;
