import React from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import AuthenticatedApp from './AuthenticatedApp';
import { environment } from './environment';
import { store, persistor } from './store';
import './index.scss';

const onRedirectCallback = (appState: AppState | undefined) => {
  window.location.replace(appState?.targetUrl || window.location.pathname);
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={environment.auth0Domain}
      clientId={environment.auth0ClientId}
      authorizationParams={{
        redirect_uri: environment.auth0Redirect,
        audience: environment.auth0Audience,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AuthenticatedApp />
        </PersistGate>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);
