import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { orchestrationApi } from './api';

const selectLocalState = (state: RootState) => state.orchestration;

export const selectPublishing = createSelector(
  [selectLocalState, (_, id: string) => id],
  (state, id) => state.isPublishing.some((value) => value === id)
);

export const selectDeployments = createSelector(
  orchestrationApi.endpoints.getDeployments.select(),
  ({ data }) => data ?? []
);

export const selectOrchestrations = createSelector(
  orchestrationApi.endpoints.getOrchestrations.select(),
  ({ data }) => data ?? []
);

export const selectTeamOrchestrations = createSelector(
  [selectOrchestrations, (_: RootState, team?: string) => team],
  (orchestrations, team) => orchestrations.filter((orchestration) => orchestration.team === team)
);
