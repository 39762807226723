import { isErrorBody } from '@nfw/contracts/base';
import { BasePath, ErrorTypes } from '@nfw/contracts/self-service';
import { MiddlewareAPI, isRejectedWithValue } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store';
import { selectSessionId } from '../app/selectors';
import { selectToken } from '../auth/selectors';

/**
 * Middleware for restarting the app and making the client login whenever an auth error
 * is received.
 */
export const authErrorHandler = (_: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action) && isErrorBody(action.payload.data)) {
    const { type } = action.payload.data;

    if (type === ErrorTypes.Auth) {
      window.location.reload();
    }
  }

  return next(action);
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: BasePath,
    prepareHeaders(headers, { getState }) {
      const state = getState() as RootState;
      const token = selectToken(state);
      const sessionId = selectSessionId(state);

      headers.set('Cache-Control', 'no-cache');
      headers.set('X-Session-Id', sessionId);
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    'audits',
    'collisions',
    'deployment',
    'experiment',
    'events',
    'experiment-metrics',
    'experiment-results',
    'feature',
    'framework_configuration',
    'market_configuration',
    'metrics',
    'nudge-group',
    'nudge-metric',
    'nudge-of-fame',
    'permission',
    'translation',
    'market-translation',
    'team',
    'widget',
  ],
  endpoints: () => ({}),
});
