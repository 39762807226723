import { useCallback, useEffect, useState } from 'react';
import Button from '@ingka/button';
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal';
import Text from '@ingka/text';
import {
  DefaultEvent,
  DefaultMetric,
  MetricConfiguration,
  isMetricConfiguration,
} from '@nfw/contracts/experiment';
import { Form, FormCallback } from '@nfw/ui/form';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useMetricConfigurationLayout } from '../../features/experiments/hooks';
import LinearLayout from '../LinearLayout';
import './index.scss';

export type MetricModalProps = {
  event?: DefaultEvent;
  onMetric: (metric: DefaultMetric) => void;
};

const MetricModal: React.FC<MetricModalProps> = ({ event, onMetric }) => {
  const [currentEvent, setCurrentEvent] = useState(event);
  const [metric, setMetric] = useState<MetricConfiguration>();

  const { t } = useTranslation();
  const layout = useMetricConfigurationLayout();

  const onChange = useCallback<FormCallback<MetricConfiguration>>(({ value }) => {
    setMetric(value);
  }, []);

  const onSubmit = useCallback(() => {
    if (event && metric) {
      onMetric({ id: event.id, ...metric });
      setCurrentEvent(undefined);
    }
  }, [event, metric, onMetric]);

  useEffect(() => {
    if (event) {
      setCurrentEvent(event);
    }
  }, [event]);

  return createPortal(
    <Modal visible={!!currentEvent} handleCloseBtn={() => setCurrentEvent(undefined)}>
      <Sheets
        size="small"
        header={<ModalHeader />}
        fullSize
        footer={
          <ModalFooter>
            <Button type="secondary" text={t('global.action.cancel')} />
            <Button
              type="primary"
              text={t('global.action.save')}
              onClick={onSubmit}
              disabled={!metric}
            />
          </ModalFooter>
        }
      >
        <ModalBody>
          <LinearLayout padded gap="xl" className="metric-container">
            <Text tagName="h1" bodySize="xl">
              {event?.name}
            </Text>

            <Text tagName="p" bodySize="m">
              {`ID: ${event?.id} - ${event?.project_name}`}
            </Text>

            {event?.description && (
              <Text tagName="p" bodySize="m">
                {event.description}
              </Text>
            )}
            <Form config={layout} validationFn={isMetricConfiguration} onChange={onChange} />
          </LinearLayout>
        </ModalBody>
      </Sheets>
    </Modal>,
    document.body
  );
};

export default MetricModal;
