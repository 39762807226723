import { useEffect, useState } from 'react';
import Combobox from '@ingka/combobox';
import { MenuItem } from '@ingka/list-box';
import { useTranslation } from 'react-i18next';
import { SupportedLngs } from '../../features/i18n';
import { LanguageOption } from '../../features/i18n/types';

export type LanguagePickerProps = {
  defaultLanguage: string;
  onLanguageChanged: (locale: string) => void;
  setPlaceholder: (text: string) => void;
  placeholder?: string;
  transparent?: boolean;
  languages?: LanguageOption[];
};

const LanguagePicker: React.FC<LanguagePickerProps> = ({
  defaultLanguage,
  onLanguageChanged,
  setPlaceholder,
  placeholder,
  languages = SupportedLngs,
}) => {
  const [language, setLanguage] = useState(defaultLanguage);
  const { t } = useTranslation();

  useEffect(() => {
    if (language !== defaultLanguage) {
      onLanguageChanged(language);
    }
  }, [onLanguageChanged, language, defaultLanguage]);

  return (
    <Combobox
      label={t('component.language_picker.select')}
      labelId="language"
      listboxId="id"
      placeholder={placeholder}
    >
      {languages.map(({ locale, text }) => (
        <MenuItem
          title={text}
          id={locale}
          key={locale}
          onClick={() => {
            setPlaceholder(text);
            setLanguage(locale);
          }}
        />
      ))}
    </Combobox>
  );
};

export default LanguagePicker;
