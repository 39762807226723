import { isRetailUnit } from '@nfw/ikea/retail';
import { isNamespace } from '@nfw/ikea/utils';
import {
  isCondition,
  isOrchestration,
  isPageMatcher,
  isRepeatValue,
  isTrigger,
  isWidget,
  isWidgetType,
  isNudgeGroup,
} from '@nfw/nudge/typeguard';
import {
  isArrayOfType,
  isBoolean,
  isNaturalNumber,
  isObject,
  isOptionallyDefined,
  isString,
} from '@nfw/utils';
import {
  DescribedWidget,
  Feature,
  FeatureNudge,
  FeatureOrchestration,
  StoredFeatureOrchestration,
  StoredNudgeGroup,
  StoredWidget,
  TeamOrchestration,
  WithDescription,
  WithHypothesis,
  WithTeamStorage,
  WithWidgetType,
} from './types';

/**
 * Check if the team storage is valid.
 *
 * @param s - the team storage to test.
 *
 * @returns true if valid, otherwise false.
 */
const isWithTeamStorage = (s: unknown): s is WithTeamStorage =>
  isObject(s) &&
  isString(s.id) &&
  isString(s.team) &&
  isString(s.creator) &&
  isString(s.creatorName) &&
  isBoolean(s.isArchived) &&
  isNaturalNumber(s.created) &&
  isNaturalNumber(s.modified);

/**
 * Check if description params are valid.
 *
 * @param d - the description to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isWithDescription = (d: unknown): d is WithDescription =>
  isObject(d) && isString(d.name) && isString(d.description);

/**
 * Check if it is a valid widget type.
 *
 * @param t - the type to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isWithWidgetType = (t: unknown): t is WithWidgetType =>
  isObject(t) && isWidgetType(t.type);

/**
 * Check if hypothesis params are valid.
 *
 * @param h - the hypothesis to test.
 *
 * @returns true if valid, otherwise false
 */
export const isWithHypothesis = (h: unknown): h is WithHypothesis =>
  isObject(h) &&
  isString(h.name) &&
  isString(h.hypothesis) &&
  isOptionallyDefined(h.ticket, isString);

/**
 * Check if a nudge is a valid feature nudge.
 *
 * @param nudge - the nudge to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isFeatureNudge = (nudge: unknown): nudge is FeatureNudge =>
  isObject(nudge) &&
  isString(nudge.widgetRef) &&
  isOptionallyDefined(nudge.repeat, isRepeatValue) &&
  isOptionallyDefined(nudge.mandatory, isBoolean) &&
  isArrayOfType(nudge.triggers, isTrigger, 1);

/**
 * Check if a widget is a valid described widget.
 *
 * @param widget - the widget to add.
 *
 * @returns the true if valid, otherwise false.
 */
export const isDescribedWidget = (widget: unknown): widget is DescribedWidget =>
  isWithDescription(widget) && isWidget(widget);

/**
 * Check if a widget is a valid stored widget.
 *
 * @param widget - the stored widget to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isStoredWidget = (widget: unknown): widget is StoredWidget =>
  isWithTeamStorage(widget) && isDescribedWidget(widget);

/**
 * Check if a orchestration is a valid team nudge orchestration.
 *
 * @param o - the team orchestration to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isTeamOrchestration = (o: unknown): o is TeamOrchestration =>
  isObject(o) &&
  isArrayOfType(o.retailUnits, isRetailUnit) &&
  isString(o.team) &&
  isOrchestration(o);

/**
 * Check if a feature is valid.
 *
 * @param f - the feature to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isFeature = (f: unknown): f is Feature =>
  isObject(f) &&
  isNamespace(f.namespace) &&
  isArrayOfType(f.conditions, isCondition) &&
  isOptionallyDefined(f.matcher, isPageMatcher) &&
  isArrayOfType(f.retailUnits, isRetailUnit, 1) &&
  isOptionallyDefined(f.groupId, isString);

/**
 * Check if a feature orchestration is valid.
 *
 * @param f - the feature orchestration to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isFeatureOrchestration = (f: unknown): f is FeatureOrchestration =>
  isObject(f) && isFeatureNudge(f.featureNudge) && isFeature(f) && isWithHypothesis(f);

/**
 * Check if a stored feature orchestration is valid.
 *
 * @param s - the stored feature orchestration to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isStoredFeatureOrchestration = (s: unknown): s is StoredFeatureOrchestration =>
  isObject(s) && isWithTeamStorage(s) && isFeatureOrchestration(s);

/**
 * Check if an array is an array of valid feature orchestrations.
 *
 * @param s - the stored feature orchestrations to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isStoredFeatureOrchestrations = (s: unknown): s is StoredFeatureOrchestration[] =>
  isArrayOfType(s, isStoredFeatureOrchestration);

/**
 * Check if a stored nudge group is valid.
 *
 * @param n - the stored nudge group to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isStoredNudgeGroup = (n: unknown): n is StoredNudgeGroup =>
  isObject(n) && isWithTeamStorage(n) && isNudgeGroup(n);

/**
 * Check if an array is an array of valid nudge groups.
 *
 * @param n - the stored nudge groups to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isStoredNudgeGroups = (n: unknown): n is StoredNudgeGroup[] =>
  isArrayOfType(n, isStoredNudgeGroup);
