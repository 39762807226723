import { useState } from 'react';
import ListView, { ListViewItem } from '@ingka/list-view';
import archivebox from '@ingka/ssr-icon/paths/archive-box';
import clipboardCheckmark from '@ingka/ssr-icon/paths/clipboard-checkmark';
import pencil from '@ingka/ssr-icon/paths/pencil';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../components/Breadcrumb';
import DetailCard from '../../components/DetailCard';
import LinearLayout from '../../components/LinearLayout';
import NudgeGroupDescription from '../../components/NudgeGroupDescription';
import NudgeGroupStateIndicator from '../../components/NudgeGroupStateIndicator';
import NudgeMetricModal from '../../components/NudgeMetricModal';
import Page, { PageHeader } from '../../components/Page';
import PopUp from '../../components/PopUp';
import StateIndicator from '../../components/StateIndicator';
import { selectNudgeGroupOrchestrations } from '../../features/app/selectors';
import { useArchiveNudgeGroup, useGetNudgeGroup } from '../../features/nudgeGroups/hooks';
import { useGetTeam } from '../../features/teams/hooks';
import { InjectedViewPermissionsProps, withViewPermissions } from '../../hocs';
import { useListViewLinks, useNavigate } from '../../routes';
import { useMemoizedSelector } from '../../store';
import './index.scss';

const NudgeGroupDetailsPage: React.FC<InjectedViewPermissionsProps> = ({
  id,
  team,
  isEditAllowed,
}) => {
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [nudgeMetricVisible, setNudgeMetricVisible] = useState(false);
  const { nudgeGroup, isLoading: nudgeGroupLoading } = useGetNudgeGroup(id);
  const { data: teamConfig } = useGetTeam(team);
  const { archive } = useArchiveNudgeGroup();
  const { t } = useTranslation();
  const { toAppPath } = useNavigate();
  const { listViewOnClickHandler } = useListViewLinks();
  const orchestrations = useMemoizedSelector(selectNudgeGroupOrchestrations, id);

  return (
    nudgeGroup && (
      <Page padded loading={nudgeGroupLoading}>
        <PopUp
          visible={popUpVisible}
          close={() => setPopUpVisible(false)}
          title={t('page.nudge_group_details.archive_title')}
          body={t('page.nudge_group_details.archive_body')}
          actions={[
            {
              type: 'secondary',
              text: t('global.action.cancel'),
            },
            {
              type: 'primary',
              text: t('page.nudge_group_details.archive_action'),
              disabled: orchestrations.length > 0,
              action: () => archive(id, team),
            },
          ]}
        />
        <NudgeMetricModal
          close={() => setNudgeMetricVisible(false)}
          visible={nudgeMetricVisible}
          groupId={id}
        />
        <div className="splitview-content">
          <div className="content">
            <Breadcrumb crumbs={[{ name: t('global.overview'), to: '/?tab=features' }]} />
            <PageHeader
              title={nudgeGroup.name}
              titleSize="m"
              audit={{ id, modified: nudgeGroup.modified }}
            />
            <NudgeGroupStateIndicator
              isArchived={nudgeGroup.isArchived}
              nudgeGroupCount={orchestrations.length}
              detailed
            />
            <ListView id="actions" onClick={listViewOnClickHandler}>
              <ListViewItem
                id="edit"
                emphasised
                inset
                control="link"
                className="action"
                controlIcon="arrowRight"
                leadingIcon={pencil}
                title={t('global.edit')}
                href={toAppPath('/edit/nudge-group/:team/:id', { team, id })}
                disabled={!isEditAllowed || nudgeGroup.isArchived}
              />
            </ListView>
            <NudgeGroupDescription nudgeGroup={nudgeGroup} />
            <ListView id="secondary-actions">
              <ListViewItem
                id="metrics"
                emphasised
                showDivider
                control="navigational"
                controlIcon="arrowRight"
                leadingIcon={clipboardCheckmark}
                title={t('page.nudge_group_details.view_metrics')}
                onClick={() => setNudgeMetricVisible(true)}
                disabled // Remove when we have data for nudge groups in prod.
              />
              <ListViewItem
                id="archive"
                emphasised
                control="navigational"
                controlIcon="arrowRight"
                leadingIcon={archivebox}
                title={t('page.nudge_group_details.archive_action')}
                disabled={!isEditAllowed || nudgeGroup.isArchived}
                onClick={() => setPopUpVisible(true)}
              />
            </ListView>
          </div>
          <div className="side">
            {orchestrations.length > 0 ? (
              <>
                <Text headingSize="m" className="table-heading">
                  {t('component.nudge_group_table.title')}
                </Text>
                <LinearLayout horizontal gap="m" wrap>
                  {orchestrations.map(
                    ({
                      id,
                      isArchived,
                      name,
                      team,
                      created,
                      creatorName,
                      retailUnits,
                      consolidatedStatus,
                    }) => (
                      <DetailCard
                        key={id}
                        id={id}
                        status={
                          <StateIndicator
                            isArchived={isArchived}
                            consolidatedStatus={consolidatedStatus}
                          />
                        }
                        title={name}
                        team={teamConfig?.name ?? team}
                        creator={creatorName}
                        created={created}
                        info={retailUnits.map((ru) => t(`global.country.${ru}`)).join(', ')}
                        navigateTo="/nudge/:team/:id"
                        navigateToParams={{ team, id }}
                      />
                    )
                  )}
                </LinearLayout>
              </>
            ) : (
              <Text headingSize="m">{t('component.nudge_group_table.no_nudges')}</Text>
            )}
          </div>
        </div>
      </Page>
    )
  );
};

export default withViewPermissions(NudgeGroupDetailsPage, false);
