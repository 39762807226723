import { LocaleTranslations, Routes, Translations } from '@nfw/contracts/self-service';
import { toPath } from '@nfw/utils';
import { api } from '../api';
import { TeamArg } from '../api/types';

export const i18nApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTeamTranslations: builder.query<Translations, TeamArg>({
      query: ({ team }) => toPath(Routes.Translations, { team }),
      providesTags: (_, __, { team }) => [{ type: 'translation', id: team }],
    }),
    getTeamTranslationsForLocale: builder.query<Translations, TeamArg & { locale: string }>({
      query: ({ team, locale }) => toPath(Routes.TranslationsByLocale, { team, locale }),
      providesTags: (_, __, { team }) => [{ type: 'translation', id: team }],
    }),
    getAllLocaleTranslations: builder.query<LocaleTranslations, TeamArg>({
      query: ({ team }) => toPath(Routes.MarketTranslations, { team }),
      providesTags: (_, __, { team }) => [{ type: 'market-translation', id: team }],
    }),
    updateAllLocaleTranslations: builder.mutation<
      void,
      TeamArg & { translations: LocaleTranslations }
    >({
      query: ({ team, translations }) => ({
        url: toPath(Routes.MarketTranslations, { team }),
        method: 'POST',
        body: translations,
      }),
      invalidatesTags: (_, __, { team }) => [
        { type: 'market-translation', id: team },
        { type: 'audits', id: team },
      ],
    }),
  }),
});

export const {
  useGetTeamTranslationsQuery,
  useGetTeamTranslationsForLocaleQuery,
  useGetAllLocaleTranslationsQuery,
  useUpdateAllLocaleTranslationsMutation,
} = i18nApi;
