import { InputTypes, LayoutConfiguration, ValidationTypes } from '@nfw/form-types';
import { DOMTargetTypes } from '@nfw/nudge/types';
import { TFunction } from 'i18next';
import { VisibilityRuleLayout } from './Visibility';

export const AnchorLayout = (t: TFunction): LayoutConfiguration => [
  {
    inputType: InputTypes.PillListbox,
    name: 'type',
    label: t('form.feature.anchor.select'),
    btnLabel: t('global.choose_option'),
    includeSearch: false,
    options: Object.entries(DOMTargetTypes).map(([name, value]) => ({ name: value, value: name })),
  },
  {
    inputType: InputTypes.TextInput,
    name: 'id',
    label: t('form.feature.anchor.label'),
    limit: 50,
    validations: [
      {
        type: ValidationTypes.Required,
        errorMessage: t('form.feature.anchor.error'),
      },
    ],
  },
  {
    inputType: InputTypes.InlineFormControl,
    name: 'visibilityRule',
    label: 'Set visibility rule',
    type: 'switch',
    configuration: VisibilityRuleLayout(t),
  },
];
