import { useEffect, useState } from 'react';
import Button from '@ingka/button';
import InputField from '@ingka/input-field';
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal';
import Text from '@ingka/text';
import { LocaleTranslations, Translations } from '@nfw/contracts/self-service';
import { useTranslation } from 'react-i18next';
import { useUpdateAllLocaleTranslations } from '../../features/i18n/hooks';
import './index.scss';

export type MarketTranslationModalProps = {
  open: boolean;
  onClose: () => void;
  setIsUpdatingTranslations: (value: boolean) => void;
  team: string;
  teamName: string;
  translations: LocaleTranslations;
  existingKey?: string;
};

const MarketTranslationModal: React.FC<MarketTranslationModalProps> = ({
  open,
  onClose,
  setIsUpdatingTranslations,
  team,
  teamName,
  translations,
  existingKey = '',
}) => {
  const [key, setKey] = useState<string>(existingKey);
  const [isValidKey, setIsValidKey] = useState<boolean>();
  const [localesData, setLocalesData] = useState<Translations>({});
  const [isValidLocales, setIsValidLocales] = useState<boolean>(true);
  const { save: saveTranslations } = useUpdateAllLocaleTranslations(team);
  const { t } = useTranslation();
  const isEditMode = existingKey !== '';

  const validateLocales = (data: Translations) => {
    const hasEmptyLocales = Object.values(data).some((val) => val.trim() === '');
    setIsValidLocales(!hasEmptyLocales);
  };

  const handleLocaleChange = (locale: string, value: string) => {
    const updatedLocalesData = { ...localesData, [locale]: value };
    setLocalesData(updatedLocalesData);
    validateLocales(updatedLocalesData);
  };

  useEffect(() => {
    setIsValidKey(!/\s/.test(key) && key.length > 0);
  }, [key]);

  // Reset the modal state whenever it opens.
  useEffect(() => {
    if (open) {
      setKey(existingKey);
      const initialData: { [locale: string]: string } = {};
      Object.keys(translations).forEach((locale) => {
        initialData[locale] = translations[locale]?.[existingKey] || '';
      });
      setLocalesData(initialData);
      validateLocales(initialData);
    } else {
      setKey('');
      setLocalesData({});
    }
  }, [open, existingKey, translations]);

  const handleUpdateTranslations = () => {
    setIsUpdatingTranslations(true);
    // Simulate loading.
    setTimeout(() => {
      setIsUpdatingTranslations(false);
    }, 2000);
  };

  const handleSave = () => {
    const updatedTranslations: LocaleTranslations = JSON.parse(JSON.stringify(translations));

    Object.keys(updatedTranslations).forEach((locale) => {
      if (!updatedTranslations[locale]) {
        updatedTranslations[locale] = {};
      }
      updatedTranslations[locale][key] = localesData[locale];
    });

    saveTranslations(updatedTranslations);
    onClose();
    handleUpdateTranslations();
  };

  const handleDelete = () => {
    const updatedTranslations: LocaleTranslations = JSON.parse(JSON.stringify(translations));

    Object.keys(updatedTranslations).forEach((locale) => {
      if (updatedTranslations[locale] && updatedTranslations[locale][key]) {
        delete updatedTranslations[locale][key];
      }
    });
    saveTranslations(updatedTranslations);
    onClose();
    handleUpdateTranslations();
  };

  return (
    <Modal visible={open} handleCloseBtn={onClose}>
      <Sheets
        size="small"
        header={
          <ModalHeader
            title={
              isEditMode
                ? t('component.market_modal.edit_title', { team: teamName })
                : t('component.market_modal.add_title', { team: teamName })
            }
          />
        }
        fullSize
        footer={
          <ModalFooter>
            <Button
              text={isEditMode ? t('global.update') : t('global.action.save')}
              type="primary"
              onClick={handleSave}
              disabled={!isValidKey || !isValidLocales}
            />
            {isEditMode ? (
              <Button text={t('global.delete')} type="danger" onClick={handleDelete} />
            ) : (
              <Button text={t('global.action.cancel')} type="secondary" onClick={onClose} />
            )}
          </ModalFooter>
        }
      >
        <ModalBody className="body">
          <InputField
            id="key"
            className="key"
            label={t('component.market_modal.key')}
            key="existingKey"
            type="text"
            defaultValue={existingKey}
            disabled={isEditMode}
            onChange={(e) => setKey(e.target.value)}
          />
          {!isEditMode && (
            <Text bodySize="s" className="help">
              {t('component.market.key_help')}
            </Text>
          )}

          {Object.keys(translations).map((locale) => {
            return (
              <InputField
                id={locale}
                label={locale}
                key={locale}
                className="locale-key"
                type="text"
                defaultValue={existingKey ? translations[locale][existingKey] : undefined}
                onChange={(e) => handleLocaleChange(locale, e.target.value)}
              />
            );
          })}
        </ModalBody>
      </Sheets>
    </Modal>
  );
};

export default MarketTranslationModal;
