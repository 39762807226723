import {
  InputTypes,
  LayoutConfiguration,
  PillListboxOption,
  ValidationTypes,
} from '@nfw/form-types';
import { TFunction } from 'i18next';

export const IMAGE_URL_REGEXP =
  /^(http|https):\/\/(www\.)?ikea(\.[a-z]+)+\/.*ingkadam.*\.(png|jpg|jpeg|PNG|JPG|JPEG)(\?.*)?$/;

export const getHeaderLayout = (
  t: TFunction,
  translationOptions: PillListboxOption[]
): LayoutConfiguration => [
  {
    inputType: InputTypes.TextInput,
    name: 'imageSrc',
    label: t('form.widget.image.label'),
    helpMessage: t('form.widget.image.help'),
    validations: [
      {
        type: ValidationTypes.Regexp,
        regexp: IMAGE_URL_REGEXP,
        errorMessage: t('form.widget.image.error'),
      },
    ],
  },
  {
    inputType: InputTypes.PillListbox,
    name: 'altText',
    includeSearch: true,
    label: t('form.widget.image.alt'),
    helpMessage: t('form.widget.image.alt_help'),
    options: translationOptions,
    btnLabel: t('form.widget.select_key'),
  },
  {
    inputType: InputTypes.RadioGroup,
    name: 'aspectRatio',
    label: t('form.widget.image.ratio'),
    options: [
      { id: 'standard', label: t('form.widget.image.standard'), value: 'standard' },
      { id: 'square', label: t('form.widget.image.square'), value: 'square' },
      { id: 'wide', label: t('form.widget.image.wide'), value: 'wide' },
    ],
    defaultValue: 'standard',
  },
];
