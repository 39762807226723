import React, { useCallback, useState } from 'react';
import {
  DefaultEvent,
  DefaultMetric,
  ExperimentRequest,
  isExperimentRequest,
} from '@nfw/contracts/experiment';
import { StoredFeatureOrchestration } from '@nfw/orchestration-types';
import { Form, FormCallback } from '@nfw/ui/form';
import { useExperimentLayout } from '../../features/experiments/hooks';
import { ExperimentMeta } from '../../features/experiments/types';
import HealthMetricsPlaceholder from '../HealthMetricsPlaceholder';
import LinearLayout from '../LinearLayout';
import MetricModal from '../MetricModal';

export type ExperimentFormProps = {
  meta: ExperimentMeta;
  events: DefaultEvent[];
  event?: DefaultEvent;
  defaultMetrics: DefaultMetric[];
  orchestration: StoredFeatureOrchestration;
  experiment?: ExperimentRequest;
  onExperiment: (experiment: ExperimentRequest) => void;
};

const ExperimentForm: React.FC<ExperimentFormProps> = ({
  meta,
  events,
  event,
  orchestration,
  defaultMetrics,
  onExperiment,
}) => {
  const [metrics, setMetrics] = useState(defaultMetrics);
  const [latestMetrics, setLatestMetrics] = useState(metrics);
  const layout = useExperimentLayout(meta, orchestration, events, metrics);

  const onChangeInternal = useCallback<FormCallback<ExperimentRequest>>(
    ({ value }) => {
      if (value) {
        onExperiment(value);
        // Set the latest metrics.
        setLatestMetrics(value.metrics);
      }
    },
    [onExperiment]
  );

  const onMetric = useCallback(
    (metric: DefaultMetric) => {
      setMetrics([metric, ...latestMetrics]); // Add the new metric to the top of the list.
    },
    [latestMetrics]
  );

  return (
    <LinearLayout padded>
      <MetricModal event={event} onMetric={onMetric} />
      <Form config={layout} validationFn={isExperimentRequest} onChange={onChangeInternal} />
      <HealthMetricsPlaceholder
        id={`${orchestration.id}-${orchestration.featureNudge.widgetRef}`}
      />
    </LinearLayout>
  );
};

export default ExperimentForm;
