import {
  IkeaCatalogueArgs,
  IkeaStoreArgs,
  Routes,
  UpdateResourceResponse,
} from '@nfw/contracts/self-service';
import { Deployment } from '@nfw/deployment-types';
import { HierarchyNode } from '@nfw/form-types';
import { IkeaStore } from '@nfw/ikea/store';
import { CollisionType } from '@nfw/nudge-collision';
import { NudgeMetric, NudgeOfFameMetrics } from '@nfw/nudge-evaluate';
import { FeatureOrchestration, StoredFeatureOrchestration } from '@nfw/orchestration-types';
import { toPath } from '@nfw/utils';
import { api } from '../api';
import { IdArg, TeamArg, TeamIdArg } from '../api/types';

export const orchestrationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOrchestration: build.query<StoredFeatureOrchestration, IdArg>({
      query: ({ id }) => toPath(Routes.Feature, { id }),
      providesTags: (_, __, { id }) => [{ type: 'feature', id }],
    }),
    getOrchestrations: build.query<StoredFeatureOrchestration[], void>({
      query: () => Routes.Features,
      providesTags: (orchestrations) =>
        orchestrations
          ? [
              ...orchestrations.map(({ id }) => ({ type: 'feature' as const, id })),
              { type: 'feature', id: 'all' },
            ]
          : [{ type: 'feature', id: 'all' }],
    }),
    getNudgeGroupOrchestrations: build.query<StoredFeatureOrchestration[], IdArg>({
      query: ({ id }) => toPath(Routes.FeatureNudgeGroups, { id }),
      providesTags: (_, __, { id }) => [{ type: 'feature', id }],
    }),
    createOrchestration: build.mutation<string, TeamArg & { orchestration: FeatureOrchestration }>({
      query: ({ team, orchestration }) => ({
        url: toPath(Routes.TeamFeatures, { team }),
        method: 'POST',
        body: orchestration,
      }),
      invalidatesTags: [
        { type: 'feature', id: 'all' },
        { type: 'deployment', id: 'all' },
        { type: 'collisions', id: 'all' },
        { type: 'nudge-group', id: 'all' },
      ],
    }),
    updateOrchestration: build.mutation<void, TeamIdArg & { orchestration: FeatureOrchestration }>({
      query: ({ id, team, orchestration }) => ({
        url: toPath(Routes.TeamFeature, { id, team }),
        method: 'PUT',
        body: orchestration,
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: 'feature', id },
        { type: 'feature', id: 'all' },
        { type: 'deployment', id },
        { type: 'deployment', id: 'all' },
        { type: 'collisions', id: 'all' },
        { type: 'nudge-group', id: 'all' },
      ],
    }),
    republishOrchestration: build.mutation<UpdateResourceResponse, TeamIdArg>({
      query: ({ id, team }) => ({
        url: toPath(Routes.RepublishTeamFeature, { id, team }),
        method: 'PUT',
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: 'feature', id },
        { type: 'feature', id: 'all' },
        { type: 'deployment', id },
        { type: 'deployment', id: 'all' },
      ],
    }),
    archiveOrchestration: build.mutation<void, TeamIdArg>({
      query: ({ id, team }) => ({
        url: toPath(Routes.Archive, { id, team }),
        method: 'PUT',
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: 'deployment', id },
        { type: 'collisions', id: 'all' },
      ],
    }),
    publishOrchestration: build.mutation<void, TeamIdArg>({
      query: ({ id, team }) => ({ url: toPath(Routes.Publish, { id, team }), method: 'PUT' }),
      invalidatesTags: (_, __, { id }) => [{ type: 'deployment', id }],
    }),
    getDeployment: build.query<Deployment, IdArg>({
      query: ({ id }) => toPath(Routes.Deployment, { id }),
      providesTags: (_, __, { id }) => [{ type: 'deployment', id }],
    }),
    getDeployments: build.query<Deployment[], void>({
      query: () => Routes.Deployments,
      providesTags: (deployments) =>
        deployments
          ? [
              ...deployments.map(({ id }) => ({ type: 'deployment' as const, id })),
              { type: 'deployment', id: 'all' },
            ]
          : [{ type: 'deployment', id: 'all' }],
    }),
    getNudgeMetric: build.query<NudgeMetric | undefined, IdArg>({
      query: ({ id }) => toPath(Routes.NudgeMetric, { id }),
      providesTags: (_, __, { id }) => [{ type: 'nudge-metric', id }],
    }),
    getNudgeGroupMetric: build.query<NudgeMetric | undefined, IdArg>({
      query: ({ id }) => toPath(Routes.NudgeGroupMetric, { id }),
      providesTags: (_, __, { id }) => [{ type: 'nudge-metric', id }],
    }),
    getNudgeOfFameMetrics: build.query<NudgeOfFameMetrics[], void>({
      query: () => Routes.NudgeOfFameMetrics,
      providesTags: [{ type: 'nudge-of-fame' }],
    }),
    getCollisions: build.query<CollisionType, void>({
      query: () => Routes.FeatureCollisions,
      providesTags: [{ type: 'collisions', id: 'all' }],
    }),
    getRootNode: build.query<HierarchyNode, IkeaCatalogueArgs>({
      query: ({ retailUnits, sspLanguage }) => {
        const queryParams = new URLSearchParams({
          retailUnits: retailUnits.join(','),
          language: sspLanguage,
        });
        return {
          url: Routes.IkeaWebStructure,
          params: queryParams,
        };
      },
    }),
    getStores: build.query<IkeaStore[], IkeaStoreArgs>({
      query: ({ retailUnits }) => {
        const queryParams = new URLSearchParams();
        if (retailUnits && retailUnits.length > 0) {
          queryParams.set('retailUnits', retailUnits.join(','));
        }
        return {
          url: Routes.IkeaStores,
          params: queryParams,
        };
      },
    }),
  }),
});

export const {
  useGetOrchestrationQuery,
  useGetOrchestrationsQuery,
  useGetNudgeGroupOrchestrationsQuery,
  useCreateOrchestrationMutation,
  useUpdateOrchestrationMutation,
  useRepublishOrchestrationMutation,
  useArchiveOrchestrationMutation,
  usePublishOrchestrationMutation,
  useGetDeploymentQuery,
  useGetDeploymentsQuery,
  useGetNudgeMetricQuery,
  useGetNudgeGroupMetricQuery,
  useGetNudgeOfFameMetricsQuery,
  useGetCollisionsQuery,
  useGetRootNodeQuery,
  useGetStoresQuery,
} = orchestrationApi;
