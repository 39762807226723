import { useEffect, useState } from 'react';
import Badge from '@ingka/badge';
import Pill from '@ingka/pill';
import Search from '@ingka/search';
import arrowUpArrowDown from '@ingka/ssr-icon/paths/arrow-up-arrow-down';
import cross from '@ingka/ssr-icon/paths/cross-small';
import Switch from '@ingka/switch';
import Text from '@ingka/text';
import { RetailUnit } from '@nfw/ikea/retail';
import { unique } from '@nfw/utils';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { clearFilter, updateFilter } from '../../../features/app/actions';
import { selectWhichFilterSelections, selectSortValue } from '../../../features/app/selectors';
import { useAppDispatch, useMemoizedSelector } from '../../../store';
import LinearLayout from '../../LinearLayout';
import Selector from './Selector';
import './index.scss';

/**
 * A filter function used for the Select component.
 */
export type FilterFunction<T> = (selectedValues: string[], value: T) => boolean;

/**
 * A filter function for the Search component where input might be undefined.
 */
export type SearchFilterFunction<T> = (selected: string, value?: T) => boolean;

/**
 * A filter record consisting of the id as key then the filter function as the value.
 */
export type FilterRecord<T> = Record<string, (value: T) => boolean>;

/**
 * The namedFilter type.
 */
export type NamedFilter<T> = {
  id: string;
  label: string;
  distinctValues: (value: T, index: number, array: T[]) => string;
  filter: FilterFunction<T>;
};

/**
 * The marketFilter type.
 */
export type MarketFilter<T> = {
  id: string;
  label: string;
  distinctValues: (value: T, index: number, array: T[]) => RetailUnit[];
  filter: FilterFunction<T>;
};

/**
 * The switchFilter type.
 */
export type SwitchFilter<T> = {
  id: string;
  label: string;
  filter: FilterFunction<T>;
  value: string;
};

/**
 * The sort type.
 */
export type Sort<T> = {
  id: string;
  label: string;
  sort: (values: T[]) => T[];
};

export type GenericFilterProps<T extends object> = {
  namedFilters?: NamedFilter<T>[];
  searchFilter: SearchFilterFunction<T>;
  marketFilter?: MarketFilter<T>;
  switchFilters?: SwitchFilter<T>[];
  sort?: Sort<T>[];
  data: T[];
  onFilterChange: (data: T[]) => void;
  type: string;
  searchLabel: string;
  stacked?: boolean;
  compact?: boolean;
};

type Selection = {
  name: string;
  value: string;
};

type AppliedFilter<T> = {
  id: string;
  selections: Selection[];
  filter: FilterFunction<T>;
};

function GenericFilter<T extends object>({
  data,
  namedFilters = [],
  marketFilter,
  onFilterChange,
  searchFilter,
  type,
  searchLabel,
  stacked = false,
  compact = false,
  switchFilters,
  sort,
}: Readonly<GenericFilterProps<T>>) {
  const [appliedFilters, setAppliedFilters] = useState<AppliedFilter<T>[]>([]);
  const [output, setOutput] = useState<T[]>([]);
  const [searchString, setSearchString] = useState<string>();
  const selections = useMemoizedSelector(selectWhichFilterSelections, type);
  const sortValue = useMemoizedSelector(selectSortValue);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const types = {
    feature: 'page.home.filter.nudge',
    widget: 'page.home.filter.design',
    request: 'page.home.filter.requests',
    project: 'page.home.filter.events',
  };
  const filterText = types[type];

  useEffect(() => {
    let filteredData = data;

    // Apply category filters
    filteredData = appliedFilters.reduce<T[]>(
      (acc, { filter, selections }) =>
        acc.filter((value) =>
          filter(
            selections.map(({ value }) => value),
            value
          )
        ),
      filteredData
    );

    // Apply search filter
    if (searchString) {
      filteredData = filteredData.filter((value) => searchFilter(searchString, value));
    }

    // Apply sorting
    if (sortValue && sort) {
      const s = sort.find(({ id }) => id === sortValue.value);
      if (s) {
        filteredData = s.sort(filteredData);
      }
    }

    setOutput(filteredData);
  }, [data, appliedFilters, searchString, searchFilter, sortValue, sort]);

  useEffect(() => {
    if (output) {
      onFilterChange(output);
    }
  }, [output, onFilterChange]);

  useEffect(() => {
    const filters = selections.reduce<AppliedFilter<T>[]>((acc, { id, values }) => {
      if (values.length > 0) {
        if (marketFilter?.id === id) {
          const { filter } = marketFilter;

          acc.push({
            id,
            filter,
            selections: values.map((value) => ({
              name: t(`global.country.${value as RetailUnit}`),
              value,
            })),
          });
        } else {
          const switchFilter = switchFilters?.find((filter) => filter.id === id);

          if (switchFilter) {
            acc.push({
              id,
              filter: switchFilter.filter,
              selections: values.map((value) => ({ name: switchFilter.id, value })),
            });
          } else {
            const namedFilter = namedFilters.find((filter) => filter?.id === id);

            if (namedFilter) {
              const { filter } = namedFilter;

              acc.push({
                id,
                filter,
                selections: values.map((value) => ({ name: value, value })),
              });
            }
          }
        }
      }
      return acc;
    }, []);

    setAppliedFilters(filters);
  }, [switchFilters, marketFilter, namedFilters, selections, t]);

  useEffect(() => {
    switchFilters?.forEach(({ id, value }) => {
      dispatch(updateFilter(true, { which: type, id, value }));
    });
  }, [dispatch, switchFilters, type]);

  const handleClearFilters = () => {
    dispatch(clearFilter());
    switchFilters?.forEach(({ id, value }) => {
      dispatch(updateFilter(true, { which: type, id, value }));
    });
  };

  return (
    <>
      <div className={classNames('filter-container', { stacked, compact })}>
        <Search
          id="name"
          className="filter-search"
          placeholder={searchLabel}
          onClear={() => setSearchString(undefined)}
          onChange={(event) => setSearchString(event.target.value.toLowerCase())}
        />
        <div className={classNames('selectors-container', { stacked, compact })}>
          {namedFilters.map(({ id, label, distinctValues }) => (
            <Selector
              key={id}
              id={id}
              type={type}
              label={label}
              options={unique(data.map(distinctValues)).map((value) => ({
                value,
                label: value,
              }))}
            />
          ))}
          {marketFilter && (
            <Selector
              key={marketFilter.id}
              id={marketFilter.id}
              type={type}
              label={marketFilter.label}
              options={unique(data.map(marketFilter.distinctValues).flat()).map((ru) => ({
                value: ru,
                label: t(`global.country.${ru}`),
              }))}
            />
          )}
          {sort && (
            <Selector
              id={`${type}-sort`}
              type={type}
              label={t('global.sort')}
              options={sort.map(({ id, label }) => ({ value: id, label }))}
              control="radiobutton"
            />
          )}
        </div>

        <div className={classNames('switches')}>
          {switchFilters?.map(({ id, label, value }) => (
            <Switch
              key={id}
              label={label}
              subtle
              id={id}
              value={(!appliedFilters.some((filter) => filter.id === id)).toString()}
              onChange={() => {
                dispatch(
                  updateFilter(!appliedFilters.some((filter) => filter.id === id), {
                    which: type,
                    id,
                    value,
                  })
                );
              }}
            />
          ))}
        </div>
      </div>
      <div className={classNames('pills-container', { compact })}>
        <div className={classNames('pills', { stacked })}>
          {sortValue && (
            <div className={classNames('filter-pill', { stacked })}>
              <Badge
                label={sort?.find(({ id }) => id === sortValue.value)?.label ?? sortValue.value}
                colour="white"
                ssrIcon={arrowUpArrowDown}
              />
            </div>
          )}
          {appliedFilters
            .filter(
              (appliedFilter) =>
                !switchFilters?.some((switchFilter) => switchFilter.id === appliedFilter.id)
            )
            .map(({ id, selections }) =>
              selections.map(({ value, name }) => (
                <div key={value} className={classNames('filter-pill', { stacked })}>
                  <Pill
                    iconPosition="leading"
                    label={name}
                    size="xsmall"
                    ssrIcon={cross}
                    onClick={() => dispatch(updateFilter(false, { which: type, id, value }))}
                  />
                </div>
              ))
            )}
          {appliedFilters.filter(
            (appliedFilter) =>
              !switchFilters?.some((switchFilter) => switchFilter.id === appliedFilter.id)
          ).length > 0 && (
            <Pill
              label={t('global.filter.clear')}
              size="xsmall"
              onClick={handleClearFilters}
              className={classNames('clear', { stacked })}
            />
          )}
        </div>
        <LinearLayout horizontal gap="m" className="toggle-select">
          <div>
            <Text>{t(filterText, { count: output?.length ?? 0 })}</Text>
          </div>
        </LinearLayout>
      </div>
    </>
  );
}

export default GenericFilter;
