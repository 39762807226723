import React, { Suspense, useCallback, useEffect, useState } from 'react';
import Button from '@ingka/button';
import SkapaModal, {
  Prompt as SkapaPrompt,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from '@ingka/modal';
import Text from '@ingka/text';
import { Action, PromptWidget } from '@nfw/nudge/types';
import { useLazyIcon } from '@nfw/ui/common';
import { formatNumber } from '@nfw/utils';

export type PromptProps = {
  /** The widget id */
  id: string;
  /** The skapa prefix */
  prefix?: string;
  /** The widget */
  widget: PromptWidget;
  /** Flag for if the nudge is always open, used as presentation in ssp */
  alwaysOpen?: boolean;
  /** The closing function */
  onClose?: () => void;
  /** The submit function */
  onSubmit: (action: Action) => void;
  /** Optional function that is run when modal is opened */
  onOpen?: () => void;
};

const ValueStatements = React.lazy(() => import('@nfw/ui/value-statements'));

export const Prompt: React.FC<PromptProps> = ({
  id,
  prefix,
  alwaysOpen,
  onClose,
  onSubmit,
  onOpen,
  widget: { text, title, valueStatements, actions, footerDivider },
}: PromptProps) => {
  const [isDismissed, setDismissed] = useState(true);
  const [open, setOpen] = useState(true);
  const [setIcons, getIcons] = useLazyIcon();

  useEffect(() => {
    const icons = actions.map((action) => action.icon?.iconType);
    setIcons(...icons);
  }, [actions, setIcons]);

  useEffect(() => {
    if (open) {
      onOpen?.();
    } else {
      onClose?.();
    }
  }, [onClose, open, onOpen]);

  const onInternalClose = useCallback(() => {
    setOpen(false);
    if (isDismissed) {
      onSubmit({ type: 'dismiss' });
    }
  }, [isDismissed, onSubmit]);

  const onInternalSubmit = useCallback(
    (action: Action) => {
      setOpen(false);
      setDismissed(false);
      onSubmit(action);
    },
    [onSubmit]
  );

  return (
    <SkapaModal
      id={id}
      prefix={prefix}
      visible={alwaysOpen || open}
      handleCloseBtn={onInternalClose}
      aria-labelledby="nfw-prompt-title"
    >
      <SkapaPrompt
        prefix={prefix}
        title={title}
        titleId={`${prefix}-prompt-title`}
        header={<ModalHeader prefix={prefix} />}
        footer={
          <ModalFooter prefix={prefix} renderBorder={!!footerDivider}>
            {actions.map((action, index) => {
              const { type, buttonType, text, icon } = action;
              switch (type) {
                case 'call':
                  return (
                    <Button
                      key={index}
                      prefix={prefix}
                      type={buttonType}
                      text={`${text} ${formatNumber(action.number)}`}
                      fluid
                      onClick={() => onInternalSubmit(action)}
                      iconPosition={icon?.iconPosition}
                      ssrIcon={getIcons(icon?.iconType)}
                    />
                  );
                case 'dismiss':
                case 'goto':
                case 'goto_external':
                case 'login':
                case 'negative':
                case 'nudge':
                case 'positive':
                  return (
                    <Button
                      key={index}
                      prefix={prefix}
                      type={buttonType}
                      text={text}
                      fluid
                      onClick={() => onInternalSubmit(action)}
                      iconPosition={icon?.iconPosition}
                      ssrIcon={getIcons(icon?.iconType)}
                    />
                  );
                default:
                  return null;
              }
            })}
          </ModalFooter>
        }
      >
        <ModalBody prefix={prefix}>
          <Text prefix={prefix} bodySize="l" tagName="p" className="text">
            {text}
          </Text>
          {valueStatements && valueStatements.length > 0 && (
            <Suspense fallback={null}>
              <ValueStatements prefix={prefix} statements={valueStatements} />
            </Suspense>
          )}
        </ModalBody>
      </SkapaPrompt>
    </SkapaModal>
  );
};

export default Prompt;
