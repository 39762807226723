import { Namespaces } from '@nfw/ikea/utils';
import {
  ActionTypes,
  ConditionTypes,
  RepeatTypes,
  TriggerTypes,
  WidgetTypes,
} from '@nfw/nudge/types';
import { FeatureToggle, TeamConfiguration } from './types';

const availableToggle: FeatureToggle = {
  widgets: [WidgetTypes.Modal, WidgetTypes.Toast, WidgetTypes.Prompt],
  namespaces: Object.values(Namespaces),
  triggers: [
    TriggerTypes.CartItemsAdded,
    TriggerTypes.CartItemsRemoved,
    TriggerTypes.DomEvent,
    TriggerTypes.ElementInViewport,
    TriggerTypes.EpisodEvent,
    TriggerTypes.ExitIntent,
    TriggerTypes.FavouritesAdded,
    TriggerTypes.FavouritesRemoved,
    TriggerTypes.Idle,
    TriggerTypes.Init,
    TriggerTypes.RegExpMatcher,
    TriggerTypes.SearchParam,
    TriggerTypes.StoreChange,
  ],
  repeat: [RepeatTypes.Page, RepeatTypes.Session],
  actions: [
    ActionTypes.Call,
    ActionTypes.Dismiss,
    ActionTypes.GoTo,
    ActionTypes.GoToExternal,
    ActionTypes.Login,
    ActionTypes.Negative,
    ActionTypes.Positive,
  ],
  conditions: Object.values(ConditionTypes),
};

/**
 * The default features that are on or off for everyone.
 */
const globalTeam: FeatureToggle = {
  ...availableToggle,
  actions: [
    ActionTypes.Call,
    ActionTypes.Dismiss,
    ActionTypes.GoTo,
    ActionTypes.GoToExternal,
    ActionTypes.Login,
  ],
};

/**
 * The default features for the market team.
 */
const marketTeam: FeatureToggle = {
  ...availableToggle,
  namespaces: [
    Namespaces.Home,
    Namespaces.Rooms,
    Namespaces.Offers,
    Namespaces.New,
    Namespaces.Campaigns,
  ],
  actions: [
    ActionTypes.Call,
    ActionTypes.Dismiss,
    ActionTypes.GoTo,
    ActionTypes.GoToExternal,
    ActionTypes.Login,
  ],
};

const mergeFeatures = (
  baseTeam: FeatureToggle,
  featureToggle: Partial<FeatureToggle>
): FeatureToggle =>
  Object.keys(baseTeam).reduce((acc, key) => {
    acc[key] = Array.isArray(featureToggle[key])
      ? [...new Set([...baseTeam[key], ...featureToggle[key]])]
      : baseTeam[key];
    return acc;
  }, {} as FeatureToggle);

export const getTeamFeatures = (team: TeamConfiguration | undefined): FeatureToggle => {
  if (!team) {
    throw new Error('Team configuration is missing');
  }

  if (team.featureToggle) {
    return team.teamType === 'market'
      ? mergeFeatures(marketTeam, team.featureToggle)
      : mergeFeatures(globalTeam, team.featureToggle);
  }

  return team.teamType === 'market' ? marketTeam : globalTeam;
};
