import React, { Suspense, useCallback, useEffect, useState } from 'react';
import Button from '@ingka/button';
import SkapaModal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal';
import Text from '@ingka/text';
import { Action, ModalWidget } from '@nfw/nudge/types';
import { useLazyIcon } from '@nfw/ui/common';
import { ImageLoader } from '@nfw/ui/image-loader';
import { formatNumber } from '@nfw/utils';
import './index.scss';

export type ModalProps = {
  /** The widget id */
  id: string;
  /** The skapa prefix */
  prefix?: string;
  /** The widget */
  widget: ModalWidget;
  /** Flag for if the nudge is always open, used as presentation in ssp. */
  alwaysOpen?: boolean;
  /** The closing function */
  onClose?: () => void;
  /** The submit function */
  onSubmit: (action: Action) => void;
  /** Optional function that is run when modal is shown */
  onShow?: () => void;
};

const ValueStatements = React.lazy(() => import('@nfw/ui/value-statements'));

export const Modal: React.FC<ModalProps> = ({
  id,
  prefix,
  alwaysOpen,
  onClose,
  onSubmit,
  onShow,
  widget: {
    actions,
    horizontalAlignment = 'right',
    size = 'small',
    header,
    footerDivider,
    title,
    text,
    valueStatements,
    ctaText = [],
  },
}) => {
  const [isDismissed, setDismissed] = useState(true);
  const [show, setShow] = useState(true);
  const [setIcons, getIcons] = useLazyIcon();

  useEffect(() => {
    const icons = actions.map((action) => action.icon?.iconType);
    setIcons(...icons);
  }, [actions, setIcons]);

  useEffect(() => {
    if (show) {
      onShow?.();
    } else {
      onClose?.();
    }
  }, [show, id, onShow, onClose]);

  const onInternalClose = useCallback(() => {
    setShow(false);
    if (isDismissed) {
      onSubmit({ type: 'dismiss' });
    }
  }, [isDismissed, onSubmit]);

  const onInternalSubmit = useCallback(
    (action: Action) => {
      setShow(false);
      setDismissed(false);
      onSubmit(action);
    },
    [onSubmit]
  );

  return (
    <SkapaModal
      id={id}
      prefix={prefix}
      visible={alwaysOpen || show}
      handleCloseBtn={onInternalClose}
      aria-labelledby="nfw-modal-title"
    >
      <Sheets
        prefix={prefix}
        header={<ModalHeader prefix={prefix} floating={!!header} />}
        alignment={horizontalAlignment}
        size={size}
        aria-labelledby="nfw-modal-title"
        footer={
          <ModalFooter prefix={prefix} renderBorder={!!footerDivider}>
            {actions.map((action, index) => {
              const { type, buttonType, text, icon } = action;
              switch (type) {
                case 'call':
                  return (
                    <Button
                      key={index}
                      prefix={prefix}
                      type={buttonType}
                      text={`${text} ${formatNumber(action.number)}`}
                      fluid
                      onClick={() => onInternalSubmit(action)}
                      iconPosition={icon?.iconPosition}
                      ssrIcon={getIcons(icon?.iconType)}
                    />
                  );
                case 'dismiss':
                case 'goto':
                case 'goto_external':
                case 'login':
                case 'negative':
                case 'nudge':
                case 'positive':
                  return (
                    <Button
                      key={index}
                      prefix={prefix}
                      type={buttonType}
                      text={text}
                      fluid
                      onClick={() => onInternalSubmit(action)}
                      iconPosition={icon?.iconPosition}
                      ssrIcon={getIcons(icon?.iconType)}
                    />
                  );
                default:
                  return null;
              }
            })}
          </ModalFooter>
        }
      >
        {header ? (
          <ImageLoader
            prefix={prefix}
            ratio={header.aspectRatio}
            src={header.imageSrc}
            altText={header.altText}
          />
        ) : (
          <div role="presentation">
            {/* Leave this here or the modal breaks when imageSrc is missing */}
          </div>
        )}
        <ModalBody prefix={prefix}>
          <Text prefix={prefix} headingSize="m" tagName="h2" id="nfw-modal-title" className="title">
            {title}
          </Text>
          <Text prefix={prefix} bodySize="l" tagName="p" className="text">
            {text}
          </Text>
          {valueStatements && valueStatements.length > 0 && (
            <Suspense fallback={null}>
              <ValueStatements prefix={prefix} statements={valueStatements} />
            </Suspense>
          )}
          {ctaText.map((text) => (
            <Text prefix={prefix} headingSize="s" tagName="p">
              {text}
            </Text>
          ))}
        </ModalBody>
      </Sheets>
    </SkapaModal>
  );
};

export default Modal;
