import {
  NudgeExperimentTypes,
  NudgeDesignExperiment,
  NudgeExperiment,
  NudgeLogicExperiment,
  NudgeVisibilityExperiment,
  NudgeExperimentType,
  NudgeCampaignExperiment,
} from '@nfw/nudge/types';
import { isArrayOfType, isObject, isString } from '@nfw/utils';
import { isWidget } from './widget';

/**
 * Check experiment base type.
 *
 * @param value - the experiment to test.
 * @param type - the type of experiment.
 *
 * @returns true when valid, otherwise false.
 */
const isNudgeExperimentBase = (
  value: unknown,
  type: NudgeExperimentType
): value is Record<string, unknown> => isObject(value) && type === value.type && isString(value.id);

/**
 * Check if an experiment type is valid.
 *
 * @param t - the type to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isNudgeExperimentType = (t: unknown): t is NudgeExperimentType =>
  Object.values(NudgeExperimentTypes).includes(t as NudgeExperimentType);

/**
 * Check if an experiment object is a valid nudge visibility experiment.
 *
 * @param e - the experiment to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isNudgeVisibilityExperiment = (e: unknown): e is NudgeVisibilityExperiment =>
  isNudgeExperimentBase(e, NudgeExperimentTypes.Visibility);

/**
 * Check if an experiment object is a valid nudge design experiment.
 *
 * @param e - the experiment to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isNudgeDesignExperiment = (e: unknown): e is NudgeDesignExperiment =>
  isNudgeExperimentBase(e, NudgeExperimentTypes.Design) && isArrayOfType(e.variants, isWidget);

/**
 * Check if an experiment object is a valid nudge logic experiment.
 *
 * @param e - the experiment to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isNudgeLogicExperiment = (e: unknown): e is NudgeLogicExperiment =>
  isNudgeExperimentBase(e, NudgeExperimentTypes.Logic) && isObject(e.variant);

/**
 * Check if an experiment object is a valid nudge campaign experiment.
 *
 * @param e - the experiment to test.
 *
 * @returns truw when valid, otherwise false.
 */
export const isNudgeCampaignExperiment = (e: unknown): e is NudgeCampaignExperiment =>
  isNudgeExperimentBase(e, NudgeExperimentTypes.Campaign) && isArrayOfType(e.variants, isWidget);

/**
 * Check if an experiment object is a valid nudge experiment.
 *
 * @param e - the experiment to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isNudgeExperiment = (e: unknown): e is NudgeExperiment =>
  isNudgeVisibilityExperiment(e) ||
  isNudgeDesignExperiment(e) ||
  isNudgeLogicExperiment(e) ||
  isNudgeCampaignExperiment(e);
