import React from 'react';
import Table, { TableBody, TableHeader } from '@ingka/table';
import Text from '@ingka/text';
import { TeamConfiguration } from '@nfw/contracts/self-service';
import { toDateString } from '@nfw/utils';
import { useTranslation } from 'react-i18next';
import PuffaHyperlink from '../../../components/PuffaHyperlink';

export type TeamsProps = {
  configurations?: TeamConfiguration[];
};

const Teams: React.FC<TeamsProps> = ({ configurations = [] }) => {
  const { t } = useTranslation();

  return (
    <Table fullWidth inset>
      <TableHeader sticky>
        <tr>
          <th>{t('global.team')}</th>
          <th>{t('global.administrator')}</th>
          <th>{t('form.team.type.label')}</th>
          <th>{t('global.created')}</th>
        </tr>
      </TableHeader>
      <TableBody striped>
        {configurations.map((team) => (
          <tr key={team.id}>
            <td>
              <PuffaHyperlink route="/team/:team" params={{ team: team.id }}>
                <b>{team.name}</b>
              </PuffaHyperlink>
            </td>
            <td>{team.creator}</td>
            <td>
              {team.teamType === 'product' ? (
                <Text bodySize="m">{t('global.product_team.label')}</Text>
              ) : (
                <Text bodySize="m">{t('global.market_team.label')}</Text>
              )}
            </td>
            <td>{toDateString(team.created)}</td>
          </tr>
        ))}
      </TableBody>
    </Table>
  );
};

export default Teams;
