import { Configuration, FrameworkConfiguration, MarketConfiguration } from '@nfw/nudge/types';
import { isBoolean, isNaturalNumber, isObject, isOptionallyDefined } from '@nfw/utils';

/**
 * Check if a config is a valid configuration object.
 *
 * @param config - the config to test.
 *
 * @returns true if the config is a valid configuration object.
 */
export const isConfiguration = (config: unknown): config is Configuration =>
  isMarketConfiguration(config) && isFrameworkConfiguration(config);

/**
 * Check if a config is a valid market configuration object.
 *
 * @param config - the config to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isMarketConfiguration = (config: unknown): config is MarketConfiguration => {
  return (
    isObject(config) &&
    isOptionallyDefined(config.isStopped, isBoolean) &&
    isOptionallyDefined(config.cartLargeSum, isNaturalNumber)
  );
};

/**
 * Check if a config is a valid framework configuration object.
 *
 * @param config - the config to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isFrameworkConfiguration = (config: unknown): config is FrameworkConfiguration =>
  isObject(config) &&
  isNaturalNumber(config.toastWithinTime) &&
  isNaturalNumber(config.maxNudgeCount) &&
  isNaturalNumber(config.maxNudgeCountPerPage) &&
  isNaturalNumber(config.nudgeIdleTime);
