import { useCallback, useEffect } from 'react';
import { RetailUnit } from '@nfw/ikea/retail';
import { FrameworkConfiguration, MarketConfiguration } from '@nfw/nudge/types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store';
import { PuffaError } from '../api/puffa-error';
import { queueToast } from '../app/actions';
import {
  useGetFrameworkConfigurationQuery,
  useGetMarketConfigurationQuery,
  useUpdateFrameworkConfigurationMutation,
  useUpdateMarketConfigurationMutation,
} from './api';

/**
 * Hook for getting a market configuration.
 *
 * @param retailUnit - the retail unit to get the configuration for.
 *
 * @returns the market configuration and loading state.
 */
export const useGetMarketConfiguration = (retailUnit: RetailUnit) => {
  const { data, isError, isLoading } = useGetMarketConfigurationQuery({ retailUnit });

  useEffect(() => {
    if (isError) {
      throw new PuffaError('Failed to load market configuration');
    }
  }, [isError]);

  return { data, isLoading };
};

/**
 * Hook for getting the framework configuration.
 *
 * @returns the framework configuration and loading state.
 */
export const useGetFrameworkConfiguration = () => {
  const { data, isError, isLoading } = useGetFrameworkConfigurationQuery();

  useEffect(() => {
    if (isError) {
      throw new PuffaError('Failed to load framework configuration');
    }
  }, [isError]);

  return { data, isLoading };
};

/**
 * Hook for updating a market configuration.
 *
 * @returns an object with the update function and loading state.
 */
export const useUpdateMarketConfiguration = () => {
  const [update, { isError, isSuccess, isLoading }] = useUpdateMarketConfigurationMutation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const updateInternal = useCallback(
    (configuration: MarketConfiguration, retailUnit: RetailUnit) => {
      update({ configuration, retailUnit });
    },
    [update]
  );

  useEffect(() => {
    if (isError) {
      dispatch(queueToast({ msg: t('global.error.generic') }));
    }
  }, [dispatch, isError, t]);

  return { update: updateInternal, isLoading, isSuccess };
};

/**
 * Hook for updating a framework configuration.
 *
 * @returns an object with the update function and loading state.
 */
export const useUpdateFrameworkConfiguration = () => {
  const [update, { isError, isSuccess, isLoading }] = useUpdateFrameworkConfigurationMutation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const updateInternal = useCallback(
    (configuration: FrameworkConfiguration) => {
      update({ configuration });
    },
    [update]
  );

  useEffect(() => {
    if (isError) {
      dispatch(queueToast({ msg: t('global.error.generic') }));
    }
  }, [dispatch, isError, t]);

  return { update: updateInternal, isLoading, isSuccess };
};
