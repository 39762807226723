import { useCallback, useEffect, useMemo } from 'react';
import { NudgeGroup } from '@nfw/nudge/types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '../../routes';
import { useAppDispatch } from '../../store';
import { useErrorLimitGuard } from '../api/hooks';
import { PuffaError } from '../api/puffa-error';
import { queueToast } from '../app/actions';
import {
  useGetNudgeGroupQuery,
  useCreateNudgeGroupMutation,
  useUpdateNudgeGroupMutation,
  useArchiveNudgeGroupMutation,
} from './api';
import { getGroupNudgeLayout } from './forms';

/**
 * Hook for creating a nudge group.
 * Will redirect to the nudge group detail page on success and
 * redirect to error page after 3 retries.
 *
 * @param team - that will own the nudge group.
 *
 * @returns create nudge group function, loading state and error state
 */
export const useCreateNudgeGroup = (team: string) => {
  const [save, result] = useCreateNudgeGroupMutation();
  const { t } = useTranslation();
  useErrorLimitGuard(result.isError);
  const { navigate } = useNavigate();

  const createInternal = useCallback(
    (nudgeGroup: NudgeGroup) => {
      save({ nudgeGroup, team });
    },
    [save, team]
  );

  useEffect(() => {
    const { isSuccess, isError, data } = result;

    if (isSuccess || isError) {
      if (isSuccess) {
        navigate('/nudge-group/:team/:id', { id: data, team });
      }
    }
  }, [result, team, navigate, t]);

  return {
    create: createInternal,
    isLoading: result.isLoading,
    isError: result.isError,
  };
};

/**
 * Hook for updating a nudge group.
 * Will redirect to the nudge group detail page on success and
 * redirect to error page after 3 retries.
 *
 * @param id - the id of the nudge group to update.
 * @param team - the team that owns the nudge group.
 *
 * @returns update nudge group function, loading state and error state.
 */
export const useUpdateNudgeGroup = (id: string, team: string) => {
  const [update, result] = useUpdateNudgeGroupMutation();
  const { t } = useTranslation();
  useErrorLimitGuard(result.isError);
  const { navigate } = useNavigate();

  const updateInternal = useCallback(
    (nudgeGroup: NudgeGroup) => {
      update({ nudgeGroup, id, team });
    },
    [update, id, team]
  );

  useEffect(() => {
    const { isSuccess, isError } = result;

    if (isSuccess || isError) {
      if (isSuccess) {
        navigate('/nudge-group/:team/:id', { id, team });
      }
    }
  }, [result, id, navigate, t, team]);

  return {
    update: updateInternal,
    isLoading: result.isLoading,
    isError: result.isError,
  };
};

/**
 * Hook for getting a nudge group.
 *
 * @param id - the id of the nudge group to get.
 *
 * @returns the nudge group and the loading state.
 */
export const useGetNudgeGroup = (id: string) => {
  const { data, isError, isLoading } = useGetNudgeGroupQuery({ id });

  useEffect(() => {
    if (isError) {
      throw new PuffaError();
    }
  }, [isError]);

  return { nudgeGroup: data, isLoading };
};

/**
 * Hook for archiving a nudge group.
 *
 * @returns the archive function, error state and the reset function.
 */
export const useArchiveNudgeGroup = () => {
  const [archive, result] = useArchiveNudgeGroupMutation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { navigate } = useNavigate();
  const { isSuccess, isError, reset } = result;
  useErrorLimitGuard(isError);

  useEffect(() => {
    if (isSuccess) {
      dispatch(queueToast({ msg: t('global.nudge_group.archived') }));
      navigate('/home');
      reset();
    }
  }, [isSuccess, reset, navigate, dispatch, t]);

  const archiveInternal = useCallback(
    (id: string, team: string) => {
      archive({ id, team });
    },
    [archive]
  );

  return { archive: archiveInternal, isError, reset: result.reset };
};

/**
 * Get the nudge group layout configuration.
 *
 * @returns the layout configuration for a group nudge.
 */
export const useNudgeGroupLayout = () => {
  const { t } = useTranslation();

  return useMemo(() => getGroupNudgeLayout(t), [t]);
};
