/**
 * Appends all style tags from the document head to the iframe head.
 *
 * This function also sets up a MutationObserver to watch for new style tags.
 *
 * @param head - The head element of the iframe.
 *
 * @returns A function that disconnects the observer.
 */
export const appendStyleTags = (head: HTMLHeadElement) => {
  const nodes: HTMLElement[] = [
    ...Array.from(document.head.getElementsByTagName('style')),
    ...Array.from(document.head.getElementsByTagName('link')),
  ];

  nodes.forEach((node) => copyStyleTag(node, head));

  const observer = new MutationObserver((mutations) => {
    mutations.forEach(({ addedNodes }) => addedNodes.forEach((node) => copyStyleTag(node, head)));
  });

  observer.observe(document.head, { childList: true });

  return () => observer.disconnect();
};

const copyStyleTag = (node: Node, head: HTMLHeadElement) => {
  if (node instanceof HTMLStyleElement) {
    head.appendChild(node.cloneNode(true));
  } else if (node instanceof HTMLLinkElement && node.rel === 'stylesheet') {
    head.appendChild(node.cloneNode(true));
  }
};
