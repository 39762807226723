/* eslint-disable @typescript-eslint/no-this-alias */
import { Node } from '@nfw/linked-list';
import { Deployment, EnvironmentState, LocalizedDeploymentState, PublishState } from '../types';

export class EnvironmentNode extends Node<EnvironmentState> {
  /**
   * Find next node which match the deployment state.
   *
   * @param deployment - the deployment for which a node match.
   * @param withMostProgress - optional flag that when enabled only take the most progressed
   *                           publish state into account.
   *
   * @returns the environment node with the correct state, or undefined if no node was found.
   */
  public findNextWithState(
    deployment: Deployment,
    withMostProgress = false
  ): EnvironmentNode | undefined {
    return this.findNextBy((node) => node.isStateValid(deployment, withMostProgress));
  }

  /**
   * Check if the deployment state is valid for this vertex.
   *
   * @param deployment - the deployment.
   * @param withMostProgress - flag that when enabled only take the most progressed publish state
   *                           into account.
   *
   * @returns true when valid, otherwise false.
   */
  private isStateValid(deployment: Deployment, withMostProgress: boolean): boolean {
    const { cte, prod } = deployment;
    const { cte: cteState, prod: prodState } = this.getValue();

    return (
      EnvironmentNode.isEnvironmentValid(cteState, cte, withMostProgress) &&
      EnvironmentNode.isEnvironmentValid(prodState, prod, withMostProgress)
    );
  }

  /**
   * Check if the environment is valid.
   *
   * @param publishState - the publish state.
   * @param state - the localized current state.
   * @param withMostProgress - flag for if most progress should be used.
   *
   * @returns true when valid.
   */
  private static isEnvironmentValid(
    publishState: PublishState,
    state: LocalizedDeploymentState,
    withMostProgress = false
  ): boolean {
    const states = Object.values(state);
    const deciderFn = withMostProgress ? states.some.bind(states) : states.every.bind(states);

    switch (publishState) {
      case 'draft':
        return (
          states.length === 0 ||
          states.every(
            ({ state }) =>
              state !== 'experiment_created' && state !== 'experimenting' && state !== 'published'
          )
        );
      case 'experiment_created':
        return deciderFn(({ state }) => state === 'experiment_created');
      case 'experimenting':
        return deciderFn(({ state }) => state === 'experimenting');
      case 'published':
        return deciderFn(({ state }) => state === 'published');
      default:
        return false;
    }
  }
}
