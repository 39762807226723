import { useCallback, useState } from 'react';
import Button from '@ingka/button';
import { isNudgeGroup } from '@nfw/nudge/typeguard';
import { NudgeGroup } from '@nfw/nudge/types';
import { Form, FormCallback } from '@nfw/ui/form';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { useNudgeGroupLayout } from '../../features/nudgeGroups/hooks';
import FormFooter from '../FormFooter';
import './index.scss';

export type NudgeGroupFormProps = {
  onSave: (nudgeGroup: NudgeGroup) => void;
  saving?: boolean;
  defaultNudgeGroup?: NudgeGroup;
};

export const NudgeGroupForm: React.FC<NudgeGroupFormProps> = ({
  onSave,
  saving,
  defaultNudgeGroup,
}) => {
  const [value, setValue] = useState<Partial<NudgeGroup>>();

  const layout = useNudgeGroupLayout();
  const { t } = useTranslation();

  const onChangeInternal = useCallback<FormCallback<NudgeGroup>>(
    ({ value }) => {
      setValue(value);
    },
    [setValue]
  );

  const save = useCallback(() => {
    if (isNudgeGroup(value)) {
      onSave(value);
    }
  }, [onSave, value]);

  return (
    <div className="nudge-group-forms">
      <Form
        config={layout}
        onChange={onChangeInternal}
        validationFn={isNudgeGroup}
        purify={DOMPurify.sanitize}
        defaultFormValue={defaultNudgeGroup}
      />
      <FormFooter singleColumn>
        <Button
          type="primary"
          text={defaultNudgeGroup ? t('global.update') : t('global.create')}
          loading={saving}
          disabled={!isNudgeGroup(value)}
          onClick={save}
        />
      </FormFooter>
    </div>
  );
};

export default NudgeGroupForm;
