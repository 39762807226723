import {
  CampaignNudge,
  EmbeddedNudge,
  InlineMessageNudge,
  MessageNudge,
  MessageNudgeTrigger,
  ModalNudge,
  ModalNudgeTrigger,
  Nudge,
  OverlayNudge,
  PromptNudge,
  ReactionNudge,
  ReactionNudgeTrigger,
  TabNudge,
  TabNudgeTrigger,
  TeaserNudge,
  TipNudge,
  TipNudgeTrigger,
  ToastNudge,
  ToastNudgeTrigger,
} from '@nfw/nudge/types';
import {
  isArrayOfType,
  isBoolean,
  isNumber,
  isObject,
  isOptionallyDefined,
  isString,
} from '@nfw/utils';
import { isCondition } from './condition';
import { isDOMAnchors, isDOMTarget } from './dom';
import { isNudgeCampaignExperiment, isNudgeExperiment } from './experiment';
import { isPageMatcher } from './pagematcher';
import { isRepeatValue } from './repeat';
import { isSchedule } from './schedule';
import {
  isDOMEventTrigger,
  isDOMEventTriggerOfType,
  isExitIntentTrigger,
  isIdleTrigger,
  isInitTrigger,
  isRegExpMatcherTrigger,
  isNativeTrigger,
  isSearchParamTrigger,
  isCartAddTrigger,
  isCartRemoveTrigger,
  isFavouritesAddTrigger,
  isFavouritesRemoveTrigger,
  isStoreChangeTrigger,
  isElementInViewportTrigger,
  isEpisodEventTrigger,
} from './trigger';
import {
  isInlineMessageWidget,
  isMessageWidget,
  isModalWidget,
  isPromptWidget,
  isReactionWidget,
  isTabWidget,
  isTeaserWidget,
  isTipWidget,
  isToastWidget,
} from './widget';

/**
 * Check if a nudge has a certain widget type.
 *
 * @param nudge - the nudge to test.
 * @param widget - the widget.
 *
 * @returns true if the nudge is a base nudge.
 */
const isNudgeType = (nudge: unknown): nudge is Record<string, unknown> =>
  isObject(nudge) &&
  isString(nudge.id) &&
  isArrayOfType(nudge.conditions, isCondition) &&
  isRepeatValue(nudge.repeat) &&
  isOptionallyDefined(nudge.matcher, isPageMatcher) &&
  isOptionallyDefined(nudge.mandatory, isBoolean) &&
  isOptionallyDefined(nudge.experiment, isNudgeExperiment) &&
  isOptionallyDefined(nudge.blacklist, (value) => isArrayOfType(value, isString)) &&
  isOptionallyDefined(nudge.groupId, isString) &&
  isOptionallyDefined(nudge.schedule, isSchedule) &&
  isOptionallyDefined(nudge._visible, isBoolean) &&
  isOptionallyDefined(nudge._decided, isBoolean) &&
  isOptionallyDefined(nudge._deferred, isBoolean) &&
  isOptionallyDefined(nudge._weight, isNumber);

/**
 * Check if the nudge is a teaser nudge.
 *
 * @param nudge - the nudge to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isTeaserNudge = (nudge: unknown): nudge is TeaserNudge =>
  isNudgeType(nudge) && isArrayOfType(nudge.triggers, isInitTrigger) && isTeaserWidget(nudge);

/**
 * Check if the nudge is an inline message nudge.
 *
 * @param nudge - the nudge to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isInlineMessageNudge = (nudge: unknown): nudge is InlineMessageNudge =>
  isNudgeType(nudge) &&
  isArrayOfType(nudge.triggers, isInitTrigger) &&
  isInlineMessageWidget(nudge);

/**
 * Check if a the nudge is a tab nudge.
 *
 * @param nudge - the nudge to test.
 *
 * @returns true if the nudge is a tab nudge, otherwise false.
 */
export const isTabNudge = (nudge: unknown): nudge is TabNudge =>
  isNudgeType(nudge) &&
  isOptionallyDefined(nudge.anchors, isDOMAnchors) &&
  isArrayOfType(
    nudge.triggers,
    (value): value is TabNudgeTrigger =>
      isInitTrigger(value) ||
      isIdleTrigger(value) ||
      isSearchParamTrigger(value) ||
      isCartAddTrigger(value) ||
      isCartRemoveTrigger(value) ||
      isFavouritesAddTrigger(value) ||
      isFavouritesRemoveTrigger(value) ||
      isStoreChangeTrigger(value) ||
      isElementInViewportTrigger(value)
  ) &&
  isTabWidget(nudge);

/**
 * Check if the nudge is a reaction nudge.
 *
 * @param nudge - the nudge to test.
 *
 * @returns true if the nudge is a reaction nudge, otherwise false.
 */
export const isReactionNudge = (nudge: unknown): nudge is ReactionNudge =>
  isNudgeType(nudge) &&
  isArrayOfType(
    nudge.triggers,
    (value): value is ReactionNudgeTrigger =>
      isExitIntentTrigger(value) ||
      isDOMEventTrigger(value) ||
      isSearchParamTrigger(value) ||
      isCartAddTrigger(value) ||
      isEpisodEventTrigger(value) ||
      isCartRemoveTrigger(value) ||
      isFavouritesAddTrigger(value) ||
      isFavouritesRemoveTrigger(value)
  ) &&
  isOptionallyDefined(nudge.target, isDOMTarget) &&
  isReactionWidget(nudge);

/**
 * Check if the nudge is a valid tip nudge.
 *
 * @param nudge - the nudge to test.
 *
 * @returns true if the nudge is a tip nudge.
 */
export const isTipNudge = (nudge: unknown): nudge is TipNudge =>
  isNudgeType(nudge) &&
  isDOMTarget(nudge.target) &&
  isArrayOfType(
    nudge.triggers,
    (value): value is TipNudgeTrigger =>
      isDOMEventTriggerOfType(value, 'mouseover') || isStoreChangeTrigger(value)
  ) &&
  isTipWidget(nudge);

/**
 * Check if the nudge is a toast nudge.
 *
 * @param nudge - the nudge to test.
 *
 * @returns true if the nudge is a plain toast nudge, otherwise false.
 */
export const isToastNudge = (nudge: unknown): nudge is ToastNudge =>
  isNudgeType(nudge) &&
  isOptionallyDefined(nudge.target, isDOMTarget) &&
  isArrayOfType(
    nudge.triggers,
    (value): value is ToastNudgeTrigger =>
      isInitTrigger(value) ||
      isExitIntentTrigger(value) ||
      isEpisodEventTrigger(value) ||
      isIdleTrigger(value) ||
      isRegExpMatcherTrigger(value) ||
      isDOMEventTrigger(value) ||
      isSearchParamTrigger(value) ||
      isCartAddTrigger(value) ||
      isCartRemoveTrigger(value) ||
      isFavouritesAddTrigger(value) ||
      isFavouritesRemoveTrigger(value) ||
      isStoreChangeTrigger(value) ||
      isElementInViewportTrigger(value)
  ) &&
  isToastWidget(nudge);

/**
 * Check if the nudge is a message nudge.
 *
 * @param nudge - the nudge to test.
 *
 * @returns true if nudge is a message nudge.
 */
export const isMessageNudge = (nudge: unknown): nudge is MessageNudge =>
  isNudgeType(nudge) &&
  isDOMTarget(nudge.target) &&
  isArrayOfType(
    nudge.triggers,
    (value): value is MessageNudgeTrigger =>
      isInitTrigger(value) ||
      isIdleTrigger(value) ||
      isRegExpMatcherTrigger(value) ||
      isDOMEventTriggerOfType(value, 'click') ||
      isDOMEventTriggerOfType(value, 'focusin') ||
      isSearchParamTrigger(value) ||
      isCartAddTrigger(value) ||
      isCartRemoveTrigger(value) ||
      isFavouritesAddTrigger(value) ||
      isFavouritesRemoveTrigger(value) ||
      isStoreChangeTrigger(value) ||
      isElementInViewportTrigger(value)
  ) &&
  isMessageWidget(nudge);

/**
 * Check if the nudge is a modal nudge.
 *
 * @param nudge - the nudge to test.
 *
 * @returns true if the nudge is a modal nudge.
 */
export const isModalNudge = (nudge: unknown): nudge is ModalNudge =>
  isNudgeType(nudge) &&
  isArrayOfType(
    nudge.triggers,
    (value): value is ModalNudgeTrigger =>
      isInitTrigger(value) ||
      isExitIntentTrigger(value) ||
      isEpisodEventTrigger(value) ||
      isRegExpMatcherTrigger(value) ||
      isIdleTrigger(value) ||
      isNativeTrigger(value) ||
      isSearchParamTrigger(value) ||
      isCartAddTrigger(value) ||
      isCartRemoveTrigger(value) ||
      isFavouritesAddTrigger(value) ||
      isFavouritesRemoveTrigger(value) ||
      isStoreChangeTrigger(value) ||
      isElementInViewportTrigger(value)
  ) &&
  isModalWidget(nudge);

/**
 * Check if the nudge is a prompt nudge.
 *
 * @param nudge - the nudge to test.
 *
 * @returns true if the nudge is a prompt nudge.
 */
export const isPromptNudge = (nudge: unknown): nudge is PromptNudge =>
  isNudgeType(nudge) &&
  isArrayOfType(
    nudge.triggers,
    (value): value is ModalNudgeTrigger =>
      isInitTrigger(value) ||
      isExitIntentTrigger(value) ||
      isEpisodEventTrigger(value) ||
      isRegExpMatcherTrigger(value) ||
      isIdleTrigger(value) ||
      isNativeTrigger(value) ||
      isSearchParamTrigger(value) ||
      isCartAddTrigger(value) ||
      isCartRemoveTrigger(value) ||
      isFavouritesAddTrigger(value) ||
      isFavouritesRemoveTrigger(value) ||
      isStoreChangeTrigger(value) ||
      isElementInViewportTrigger(value)
  ) &&
  isPromptWidget(nudge);

/**
 * Check if a the nudge is an embedded nudge.
 *
 * @param nudge - the nudge to test.
 *
 * @returns true if the nudge is an embedded nudge, otherwise false.
 */
export const isEmbeddedNudge = (nudge: unknown): nudge is EmbeddedNudge =>
  isTeaserNudge(nudge) || isInlineMessageNudge(nudge);

/**
 * Check if a nudge is an overlay nudge.
 *
 * @param nudge - the nudge to test.
 *
 * @returns true when valid, otherwise false.
 */
export const isOverlayNudge = (nudge: unknown): nudge is OverlayNudge =>
  isToastNudge(nudge) ||
  isTipNudge(nudge) ||
  isTabNudge(nudge) ||
  isMessageNudge(nudge) ||
  isModalNudge(nudge) ||
  isReactionNudge(nudge) ||
  isPromptNudge(nudge);

/**
 * Check if the nudge is a valid nudge.
 *
 * @param nudge - the nudge to check.
 *
 * @returns true if the nudge is a nudge, otherwise false.
 */
export const isNudge = (nudge: unknown): nudge is Nudge =>
  isEmbeddedNudge(nudge) || isOverlayNudge(nudge);

/**
 * Check if the nudge is a campaign nudge.
 *
 * @param nudge - the nudge to test.
 *
 * @returns true if the nudge is a campaign nudge, otherwise false.
 */
export const isCampaignNudge = (nudge: unknown): nudge is CampaignNudge =>
  isNudge(nudge) && isNudgeCampaignExperiment(nudge.experiment) && isSchedule(nudge.schedule);
