import { NudgeExperimentType } from '@nfw/nudge/types';
import { Event, Scope, WinningDirection } from '@nfw/optimizely/types';
import { StoredWidget } from '@nfw/orchestration-types';

/**
 * Events list type collected by our nightly jobs. It maps the name of the project
 * to the events.
 */
export type ProjectEvents = {
  events: Event[];
  name: string;
};

/**
 * Representation of a default event, with the project name added.
 */
export type DefaultEvent = Event & { project_name: string };

/**
 * The visitor types.
 */
export const MeasureTypes = {
  UniqueConversions: 'unique_conversions',
  TotalConversions: 'total_conversions',
  TotalRevenue: 'total_revenue',
  TotalValue: 'total_value',
} as const;
export type MeasureType = (typeof MeasureTypes)[keyof typeof MeasureTypes];

/**
 * The metric configuration.
 */
export type MetricConfiguration = {
  direction: WinningDirection;
  measure: MeasureType;
  scope?: Scope;
};

/**
 * The default metric it is associated with an event id and can be governed.
 */
export type DefaultMetric = MetricConfiguration & {
  id: number;
  isGoverned?: boolean;
};

/**
 * A metrics record.
 */
export type Metrics = {
  metrics: DefaultMetric[];
};

type ExperimentRequestBase<T extends NudgeExperimentType> = Metrics & {
  /**
   * The type of experiment.
   */
  type: T;
  /**
   * Feature id.
   */
  orchestrationId: string;
  /**
   * Widget id.
   */
  widgetId: string;
};

/**
 * A request for a visibility experiment.
 */
export type VisibilityExperimentRequest = ExperimentRequestBase<'visibility'>;

/**
 * A request for a text variant experiment.
 */
export type DesignExperimentRequest = ExperimentRequestBase<'design'> & {
  variants: StoredWidget[];
};

export type CampaignExperimentRequest = ExperimentRequestBase<'campaign'> & {
  variants: StoredWidget[];
};

export type ExperimentRequest =
  | VisibilityExperimentRequest
  | DesignExperimentRequest
  | CampaignExperimentRequest;

/**
 * The ExperimentBase type.
 */
export type ExperimentBase<T = unknown> = {
  /** The team owning the experiment. */
  team: string;
  /** The flag key id in optimizely. */
  flagKey: string;
  /** The rule key in optimizely. */
  ruleKey: string;
  /** The core web vital event ids. */
  cwvEventIds?: number[];
  /** Flag for if an experiment is running. */
  isRunning: boolean;
  /** Who created the experiment. */
  creator?: string;
  /** If an reminder email has been sent. */
  isReminderEmailSent?: boolean;
  /** The report url. */
  reportUrl?: string;
  /** The start date of the experiment. */
  startDate?: number;
  /** The decision identifier. */
  decision?: T;
  /** Motivation for the taken decision. */
  motivation?: string;
};

/**
 * The text variant experiment.
 */
export type DesignExperiment = DesignExperimentRequest & ExperimentBase<string>;

/**
 * The visibility experiment.
 */
export type VisibilityExperiment = VisibilityExperimentRequest & ExperimentBase<boolean>;

/**
 * The campaign experiment.
 */
export type CampaignExperiment = CampaignExperimentRequest & ExperimentBase<undefined>;

/**
 * The experiment.
 */
export type Experiment = DesignExperiment | VisibilityExperiment | CampaignExperiment;

/**
 * The experiment result.
 */
export type ExperimentResult = {
  /** If the experiment was successful or not. */
  successful: boolean;
  /** When it was concluded. */
  concludedTimestamp: number;
  /** The type of experiment. */
  type: NudgeExperimentType;
  /** The url to the Optimizely report. */
  reportUrl: string;
  /** Motivation why the experiment was consider a success or not. */
  motivation?: string;
};

/**
 * The experiment result record.
 */
export type ExperimentResultRecord = {
  /** The experiment id. */
  id: string;
  /** The team conducting the experiment. */
  team: string;
  /** The experiment result. */
  results: ExperimentResult[];
};
