import { NudgeLogic } from './nudge';
import { Widget } from './widget';

/**
 * The different experiment types we provide.
 */
export const NudgeExperimentTypes = {
  /**
   * Experiment used by campaign nudges, typically a design
   */
  Campaign: 'campaign',
  /**
   * Experiment where the nudge design is replaced.
   */
  Design: 'design',
  /**
   * Experiment where parts of the nudge logic is replaced.
   */
  Logic: 'logic',
  /**
   * Experiment where the nudge is either shown or not shown.
   */
  Visibility: 'visibility',
} as const;
export type NudgeExperimentType = (typeof NudgeExperimentTypes)[keyof typeof NudgeExperimentTypes];

type NudgeExperimentBase<T extends NudgeExperimentType> = {
  type: T;
  id: string;
};

export type NudgeVisibilityExperiment = NudgeExperimentBase<'visibility'>;
export type NudgeDesignExperiment = NudgeExperimentBase<'design'> & {
  /**
   * The nudge widgets that can be applied.
   */
  variants: Widget[];
};
export type NudgeLogicExperiment = NudgeExperimentBase<'logic'> & {
  variant: Partial<NudgeLogic>;
};

export type NudgeCampaignExperiment = NudgeExperimentBase<'campaign'> & {
  /**
   * The nudge widgets that can be applied.
   */
  variants: Widget[];
};

/**
 * The nudge experiment type.
 */
export type NudgeExperiment =
  | NudgeVisibilityExperiment
  | NudgeDesignExperiment
  | NudgeLogicExperiment
  | NudgeCampaignExperiment;
