import { isDefined, isInteger, isNaturalNumber } from './typeguards';
import { Collection, DebounceType, FilterFn, TransformFn, UniqueFn } from './types';

/**
 * Identity function.
 *
 * @param value - the value.
 *
 * @returns the value given.
 */
export const identity = <T>(value: T) => value;

/**
 * Round a number to the desired number of decimals.
 *
 * @param value - the value to round.
 * @param decimals - the number of decimals.
 *
 * @returns the number rounded to the correct number of decimals.
 */
export const round = (value: number, decimals = 2) => {
  if (isInteger(value)) {
    return value;
  } else {
    const exp = Math.pow(10, Math.max(toInteger(decimals), 0));
    return Math.round(exp * value) / exp;
  }
};

/**
 * Create an integer from a number.
 *
 * @param value - the value.
 *
 * @returns the value as integer.
 */
export const toInteger = (value: number) => Math.trunc(value);

/**
 * Create a natural number.
 *
 * @param value - the value.
 *
 * @returns the value as a natural number.
 */
export const toNaturalNumber = (value: number) => Math.abs(toInteger(value));

/**
 * Transform to array.
 * Please note that when maps and sets are used order is not guaranteed.
 *
 * @param collection - the collection.
 * @param collections - the other collections to merge.
 *
 * @returns an array of the values stored.
 */
export const toArray = <T>(collection: Collection<T>, ...collections: Collection<T>[]) =>
  [collection, ...collections].reduce<T[]>(
    (acc, c) => acc.concat(...(c instanceof Map || c instanceof Set ? Array.from(c.values()) : c)),
    []
  );

/**
 * Check if an index is 0 or greater, and less than the length of the array.
 *
 * @param index - the index.
 * @param array - the array to check if index exists.
 *
 * @returns true if the array can be indexed, otherwise false.
 */
export const isIndexedBy = <T>(index: number, array: T[] | undefined | null): array is T[] =>
  isNaturalNumber(index) && isDefined(array) && index < array.length;

/**
 * Filter out duplicates from an array.
 *
 * @param collection - the collection to remove duplicates from.
 *
 * @returns the array with unique values.
 */
export const unique = <T>(collection: T[]) => uniqueBy(collection, identity);

/**
 * Filter out duplicates from a collection.
 *
 * @param collection - the collection to remove duplicates from.
 * @param fn - the value iteration function.
 *
 * @returns the array with unique values.
 */
export const uniqueBy = <T>(collection: Collection<T>, fn: UniqueFn<T>) =>
  toArray(collection).filter(
    (value, index, self) => self.findIndex((candidate) => fn(candidate) === fn(value)) === index
  );

/**
 * The difference by two sets (arrays).
 *
 * @param a - the first set.
 * @param b - the second test.
 *
 * @returns the elements that exists in a but not in b.
 */
export const difference = <T>(a: T[], b: T[]) => differenceBy(a, b, identity);

/**
 * The difference by two sets.
 *
 * @param a - the first set.
 * @param b - the second set.
 * @param fn - the value iteration function defining the uniqueness of the item.
 *
 * @returns the elements that exists in a but not in b.
 */
export const differenceBy = <T>(a: Collection<T>, b: Collection<T>, fn: UniqueFn<T>) => {
  const uniqueB = uniqueBy(b, fn);
  return uniqueBy(a, fn).filter((member) => uniqueB.every((m) => fn(member) !== fn(m)));
};

/**
 * The intersection of two sets (arrays).
 *
 * @param a - the first set.
 * @param b - the second set.
 *
 * @returns all elements included in both a and b.
 */
export const intersection = <T>(a: Collection<T>, b: Collection<T>) =>
  intersectionBy(a, b, identity);

/**
 * The intersection of two sets (arrays).
 *
 * @param a - the first set.
 * @param b - the second set.
 * @param fn - the value iteration function defining the uniqueness of the item.
 *
 * @returns all elements included in both a and b.
 */
export const intersectionBy = <T>(a: Collection<T>, b: Collection<T>, fn: UniqueFn<T>) => {
  const uniqueB = uniqueBy(b, fn);
  return uniqueBy(a, fn).filter((member) => uniqueB.some((m) => fn(m) === fn(member)));
};

/**
 * Check if two collections intersects.
 *
 * @param a - the first set.
 * @param b - the second set.
 *
 * @returns true when elements in a exists in b, otherwise false.
 */
export const intersects = <T>(a: Collection<T>, b: Collection<T>) => intersectsBy(a, b, identity);

/**
 * Check if two collections intersects.
 *
 * @param a - the first set.
 * @param b - the second set.
 * @param fn - the value iteration function defining the uniqueness of the item.
 *
 * @returns true when elements in a exists in b, otherwise false.
 */
export const intersectsBy = <T>(a: Collection<T>, b: Collection<T>, fn: UniqueFn<T>) =>
  intersectionBy(a, b, fn).length > 0;

/**
 * Check if an array contains the same elements.
 *
 * @param a - the first set.
 * @param b - the second set.
 *
 * @returns true when they contain the same elements, otherwise false.
 */
export const isEqualSet = <T>(a: T[], b: T[]) => isEqualSetBy(a, b, identity);

/**
 * Check if an array contains the same elements.
 *
 * @param a - the first set.
 * @param b - the second set.
 * @param fn - the value iteration function defining the uniqueness of the item.
 *
 * @returns true when they contain the same elements, otherwise false.
 */
export const isEqualSetBy = <T>(a: T[], b: T[], fn: UniqueFn<T>) =>
  b.length === a.length && intersectionBy(a, b, fn).length === a.length;

/**
 * Move an item in an array from a position to another position.
 *
 * @param array - the array to move item in.
 * @param from - the from index.
 * @param to - the to index.
 *
 * @returns the a new array with the items re-arranged.
 */
export const move = <T>(array: T[], from: number, to: number) => {
  const result = [...array];
  const [item] = result.splice(from, 1);

  if (isDefined(item)) {
    result.splice(to, 0, item);
  }
  return result;
};

/**
 * Create a map flow function. Each function is input to the next.
 * The transform functions are
 *
 * @param transforms - the transform functions to chain.
 *
 * @returns the map flow function.
 */
export const mapFlow =
  <T>(...transforms: TransformFn<T>[]) =>
  (value: T) =>
    transforms.reduce((acc, transform) => transform(acc), value);

/**
 * Create a filter flow function. Every filter functions need to pass.
 * The filters are ran in the order they are added.
 *
 * @param filters - the filter functions.
 *
 * @returns the filter function.
 */
export const filterFlow =
  <T>(...filters: FilterFn<T>[]) =>
  (value: T) =>
    filters.every((filter) => filter(value));

/**
 * Get a random positive integer from an interval.
 *
 * @param min - the min interval, must be a positive number.
 * @param max - the max interval, must be a positive number greater than the min value.
 *
 * @returns a random integer in interval [min, max].
 */
export const randomize = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

/**
 * Omit keys from an object.
 *
 * @param o - the object containing the props.
 * @param key - the keys to omit.
 *
 * @returns the values of the record without the omitted keys.
 */
export const omit = <T extends object, U extends keyof T>(o: T, ...keys: U[]) => {
  return Object.keys(o).reduce(
    (acc, key) => (keys.includes(key as U) ? acc : { ...acc, [key]: o[key] }),
    {} as Omit<T, U>
  );
};

/**
 * Pick keys from an object.
 *
 * @param o - the object to  pick from.
 * @param keys - the keys to pick.
 *
 * @returns a new object containing the picked items.
 */
export const pick = <T extends object, U extends keyof T>(o: T, ...keys: U[]) =>
  Object.keys(o).reduce(
    (acc, key) => (keys.includes(key as U) ? { ...acc, [key]: o[key] } : acc),
    {} as Pick<T, U>
  );

/**
 * Filter the object properties.
 *
 * @param object - the object to filter.
 * @param filterFn - the filter function to apply.
 *
 * @returns the filtered object.
 */
export const filterObject = <R extends string, S, T extends Record<R, S>>(
  object: T,
  filterFn: FilterFn<unknown>
) =>
  Object.entries(object)
    .filter(([_, value]) => filterFn(value))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {} as Partial<T>);

/**
 * Exclude values from a union type.
 *
 * @param o - the object containing representing the unit types.
 * @param values - the values to exclude.
 *
 * @returns the values of the record with
 */
export const exclude = <T extends string>(o: Record<string, T>, ...values: T[]) =>
  Object.values(o).filter((v) => !values.includes(v));

/**
 * Formats a DDDDD...N number to DDD DD DD ...N.
 *
 * @param number - the number to format.
 *
 * @returns formatted string.
 */
export const formatNumber = (number: string) => {
  let result = number.slice(0, 3);
  for (let i = 3; i < number.length; i += 2) {
    result += ` ${number.slice(i, i + 2)}`;
  }
  return result;
};

/**
 * A trim function.
 *
 * @param value - the value to trim.
 *
 * @returns the trim function.
 */
export const trimFn = (value: string) => value.trim();

/**
 * A leading or trailing debounce function.
 *
 * **Leading definition:** function is called when debounced function is called and then not called
 * until the timeout has expired.
 *
 * **Trailing definition:** function is called after the timeout has expired, provided that no new
 * calls are received.
 *
 * @param fn - the function to debounce.
 * @param timeout - the timeout time.
 * @param type - leading or trailing (default leading).
 *
 * @returns the function debounced.
 */
export const debounce = <T extends (...args: never[]) => unknown>(
  fn: T,
  timeout: number,
  type: DebounceType = 'leading'
) => {
  let timeoutId: NodeJS.Timeout | undefined;

  switch (type) {
    case 'trailing':
      return (...args: Parameters<T>) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
          fn(...args);
        }, timeout);
      };
    case 'leading': // Fall-through
    default:
      return (...args: Parameters<T>) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        } else {
          fn(...args);
        }

        timeoutId = setTimeout(() => {
          timeoutId = undefined;
        }, timeout);
      };
  }
};

/**
 * Restricts a function to be called only once - repeat calls will be ignored.
 *
 * @param fn - The function to restrict.
 *
 * @returns The new restricted function.
 */
export const once =
  <T extends (...args: never[]) => void>(fn: T) =>
  (...args: Parameters<T>) => {
    if (fn) {
      fn(...args);
      fn = undefined!;
    }
  };

/**
 * Get the directories of the pathname.
 *
 * @param path - the received path in the form /dir1/dir2/dir3/.
 * @param from - the from index.
 * @param to - the to index.
 *
 * @returns the directories of the location pathname.
 */
export const getPathDirectories = (path: string, from?: number, to?: number): string[] =>
  path
    .substring(1)
    .split('/')
    .slice(from, to)
    .filter((value) => value.length);

/**
 * FNV-1a hash function.
 * @see https://en.wikipedia.org/wiki/Fowler%E2%80%93Noll%E2%80%93Vo_hash_function
 *
 * @param id - the id to hash.
 *
 * @returns a 32-bit unsigned integer hash of the input string.
 */
const fnv1aHash = (id: string): number => {
  let hash = 2166136261;
  for (let i = 0; i < id.length; i++) {
    hash ^= id.charCodeAt(i);
    hash += (hash << 1) + (hash << 4) + (hash << 7) + (hash << 8) + (hash << 24);
  }
  return hash >>> 0;
};

/**
 * Generates a pseudo-random number between 0 and 1, consistently produced from the input string.
 *
 * @param id - the id to seed the random number generator with.
 *
 * @returns a pseudo-random number between 0 and 1.
 */
export const getSeededRandom = (id: string): number => {
  const seed = fnv1aHash(id);

  let x = seed;
  x ^= x << 13;
  x ^= x >> 17;
  x ^= x << 5;

  return (x >>> 0) / 4294967296;
};
