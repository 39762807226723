import React, { useCallback, useEffect, useState } from 'react';
import Button from '@ingka/button';
import Text from '@ingka/text';
import { Input } from '@nfw/form-types';
import { isWidget } from '@nfw/nudge/typeguard';
import { Widget } from '@nfw/nudge/types';
import {
  DescribedWidget,
  WithDescription,
  WithWidgetType,
  isDescribedWidget,
  isWithDescription,
  isWithWidgetType,
} from '@nfw/orchestration-types';
import { Form, FormCallback } from '@nfw/ui/form';
import { Translations } from '@nfw/widget-translation';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import {
  useWidgetTypeLayout,
  useWidgetLayout,
  useWidgetDescriptionLayout,
} from '../../features/widgets/hooks';
import FormFooter from '../FormFooter';
import './index.scss';

export type WidgetFormProps = {
  translations: Translations;
  team: string;
  loading?: boolean;
  submit: (widget: DescribedWidget) => void;
  onProgress: (page: number) => void;
  onInput?: (input: Input) => void;
  onWidget: (widget: Widget) => void;
  defaultWidget?: Partial<DescribedWidget>;
};

const WidgetForm: React.FC<WidgetFormProps> = ({
  loading,
  submit,
  onProgress,
  onInput,
  onWidget,
  translations,
  defaultWidget,
  team,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [description, setDescription] = useState<WithDescription>();
  const [widget, setWidget] = useState<Widget>();
  const [describedWidget, setDescribedWidget] = useState<Partial<DescribedWidget>>();
  const [widgetType, setWidgetType] = useState<WithWidgetType>();
  const [validDescription, setValidDescription] = useState(false);
  const [validWidgetType, setValidWidgetType] = useState(false);
  const [isValidWidget, setValidWidget] = useState(false);
  const { t } = useTranslation();

  const widgetTypeLayout = useWidgetTypeLayout(team);
  const widgetLayout = useWidgetLayout(translations, widgetType, team);
  const descriptionLayout = useWidgetDescriptionLayout();

  const handleBack = () => {
    setSelectedIndex(selectedIndex - 1);
  };

  const handleContinue = () => {
    setSelectedIndex(selectedIndex + 1);
  };

  const onDescriptionChangeInternal = useCallback<FormCallback<WithDescription>>(
    ({ input, value, valid }) => {
      setValidDescription(valid);
      setDescription(value);
      if (input) {
        const copy = { ...widget };
        onInput?.({ ...input, ...copy });
      }
    },
    [widget, onInput]
  );

  const onWidgetChangeInternal = useCallback<FormCallback<Widget>>(
    ({ value, input, valid }) => {
      setValidWidget(valid);
      setWidget(value);
      if (input) {
        onInput?.({ ...input, ...description, ...widgetType });
      }
    },
    [description, onInput, widgetType]
  );

  const onWidgetTypeChangeInternal = useCallback<FormCallback<WithWidgetType>>(
    ({ value, input, valid }) => {
      setValidWidgetType(valid);
      setWidgetType(value);
      if (input) {
        onInput?.({ ...input, ...description });
      }
    },
    [description, onInput]
  );

  const onSubmit = () => {
    if (isDescribedWidget(describedWidget)) {
      submit(describedWidget);
    }
  };

  useEffect(() => {
    onProgress(selectedIndex);
  }, [selectedIndex, onProgress]);

  useEffect(() => {
    if (widget) {
      onWidget(widget);
      if (widgetType) {
        setDescribedWidget({ ...widget });
      }
      if (description) {
        setDescribedWidget({ ...description, ...widget });
      }
    } else {
      setDescribedWidget(undefined);
    }
  }, [description, widget, widgetType, onWidget]);

  return (
    <div>
      <div className="form-div" style={{ display: selectedIndex === 0 ? 'block' : 'none' }}>
        <div className="header-div">
          <Text className="title" headingSize="s">
            {t('form.widget.description.header')}
          </Text>
          <hr className="title-hr" />
        </div>
        <Form
          config={descriptionLayout}
          onChange={onDescriptionChangeInternal}
          validationFn={isWithDescription}
          defaultFormValue={defaultWidget}
          purify={DOMPurify.sanitize}
        />
        <FormFooter>
          <Button
            type="primary"
            text={t('global.action.continue')}
            onClick={handleContinue}
            disabled={!validDescription}
          />
        </FormFooter>
      </div>
      <div className="form-div" style={{ display: selectedIndex === 1 ? 'block' : 'none' }}>
        <div className="header-div extra">
          <Text className="title" headingSize="s">
            {t('form.widget.skapa.header')}
          </Text>
          <hr className="title-hr" />
        </div>
        <Form
          config={widgetTypeLayout}
          onChange={onWidgetTypeChangeInternal}
          validationFn={isWithWidgetType}
          defaultFormValue={defaultWidget}
          purify={DOMPurify.sanitize}
        />
        <FormFooter>
          <Button
            type="secondary"
            style={{
              backgroundColor: '#fff',
            }}
            text={t('global.action.back')}
            onClick={handleBack}
          />
          <Button
            type="primary"
            text={t('global.action.continue')}
            onClick={handleContinue}
            disabled={!validWidgetType}
          />
        </FormFooter>
      </div>
      <div className="form-div" style={{ display: selectedIndex === 2 ? 'block' : 'none' }}>
        <div className="header-div extra">
          <Text className="title" headingSize="s">
            {t('form.widget.customize_header')}
          </Text>
          <hr className="title-hr" />
        </div>
        <Form
          config={widgetLayout}
          onChange={onWidgetChangeInternal}
          validationFn={isWidget}
          defaultFormValue={defaultWidget}
          purify={DOMPurify.sanitize}
        />
        <FormFooter>
          <Button
            type="secondary"
            style={{
              backgroundColor: '#fff',
            }}
            text={t('global.action.back')}
            onClick={handleBack}
          />
          <Button
            type="primary"
            text={defaultWidget ? t('global.update') : t('global.design.create')}
            onClick={onSubmit}
            loading={loading}
            disabled={!isDescribedWidget(describedWidget) || !isValidWidget}
          />
        </FormFooter>
      </div>
    </div>
  );
};

export default WidgetForm;
