import React from 'react';
import SSRIcon from '@ingka/ssr-icon';
import chevronLeft from '@ingka/ssr-icon/paths/chevron-left-small';
import Text from '@ingka/text';
import { useNavigate } from 'react-router-dom';
import { Route } from '../../routes/types';
import LinearLayout from '../LinearLayout';
import PuffaHyperlink from '../PuffaHyperlink';
import './index.scss';

export type Path = {
  name: string;
  to?: string;
};

export type BreacrumbProps = {
  crumbs: Path[];
};

const Breadcrumb: React.FC<BreacrumbProps> = ({ crumbs }) => {
  const navigate = useNavigate();

  return (
    <LinearLayout horizontal gap="xs" className="breadcrumb-container">
      {crumbs.reduce(
        (acc, { name, to }, index) => {
          if (index > 0) {
            acc.push(
              <div key={`separator${index}`}>
                <Text tagName="p">/</Text>
              </div>
            );
          }
          if (to) {
            acc.push(
              <Text key={index} className="breadcrumb-link">
                <PuffaHyperlink route={to as Route}>{name}</PuffaHyperlink>
              </Text>
            );
          } else {
            acc.push(
              <Text key={index} tagName="p">
                {name}
              </Text>
            );
          }

          return acc;
        },
        [
          <SSRIcon
            key={'icon'}
            className="icon"
            paths={chevronLeft}
            onClick={() => navigate('/')}
          />,
        ]
      )}
    </LinearLayout>
  );
};

export default Breadcrumb;
