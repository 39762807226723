import { TriggerType, WidgetType } from '@nfw/nudge/types';
import { NudgeTrigger, NudgeDOMEventNames } from './triggermaps';

/**
 * Get the nudge dom event names.
 *
 * @param widgetType - the widget type.
 *
 * @returns the matching dom event names for a certain trigger.
 */
export const getNudgeDOMEventNames = (widgetType: WidgetType) => NudgeDOMEventNames[widgetType];

/**
 * Get the trigger types for a certain widget type.
 *
 * @param widgetType - the widget type.
 * @param availableTriggers - the available triggers.
 *
 * @returns a list of trigger types which matches a certain widget.
 */
export const getNudgeTriggerTypes = (
  widgetType: WidgetType,
  availableTriggers: string[]
): TriggerType[] =>
  NudgeTrigger[widgetType].filter((triggerType) => availableTriggers.includes(triggerType));
