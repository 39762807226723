import React from 'react';
import Text from '@ingka/text';
import { isString } from '@nfw/utils';

export type PopupBodyProps = {
  body: string | React.ReactElement;
};

const PopupBody: React.FC<PopupBodyProps> = ({ body }) => {
  if (isString(body)) {
    return <Text bodySize="m">{body}</Text>;
  } else {
    return body;
  }
};

export default PopupBody;
