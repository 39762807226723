import { InputTypes, LayoutConfiguration, ValidationTypes } from '@nfw/form-types';
import { VisibilityTypes } from '@nfw/nudge/types';
import { TFunction } from 'i18next';
import { VisibilityExceptionLayout } from './VisibilityException';

export const VisibilityRuleLayout = (t: TFunction): LayoutConfiguration => [
  {
    inputType: InputTypes.PillListbox,
    name: 'when',
    label: t('form.feature.visibility.select'),
    btnLabel: t('global.choose_option'),
    includeSearch: false,
    options: Object.entries(VisibilityTypes).map(([name, value]) => ({ name: value, value: name })),
    validations: [
      {
        type: ValidationTypes.Required,
        errorMessage: t('form.general.action.type.error'),
      },
    ],
  },
  {
    inputType: InputTypes.ModalFormControl,
    editable: false,
    name: 'exceptions',
    label: t('form.feature.visibility.help.label'),
    helpMessage: t('form.feature.visibility.help'),
    configuration: VisibilityExceptionLayout(t),
    valueIdentifier: 'type',
    modalPrimaryActionText: t('form.general.select'),
    modalSecondaryActionText: t('global.action.cancel'),
  },
];
