import { useCallback, useState } from 'react';
import Button from '@ingka/button';
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal';
import { FrameworkConfiguration } from '@nfw/nudge/types';
import { useTranslation } from 'react-i18next';
import { queueToast } from '../../features/app/actions';
import { useUpdateFrameworkConfiguration } from '../../features/configurations/hooks';
import { useAppDispatch } from '../../store';
import FrameworkConfigurationForm from './ConfigurationForm';

export type UpdateFrameworkConfigModalProps = {
  open: boolean;
  onClose: (isUpdated: boolean) => void;
};

const UpdateFrameworkConfigModal: React.FC<UpdateFrameworkConfigModalProps> = ({
  open,
  onClose,
}) => {
  const [configuration, setConfiguration] = useState<FrameworkConfiguration>();
  const { update, isLoading, isSuccess } = useUpdateFrameworkConfiguration();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onCloseInternal = () => {
    setConfiguration(undefined);
    onClose(false);
  };

  const onSubmit = useCallback(() => {
    if (configuration) {
      update(configuration);
      if (isSuccess) {
        setConfiguration(undefined);

        dispatch(queueToast({ msg: t('form.configuration.saved') }));
      }
    }
    onClose(true);
  }, [configuration, update, isSuccess, onClose, dispatch, t]);

  return (
    <Modal visible={open} handleCloseBtn={onCloseInternal}>
      <Sheets
        header={<ModalHeader title={t('global.update_framework_config')} />}
        footer={
          <ModalFooter>
            <Button type="secondary" text={t('global.action.cancel')} onClick={onCloseInternal} />
            <Button
              type="primary"
              loading={isLoading}
              text={t('global.action.save')}
              onClick={onSubmit}
              disabled={!configuration}
            />
          </ModalFooter>
        }
      >
        <ModalBody>
          <FrameworkConfigurationForm onConfigurationChange={setConfiguration} />
        </ModalBody>
      </Sheets>
    </Modal>
  );
};
export default UpdateFrameworkConfigModal;
