import React from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import { animated, useTransition } from '@react-spring/web';
import './index.scss';

export interface LoadingScreenProps {
  visible?: boolean;
  text?: string;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ visible = false, text }) => {
  const transitions = useTransition(visible, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transitions(
    ({ opacity }, show) =>
      show && (
        <animated.div className="screen-loading-overlay" style={{ opacity }}>
          <Loading text={text ?? ''} labelTransitions labelPosition="center">
            <LoadingBall size="large" />
          </Loading>
        </animated.div>
      )
  );
};

export default LoadingScreen;
