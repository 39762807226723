import { useCallback, useEffect, useState } from 'react';
import Button from '@ingka/button';
import InlineMessage from '@ingka/inline-message';
import Text from '@ingka/text';
import { DefaultEvent, ExperimentRequest } from '@nfw/contracts/experiment';
import { toPath } from '@nfw/utils';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import EventList from '../../components/EventList';
import ExperimentForm from '../../components/ExperimentForm';
import FormFooter from '../../components/FormFooter';
import LinearLayout from '../../components/LinearLayout';
import SplitPage from '../../components/SplitPage';
import { queueToast } from '../../features/app/actions';
import {
  useExperimentation,
  useGetDefaultMetrics,
  useGetExperiment,
} from '../../features/experiments/hooks';
import { ExperimentMeta, isExperimentMeta } from '../../features/experiments/types';
import { useGetOrchestration } from '../../features/orchestrations/hooks';
import { InjectedViewPermissionsProps, withViewPermissions } from '../../hocs';
import { useNavigate } from '../../routes';
import { Routes } from '../../routes/types';
import { useAppDispatch } from '../../store';

const ExperimentPage: React.FC<InjectedViewPermissionsProps> = ({ id, team }) => {
  const [experiment, setExperiment] = useState<ExperimentRequest>();
  const [event, setEvent] = useState<DefaultEvent>();
  const [meta, setMeta] = useState<ExperimentMeta>();

  const { navigate, back, searchParams } = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { orchestration, isLoading } = useGetOrchestration(id);
  const { metrics: defaultMetrics = [], isLoading: isLoadingMetrics } = useGetDefaultMetrics(
    orchestration?.namespace,
    isLoading
  );

  const { experiment: experimentMetrics } = useGetExperiment(
    id,
    team,
    !(location.state === 'edit')
  );

  const { events, isMetricsError, refetch, save, isSaving } = useExperimentation((success) => {
    if (success && orchestration) {
      const { id, team } = orchestration;
      navigate('/nudge/:team/:id', { id, team }, { replace: true });
    }
  });

  useEffect(() => {
    const type = searchParams.get('type');
    const widgetId = searchParams.get('widgetId');

    const meta = { type, widgetId };
    if (isExperimentMeta(meta)) {
      setMeta(meta);
    } else {
      dispatch(queueToast({ msg: t('page.experiment.no_start') }));
      back();
    }
  }, [searchParams, back, dispatch, t]);

  const onSave = useCallback(() => {
    if (experiment && orchestration) {
      const { id, team } = orchestration;

      save({ id, team, experiment });
    }
  }, [experiment, orchestration, save]);

  return (
    <SplitPage
      loading={!events || !orchestration || isLoadingMetrics}
      side={
        orchestration &&
        meta &&
        (defaultMetrics || experimentMetrics) &&
        events && (
          <ExperimentForm
            meta={meta}
            event={event}
            events={events}
            defaultMetrics={experimentMetrics ? experimentMetrics.metrics : defaultMetrics}
            orchestration={orchestration}
            onExperiment={setExperiment}
          />
        )
      }
    >
      <LinearLayout gap="xl">
        <Breadcrumb
          crumbs={[
            { name: t('global.overview'), to: '/home' },
            {
              name: `#${id}`,
              to: toPath(Routes.ViewOrchestration, { id, team }),
            },
          ]}
        />
        <Text tagName="h1" bodySize="xl">
          {t('global.experiment.create')}
        </Text>
        {isMetricsError && (
          <InlineMessage
            variant="negative"
            body={t('global.error.generic')}
            actions={[
              {
                text: t('global.action.retry'),
                onClick: refetch,
              },
            ]}
          />
        )}
        <Text tagName="h2" headingSize="s">
          {t('page.nudge_details.optimizely_header')}
        </Text>
        <Text tagName="p">{t('page.nudge_details.optimizely_metrics')}</Text>
        {events && <EventList events={events} onSelect={setEvent} />}
        <FormFooter>
          <Button
            type="secondary"
            onClick={back}
            style={{
              backgroundColor: '#fff',
            }}
            text={t('global.action.back')}
            disabled={isSaving}
          />
          <Button
            type="primary"
            onClick={onSave}
            text={t('global.action.save')}
            loading={isSaving}
            disabled={!experiment}
          />
        </FormFooter>
      </LinearLayout>
    </SplitPage>
  );
};

export default withViewPermissions(ExperimentPage, true, 'PUBLISH');
