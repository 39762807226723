import { isObject, isString } from '@nfw/utils';

/**
 * The base error type denotes that the error comes from the app itself.
 */
export const BaseErrorTypes = {
  App: 'app',
} as const;
export type BaseErrorType = (typeof BaseErrorTypes)[keyof typeof BaseErrorTypes];

/**
 * The common error body for all express servers.
 */
export type ErrorBody<T extends string = string> = {
  status: number;
  method: string;
  path: string;
  type: T;
};

/**
 * Check if the body is an error body.
 *
 * @param body - the body to test.
 *
 * @returns true if the body is an error body.
 */
export const isErrorBody = (body: unknown): body is ErrorBody =>
  isObject(body) && isString(body.type) && isString(body.method) && isString(body.path);
