import React, { useEffect, useRef, useState } from 'react';
import ListBox from '@ingka/list-box';
import ListView, { ListViewItem } from '@ingka/list-view';
import Pill from '@ingka/pill';
import chevronDown from '@ingka/ssr-icon/paths/chevron-down-small';
import { updateFilter, updateSort } from '../../../../features/app/actions';
import { selectFilterValues, selectSortValue } from '../../../../features/app/selectors';
import { useAppDispatch, useMemoizedSelector } from '../../../../store';
import LinearLayout from '../../../LinearLayout';
import './index.scss';

export type SelectOption = {
  value: string;
  label: string;
};

export type SelectorProps = {
  id: string;
  type: string;
  label: string;
  options: SelectOption[];
  control?: 'checkbox' | 'radiobutton';
};

const Selector: React.FC<SelectorProps> = ({ id, type, label, options, control = 'checkbox' }) => {
  const [isOpen, setOpen] = useState(false);
  const selections = useMemoizedSelector(selectFilterValues, type, id);
  const sortValue = useMemoizedSelector(selectSortValue);

  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const element = ref.current;

    if (element && isOpen) {
      const cb = () => setOpen(false);
      element.addEventListener('mouseleave', cb, { passive: true });

      return () => element.removeEventListener('mouseleave', cb);
    }
  }, [ref, id, isOpen]);

  return (
    <LinearLayout gap="s" ref={ref} className="selector-container">
      <Pill
        size="small"
        label={label}
        selected={isOpen}
        ssrIcon={chevronDown}
        iconPosition="trailing"
        onClick={(event) => {
          event.stopPropagation();
          setOpen(!isOpen);
        }}
      />
      <div>
        <ListBox open={isOpen} className="selections">
          <ListView id={id} showDivider={false} size="small">
            {options.map(({ label, value }, index) => (
              <ListViewItem
                key={index}
                inset
                title={label}
                control={control}
                controlProps={{
                  value,
                  checked:
                    control === 'checkbox'
                      ? selections.some(({ values }) => values.includes(value))
                      : sortValue?.value === value,
                }}
                onChange={(event) => {
                  if (event.currentTarget instanceof HTMLInputElement) {
                    if (control === 'checkbox') {
                      dispatch(
                        updateFilter(event.currentTarget.checked, { which: type, id, value })
                      );
                    } else if (control === 'radiobutton') {
                      dispatch(updateSort({ which: type, value }));
                    }
                  }
                }}
              />
            ))}
          </ListView>
        </ListBox>
      </div>
    </LinearLayout>
  );
};

export default Selector;
