import { DOMTargetType } from './dom';

export const TriggerTypes = {
  DomEvent: 'dom_event',
  EpisodEvent: 'episod_event',
  Idle: 'idle',
  Init: 'init',
  RegExpMatcher: 'regexp_matcher',
  ExitIntent: 'exit_intent',
  CartItemsAdded: 'cart_add',
  CartItemsRemoved: 'cart_remove',
  FavouritesAdded: 'favourite_add',
  FavouritesRemoved: 'favourite_remove',
  Native: 'native',
  SearchParam: 'search_param',
  StoreChange: 'store_change',
  ElementInViewport: 'element_in_viewport',
} as const;
export type TriggerType = (typeof TriggerTypes)[keyof typeof TriggerTypes];

export const MaxDelayTime = 30;

/**
 * The supported trigger event types.
 */
export const DOMEventNames = {
  Click: 'click',
  Focus: 'focusin',
  Mouseover: 'mouseover',
} as const;
export type DOMEventName = (typeof DOMEventNames)[keyof typeof DOMEventNames];

type TriggerBase<T extends TriggerType> = {
  type: T;
  delay?: number;
};

type WithRegExps = {
  regExps: string[];
};

type WithDOMEvent<T extends DOMEventName> = {
  domEventName: T;
};

type WithElementAnchor = {
  value: string;
  targetType: DOMTargetType;
};

type WithEpisodEvent = {
  category: string;
  action: string;
};

/**
 * Init trigger.
 */
export type InitTrigger = TriggerBase<'init'>;

/**
 * The exit intent trigger fired when mouse leaves the screen.
 */
export type ExitIntentTrigger = TriggerBase<'exit_intent'>;

/**
 * Triggered when a dom event (click, focus or mouseover) is fired.
 */
export type DOMEventTrigger<T extends DOMEventName> = TriggerBase<'dom_event'> & WithDOMEvent<T>;

/**
 * Triggered when app has been in idle state for a certain amount of time.
 */
export type IdleTrigger = TriggerBase<'idle'>;

/**
 * Triggered for certain text inputs
 */
export type RegExpMatcherTrigger = TriggerBase<'regexp_matcher'> & WithRegExps;

export type NativeTrigger = TriggerBase<'native'>;

export type SearchParamTrigger = TriggerBase<'search_param'>;

export type CartAddTrigger = TriggerBase<'cart_add'>;
export type CartRemoveTrigger = TriggerBase<'cart_remove'>;

export type FavouriteAddTrigger = TriggerBase<'favourite_add'>;
export type FavouriteRemoveTrigger = TriggerBase<'favourite_remove'>;

export type StoreChangeTrigger = TriggerBase<'store_change'>;

export type ElementInViewportTrigger = TriggerBase<'element_in_viewport'> & WithElementAnchor;

export type EpisodEventTrigger = TriggerBase<'episod_event'> & WithEpisodEvent;

export type Trigger =
  | InitTrigger
  | EpisodEventTrigger
  | ExitIntentTrigger
  | DOMEventTrigger<DOMEventName>
  | IdleTrigger
  | RegExpMatcherTrigger
  | CartAddTrigger
  | CartRemoveTrigger
  | FavouriteAddTrigger
  | FavouriteRemoveTrigger
  | NativeTrigger
  | SearchParamTrigger
  | StoreChangeTrigger
  | ElementInViewportTrigger;
