import React, { useEffect, useState } from 'react';
import Text from '@ingka/text';
import { getEnvironmentState } from '@nfw/deployment-types';
import { Widget } from '@nfw/nudge/types';
import { toPath } from '@nfw/utils';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../components/Breadcrumb';
import DesignPage from '../../components/DesignPage';
import LinearLayout from '../../components/LinearLayout';
import OrchestrationForm from '../../components/OrchestrationForm';
import ProgressIndicator from '../../components/ProgressIndicator';
import WidgetPreview from '../../components/WidgetPreview';
import { PuffaError } from '../../features/api/puffa-error';
import { useGetTeamTranslations } from '../../features/i18n/hooks';
import { selectActiveTeamNudgeGroups } from '../../features/nudgeGroups/selectors';
import {
  useGetDeployment,
  useGetOrchestration,
  useUpdate,
} from '../../features/orchestrations/hooks';
import { selectActiveTeamWidgets } from '../../features/widgets/selectors';
import { InjectedViewPermissionsProps, withViewPermissions } from '../../hocs';
import { useNavigate } from '../../routes';
import { Routes } from '../../routes/types';
import { useMemoizedSelector } from '../../store';

const EditOrchestrationPage: React.FC<InjectedViewPermissionsProps> = ({
  id,
  team,
  retailUnits,
  permissions,
}) => {
  const [progress, setProgress] = useState(0);
  const [selectedWidget, setSelectedWidget] = useState<Widget>();
  const widgets = useMemoizedSelector(selectActiveTeamWidgets, team);
  const nudgeGroups = useMemoizedSelector(selectActiveTeamNudgeGroups, team);

  const { deployment } = useGetDeployment(id);

  const { t } = useTranslation();
  const { navigate } = useNavigate();
  const { translations, isLoading: isLoadingTranslations } = useGetTeamTranslations(team);
  const { orchestration: defaultOrchestration, isLoading: isLoadingOrchestration } =
    useGetOrchestration(id);

  useEffect(() => {
    if (deployment) {
      const state = getEnvironmentState(deployment);

      if (!state.isEditable(permissions)) {
        navigate('/home');
      }
    }
  }, [deployment, navigate, permissions]);

  const { update, isLoading } = useUpdate(team, id);

  useEffect(() => {
    if (!widgets) {
      throw new PuffaError('Failed to load the nudge designs');
    }
  }, [widgets]);

  return (
    <DesignPage
      compact
      loading={isLoadingOrchestration || isLoadingTranslations}
      designOnly
      designComponent={<WidgetPreview widget={selectedWidget} translations={translations} />}
    >
      <LinearLayout gap="l">
        {widgets && defaultOrchestration && (
          <>
            <Breadcrumb
              crumbs={[
                { name: t('global.overview'), to: '/?tab=features' },
                { name: `#${id}`, to: toPath(Routes.ViewOrchestration, { id, team }) },
              ]}
            />
            <Text headingSize="m">{t('global.nudge.edit')}</Text>
            <ProgressIndicator
              currentPage={progress + 1}
              steps={[
                t('component.progress_indicator.nudge_first'),
                t('component.progress_indicator.nudge_second'),
              ]}
            />
            <OrchestrationForm
              team={team}
              retailUnits={retailUnits}
              widgets={widgets}
              nudgeGroups={nudgeGroups}
              saving={isLoading}
              onSave={update}
              onProgress={setProgress}
              onWidgetSelected={setSelectedWidget}
              defaultFeatureOrchestration={defaultOrchestration}
              defaultOrchestrationId={`${defaultOrchestration.id}-${defaultOrchestration.featureNudge.widgetRef}`}
            />
          </>
        )}
      </LinearLayout>
    </DesignPage>
  );
};

export default withViewPermissions(EditOrchestrationPage, true, 'EDIT');
