import Text from '@ingka/text';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './index.scss';

export type NudgeGroupStateIndicatorProps = {
  isArchived: boolean;
  nudgeGroupCount?: number;
  detailed?: boolean;
};

const NudgeGroupStateIndicator: React.FC<NudgeGroupStateIndicatorProps> = ({
  isArchived,
  nudgeGroupCount,
  detailed,
}) => {
  const { t } = useTranslation();

  const getStatusDetails = () => {
    if (isArchived) {
      return {
        title: t('global.state.archived'),
        content: t('component.nudge_group_state.archive_description'),
        styling: 'archived',
      };
    } else if (nudgeGroupCount === 0) {
      return {
        title: t('global.state.empty'),
        content: t('component.nudge_group_state.empty_description'),
        styling: 'draft',
      };
    } else {
      return {
        title: t('global.state.active'),
        content: t('component.nudge_group_state.active_description'),
        styling: 'active',
      };
    }
  };

  const { title, content, styling } = getStatusDetails();

  return (
    <div className={classNames('status-content', { detailed }, styling)}>
      <div className={classNames('circle', styling)} />
      <div className="text-div">
        <Text bodySize="m" className={classNames('title', { detailed })}>
          {title}
        </Text>
        {detailed && <Text bodySize="m">{content}</Text>}
      </div>
    </div>
  );
};

export default NudgeGroupStateIndicator;
