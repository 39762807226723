import {
  JiraData,
  Routes,
  TeamConfiguration,
  TeamRequest,
  TeamStatus,
} from '@nfw/contracts/self-service';
import { toPath } from '@nfw/utils';
import { api } from '../api';
import { TeamArg } from '../api/types';

export const teamApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTeam: builder.query<TeamConfiguration, TeamArg>({
      query: ({ team }) => toPath(Routes.TeamConfiguration, { team }),
      providesTags: (_, __, { team }) => [{ type: 'team', id: team }],
    }),
    getTeams: builder.query<TeamConfiguration[], void>({
      query: () => Routes.TeamConfigurations,
      providesTags: (teamConfigurations) =>
        teamConfigurations
          ? teamConfigurations.map((team) => ({
              type: 'team',
              id: team.id,
            }))
          : [{ type: 'team', id: 'all' }],
    }),

    requestTeam: builder.mutation<JiraData, TeamRequest>({
      query: (request) => ({
        url: Routes.TeamConfigurations,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: [{ type: 'team', id: 'all' }],
    }),
    deleteTeam: builder.mutation<void, TeamArg>({
      query: ({ team }) => ({
        url: toPath(Routes.TeamConfiguration, { team }),
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'team', id: 'all' }, { type: 'permission' }],
    }),
    updateTeam: builder.mutation<void, TeamConfiguration>({
      query: (config) => ({
        url: toPath(Routes.TeamConfiguration, { team: config.id }),
        method: 'PUT',
        body: config,
      }),
      invalidatesTags: (_, __, config) => [{ type: 'team', id: config.id }, { type: 'permission' }],
    }),
    updateTeamStatus: builder.mutation<void, TeamArg & { status: TeamStatus }>({
      query: ({ team, status }) => ({
        url: toPath(Routes.TeamStatus, { team, status }),
        method: 'PUT',
      }),
      invalidatesTags: (_, __, { team }) => [{ type: 'team', id: team }, { type: 'permission' }],
    }),
  }),
});

export const {
  useGetTeamQuery,
  useGetTeamsQuery,
  useRequestTeamMutation,
  useDeleteTeamMutation,
  useUpdateTeamMutation,
  useUpdateTeamStatusMutation,
} = teamApi;
