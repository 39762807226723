import {
  RepeatAlwaysValue,
  RepeatLifetimeValue,
  RepeatPageValue,
  RepeatSessionValue,
  RepeatType,
  RepeatTypes,
  RepeatValue,
  WithPageInterval,
} from '@nfw/nudge/types';
import { isBoolean, isNaturalNumber, isObject, isOptionallyDefined } from '@nfw/utils';

/**
 * Check if the repeat value is of a certain repeat type.
 *
 * @param value - the repeat value to test.
 * @param type - the repeat type.
 *
 * @returns true if the base repeat value is of the expected type, otherwise false.
 */
const isBaseRepeatValue = (repeat: unknown, type: RepeatType): repeat is Record<string, unknown> =>
  isObject(repeat) && repeat.type === type;

/**
 * Check if the type is a valid repeat type.
 *
 * @param type - the type to check.
 *
 * @returns true if valid, otherwise false.
 */
export const isRepeatType = (type: unknown): type is RepeatType =>
  Object.values(RepeatTypes).includes(type as RepeatType);

/**
 * Check if the page period is a valid page period type.
 *
 * @param p - the type to check.
 *
 * @returns true if valid, otherwise false.
 */
export const isWithPageInterval = (p: unknown): p is WithPageInterval =>
  isObject(p) && isNaturalNumber(p.max) && isNaturalNumber(p.interval) && isBoolean(p.initShow);

/**
 * Check if a repeat value is a valid always repeat value.
 *
 * @param value - the repeat value to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isRepeatAlwaysValue = (value: unknown): value is RepeatAlwaysValue =>
  isBaseRepeatValue(value, RepeatTypes.Always);

/**
 * Check if a repeat value is a valid page repeat value.
 *
 * @param value - the repeat value to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isRepeatPageValue = (value: unknown): value is RepeatPageValue =>
  isBaseRepeatValue(value, RepeatTypes.Page) && isNaturalNumber(value.count);

/**
 * Check if a repeat value is a valid page repeat value.
 *
 * @param value - the repeat value to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isRepeatSessionValue = (value: unknown): value is RepeatSessionValue =>
  isBaseRepeatValue(value, RepeatTypes.Session) &&
  isNaturalNumber(value.count) &&
  isOptionallyDefined(value.page, isWithPageInterval);

/**
 * Check if a repeat value is a valid lifetime repeat value.
 *
 * @param value - the repeat value to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isRepeatLifetimeValue = (value: unknown): value is RepeatLifetimeValue =>
  isBaseRepeatValue(value, RepeatTypes.Lifetime) &&
  isNaturalNumber(value.count) &&
  isOptionallyDefined(value.page, isWithPageInterval);

/**
 * Check if a repeat value is valid.
 *
 * @param value - the repeat value to test.
 *
 * @returns true if valid, otherwise false.
 */
export const isRepeatValue = (value: unknown): value is RepeatValue =>
  isRepeatAlwaysValue(value) ||
  isRepeatPageValue(value) ||
  isRepeatSessionValue(value) ||
  isRepeatLifetimeValue(value);
