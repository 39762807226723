import { isNaturalNumber, isObject, isOptionallyDefined } from '@nfw/utils';

export const Aggregators = {
  Unique: 'unique',
  Count: 'count',
  Sum: 'sum',
} as const;
export type Aggregator = (typeof Aggregators)[keyof typeof Aggregators];

export const Fields = {
  Revenue: 'revenue',
  Value: 'value',
} as const;
export type Field = (typeof Fields)[keyof typeof Fields];

export const Scopes = {
  Session: 'session',
  Visitor: 'visitor',
  Event: 'event',
} as const;
export type Scope = (typeof Scopes)[keyof typeof Scopes];

export const WinningDirections = {
  Increasing: 'increasing',
  Decreasing: 'decreasing',
} as const;
export type WinningDirection = (typeof WinningDirections)[keyof typeof WinningDirections];

/**
 * The optimizely metric type.
 * {@link https://library.optimizely.com/docs/api/app/v2/index.html#tag/Metrics}
 */
export type Metric = {
  aggregator?: Aggregator;
  event_id: number;
  field?: Field;
  scope?: Scope;
  winning_direction?: WinningDirection;
};

/**
 * Check if an aggregator is valid.
 *
 * @param a - the aggregator to check.
 *
 * @returns true when valid, otherwise false.
 */
export const isAggregator = (a: unknown): a is Aggregator =>
  Object.values(Aggregators).includes(a as Aggregator);

/**
 * Check if a field is valid.
 *
 * @param f - the aggregator to check.
 *
 * @returns true when valid, otherwise false.
 */
export const isField = (f: unknown): f is Field => Object.values(Fields).includes(f as Field);

/**
 * Check if a scope is valid.
 *
 * @param s - the scope to check.
 *
 * @returns true when valid, otherwise false.
 */
export const isScope = (s: unknown): s is Scope => Object.values(Scopes).includes(s as Scope);

/**
 * Check if a winning direction is valid.
 *
 * @param w - the winning direction to check.
 *
 * @returns true when valid, otherwise false.
 */
export const isWinningDirection = (w: unknown): w is WinningDirection =>
  Object.values(WinningDirections).includes(w as WinningDirection);

/**
 * Check if a metric is valid.
 *
 * @param m - the metric to check.
 *
 * @returns true when valid, otherwise false.
 */
export const isMetric = (m: unknown): m is Metric =>
  isObject(m) &&
  isNaturalNumber(m.event_id) &&
  isOptionallyDefined(m.aggregator, isAggregator) &&
  isOptionallyDefined(m.field, isField) &&
  isOptionallyDefined(m.scope, isScope) &&
  isOptionallyDefined(m.winning_direction, isWinningDirection);
