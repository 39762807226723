import Hyperlink from '@ingka/hyperlink';
import Modal, { ModalHeader, Theatre } from '@ingka/modal';
import Table, { TableBody, TableHeader } from '@ingka/table';
import Text from '@ingka/text';
import { toDateString } from '@nfw/utils';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useGetExperimentResults } from '../../features/experiments/hooks';

export type ExperimentResultTheatreProps = {
  visible: boolean;
  close: () => void;
  id?: string;
  skip?: boolean;
};

const ExperimentResultTheatre: React.FC<ExperimentResultTheatreProps> = ({
  visible,
  close,
  id,
  skip,
}) => {
  const { t } = useTranslation();
  const { result } = useGetExperimentResults(id, skip);

  return createPortal(
    <Modal visible={visible} handleCloseBtn={close}>
      <Theatre header={<ModalHeader title={t('component.experiment_result.table_header')} />}>
        <Table fullWidth inset>
          <TableHeader>
            <tr key="experiment-result-header">
              <td>
                <Text headingSize="s">{t('global.id')}</Text>
              </td>
              <td>
                <Text headingSize="s">{t('global.team')}</Text>
              </td>
              <td>
                <Text headingSize="s">{t('component.experiment_result.type')}</Text>
              </td>
              <td>
                <Text headingSize="s">{t('component.experiment_result.success_status')}</Text>
              </td>
              <td>
                <Text headingSize="s">{t('component.experiment_result.motivation')}</Text>
              </td>
              <td>
                <Text headingSize="s">{t('component.experiment_result.concluded')}</Text>
              </td>
              <td>
                <Text headingSize="s">{t('component.experiment_result.report_url')}</Text>
              </td>
            </tr>
          </TableHeader>
          <TableBody striped>
            {result?.flatMap(({ team, results, id }) =>
              results.map(
                ({ type, concludedTimestamp, successful, reportUrl, motivation }, index) => (
                  <tr key={`${id}-${index}`}>
                    <td>{id}</td>
                    <td>{team}</td>
                    <td>{type}</td>
                    <td>
                      {successful
                        ? t('component.experiment_result.success')
                        : t('component.experiment_result.unsuccessful')}
                    </td>
                    <td>{motivation}</td>
                    <td>{toDateString(concludedTimestamp, true)}</td>
                    <td>
                      {reportUrl.startsWith('https') ? (
                        <Hyperlink newWindow url={reportUrl}>
                          {reportUrl}
                        </Hyperlink>
                      ) : (
                        reportUrl
                      )}
                    </td>
                  </tr>
                )
              )
            )}
          </TableBody>
        </Table>
      </Theatre>
    </Modal>,
    document.body
  );
};

export default ExperimentResultTheatre;
