import Button from '@ingka/button';
import ListView, { ListViewItem } from '@ingka/list-view';
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal';
import Text from '@ingka/text';
import { StoredFeatureOrchestration } from '@nfw/orchestration-types';
import { useTranslation } from 'react-i18next';
import { useListViewLinks, useNavigate } from '../../routes';

export type InUseModalProps = {
  title?: string;
  visible: boolean;
  close: () => void;
  text: string;
  features: StoredFeatureOrchestration[];
};

export const InUseModal: React.FC<InUseModalProps> = ({
  title,
  features,
  visible,
  close,
  text,
}) => {
  const { t } = useTranslation();
  const { toAppPath } = useNavigate();
  const { listViewOnClickHandler } = useListViewLinks();

  return (
    <Modal visible={visible} handleCloseBtn={close}>
      <Sheets
        footer={
          <ModalFooter>
            <Button type="primary" text={t('global.action.back')} onClick={close} />
          </ModalFooter>
        }
        size="small"
        header={<ModalHeader ariaCloseTxt={t('global.close')} />}
      >
        <ModalBody>
          <Text tagName="h1" bodySize="xl" className="header">
            {title}
          </Text>
          <Text className="description">{text}</Text>
          <ListView id="widgets" onClick={listViewOnClickHandler}>
            {features.map(({ id, team, name }) => {
              return (
                <ListViewItem
                  id={id}
                  key={id}
                  control="link"
                  inset
                  title={<b>{name}</b>}
                  description={`#${id}`}
                  href={toAppPath('/nudge/:team/:id', { id, team })}
                />
              );
            })}
          </ListView>
        </ModalBody>
      </Sheets>
    </Modal>
  );
};
