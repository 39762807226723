import React, { useEffect } from 'react';
import { RetailUnit } from '@nfw/ikea/retail';
import { UserPermission } from '@nfw/permissions';
import { useTranslation } from 'react-i18next';
import LoadingScreen from '../components/LoadingScreen';
import { queueToast } from '../features/app/actions';
import { usePermission, useTeamPermission } from '../features/auth/hooks';
import { useResourceLock } from '../features/resourcelocks/hooks';
import { useNavigate } from '../routes';
import { useAppDispatch } from '../store';

export type InjectedViewPermissionsProps = {
  id: string;
  team: string;
  retailUnits: RetailUnit[];
  permissions: UserPermission[];
  isEditAllowed: boolean;
  isPublishAllowed: boolean;
};

/**
 * Higher order component for viewing a component.
 * It is required that the path contains both team and id of the item to view.
 *
 * @param Component - the component to view.
 * @param isLockRequired - flag for if a resource lock is required to stay on page.
 * @param permission - the optional enforced permission.
 *                   If set the user will not be authenticated to access if permissions does not satisfy.
 *
 * @returns the component with injected view props.
 */
export const withViewPermissions = <P extends InjectedViewPermissionsProps>(
  Component: React.ComponentType<P>,
  isLockRequired: boolean,
  permission?: UserPermission
) => {
  const Wrapper: React.FC<Omit<P, keyof InjectedViewPermissionsProps>> = (props) => {
    const { isLoading, lockedPermissions, setResourceId, setTeam, team, resourceId, retailUnits } =
      useResourceLock(isLockRequired);

    const { params, navigate } = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
      const { id, team } = params;

      if (id && team) {
        setTeam(team);
        setResourceId(id);
      } else {
        navigate('/home');
      }
    }, [params, navigate, setTeam, setResourceId]);

    useEffect(() => {
      if (!isLoading && permission && !lockedPermissions.includes(permission)) {
        dispatch(queueToast({ msg: t('global.unallowed') }));
        navigate('/home');
      }
    }, [lockedPermissions, dispatch, navigate, isLoading, t]);

    return (
      <>
        <LoadingScreen visible={isLoading} />
        {resourceId && team && !isLoading && (
          <Component
            {...(props as P)}
            id={resourceId}
            team={team}
            permissions={lockedPermissions}
            isEditAllowed={lockedPermissions.includes('EDIT')}
            isPublishAllowed={lockedPermissions.includes('PUBLISH')}
            retailUnits={retailUnits}
          />
        )}
      </>
    );
  };
  return Wrapper;
};

export type InjectedTeamProps = {
  team: string;
  retailUnits: RetailUnit[];
  isEditAllowed: boolean;
  isPublishAllowed: boolean;
  presetWidgetId?: string;
};

/**
 * Higher order component used to allow/disallow users from certain urls.
 *
 * @param Component - the component to access.
 * @param permission - the permission that needs to be granted.
 *
 * @returns the component with injected team props.
 */
export const withRoutedTeamPermissions = <P extends InjectedTeamProps>(
  Component: React.ComponentType<P>,
  permission: UserPermission
) => {
  const Wrapper: React.FC<Omit<P, keyof InjectedTeamProps>> = (props) => {
    const { setTeam, isInitialised, permissions, retailUnits } = useTeamPermission();
    const { navigate, params } = useNavigate();
    const dispatch = useAppDispatch();
    const { team, widgetId } = params;
    const { t } = useTranslation();

    useEffect(() => {
      if (team) {
        setTeam(team);
      }
    }, [team, setTeam]);

    useEffect(() => {
      if (isInitialised && permissions && !permissions.includes(permission)) {
        dispatch(queueToast({ msg: t('global.unallowed') }));
        navigate('/home');
      }
    }, [isInitialised, permissions, navigate, dispatch, t]);

    return (
      <>
        <LoadingScreen visible={!isInitialised} />
        {isInitialised && team && permissions && (
          <Component
            {...(props as P)}
            team={team}
            retailUnits={retailUnits}
            isPublishAllowed={permissions.includes('PUBLISH')}
            isEditAllowed={permissions.includes('EDIT')}
            presetWidgetId={widgetId}
          />
        )}
      </>
    );
  };
  return Wrapper;
};

export type InjectedTeamAdminProps = {
  teams: string[];
  isTeamSuperAdmin: boolean;
};

/**
 * Higher order component used grant access to team admins.
 *
 * @param Component - the component to access.
 *
 * @returns the component with injected team admin props.
 */
export const withTeamAdmin = <P extends InjectedTeamAdminProps>(
  Component: React.ComponentType<P>
) => {
  const Wrapper: React.FC<Omit<P, keyof InjectedTeamAdminProps>> = (props) => {
    const { teams, isLoading, isAllowed, isTeamSuperAdmin } = usePermission('MANAGE_TEAM');
    const { navigate } = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
      if (!isLoading && !isAllowed) {
        navigate('/home');
        dispatch(queueToast({ msg: t('global.unallowed') }));
      }
    }, [isAllowed, isLoading, navigate, dispatch, t]);

    return (
      <>
        <LoadingScreen visible={isLoading} />
        {!isLoading && teams.length > 0 && (
          <Component {...(props as P)} teams={teams} isTeamSuperAdmin={isTeamSuperAdmin} />
        )}
      </>
    );
  };
  return Wrapper;
};

export type InjectedSuperAdminProps = {
  teams: string[];
  isTeamSuperAdmin: boolean;
};

/**
 * Higher order component used to grant access to team super admins.
 *
 * @param Component - the component to access.
 *
 * @returns component with injected team super admin props.
 */
export const withSuperAdmin = <P extends InjectedSuperAdminProps>(
  Component: React.ComponentType<P>
) => {
  const Wrapper: React.FC<Omit<P, keyof InjectedSuperAdminProps>> = (props) => {
    const { teams, isLoading, isTeamSuperAdmin } = usePermission('MANAGE_TEAM');
    const { navigate } = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
      if (!isLoading && !isTeamSuperAdmin) {
        navigate('/home');
        dispatch(queueToast({ msg: t('global.unallowed') }));
      }
    }, [isLoading, navigate, dispatch, isTeamSuperAdmin, t]);

    return (
      <>
        <LoadingScreen visible={isLoading} />
        {!isLoading && teams.length > 0 && (
          <Component {...(props as P)} isTeamSuperAdmin={isTeamSuperAdmin} />
        )}
      </>
    );
  };
  return Wrapper;
};
