import { useCallback } from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import { RetailUnit } from '@nfw/ikea/retail';
import { isMarketConfiguration } from '@nfw/nudge/typeguard';
import { MarketConfiguration } from '@nfw/nudge/types';
import { useRefresh } from '@nfw/ui/common';
import { Form, FormCallback } from '@nfw/ui/form';
import DOMPurify from 'dompurify';
import { useGetMarketConfiguration } from '../../../features/configurations/hooks';
import { useMarketConfigurationLayout } from '../../../features/orchestrations/hooks';

export type MarketConfigurationFormProps = {
  retailUnit: RetailUnit;
  onConfigurationChange: (configuration: MarketConfiguration) => void;
};

const MarketConfigurationForm: React.FC<MarketConfigurationFormProps> = ({
  retailUnit,
  onConfigurationChange,
}) => {
  const { data: configuration, isLoading } = useGetMarketConfiguration(retailUnit);
  const configLayout = useMarketConfigurationLayout();

  const onChange = useCallback<FormCallback<MarketConfiguration>>(
    ({ value }) => {
      if (value) {
        onConfigurationChange(value);
      }
    },
    [onConfigurationChange]
  );

  // Need to re-render form on retail change.
  const refreshedConfig = useRefresh(configuration, 1);

  return isLoading ? (
    <Loading text="">
      <LoadingBall size="large" />
    </Loading>
  ) : (
    refreshedConfig && (
      <Form
        config={configLayout}
        defaultFormValue={refreshedConfig}
        onChange={onChange}
        validationFn={isMarketConfiguration}
        purify={DOMPurify.sanitize}
      />
    )
  );
};

export default MarketConfigurationForm;
