import { TeamTypes } from '@nfw/contracts/self-service';
import { InputTypes, LayoutConfiguration, ValidationTypes } from '@nfw/form-types';
import { getRetailUnits } from '@nfw/ikea/retail';
import { TFunction } from 'i18next';

export const getTeamEditLayout = (t: TFunction): LayoutConfiguration => [
  {
    inputType: InputTypes.TextInput,
    name: 'name',
    limit: 50,
    label: t('form.team.name.label'),
    validations: [{ type: ValidationTypes.Required, errorMessage: t('form.team.name.error') }],
  },
  {
    inputType: InputTypes.TextArea,
    name: 'description',
    label: t('global.description'),
    validations: [
      { type: ValidationTypes.Required, errorMessage: t('form.team.description.error') },
    ],
  },
  {
    inputType: InputTypes.ManyOfMany,
    name: 'retailUnits',
    header: t('form.feature.market.header'),
    label: t('form.general.select'),
    helpMessage: t('form.team.units.help'),
    selectAllText: t('form.general.select_all'),
    clearAllText: t('global.clear_all'),
    validations: [{ type: ValidationTypes.Required, errorMessage: t('form.team.units.error') }],
    values: getRetailUnits().map((value) => ({
      name: t(`global.country.${value}`),
      value,
      extra: value,
    })),
  },
];

export const getTeamLayout = (t: TFunction, owner: string): LayoutConfiguration => [
  {
    inputType: InputTypes.TextInput,
    limit: 50,
    name: 'name',
    label: t('form.team.name.label'),
    validations: [{ type: ValidationTypes.Required, errorMessage: t('form.team.name.error') }],
  },
  {
    inputType: InputTypes.TextInput,
    name: 'id',
    label: t('form.team.abbreviation.label'),
    limit: 6,
    helpMessage: t('form.team.abbreviation.help'),
    validations: [
      {
        type: ValidationTypes.Regexp,
        regexp: '^[a-z]{3,6}$',
        errorMessage: t('form.team.abbreviation.error'),
      },
    ],
  },
  {
    inputType: InputTypes.RadioGroup,
    name: 'teamType',
    label: t('form.team.type.label'),
    helpMessage: t('form.team.type.help_no_market'),
    selectedHelpMessage: {
      [TeamTypes.Product]: t('global.product_team.help'),
      [TeamTypes.Market]: t('global.market_team.help'),
    },
    options: [
      {
        id: 'market',
        label: t('global.market_team.label'),
        value: TeamTypes.Market,
      },
      {
        id: 'product',
        label: t('global.product_team.label'),
        value: TeamTypes.Product,
      },
    ],
  },
  {
    inputType: InputTypes.FixedText,
    label: t('global.administrator'),
    text: owner,
    helpMessage: t('form.team.admin.help'),
  },
  {
    inputType: InputTypes.ManyOfMany,
    name: 'retailUnits',
    header: t('form.feature.market.header'),
    label: t('form.general.select'),
    helpMessage: t('form.team.units.help'),
    selectAllText: t('form.general.select_all'),
    selectOneText: t('global.add'),
    clearAllText: t('global.clear_all'),
    values: getRetailUnits().map((value) => ({
      name: t(`global.country.${value}`),
      value,
      extra: value,
    })),
  },
  {
    inputType: InputTypes.TextArea,
    name: 'description',
    label: t('form.team.description.label'),
    helpMessage: t('form.team.description.help'),
    limit: 300,
    validations: [
      { type: ValidationTypes.Required, errorMessage: t('form.team.description.error') },
    ],
  },
  {
    inputType: InputTypes.TextInput,
    name: 'phraseProjectId',
    dependsOn: 'teamType',
    requiresValueOf: [TeamTypes.Product],
    limit: 50,
    label: t('form.team.phrase.label'),
    helpMessage: t('form.team.phrase.help'),
    validations: [
      {
        type: ValidationTypes.Required,
        errorMessage: t('form.general.field.error'),
      },
    ],
  },
  {
    inputType: InputTypes.TextInput,
    name: 'defaultLocale',
    limit: 5,
    label: t('form.team.locale.label'),
    helpMessage: t('form.team.locale.help'),
    validations: [
      {
        type: ValidationTypes.Required,
        errorMessage: t('form.general.field.error'),
      },
    ],
  },
];
