import React, { useMemo } from 'react';
import Toast from '@ingka/toast';
import { WidgetTypes, Widget } from '@nfw/nudge/types';
import { Message } from '@nfw/ui/message';
import { Modal } from '@nfw/ui/modal';
import { Prompt } from '@nfw/ui/prompt';
import { Reaction } from '@nfw/ui/reaction';
import { Translations, translateWidget } from '@nfw/widget-translation';
import { copy } from '../../utils';

export interface WidgetPreviewProps {
  widget?: Widget;
  translations?: Translations;
}

const VoidFn = () => undefined;

const WidgetPreview: React.FC<WidgetPreviewProps> = ({ widget, translations }) =>
  useMemo(() => {
    let tWidget: Widget | undefined = undefined;
    if (widget) {
      tWidget = translateWidget(copy(widget), translations)[0];

      switch (tWidget?.type) {
        case WidgetTypes.Toast:
          return (
            <Toast
              text={tWidget.text || ''}
              actionButtonText={tWidget.action?.text}
              isOpen
              onCloseRequest={VoidFn}
            />
          );
        case WidgetTypes.Modal:
          return (
            <Modal id="modal" widget={tWidget} onSubmit={VoidFn} onClose={VoidFn} alwaysOpen />
          );
        case WidgetTypes.Message:
        case WidgetTypes.Tip:
          return (
            <Message
              id="message"
              show
              hierarchyIndex={0}
              onClose={VoidFn}
              onAction={VoidFn}
              widget={tWidget}
            />
          );
        case WidgetTypes.Reaction:
          return <Reaction id="reaction" reactions={tWidget.reactions} />;
        case WidgetTypes.Prompt:
          return (
            <Prompt id="prompt" widget={tWidget} onSubmit={VoidFn} onClose={VoidFn} alwaysOpen />
          );
        default:
          return null;
      }
    }
    return undefined;
  }, [widget, translations]);

export default WidgetPreview;
