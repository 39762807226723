import React, { useCallback } from 'react';
import InlineMessage from '@ingka/inline-message';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import Text from '@ingka/text';
import { NudgeExperimentTypes } from '@nfw/nudge/types';
import { StoredFeatureOrchestration } from '@nfw/orchestration-types';
import { Form } from '@nfw/ui/form';
import { isObject, voidFn } from '@nfw/utils';
import { useTranslation } from 'react-i18next';
import { useGetExperiment, useMetricsPreviewLayout } from '../../features/experiments/hooks';
import { useNavigate } from '../../routes';
import PopUp, { ActionProps } from '../PopUp';

export type StartExperimentPopUpProps = {
  visible: boolean;
  onClose: () => void;
  orchestration: StoredFeatureOrchestration;
  startExperiment: () => void;
  cancelExperiment: () => void;
  isIteration?: boolean;
};

const StartExperimentPopup: React.FC<StartExperimentPopUpProps> = ({
  visible,
  orchestration,
  onClose,
  startExperiment,
  cancelExperiment,
  isIteration = false,
}) => {
  const { navigateWithSearch } = useNavigate();
  const { t } = useTranslation();
  const { experiment } = useGetExperiment(orchestration.id, orchestration.team, !visible);

  const layoutMetricsPreview = useMetricsPreviewLayout(
    experiment?.metrics,
    `${orchestration.id}-${orchestration.featureNudge.widgetRef}`
  );

  const gotoExperimentPage = useCallback(() => {
    if (experiment) {
      const { type, widgetId } = experiment;
      const { id, team } = orchestration;
      const searchParams = new URLSearchParams();

      switch (type) {
        case NudgeExperimentTypes.Design:
          searchParams.set('type', type);
          searchParams.set('widgetId', widgetId);
          break;
        case NudgeExperimentTypes.Visibility: // Fall-through
        default:
          searchParams.set('type', type);
          break;
      }

      navigateWithSearch('/create/experiment/:team/:id', { team, id }, searchParams, {
        state: 'edit',
      });
    }
  }, [orchestration, navigateWithSearch, experiment]);

  const getActions = (): ActionProps[] => {
    const baseActions: ActionProps[] = [
      {
        type: 'secondary',
        text: t('global.action.edit'),
        action: gotoExperimentPage,
      },
      ...(isIteration
        ? ([
            {
              type: 'secondary',
              text: t('publish.experiment.action.cancel'),
              action: cancelExperiment,
            },
          ] as ActionProps[])
        : []),
      {
        type: 'primary',
        text: t('publish.experiment.action.text'),
        action: startExperiment,
      },
    ];

    return baseActions;
  };

  return (
    experiment && (
      <PopUp
        visible={visible}
        close={onClose}
        title={t('publish.experiment.popup.title')}
        body={
          <>
            <Text bodySize="m">{t('metric.preview.description')}</Text>
            &nbsp;
            <InlineMessage
              ssrIcon={informationCircle}
              variant="informative"
              subtle={true}
              body={t('metric.preview.caution')}
            />
            <hr className="hr" />
            <div className="preview-form">
              <Form config={layoutMetricsPreview} validationFn={isObject} onChange={voidFn} />
            </div>
          </>
        }
        actions={getActions()}
      />
    )
  );
};

export default StartExperimentPopup;
