import { NudgeVisibilityRule } from './visibility';

export const DOMTargetTypes = {
  DataNudge: 'data_nudge',
  ClassName: 'class_name',
  ElementId: 'element_id',
} as const;
export type DOMTargetType = (typeof DOMTargetTypes)[keyof typeof DOMTargetTypes];

/**
 * A DOM target is an element that identifies a DOMElement.
 */
export type DOMTarget = {
  type: DOMTargetType;
  /**
   * The element id could be a data-nudge id a regular id or a html type.
   */
  id: string;
};

/**
 * Anchor is a description on how the widget visibility
 * should change with dom changes (scrolling, mutations etc. ).
 */
export type DOMAnchor = DOMTarget & {
  visibilityRule?: NudgeVisibilityRule;
};
