import Button from '@ingka/button';
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal';
import SSRIcon from '@ingka/ssr-icon';
import checkmark from '@ingka/ssr-icon/paths/checkmark';
import checkmarkCircle from '@ingka/ssr-icon/paths/checkmark-circle';
import circle from '@ingka/ssr-icon/paths/circle';
import cross from '@ingka/ssr-icon/paths/cross';
import incorrect from '@ingka/ssr-icon/paths/incorrect';
import Text from '@ingka/text';
import { TeamConfiguration } from '@nfw/contracts/self-service';
import { toDateString } from '@nfw/utils';
import { useTranslation } from 'react-i18next';
import { useUpdateTeamStatusMutation } from '../../features/teams/api';
import { useNavigate } from '../../routes';
import DescribedItem from '../DescribedItem';
import LinearLayout from '../LinearLayout';
import './index.scss';

export type ManageTeamRequestModalProps = {
  team: TeamConfiguration;
  visible: boolean;
  close: () => void;
};

const ManageTeamRequestModal: React.FC<ManageTeamRequestModalProps> = ({
  visible,
  close,
  team,
}) => {
  const { t } = useTranslation();
  const { navigate } = useNavigate();
  const retailUnits = team.retailUnits.map((ru) => t(`global.country.${ru}`)).join(', ');
  const [updateTeamStatus] = useUpdateTeamStatusMutation();

  return (
    <Modal visible={visible} handleCloseBtn={close}>
      <Sheets
        footer={
          <ModalFooter renderBorder>
            {team.status !== 'approved' ? (
              <>
                <Button
                  fluid
                  key="Reject"
                  type="secondary"
                  ssrIcon={cross}
                  text={t('global.reject')}
                  disabled={team.status === 'rejected'}
                  onClick={() => updateTeamStatus({ team: team.id, status: 'rejected' })}
                />
                <Button
                  fluid
                  key="Approve"
                  type="primary"
                  ssrIcon={checkmark}
                  text={t('global.approve')}
                  disabled={team.status === 'rejected'}
                  onClick={() => updateTeamStatus({ team: team.id, status: 'approved' })}
                />
              </>
            ) : (
              <Button
                fluid
                key="View team"
                type="primary"
                text={t('team.onboard.view-team')}
                onClick={() => navigate('/team/:team', { team: team.id })}
              />
            )}
          </ModalFooter>
        }
        size="small"
        header={<ModalHeader ariaCloseTxt={t('global.close')} />}
      >
        <ModalBody>
          <Text tagName="h1" bodySize="xl" className="header">
            {t('team.onboard.manage')}
          </Text>
          <LinearLayout className="details" gap="l">
            <Text headingSize="s">{team.name}</Text>
            <Text bodySize="s" className="status-title">
              {t('global.status')}
            </Text>
            {team.status === 'rejected' && (
              <div className="request-status">
                <SSRIcon className="icon reject" paths={incorrect} />
                <Text bodySize="m">{t('global.reject')}</Text>
              </div>
            )}
            {team.status === 'approved' && (
              <div className="request-status">
                <SSRIcon className="icon approve" paths={checkmarkCircle} />
                <Text bodySize="m"> {t('global.approve')}</Text>
              </div>
            )}
            {team.status === 'pending' && (
              <div className="request-status">
                <SSRIcon className="icon open" paths={circle} />
                <Text bodySize="m"> {t('global.open')}</Text>
              </div>
            )}
            <DescribedItem title={t('global.description')} text={team.description} />
            <DescribedItem title={t('global.administrator')} text={team.creator} />
            <DescribedItem title={t('form.team.type.label')} text={team.teamType} />
            <DescribedItem title={t('global.markets')} text={retailUnits} />
            {team.teamType === 'product' && (
              <DescribedItem title={t('form.team.phrase.label')} text={team.phraseProjectId} />
            )}
            <DescribedItem
              title={t('team.onboard.date.received')}
              text={toDateString(team.created)}
            />
          </LinearLayout>
        </ModalBody>
      </Sheets>
    </Modal>
  );
};

export default ManageTeamRequestModal;
