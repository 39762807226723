import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { widgetApi } from './api';

export const selectWidgets = createSelector(
  widgetApi.endpoints.getWidgets.select(),
  ({ data }) => data ?? []
);

export const selectTeamWidgets = createSelector(
  [selectWidgets, (_: RootState, team?: string) => team],
  (widgets, team) => widgets.filter((widget) => widget.team === team)
);

export const selectActiveTeamWidgets = createSelector(
  [selectWidgets, (_: RootState, team?: string) => team],
  (widgets, team) => widgets.filter((widget) => widget.team === team && !widget.isArchived)
);
