import { BaseErrorTypes, ErrorBody as BaseErrorBody } from '@nfw/contracts/base';
import { RetailUnit } from '@nfw/ikea/retail';
import { Namespace } from '@nfw/ikea/utils';
import { ActionType, ConditionType, RepeatType, TriggerType, WidgetType } from '@nfw/nudge/types';
import { UserPermission } from '@nfw/permissions';

/**
 * The error namespace.
 */
export const ErrorTypes = {
  ...BaseErrorTypes,
  /**
   * Error type indicating that the error is related to the cloud storage
   */
  Storage: 'storage',

  /**
   * Error type indicating that the error is related to the secret manager.
   */
  Secret: 'secret',

  /**
   * Error type indicating that the error is related to pubsub.
   */
  PubSub: 'pubsub',

  /**
   * Error type indicating that the error is related to experiments.
   */
  Experiment: 'experiment',

  /**
   * Error type indicating that the error is a validation error.
   */
  Validation: 'validation',

  /**
   * Error type indicating that the error is related to optimizely.
   */
  Optimizely: 'optimizely',

  /**
   * Error type indicating that the error is related to jira.
   */
  Alert: 'alert',

  /**
   * Error type indicating that an error is related to a dependency
   */
  Dependency: 'dependency',

  /**
   * Error type indicating that the error is related to authentication.
   */
  Auth: 'auth',

  /**
   * Error type indicating that the error is related to permissions.
   */
  Permission: 'permission',

  /**
   * Error that access to a resource is not permitted.
   */
  Resource: 'resource',

  /**
   * Error related to the resource locks.
   */
  Lock: 'lock',

  /**
   * Error related to the scheduler.
   */
  Scheduler: 'scheduler',

  /**
   * Error indicating the catalogue structure api.
   */
  Catalogue: 'catalogue',

  /**
   * Error indicating the store api.
   */
  Store: 'store',
} as const;
export type ErrorType = (typeof ErrorTypes)[keyof typeof ErrorTypes];

/** The error body. */
export type ErrorBody = BaseErrorBody<ErrorType>;

/**
 * A success response.
 */
export type SuccessResponse = { success: boolean };

/**
 * The team translations is a simple record of strings.
 */
export type Translations = { [key: string]: string };

/**
 * The locale translations is a record of translations for a certain locale.
 */
export type LocaleTranslations = { [locale: string]: Translations };

export type TeamPermission = {
  userPermissions: UserPermission[];
  retailUnits: RetailUnit[];
};

export type TeamPermissions = {
  [team: string]: TeamPermission;
};

export type TeamStatusRequest = {
  status: TeamStatus;
};

export type UpdateResourceResponse = {
  isRepublished: boolean;
};

export type JiraData = {
  url: string;
  ticketId: string;
};

/**
 * The create response.
 */
export type CreateResponse = { id: string };

/**
 * The lock request, including which resource to lock and for which team.
 */
export type LockRequest = { resourceId: string; team: string };

/**
 * The lock response, including if the lock was acquired and who the owner is.
 */
export type LockResponse = {
  isAcquired: boolean;
  owner: string;
};

/**
 * The release request for which resource the lock should be released for.
 */
export type ReleaseRequest = { resourceId: string };

/**
 * The lock permissions. Including the user permissions, the retail units and if the user is authenticated to a team.
 */
export type LockPermissions = LockResponse & {
  userPermissions: UserPermission[];
  retailUnits: RetailUnit[];
  isAuthenticated: boolean;
};

/**
 * A lock representation.
 */
export type Lock = { owner: string; expiresAt: number };

export type UrlResult = { url: string };

/**
 * The nudge design request.
 */
export type WidgetRequest = {
  creator: string;
  team: string;
  figmaLink: string;
  description: string;
};

/**
 * The experiment start experiment notification.
 */
export type StartExperimentNotification = {
  nudgeId: string;
  team: string;
  user: string;
};

export const TeamTypes = {
  Market: 'market',
  Product: 'product',
} as const;
export type TeamType = (typeof TeamTypes)[keyof typeof TeamTypes];

export const TeamStatuses = {
  Pending: 'pending',
  Approved: 'approved',
  Rejected: 'rejected',
} as const;
export type TeamStatus = (typeof TeamStatuses)[keyof typeof TeamStatuses];

/**
 * The edit team configuration.
 */
export type EditableTeamConfiguration = {
  name: string;
  description: string;
  retailUnits: RetailUnit[];
};

/**
 * The product team request.
 */
export type ProductTeamRequest = EditableTeamConfiguration & {
  id: string;
  teamType: 'product';
  phraseProjectId: string;
  defaultLocale: string;
};

/**
 * The feature toggle type.
 */
export type FeatureToggle = {
  widgets: WidgetType[];
  namespaces: Namespace[];
  triggers: TriggerType[];
  repeat: RepeatType[];
  actions: ActionType[];
  conditions: ConditionType[];
};

/**
 * The market team request.
 */
export type MarketTeamRequest = EditableTeamConfiguration & {
  id: string;
  teamType: 'market';
  defaultLocale: string;
};

/**
 * The team request.
 */
export type TeamRequest = ProductTeamRequest | MarketTeamRequest;

/**
 * The team configuration.
 */
export type TeamConfiguration = TeamRequest & {
  status: TeamStatus;
  creator: string;
  created: number;
  modified: number;
  featureToggle?: Partial<FeatureToggle>;
};

/**
 * The ikea catalogue args.
 */
export type IkeaCatalogueArgs = {
  retailUnits: string[];
  sspLanguage: string;
};

/**
 * The decision taken for the experiment.
 */
export type Decision = {
  value: boolean | string;
  motivation?: string;
};

/**
 * The ikea store args.
 */
export type IkeaStoreArgs = {
  retailUnits: string[];
};
