import { useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { OverviewNudgeGroup } from '../../../features/app/types';
import GenericFilter, {
  NamedFilter,
  SearchFilterFunction,
  Sort,
  SwitchFilter,
} from '../GenericFilter';

const searchFilter: SearchFilterFunction<OverviewNudgeGroup> = (searchValue, value) =>
  value
    ? value.id.toLowerCase().includes(searchValue) || value.name.toLowerCase().includes(searchValue)
    : false;

export const getNamedFilters = (t: TFunction): NamedFilter<OverviewNudgeGroup>[] => [
  {
    id: 'creator',
    label: t('global.created_by'),
    distinctValues: (value: OverviewNudgeGroup) => value.creatorName,
    filter: (values: string[], value: OverviewNudgeGroup) => values.includes(value.creatorName),
  },
  {
    id: 'team',
    label: t('global.team'),
    distinctValues: (value: OverviewNudgeGroup) => value.teamName,
    filter: (values: string[], value: OverviewNudgeGroup) => values.includes(value.teamName),
  },
];

const getSwitchFilters = (t: TFunction): SwitchFilter<OverviewNudgeGroup>[] => [
  {
    id: 'showArchived',
    label: t('page.home.filter.include_archived'),
    filter: (values: string[], value: OverviewNudgeGroup) =>
      values.includes('false') ? !value.isArchived : true,
    value: 'false',
  },
];

const getSort = (t: TFunction): Sort<OverviewNudgeGroup>[] => [
  {
    id: 'name',
    label: t('global.name'),
    sort: (values: OverviewNudgeGroup[]) =>
      values.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
  },
  {
    id: 'newest',
    label: t('global.sort.newest'),
    sort: (values: OverviewNudgeGroup[]) => values.sort((a, b) => (a.created < b.created ? 1 : -1)),
  },
  {
    id: 'oldest',
    label: t('global.sort.oldest'),
    sort: (values: OverviewNudgeGroup[]) => values.sort((a, b) => (a.created > b.created ? 1 : -1)),
  },
  {
    id: 'recently_edited',
    label: t('global.last_edited'),
    sort: (values: OverviewNudgeGroup[]) =>
      values.sort((a, b) => (a.modified < b.modified ? 1 : -1)),
  },
];

const GroupNudgeFilter: React.FC<{
  data: OverviewNudgeGroup[];
  onFilterChange: (data: OverviewNudgeGroup[]) => void;
  layoutIndex: number;
}> = ({ data, onFilterChange }) => {
  const { t } = useTranslation();
  const namedFilters = useMemo(() => getNamedFilters(t), [t]);
  const switchFilters = useMemo(() => getSwitchFilters(t), [t]);
  const sort = useMemo(() => getSort(t), [t]);
  return (
    <GenericFilter
      data={data}
      onFilterChange={onFilterChange}
      searchFilter={searchFilter}
      namedFilters={namedFilters}
      switchFilters={switchFilters}
      sort={sort}
      type="nudge_group"
      searchLabel={t('global.filter.placeholder')}
    />
  );
};

export default GroupNudgeFilter;
