import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store';
import { PuffaError } from '../api/puffa-error';
import { queueToast } from '../app/actions';
import { useGetTeamQuery, useGetTeamsQuery, useRequestTeamMutation } from './api';
import { getTeamLayout, getTeamEditLayout } from './forms';

/**
 * Get the team request layout.
 *
 * @returns the layout.
 */
export const useTeamRequestLayout = (name: string | undefined) => {
  const { t } = useTranslation();

  return useMemo(() => getTeamLayout(t, name ?? ''), [t, name]);
};

/**
 * Hook for getting the edit team layout configuration.
 *
 * @returns the create team layout configuration.
 */
export const useEditTeamLayout = () => {
  const { t } = useTranslation();

  return useMemo(() => getTeamEditLayout(t), [t]);
};

/**
 * Hook for handling the creation of a teamRequest.
 *
 * @returns create function, if it is loading and whether or not it successfully created the request.
 */
export const useTeamRequestConfirmation = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [create, result] = useRequestTeamMutation();

  useEffect(() => {
    const { isError, data, reset } = result;
    if (data || isError) {
      if (data) {
        const { ticketId, url } = data;
        dispatch(queueToast({ msg: t('global.request_submitted', { id: ticketId }), action: url }));
      }
      reset();
    }
  }, [dispatch, result, t]);

  return {
    create,
    isLoading: result.isLoading,
    isSuccess: result.isSuccess,
    isError: result.isError,
  };
};

/**
 * Hook for getting the teams.
 *
 * @returns the teams and whether or not it is loading.
 *
 * @throws PuffaError if there is an error.
 */
export const useGetTeams = () => {
  const { data, isError, isLoading } = useGetTeamsQuery();

  useEffect(() => {
    if (isError) {
      throw new PuffaError();
    }
  }, [isError]);

  return { data, isLoading };
};

/**
 * Hook for getting a team configuration.
 *
 * @param team - the team to get the configuration for.
 * @returns the configuration and whether or not it is loading.
 */
export const useGetTeam = (team: string) => {
  const { data, isLoading, isError } = useGetTeamQuery({ team });
  useEffect(() => {
    if (isError) {
      throw new PuffaError();
    }
  }, [isError]);

  return { data, isLoading };
};
