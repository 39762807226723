import { useCallback } from 'react';
import { toPath } from '@nfw/utils';
import {
  NavigateOptions,
  useNavigate as useNav,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Route } from '../types';

/**
 * Utility hook for typed navigation.
 */
export const useNavigate = () => {
  const nav = useNav();
  const params = useParams();
  const [searchParams] = useSearchParams();

  /**
   * Navigate function.
   */
  const navigate = useCallback(
    (route: Route, params: Record<string, string | number> = {}, options?: NavigateOptions) => {
      nav(toPath(route, params), options);
    },
    [nav]
  );

  const navigateWithSearch = useCallback(
    (
      route: Route,
      params: Record<string, string> = {},
      searchParams: URLSearchParams,
      options?: NavigateOptions
    ) => {
      let url = toPath(route, params);
      if (searchParams) {
        url = `${url}?${searchParams.toString()}`;
      }
      nav(url, options);
    },
    [nav]
  );

  const back = useCallback(() => {
    nav(-1);
  }, [nav]);

  /**
   * Variant of toPath but it only accepts our defined routes.
   */
  const toAppPath = toPath as (route: Route, params: Record<string, string | number>) => string;

  return { back, navigate, navigateWithSearch, toAppPath, params, searchParams };
};

/**
 * Workaround for the Skapa ListView component not supporting SPA navigation.
 *
 * Solution was suggested by the Skapa team. Only needed if using ListViewItem with control="link"
 * that links to another page within the app.
 *
 * Todo: When/if the Skapa ListView component supports SPA navigation, this workaround should be
 * removed.
 *
 * @returns An onClick handler for ListView components to SPA-ify links.
 */
export const useListViewLinks = () => {
  const navigate = useNav();

  const listViewOnClickHandler = useCallback(
    (e: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
      const anchorElement = (e.target as HTMLElement).closest('a');
      if (anchorElement) {
        e.preventDefault();
        const href = anchorElement.getAttribute('href') ?? '';
        navigate(href);
      }
    },
    [navigate]
  );

  return { listViewOnClickHandler };
};
