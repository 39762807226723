export const RepeatTypes = {
  Always: 'always',
  Session: 'session',
  Page: 'page',
  Lifetime: 'lifetime',
} as const;
export type RepeatType = (typeof RepeatTypes)[keyof typeof RepeatTypes];

export type RepeatValueBase<T extends RepeatType> = {
  type: T;
};

export type WithPageInterval = {
  max: number;
  initShow: boolean;
  interval: number;
};

export type WithCount = {
  count: number;
};

export type RepeatAlwaysValue = RepeatValueBase<'always'>;

export type RepeatPageValue = RepeatValueBase<'page'> & WithCount;

export type RepeatLifetimeValue = RepeatValueBase<'lifetime'> &
  WithCount & {
    page?: WithPageInterval;
  };

export type RepeatSessionValue = RepeatValueBase<'session'> &
  WithCount & {
    page?: WithPageInterval;
  };

export type RepeatValue =
  | RepeatAlwaysValue
  | RepeatPageValue
  | RepeatLifetimeValue
  | RepeatSessionValue;
