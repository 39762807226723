import { useEffect } from 'react';
import { ButtonProps } from '@ingka/button';
import Text from '@ingka/text';
import classNames from 'classnames';
import { enableFocusPortal } from '../../features/app/actions';
import { useAppDispatch } from '../../store';
import Audits from '../Audits';
import Breadcrumb, { Path } from '../Breadcrumb';
import LoadingScreen from '../LoadingScreen';
import './index.scss';

export type PageHeaderProps = {
  title: string;
  caption?: string;
  actions?: React.ReactElement<ButtonProps>[];
  audit?: {
    id: string;
    modified?: number;
  };
  titleSize?: 'xl' | 'l' | 'm' | 's' | 'xs';
};

export const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  caption,
  actions,
  audit,
  titleSize = 'xl',
}) => {
  return (
    <div className="page-header-container">
      <div>
        <Text tagName="h1" headingSize={titleSize}>
          {title}
        </Text>

        {audit && (
          <div className="audits">
            <Audits id={audit.id} modified={audit?.modified} />
          </div>
        )}
        {caption && <Text tagName="p">{caption}</Text>}
      </div>
      <div className="header">{actions}</div>
    </div>
  );
};

export interface PageProps {
  children: React.ReactNode[] | React.ReactNode;
  header?: React.ReactElement<PageHeaderProps>;
  padded?: boolean;
  loading?: boolean;
  focusPortal?: boolean;
  crumbs?: Path[];
  singleColumn?: boolean;
}

const Page: React.FC<PageProps> = ({
  children,
  header,
  padded,
  loading,
  crumbs,
  focusPortal = false,
  singleColumn = false,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(enableFocusPortal(focusPortal));
  }, [focusPortal, dispatch]);
  return (
    <>
      <LoadingScreen visible={loading} />
      <div className={classNames('page-container', { padded, 'single-column': singleColumn })}>
        {crumbs && <Breadcrumb crumbs={crumbs} />}
        {header}
        {children}
      </div>
    </>
  );
};

export default Page;
