export const IconTypes = {
  ArrowCounterclockwise: 'arrow-counterclockwise',
  ArrowLeft: 'arrow-left',
  ArrowUpFromBase: 'arrow-up-from-base',
  BarChartDowntrend: 'bar-chart-downtrend',
  BarChartUptrend: 'bar-chart-uptrend',
  ShoppingBag: 'shopping-bag',
  ShoppingBagActive: 'shopping-bag-active',
  ClipboardCheckmark: 'clipboard-checkmark',
  HotDrink: 'hot-drink',
  Chat: 'chat',
  Checkmark: 'checkmark',
  CheckmarkCircle: 'checkmark-circle',
  ChevronUp: 'chevron-up',
  ChevronUpSmall: 'chevron-up-small',
  ChevronDown: 'chevron-down',
  ChevronDownSmall: 'chevron-down-small',
  Calendar: 'calendar',
  Circle: 'circle',
  CircleFilled: 'circle-filled',
  Cross: 'cross',
  AllenKey: 'allen-key',
  Document: 'document',
  FamilyGenderMixed: 'family-gender-mixed',
  FeedbackDissatisfied: 'feedback-dissatisfied',
  FeedbackHappy: 'feedback-happy',
  FeedbackNeutral: 'feedback-neutral',
  FeedbackSad: 'feedback-sad',
  FeedbackSatisfied: 'feedback-satisfied',
  Flame: 'flame',
  Flower: 'flower',
  Globe: 'globe',
  Heart: 'heart',
  IceCream: 'ice-cream',
  InfoCircle: 'information-circle',
  Link: 'link',
  LinkOut: 'link-out',
  MembershipCard: 'membership-card',
  Pencil: 'pencil',
  People: 'people',
  Phone: 'phone',
  Plus: 'plus',
  Person: 'person',
  PersonActive: 'person-active',
  Power: 'power',
  MagnifyingGlassList: 'magnifying-glass-list',
  QuestionMark: 'question-mark',
  Reward: 'reward',
  Star: 'star',
  ThumbsDown: 'thumbs-down',
  ThumbsUp: 'thumbs-up',
  TrashCan: 'trash-can',
  Lock: 'lock',
  Pairing: 'pairing',
  ChevronUpChevronDownSmall: 'chevron-up-chevron-down-small',
  Store: 'store',
  MailArrowIn: 'mail-arrow-in',
} as const;
export type IconType = (typeof IconTypes)[keyof typeof IconTypes];
