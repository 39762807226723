import React, { useCallback, useEffect, useState } from 'react';
import Button from '@ingka/button';
import InlineMessage from '@ingka/inline-message';
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal';
import {
  EditableTeamConfiguration,
  TeamConfiguration,
  isTeamConfiguration,
  isEditableTeamConfiguration,
} from '@nfw/contracts/self-service';
import { Form, FormCallback } from '@nfw/ui/form';
import DOMPurify from 'dompurify';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useUpdateTeamMutation } from '../../../../features/teams/api';
import { useEditTeamLayout } from '../../../../features/teams/hooks';

export type EditTeamProps = {
  team?: TeamConfiguration;
  close: () => void;
};

const EditTeam: React.FC<EditTeamProps> = ({ team, close }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [teamConfig, setTeamConfig] = useState<EditableTeamConfiguration>();
  const [update, result] = useUpdateTeamMutation();
  const { t } = useTranslation();
  const layout = useEditTeamLayout();

  const onChangeInternal = useCallback<FormCallback<EditableTeamConfiguration>>(
    ({ value, valid }) => {
      if (valid) {
        setTeamConfig(value);
      }
    },
    []
  );

  useEffect(() => {
    setIsLoading(result.isLoading);
    setIsError(result.isError);
  }, [close, result]);

  const onSubmit = useCallback(() => {
    const updatedTeam = { ...team, ...teamConfig };
    if (isTeamConfiguration(updatedTeam)) {
      update(updatedTeam);
      close();
    }
  }, [close, team, teamConfig, update]);

  return createPortal(
    <Modal id="teamSelect" visible={!!team} handleCloseBtn={close}>
      <Sheets
        size="small"
        header={<ModalHeader ariaCloseTxt={t('global.close')} title={t('global.edit_team')} />}
        footer={
          <ModalFooter>
            <Button type="secondary" text={t('global.action.cancel')} />
            <Button
              type="primary"
              text={t('global.action.save')}
              disabled={!teamConfig}
              onClick={onSubmit}
              loading={isLoading}
            />
          </ModalFooter>
        }
      >
        <ModalBody>
          {isError && <InlineMessage variant="negative" body={t('global.error.generic')} />}
          <Form
            config={layout}
            defaultFormValue={team}
            onChange={onChangeInternal}
            validationFn={isEditableTeamConfiguration}
            purify={DOMPurify.sanitize}
          />
        </ModalBody>
      </Sheets>
    </Modal>,
    document.body
  );
};

export default EditTeam;
