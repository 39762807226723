import Text from '@ingka/text';
import { StoredNudgeGroup } from '@nfw/orchestration-types';
import { toDateString } from '@nfw/utils';
import { useTranslation } from 'react-i18next';
import DescribedItem from '../DescribedItem';
import LinearLayout from '../LinearLayout';

export type NudgeGroupDescriptionProps = {
  nudgeGroup: StoredNudgeGroup;
};

const NudgeGroupDescription: React.FC<NudgeGroupDescriptionProps> = ({ nudgeGroup }) => {
  const { description, creatorName, team, created } = nudgeGroup;
  const { t } = useTranslation();

  return (
    <LinearLayout gap="l" border padded>
      <Text headingSize="xs">{t('global.details')}</Text>
      <DescribedItem key={'created_by'} title={t('global.created_by')} text={creatorName} />
      <DescribedItem key={'team'} title={t('global.team')} text={team} />
      <DescribedItem key={'description'} title={t('global.description')} text={description} />
      <DescribedItem
        key={'created'}
        title={t('global.created')}
        text={toDateString(created, true)}
      />
    </LinearLayout>
  );
};

export default NudgeGroupDescription;
