import { NudgeGroup } from '@nfw/nudge/types';
import { isObject, isString } from '@nfw/utils';

/**
 * Check if the nudges group is a valid nudge group.
 *
 * @param nudgeGroup - the nudge group to test.
 *
 * @returns true if the nudge group is a valid nudge group, otherwise false.
 */
export const isNudgeGroup = (nudgeGroup: unknown): nudgeGroup is NudgeGroup =>
  isObject(nudgeGroup) && isString(nudgeGroup.name) && isString(nudgeGroup.description);
