import { Form } from '@nfw/ui/form';
import { isObject, voidFn } from '@nfw/utils';
import { useHealthMetricsLayout } from '../../features/experiments/hooks';

export type HealthMetricsPlaceholderProps = {
  id: string;
};

const HealthMetricsPlaceholder: React.FC<HealthMetricsPlaceholderProps> = ({ id }) => {
  const layout = useHealthMetricsLayout(id);

  return <Form config={layout} validationFn={isObject} onChange={voidFn} />;
};

export default HealthMetricsPlaceholder;
