import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Avatar from '@ingka/avatar';
import ListView, { ListViewItem } from '@ingka/list-view';
import Modal, { Sheets, ModalHeader } from '@ingka/modal';
import rightArrow from '@ingka/ssr-icon/paths/arrow-right-from-base';
import Text from '@ingka/text';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectInitials, selectName } from '../../features/auth/selectors';
import LanguagePicker from '../LanguagePicker';
import LinearLayout from '../LinearLayout';
import './index.scss';

type ProfileSliderProps = {
  modalVisible: boolean;
  onClose: () => void;
};

export const ProfileSlider: React.FC<ProfileSliderProps> = ({ modalVisible, onClose }) => {
  const name = useSelector(selectName);
  const initials = useSelector(selectInitials);
  const { logout } = useAuth0();
  const { t, i18n } = useTranslation();
  const [placeholder, setPlaceholder] = useState<string>(t('global.choose_option'));

  const onLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
    onClose();
  };

  return createPortal(
    <Modal visible={modalVisible} handleCloseBtn={onClose}>
      <Sheets footer={null} header={<ModalHeader />}>
        <div role="presentation">
          <LinearLayout horizontal gap="l" className="profile">
            <Avatar
              id="profile"
              size="large"
              screenReaderText={t('global.profile.modal.header')}
              staticColour
              variant="primary"
              text={initials}
            />
            <Text headingSize="s" bodySize="m">
              {name}
            </Text>
          </LinearLayout>
        </div>
        <div className="profile-controls">
          <ListView id="profile-slider" size="large">
            <ListViewItem
              title={<b>{t('component.header.logout')}</b>}
              leadingIcon={rightArrow}
              controlIcon={'chevronRight'}
              inset
              control="navigational"
              onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
            />
          </ListView>
          <div className="language-picker">
            <LanguagePicker
              defaultLanguage={i18n.language}
              onLanguageChanged={onLanguageChange}
              setPlaceholder={setPlaceholder}
              placeholder={placeholder}
            />
          </div>
        </div>
      </Sheets>
    </Modal>,
    document.body
  );
};

export default ProfileSlider;
