/**
 * The style measurements used to display contextual nudge.
 */
export type StyledMeasure = {
  maxWidth: number;
  minHeight: number;
  top: number;
  left: number;
};

export type TagName = keyof HTMLElementTagNameMap;

export type DecideFunction = (element: HTMLElement, id: string) => boolean;

const MaxMessageWidth = 350;
const MinMessageHeight = 30;
/**
 * Offset used to visually indicate the message is a child message.
 */
const Offset = 3;

/**
 * Calculates the euclidian distance between two points.
 *
 * @param rect1 - the fist rectangle.
 * @param rect2 - the second rectangle.
 *
 * @returns triple of distances, where the first element is distance between the rectangles in x-axis,
 *          and second is distance between the rectangles in y-axis and the third element
 *          is the euclidian distance.
 */
export const getDistance = (rect1: DOMRect, rect2: DOMRect): [number, number, number] => {
  const distanceX = Math.abs(rect1.left - rect2.left);
  const distanceY = Math.abs(rect1.top - rect2.top);
  const distance = Math.sqrt(Math.pow(distanceX, 2) + Math.pow(distanceY, 2));
  return [distanceX, distanceY, distance];
};

/**
 * Check if a child is a descendant of a parent node.
 *
 * @param id - the nudge id of the root parent.
 * @param child - the child element.
 * @param decideFn - the function that decide if element is found.
 * @param maxDepth - optional max depth to search for an ancestor.
 *
 * @returns true if the child or a parent has the requested nudge id, otherwise false.
 */
export const isDescendant = (
  id: string,
  child: HTMLElement,
  decideFn: DecideFunction,
  maxDepth = 4
) => {
  let found = false;
  let currentDepth = 0;
  let element: HTMLElement | null = child;

  while (!found && element && currentDepth <= maxDepth) {
    found = decideFn(element, id);
    element = element.parentElement;
    currentDepth++;
  }
  return found;
};

/**
 * Get the measured area.
 *
 * @param rect - the dom rectangle.
 * @param offset - the offset in x and y axis.
 *
 * @returns the style adjusted properties.
 */
export const measure = (rect: DOMRect, offset = 0): StyledMeasure => ({
  top: offset * Offset + rect.top + rect.height + window.scrollY,
  left: offset * Offset + rect.left + window.scrollX,
  minHeight: MinMessageHeight,
  maxWidth: MaxMessageWidth,
});

/**
 * Transform the DOMRect to viewport space.
 *
 * @param rect - the rect.
 *
 * @returns the rect with viewport coordinates.
 */
export const toViewport = (rect: DOMRect) => ({
  ...rect,
  left: rect.left + window.scrollX,
  right: rect.right + window.scrollX,
  top: rect.top + window.scrollY,
  bottom: rect.bottom + window.scrollY,
});

/**
 * Check if two styled measures intersect.
 *
 * @param m1 - the first styled measure.
 * @param m2 - the second styled measure.
 *
 * @returns true if the styled measures intersect, otherwise false.
 */
export const isStyledIntersection = (m1: StyledMeasure, m2: StyledMeasure) =>
  !(
    m1.left >= m2.left + m2.maxWidth ||
    m1.left + m1.maxWidth <= m2.left ||
    m1.top >= m2.top + m2.minHeight ||
    m1.top + m1.minHeight <= m2.top
  );

/**
 * Check if two DOM rectangles are intersecting.
 *
 * @param rect1 - the first rectangle.
 * @param rect2 - the second rectangle.
 *
 * @returns true if rectangles are intersecting, otherwise false.
 */
export const isIntersection = (rect1: DOMRect, rect2: DOMRect) =>
  !(
    rect1.left >= rect2.right ||
    rect1.right <= rect2.left ||
    rect1.top >= rect2.bottom ||
    rect1.bottom <= rect2.top
  );

/**
 * Check if a rect is in the current view port.
 *
 * @param rect - the DOM rect to test.
 *
 * @returns true if the rect is in the current viewport, otherwise false.
 */
export const isInViewport = (rect: DOMRect) =>
  rect.top >= 0 &&
  rect.left >= 0 &&
  rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
  rect.right <= (window.innerWidth || document.documentElement.clientWidth);

/**
 * Check if rect is under the current view port.
 *
 * @param rect - the DOM rect to test.
 * @returns true when rect is under current viewport, otherwise false.
 */
export const isUnderViewport = (rect: DOMRect) =>
  rect.bottom > (window.innerHeight || document.documentElement.clientHeight) &&
  rect.right > (window.innerWidth || document.documentElement.clientWidth);

/**
 * Check if a node is a HTMLElement.
 *
 * @param node - the node.
 *
 * @returns true if the node is a HTMLElement, otherwise false.
 */
export const isHTMLElement = (node: unknown): node is HTMLElement => node instanceof HTMLElement;

/**
 * Check if the node is an Element with elementId set as data-nudge.
 *
 * @param node - the node.
 * @param elementId - the element id.
 *
 * @returns true if the node has the element set as data-nudge attribute, otherwise false.
 */
export const isNudgeAnchor = (node: unknown, elementId: string): node is HTMLElement =>
  isHTMLElement(node) && node.dataset['nudge'] === elementId;

/**
 * Check if a node has id set.
 *
 * @param node - the node.
 * @param id - the id of the element to match.
 *
 * @returns true if the element has the id provided.
 */
export const isElementWithId = (node: unknown, id: string): node is HTMLElement =>
  isHTMLElement(node) && node.id === id;

/**
 * Check if a node has a class name added.
 *
 * @param node - the node.
 * @param className - the class name to match.
 *
 * @returns true when element class name string match the className provided, otherwise false.
 */
export const isElementWithClass = (node: unknown, className: string): node is HTMLElement =>
  isHTMLElement(node) && node.className === className;

/**
 * Check if a node has a class name added.
 *
 * @param node - the node.
 * @param classNames - the list of class name to match.
 *
 * @returns true when element class name string includes every classNames provided, otherwise false.
 */
export const isElementWithEveryClassName = (
  node: unknown,
  ...classNames: string[]
): node is HTMLElement =>
  isHTMLElement(node) &&
  node.className
    .trim()
    .split(' ')
    .every((c) => classNames.some((name) => c.includes(name)));

/**
 * Check if a node has a class name added.
 *
 * @param node - the node.
 * @param classNames - the list of class name to match.
 *
 * @returns true when element class name string includes some classNames provided, otherwise false.
 */
export const isElementWithSomeClassName = (
  node: Node,
  ...classNames: string[]
): node is HTMLElement =>
  isHTMLElement(node) &&
  node.className
    .trim()
    .split(' ')
    .some((c) => classNames.some((name) => c.includes(name)));

/**
 * Check if an element exists in the DOM.
 *
 * @param id - the id of the HTMLElement.
 * @param ids - the ids of the HTMLElements.
 *
 * @returns true if at least one element exists, otherwise false.
 */
export const elementWithIdExist = (id: string, ...ids: string[]) =>
  [id, ...ids].some((id) => !!getElementById(id));

/**
 * Get the element that has the nudge id set.
 *
 * @param elementId - the element id.
 *
 * @returns the element as HTMLElement if found, otherwise undefined.
 */
export const getElementByTriggerId = (elementId: string) =>
  getElementByDataValue('nudge', elementId);

/**
 * Get an element by the data tag.
 *
 * @param dataTag - the data tag with data- omitted.
 *
 * @returns the element as HTMLElement if found, otherwise undefined.
 */
export const getElementByDataValue = (dataTag: string, value: string) => {
  const node = document.querySelector(`[data-${dataTag}=${value}]`);
  return isHTMLElement(node) ? node : undefined;
};

/**
 * Get all elements containing the data tag.
 *
 * @param dataTag - the data tag with data- omitted.
 * @param value - the value.
 *
 * @returns a list of html elements containing the data tag.
 */
export const getElementsByDataValue = (dataTag: string, value: string) => {
  const nodes = Array.from(document.querySelectorAll(`[data-${dataTag}=${value}]`));
  return nodes.filter(isHTMLElement);
};

/**
 * Get the element from the element id.
 *
 * @param elementId - the element id.
 *
 * @returns the element as HTMLElement if found otherwise undefined.
 */
export const getElementById = (elementId: string) =>
  document.getElementById(elementId) ?? undefined;

/**
 * Get elements by class name(s).
 *
 * @param className - the class name to search for.
 *
 * @returns the first element with the class name, or undefined.
 */
export const getElementByClassName = (className: string) => {
  const collection = document.getElementsByClassName(className);

  if (collection.length > 0 && isHTMLElement(collection[0])) {
    return collection[0];
  }
  return undefined;
};

/**
 * Get the dom rect from the element with a nudge id.
 *
 * @param nudgeId - the nudge id.
 *
 * @returns the dom rect if exists and HTMLElement.
 */
export const getDOMRect = (elementId: string) =>
  getElementByTriggerId(elementId)?.getBoundingClientRect();
