import {
  HierarchyNode,
  InputTypes,
  LayoutConfiguration,
  PillListboxOption,
  ValidationTypes,
} from '@nfw/form-types';
import { ExceptionTypes } from '@nfw/nudge/types';
import { TFunction } from 'i18next';

const getPageMatcherExceptionsLayout = (
  t: TFunction,
  includeBreadcrumbs: boolean,
  rootNode?: HierarchyNode,
  isLoading?: boolean
): LayoutConfiguration => {
  const exceptionExplanations = {
    [ExceptionTypes.StringMatch]: t('form.feature.pagematcher.exception.string.label'),
    [ExceptionTypes.Breadcrumb]: t('form.feature.pagematcher.exception.breadcrumb.label'),
  };

  const exceptionTypes = Object.entries(ExceptionTypes).map<PillListboxOption>(([_, value]) => ({
    name: value,
    description: exceptionExplanations[value],
    value,
  }));

  const layout: LayoutConfiguration = [
    {
      inputType: InputTypes.PillListbox,
      name: 'type',
      label: t('form.feature.pagematcher.exception.select.label'),
      btnLabel: t('form.general.select_type'),
      options: includeBreadcrumbs
        ? exceptionTypes
        : exceptionTypes.filter(({ name }) => name !== ExceptionTypes.Breadcrumb),
      includeSearch: false,
    },
    {
      inputType: InputTypes.TextInputGroup,
      name: 'startsWith',
      label: t('form.feature.pagematcher.exception.starts.header'),
      helpMessage: t('form.feature.pagematcher.exception.starts.help'),
      dependsOn: 'type',
      requiresValueOf: [ExceptionTypes.StringMatch],
    },
    {
      inputType: InputTypes.TextInputGroup,
      name: 'endsWith',
      label: t('form.feature.pagematcher.exception.ends.header'),
      helpMessage: t('form.feature.pagematcher.exception.ends.help'),
      dependsOn: 'type',
      requiresValueOf: [ExceptionTypes.StringMatch],
    },
    {
      inputType: InputTypes.TextInputGroup,
      name: 'includes',
      label: t('form.feature.pagematcher.exception.includes.header'),
      helpMessage: t('form.feature.pagematcher.exception.includes.help'),
      dependsOn: 'type',
      requiresValueOf: [ExceptionTypes.StringMatch],
    },
  ];

  if (includeBreadcrumbs) {
    layout.push({
      inputType: InputTypes.HierarchySelect,
      name: 'exceptions',
      label: t('form.feature.pagematcher.exception.breadcrumb.header'),
      errorMessage: t('form.team.units.error'),
      traceLabel: t('component.hierarchy_select.trace'),
      backButtonLabel: t('global.action.back'),
      dependsOn: 'type',
      requiresValueOf: [ExceptionTypes.Breadcrumb],
      rootNode,
      isLoading,
    });
  }
  return layout;
};

export const getPageMatcherLayout = (
  t: TFunction,
  includeBreadcrumbs: boolean,
  rootNode?: HierarchyNode,
  isLoading?: boolean
): LayoutConfiguration => [
  {
    inputType: InputTypes.ModalFormControl,
    editable: false,
    name: 'searchparams',
    label: t('form.feature.pagematcher.queryparams.label'),
    modalTitle: t('form.feature.pagematcher.queryparams.label'),
    helpMessage: t('form.feature.pagematcher.queryparams.help'),
    modalBodyText: t('form.feature.pagematcher.queryparams.title'),
    modalPrimaryActionText: t('global.action.continue'),
    modalSecondaryActionText: t('global.action.cancel'),
    valueIdentifier: 'key',
    configuration: [
      {
        inputType: InputTypes.TextInput,
        name: 'key',
        label: t('form.feature.pagematcher.queryparams.key'),
        validations: [
          {
            type: ValidationTypes.Required,
            errorMessage: t('form.general.field.error'),
          },
        ],
      },
      {
        inputType: InputTypes.TextInput,
        name: 'value',
        label: t('form.feature.pagematcher.queryparams.value'),
        validations: [
          {
            type: ValidationTypes.Required,
            errorMessage: t('form.general.field.error'),
          },
        ],
      },
    ],
  },
  {
    inputType: InputTypes.Switch,
    name: 'includeNamespacePage',
    label: t('form.feature.pagematcher.namespace.label'),
    helpMessage: t('form.feature.pagematcher.namespace.help'),
    defaultValue: true,
    reverse: true,
  },
  {
    inputType: InputTypes.Switch,
    name: 'includeChildPages',
    label: t('form.feature.pagematcher.childpage.label'),
    helpMessage: t('form.feature.pagematcher.childpage.help'),
    defaultValue: false,
  },
  {
    inputType: InputTypes.InlineFormControl,
    name: 'exception',
    type: 'switch',
    label: t('form.feature.pagematcher.exception.label'),
    helpMessage: t('form.feature.pagematcher.exception.help'),
    configuration: getPageMatcherExceptionsLayout(t, includeBreadcrumbs, rootNode, isLoading),
  },
];
