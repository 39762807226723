import { IconType, IconTypes } from '@nfw/nudge/types';

/**
 * Check if the icon is valid.
 *
 * @param icon - the icon to check.
 *
 * @returns true if the icon is valid, otherwise false.
 */
export const isIconType = (icon: unknown): icon is IconType =>
  Object.values(IconTypes).includes(icon as IconType);
