import { Translations } from '@nfw/contracts/self-service';
import { InputTypes, LayoutConfiguration, ValidationTypes } from '@nfw/form-types';
import { WidgetTypes, HorizontalAligns, WidgetType, ActionType } from '@nfw/nudge/types';
import { WithWidgetType } from '@nfw/orchestration-types';
import { TFunction } from 'i18next';
import { getActionLayout } from './Action';
import { getHeaderLayout } from './Header';
import { getValueStatementLayout } from './ValueStatements';

export const getWidgetTypeLayout = (widgets: WidgetType[], t: TFunction): LayoutConfiguration => [
  {
    inputType: InputTypes.Choice,
    name: 'type',
    label: '',
    defaultValue: widgets[0],
    choiceItems: widgets.map((value) => ({
      title: value === 'modal' ? 'Sheet' : value,
      caption: t(`form.widget.${value}`),
      id: value,
      value,
    })),
  },
];

export const getWidgetLayout = (
  translations: Translations,
  t: TFunction,
  widgetType: WithWidgetType | undefined,
  actions: ActionType[]
): LayoutConfiguration => {
  if (widgetType) {
    const { type } = widgetType;
    const translationOptions = Object.entries(translations)
      .map(([key, value]) => ({ name: key, value: key, description: value }))
      .sort((a, b) => a.value.localeCompare(b.value));

    return [
      {
        inputType: InputTypes.HiddenInput,
        name: 'type',
        value: type,
      },
      {
        inputType: InputTypes.FixedText,
        text: t('form.widget.alignment.header'),
        textHeadingSize: 'xs',
        dependsOn: 'type',
        requiresValueOf: [WidgetTypes.Modal],
      },
      {
        inputType: InputTypes.PillListbox,
        name: 'horizontalAlignment',
        label: t('form.widget.alignment.label'),
        btnLabel: t('form.widget.select_key'),
        options: Object.entries(HorizontalAligns).map(([key, value]) => ({ name: key, value })),
        dependsOn: 'type',
        requiresValueOf: [WidgetTypes.Modal],
        defaultValue: HorizontalAligns.Right,
        includeSearch: false,
        validations: [
          {
            type: ValidationTypes.Required,
            errorMessage: t('form.widget.error'),
          },
        ],
      },
      {
        inputType: InputTypes.FixedText,
        text: t('form.widget.image.header'),
        textHeadingSize: 'xs',
        dependsOn: 'type',
        requiresValueOf: [WidgetTypes.Modal],
      },
      {
        inputType: InputTypes.InlineFormControl,
        type: 'switch',
        name: 'header',
        label: t('form.widget.header_config'),
        dependsOn: 'type',
        requiresValueOf: [WidgetTypes.Modal],
        configuration: getHeaderLayout(t, translationOptions),
      },
      {
        inputType: InputTypes.FixedText,
        text: t('form.widget.container.label'),
        textHeadingSize: 'xs',
        dependsOn: 'type',
        requiresValueOf: [WidgetTypes.Modal, WidgetTypes.Prompt],
        sectionGap: true,
      },
      {
        inputType: InputTypes.PillListbox,
        name: 'title',
        label: t('form.widget.modal_title'),
        btnLabel: t('form.widget.select_key'),
        options: translationOptions,
        dependsOn: 'type',
        requiresValueOf: [WidgetTypes.Modal, WidgetTypes.Prompt],
        defaultValue: 'global.placeholder.text',
        includeSearch: true,
        validations: [
          {
            type: ValidationTypes.Required,
            errorMessage: t('form.widget.title.error'),
          },
        ],
      },
      {
        inputType: InputTypes.FixedText,
        text: t('form.widget.body_text'),
        textHeadingSize: 'xs',
        dependsOn: 'type',
        requiresValueOf: [WidgetTypes.Toast],
      },
      {
        inputType: InputTypes.PillListbox,
        name: 'text',
        label: type === 'toast' ? t('form.widget.toast_header') : t('form.widget.body_text'),
        btnLabel: t('form.widget.select_key'),
        defaultValue: 'global.placeholder.text',
        options: translationOptions,
        includeSearch: true,
        validations: [
          {
            type: ValidationTypes.Required,
            errorMessage: t('form.widget.text.error'),
          },
        ],
      },
      {
        inputType: InputTypes.ModalFormControl,
        editable: true,
        name: 'valueStatements',
        draggable: true,
        sectionLabel: t('form.widget.value.header'),
        label: t('form.widget.value.label'),
        helpMessage: t('form.widget.value.help'),
        dependsOn: 'type',
        requiresValueOf: [WidgetTypes.Modal, WidgetTypes.Prompt],
        valueIdentifier: 'statement',
        configuration: getValueStatementLayout(t, translationOptions),
        modalPrimaryActionText: t('form.general.select'),
        modalSecondaryActionText: t('global.action.cancel'),
        modalEditPrimaryActionText: t('global.action.save'),
        modalEditSecondaryActionText: t('global.delete'),
      },
      {
        inputType: InputTypes.FixedText,
        text: t('form.widget.buttons.header'),
        textHeadingSize: 'xs',
        sectionGap: true,
        dependsOn: 'type',
        requiresValueOf: [WidgetTypes.Modal, WidgetTypes.Prompt],
      },
      {
        inputType: InputTypes.ModalFormControl,
        name: 'actions',
        editable: true,
        disabledFieldsWhenEditable: ['type'],
        draggable: true,
        sectionLabel: t('form.widget.buttons.label'),
        label: t('global.add'),
        dependsOn: 'type',
        requiresValueOf: [WidgetTypes.Modal, WidgetTypes.Prompt],
        valueIdentifier: 'text',
        configuration: getActionLayout(translations, t, type, actions),
        modalPrimaryActionText: t('form.general.select'),
        modalSecondaryActionText: t('global.action.cancel'),
        modalEditPrimaryActionText: t('global.action.save'),
        modalEditSecondaryActionText: t('global.delete'),
      },
      {
        inputType: InputTypes.InlineFormControl,
        type: 'switch',
        name: 'action',
        label: t('form.widget.actions.header'),
        dependsOn: 'type',
        requiresValueOf: [WidgetTypes.Toast],
        configuration: getActionLayout(translations, t, type, actions),
        required: true,
      },
      {
        inputType: InputTypes.FixedText,
        text: t('form.widget.footer'),
        textHeadingSize: 'xs',
        dependsOn: 'type',
        requiresValueOf: [WidgetTypes.Modal, WidgetTypes.Prompt],
        sectionGap: true,
      },
      {
        inputType: InputTypes.Switch,
        name: 'footerDivider',
        label: t('form.widget.footer_divider'),
        dependsOn: 'type',
        subtle: true,
        requiresValueOf: [WidgetTypes.Modal, WidgetTypes.Prompt],
      },
    ];
  } else {
    return [];
  }
};

export const getWidgetRequestLayout = (
  creator: string,
  team: string,
  t: TFunction
): LayoutConfiguration => [
  {
    inputType: InputTypes.HiddenInput,
    name: 'creator',
    value: creator,
  },
  {
    inputType: InputTypes.HiddenInput,
    name: 'team',
    value: team,
  },
  {
    inputType: InputTypes.FixedText,
    label: t('global.created_by'),
    text: creator,
  },
  {
    inputType: InputTypes.FixedText,
    label: t('global.team'),
    text: team,
  },
  {
    inputType: InputTypes.TextInput,
    name: 'figmaLink',
    label: t('form.widget.request.figma_link'),
    helpMessage: t('form.widget.request.figma_help'),
    validations: [
      {
        type: ValidationTypes.Required,
        errorMessage: t('form.general.field.error'),
      },
      {
        type: ValidationTypes.Regexp,
        regexp:
          '^(http(s)?://|www\\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$',
        errorMessage: t('global.url_error'),
      },
    ],
  },
  {
    inputType: InputTypes.TextArea,
    name: 'description',
    limit: 300,
    label: t('global.description'),
    helpMessage: t('form.widget.description_help'),
    validations: [
      {
        type: ValidationTypes.Required,
        errorMessage: t('form.widget.description.error'),
      },
    ],
  },
];

export const getWidgetDescriptionLayout = (t: TFunction): LayoutConfiguration => [
  {
    inputType: InputTypes.TextInput,
    name: 'name',
    label: t('form.widget.name'),
    limit: 50,
    validations: [
      {
        type: ValidationTypes.Required,
        errorMessage: t('form.general.name.error'),
      },
    ],
  },
  {
    inputType: InputTypes.TextArea,
    name: 'description',
    label: t('global.description'),
    validations: [
      {
        type: ValidationTypes.Required,
        errorMessage: t('form.widget.description.error'),
      },
    ],
    limit: 300,
    helpMessage: t('form.widget.description.help'),
  },
];
