import { SagaIterator } from '@redux-saga/types';
import { actionChannel, all, call, fork, put, take } from 'redux-saga/effects';
import { ACTION_ACQUIRE_LOCK, ACTION_RELEASE_LOCK, acquire } from './actions';
import { resourcelockApi } from './api';

/**
 * Request a new lock.
 *
 * @param resourceId - the resource id.
 * @param team - the team.
 * @param lockId - the lock id.
 *
 * @returns the subscription.
 */
function* acquireLock(resourceId: string, team: string, lockId: string): SagaIterator {
  const thunk = yield call(
    resourcelockApi.endpoints.acquire.initiate,
    { resourceId, team },
    { fixedCacheKey: lockId }
  );
  const subscription = yield put(thunk);
  yield call(subscription.unwrap);
  return subscription;
}

/**
 * Release an existing lock.
 *
 * @param resourceId - the resource id.
 *
 * @returns the request subscription.
 */
function* releaseLock(resourceId: string) {
  const thunk = yield call(resourcelockApi.endpoints.release.initiate, { resourceId });
  const subscription = yield put(thunk);
  /*
   * Todo: use the result for something,
   * it resolves with the response body, or rejects on error.
   */
  yield call(subscription.unwrap);
  return subscription;
}

/**
 * A resource locking queue. A lock cannot be acquired before an existing lock is released.
 */
function* lockResource(): SagaIterator {
  const channel = yield actionChannel(ACTION_ACQUIRE_LOCK);

  while (true) {
    const action = yield take(channel);

    if (acquire.match(action)) {
      const {
        payload: { resourceId, team, lockId },
      } = action;

      const [acquiresub] = yield all([
        call(acquireLock, resourceId, team, lockId),
        take(ACTION_RELEASE_LOCK),
      ]);
      const releasesub = yield call(releaseLock, resourceId);

      acquiresub.unsubscribe();
      releasesub.unsubscribe();
    }
  }
}

const sagas = [fork(lockResource)];
export default sagas;
