import { Audit } from '@nfw/audit-types';
import { Routes } from '@nfw/contracts/self-service';
import { toPath } from '@nfw/utils';
import { api } from '../api';
import { IdArg } from '../api/types';

export const auditApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAudits: builder.query<Audit[], IdArg>({
      query: ({ id }) => toPath(Routes.Audits, { id }),
      providesTags: (_, __, { id }) => [{ type: 'audits', id }],
    }),
  }),
});

export const { useGetAuditsQuery } = auditApi;
