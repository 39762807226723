import { HierarchyNode, InputTypes, LayoutConfiguration, ValidationTypes } from '@nfw/form-types';
import { getRetailUnits, RetailUnit } from '@nfw/ikea/retail';
import { IkeaStore } from '@nfw/ikea/store';
import { Namespace, Namespaces } from '@nfw/ikea/utils';
import { ConditionType } from '@nfw/nudge/types';
import { StoredNudgeGroup, StoredWidget } from '@nfw/orchestration-types';
import { exclude } from '@nfw/utils';
import { TFunction } from 'i18next';
import { getConditionLayout } from './Conditions';
import { getPageMatcherLayout } from './PageMatcher';

export const getWidgetRefLayout = (
  widgets: StoredWidget[],
  t: TFunction,
  presetWidgetId?: string
): LayoutConfiguration => {
  const availableWidgets = widgets.map((widget) => ({
    name: widget.name,
    description: widget.description,
    value: widget.id,
  }));

  const presetName = availableWidgets.find((widget) => widget.value === presetWidgetId)?.name;

  return [
    {
      inputType: InputTypes.FixedText,
      text: t('form.feature.form_header'),
      textHeadingSize: 'xs',
    },
    {
      inputType: InputTypes.PillListbox,
      name: 'widgetRef',
      label: t('form.feature.design.label'),
      btnLabel: presetName ?? t('form.feature.design.btn_label'),
      helpMessage: t('form.feature.design.help'),
      options: availableWidgets,
      defaultValue: presetWidgetId,
      includeSearch: true,
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.general.name.error'),
        },
      ],
    },
  ];
};

export const getOrchestrationLayout = (
  retailUnits: RetailUnit[],
  editMode: boolean,
  t: TFunction,
  conditions: ConditionType[],
  stores: IkeaStore[],
  namespaces: Namespace[],
  nudgeGroups?: StoredNudgeGroup[],
  rootNode?: HierarchyNode,
  isLoading?: boolean
): LayoutConfiguration => {
  const baseUrl = 'www.ikea.com/xx/yy/';

  const availablePages = namespaces.map((value) => ({
    name: value,
    value,
    description: value === 'home' ? baseUrl : baseUrl + value,
  }));

  return [
    {
      inputType: InputTypes.TextInput,
      name: 'name',
      limit: 50,
      label: t('form.feature.name.label'),
      helpMessage: t('form.feature.name.help'),
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.general.name.error'),
        },
      ],
    },
    {
      inputType: InputTypes.TextArea,
      name: 'hypothesis',
      label: t('form.feature.hypothesis.label'),
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.feature.hypothesis.error'),
        },
      ],
      helpMessage: t('form.feature.hypothesis.help'),
      limit: 300,
    },
    {
      inputType: InputTypes.TextInput,
      name: 'ticket',
      limit: 100,
      label: t('form.feature.ticket_label'),
      helpMessage: t('form.feature.ticket_help'),
      validations: [
        {
          type: ValidationTypes.Regexp,
          regexp: `https://jira.digital.ingka.com/[^s]*`,
          errorMessage: t('form.feature.ticket_error'),
        },
      ],
    },
    {
      inputType: InputTypes.FixedText,
      text: t('form.feature.placement.label'),
      textHeadingSize: 'xs',
      sectionGap: true,
    },
    {
      inputType: InputTypes.ManyOfMany,
      name: 'retailUnits',
      label: t('form.feature.market.btn_label'),
      header: t('form.feature.market.header'),
      helpMessage: t('form.feature.markets.hint'),
      selectAllText: t('form.general.select_all'),
      selectOneText: t('global.add'),
      clearAllText: t('global.clear_all'),
      values: getRetailUnits()
        .filter((value) => retailUnits.includes(value))
        .map((value) => ({ name: t(`global.country.${value}`), value, extra: value })),
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.features.markets.error'),
        },
      ],
    },
    {
      inputType: InputTypes.ModalFormControl,
      editable: true,
      disabledFieldsWhenEditable: ['type'],
      name: 'conditions',
      sectionLabel: t('global.conditions'),
      label: t('form.feature.condition.btn_label'),
      helpMessage: t('form.feature.condition.section_label'),
      defaultValue: [],
      valueIdentifier: 'type',
      configuration: getConditionLayout(t, conditions, stores),
      modalTitle: t('form.feature.condition.modal_title'),
      modalBodyText: t('form.feature.condition.modal_body'),
      modalPrimaryActionText: t('form.general.select'),
      modalSecondaryActionText: t('global.action.cancel'),
      modalEditPrimaryActionText: t('global.action.save'),
      modalEditSecondaryActionText: t('global.delete'),
    },
    {
      inputType: InputTypes.PillListbox,
      name: 'groupId',
      label: t('form.feature.group_id.label'),
      includeSearch: false,
      placeholderOptionName: t('form.feature.group_id.placeholder'),
      includePlaceholderOption: true,
      disabled: nudgeGroups ? nudgeGroups.length === 0 : true,
      options:
        nudgeGroups?.map(({ id, description, name }) => ({
          name,
          value: id,
          description,
        })) ?? [],
      btnLabel: t('form.feature.group_id.btn_label'),
      helpMessage: t('form.feature.group_id.help_message'),
    },
    {
      inputType: InputTypes.PillListbox,
      name: 'namespace',
      label: t('form.feature.namespace.label'),
      helpMessage: t('form.feature.namespace.description'),
      includeSearch: false,
      btnLabel: t('form.feature.namespace.btn_label'),
      options: availablePages,
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.general.field.error'),
        },
      ],
      disabled: editMode,
    },
    // This is mutually exclusive...
    {
      inputType: InputTypes.InlineFormControl,
      type: 'switch',
      name: 'matcher',
      label: t('form.feature.matcher.label'),
      helpMessage: t('form.feature.matcher.help'),
      dependsOn: 'namespace',
      requiresValueOf: [Namespaces.Pip, Namespaces.Catalogue, Namespaces.Rooms],
      configuration: getPageMatcherLayout(t, true, rootNode, isLoading),
    },
    // ...with this one
    {
      inputType: InputTypes.InlineFormControl,
      type: 'switch',
      name: 'matcher',
      label: t('form.feature.matcher.label'),
      helpMessage: t('form.feature.matcher.help'),
      dependsOn: 'namespace',
      requiresValueOf: exclude<Namespace>(
        Namespaces,
        Namespaces.Pip,
        Namespaces.Catalogue,
        Namespaces.Rooms
      ),
      configuration: getPageMatcherLayout(t, false, rootNode, isLoading),
    },
  ];
};

export const getMarketConfigurationLayout = (t: TFunction): LayoutConfiguration => [
  {
    inputType: InputTypes.Switch,
    label: t('form.configuration.panic'),
    name: 'isStopped',
  },
  {
    inputType: InputTypes.NumberInput,
    name: 'cartLargeSum',
    label: t('form.configuration.cart_large_sum'),
    limit: 10,
    validations: [
      {
        type: ValidationTypes.Required,
        errorMessage: t('form.general.field.error'),
      },
      {
        type: ValidationTypes.Regexp,
        errorMessage: t('form.general.numeric.error'),
        regexp: /^\d+$/,
      },
    ],
  },
];

export const getFrameworkConfigurationLayout = (t: TFunction): LayoutConfiguration => [
  {
    inputType: InputTypes.Slider,
    name: 'maxNudgeCount',
    label: t('form.configuration.nudge_count'),
    defaultValue: 10,
    min: 1,
    max: 30,
    step: 1,
    unit: ' pcs',
  },
  {
    inputType: InputTypes.Slider,
    name: 'maxNudgeCountPerPage',
    label: t('form.configuration.nudge_pagecount'),
    dependsOn: 'maxNudgeCount',
    defaultValue: 2,
    min: 1,
    max: 10,
    step: 1,
    unit: ' pcs',
  },
  {
    inputType: InputTypes.Slider,
    name: 'toastWithinTime',
    label: t('form.configuration.toast_within_time'),
    defaultValue: 10000,
    min: 1000,
    max: 30000,
    step: 1000,
    unit: ' ms',
  },
  {
    inputType: InputTypes.Slider,
    name: 'nudgeIdleTime',
    label: t('form.configuration.nudge_idle_time'),
    defaultValue: 5000,
    min: 1000,
    max: 30000,
    step: 1000,
    unit: ' ms',
  },
];
