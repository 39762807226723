import { createAction } from '@reduxjs/toolkit';

export const ACTION_ACQUIRE_LOCK = 'resourcelocks/ACTION_ACQUIRE_LOCK';
export const ACTION_RELEASE_LOCK = 'resourcelocks/ACTION_RELEASE_LOCK';

export const acquire = createAction(
  ACTION_ACQUIRE_LOCK,
  (resourceId: string, team: string, lockId: string) => ({
    payload: { lockId, team, resourceId },
  })
);
export type AcquireAction = ReturnType<typeof acquire>;

export const release = createAction(ACTION_RELEASE_LOCK);
export type ReleaseAction = ReturnType<typeof release>;
