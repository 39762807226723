import React from 'react';
import classNames from 'classnames';
import Page from '../Page';
import './index.scss';

export type SplitPageProps = {
  children?: React.ReactElement[] | React.ReactElement;
  side?: React.ReactElement;
  compact?: boolean;
  design?: boolean;
  loading?: boolean;
};

const SplitPage: React.FC<SplitPageProps> = ({
  compact,
  children,
  side,
  design = false,
  loading = false,
}) => (
  <Page loading={loading} focusPortal={design}>
    <div className="splitview-content">
      <div className="childview">
        <div className={classNames('content', { compact })}>{children}</div>
      </div>
      <div className={classNames('childview', 'side', { design })}>{side}</div>
    </div>
  </Page>
);

export default SplitPage;
