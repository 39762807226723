import React from 'react';
import classNames from 'classnames';
import './index.scss';

export type LinearLayoutProps = {
  className?: string;
  horizontal?: boolean;
  gap?: 'xs' | 's' | 'm' | 'l' | 'xl';
  padded?: boolean;
  border?: boolean;
  wrap?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
};

export const LinearLayout = React.forwardRef<HTMLDivElement, LinearLayoutProps>(
  ({ children, horizontal = false, gap, padded, border, wrap, className, onClick }, ref) => (
    <div
      onClick={onClick}
      ref={ref}
      className={classNames('layout', className, {
        horizontal,
        'gap-xs': gap === 'xs',
        'gap-s': gap === 's',
        'gap-m': gap === 'm',
        'gap-l': gap === 'l',
        'gap-xl': gap === 'xl',
        padded,
        border,
        wrap,
      })}
    >
      {children}
    </div>
  )
);

export default LinearLayout;
