import { useState } from 'react';
import DetailCard from '../../../components/DetailCard';
import { NudgeGroupFilter } from '../../../components/filters';
import LinearLayout from '../../../components/LinearLayout';
import NudgeGroupStateIndicator from '../../../components/NudgeGroupStateIndicator';
import { selectOverviewLayout } from '../../../features/app/selectors';
import { OverviewNudgeGroup, OverviewOrchestration } from '../../../features/app/types';
import { useMemoizedSelector } from '../../../store';

export type NudgeGroupOverviewProps = {
  nudgeGroups?: OverviewNudgeGroup[];
  orchestrations?: OverviewOrchestration[];
};

const NudgeGroupOverview: React.FC<NudgeGroupOverviewProps> = ({
  nudgeGroups = [],
  orchestrations = [],
}) => {
  const layoutIndex = useMemoizedSelector(selectOverviewLayout, 'nudge_groups');
  const [filteredNudgeGroups, setFilteredNudgeGroups] = useState(nudgeGroups);

  return (
    <>
      <NudgeGroupFilter
        data={nudgeGroups}
        onFilterChange={setFilteredNudgeGroups}
        layoutIndex={layoutIndex}
      />
      <LinearLayout horizontal gap="xl" wrap>
        {filteredNudgeGroups.map(
          ({ id, name, creatorName, team, teamName, created, isArchived, description }) => {
            const nudgeGroupCount = orchestrations.filter(
              (orchestration) => orchestration.groupId === id
            ).length;
            return (
              <DetailCard
                key={id}
                id={id}
                status={
                  <NudgeGroupStateIndicator
                    isArchived={isArchived}
                    nudgeGroupCount={nudgeGroupCount}
                  />
                }
                title={name}
                team={teamName}
                creator={creatorName}
                created={created}
                info={description}
                navigateTo="/nudge-group/:team/:id"
                navigateToParams={{ team, id }}
              />
            );
          }
        )}
      </LinearLayout>
    </>
  );
};

export default NudgeGroupOverview;
