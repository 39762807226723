import React, { useEffect, useState } from 'react';
import Text from '@ingka/text';
import { Widget } from '@nfw/nudge/types';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../components/Breadcrumb';
import DesignPage from '../../components/DesignPage';
import LinearLayout from '../../components/LinearLayout';
import OrchestrationForm from '../../components/OrchestrationForm';
import ProgressIndicator from '../../components/ProgressIndicator';
import WidgetPreview from '../../components/WidgetPreview';
import { PuffaError } from '../../features/api/puffa-error';
import { useGetTeamTranslations } from '../../features/i18n/hooks';
import { selectActiveTeamNudgeGroups } from '../../features/nudgeGroups/selectors';
import { useCreate } from '../../features/orchestrations/hooks';
import { selectActiveTeamWidgets } from '../../features/widgets/selectors';
import { InjectedTeamProps, withRoutedTeamPermissions } from '../../hocs';
import { useMemoizedSelector } from '../../store';

const CreateOrchestrationPage: React.FC<InjectedTeamProps> = ({
  team,
  retailUnits,
  presetWidgetId,
}) => {
  const [progress, setProgress] = useState(0);
  const [selectedWidget, setSelectedWidget] = useState<Widget>();
  const widgets = useMemoizedSelector(selectActiveTeamWidgets, team);
  const nudgeGroups = useMemoizedSelector(selectActiveTeamNudgeGroups, team);
  const { t } = useTranslation();

  const { translations, isLoading: isLoadingTranslations } = useGetTeamTranslations(team);
  const { save, isSaving, defaultOrchestration, isLoadingOrchestration } = useCreate(team);

  useEffect(() => {
    if (!widgets) {
      throw new PuffaError('Failed to load the nudge designs');
    }
  }, [widgets]);

  return (
    <DesignPage
      compact
      loading={isLoadingTranslations || isLoadingOrchestration}
      designComponent={<WidgetPreview widget={selectedWidget} translations={translations} />}
      designOnly
    >
      <LinearLayout gap="l">
        {widgets && !isLoadingOrchestration && (
          <>
            <Breadcrumb crumbs={[{ name: t('global.overview'), to: '/?tab=features' }]} />
            <Text headingSize="m">{t('global.nudge.create')}</Text>
            <ProgressIndicator
              currentPage={progress + 1}
              steps={[
                t('component.progress_indicator.nudge_first'),
                t('component.progress_indicator.nudge_second'),
              ]}
            />
            <OrchestrationForm
              team={team}
              retailUnits={retailUnits}
              widgets={widgets}
              nudgeGroups={nudgeGroups}
              saving={isSaving}
              onSave={save}
              onProgress={setProgress}
              onWidgetSelected={setSelectedWidget}
              defaultFeatureOrchestration={defaultOrchestration}
              presetWidgetId={presetWidgetId}
            />
          </>
        )}
      </LinearLayout>
    </DesignPage>
  );
};

export default withRoutedTeamPermissions(CreateOrchestrationPage, 'EDIT');
