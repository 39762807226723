export const TabNames = {
  Widgets: 'widgets',
  Features: 'features',
  NudgeGroups: 'nudge_groups',
} as const;
export type TabName = (typeof TabNames)[keyof typeof TabNames];

/**
 * Check if a tab name is valid.
 *
 * @param name - the name of the tab.
 *
 * @returns true if valid, otherwise false.
 */
export const isTabName = (name: unknown): name is TabName =>
  Object.values(TabNames).includes(name as TabName);
