import { DefaultMetric } from '@nfw/contracts/experiment';
import { isObject, isString } from '@nfw/utils';

export type NamedMetric = DefaultMetric & {
  name: string;
};

export type ExperimentMeta =
  | { type: 'visibility'; widgetId: undefined }
  | { type: 'design'; widgetId: string };

/**
 * Check if the experiment meta is valid.
 *
 * @param m - the value to test.
 *
 * @returns true when the meta is valid, otherwise false.
 */
export const isExperimentMeta = (m: unknown): m is ExperimentMeta =>
  isObject(m) && (m.type === 'visibility' || (m.type === 'design' && isString(m.widgetId)));
