import Button from '@ingka/button';
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal';
import { Input } from '@nfw/form-types';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import JSONPretty from 'react-json-pretty';

export type CodeModalProps = {
  visible: boolean;
  close: () => void;
  code?: Input;
};

const CodeModal: React.FC<CodeModalProps> = ({ visible, code, close }) => {
  const { t } = useTranslation();

  return createPortal(
    <Modal visible={visible} handleCloseBtn={close}>
      <Sheets
        header={<ModalHeader title={t('global.view.code')} />}
        footer={
          <ModalFooter>
            <Button type="primary" text={t('global.close')} />
          </ModalFooter>
        }
      >
        <ModalBody>
          <JSONPretty silent className="json-pretty" data={code} />
        </ModalBody>
      </Sheets>
    </Modal>,
    document.body
  );
};

export default CodeModal;
