import { Routes } from '@nfw/contracts/self-service';
import { api } from '../api';

export const translationManagerApi = api.injectEndpoints({
  endpoints: (build) => ({
    requestLatestTranslations: build.mutation<void, void>({
      query: () => ({
        url: Routes.TranslationManager,
        method: 'POST',
        invalidatesTags: [{ type: 'translation', id: 'all' }],
      }),
    }),
  }),
});

export const { useRequestLatestTranslationsMutation } = translationManagerApi;
