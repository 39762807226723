import { useCallback, useMemo, useState } from 'react';
import Button from '@ingka/button';
import Modal, { ModalFooter, ModalHeader, Sheets } from '@ingka/modal';
import Text from '@ingka/text';
import { NudgeExperimentTypes } from '@nfw/nudge/types';
import { StoredFeatureOrchestration, toNudge } from '@nfw/orchestration-types';
import { Form, FormCallback } from '@nfw/ui/form';
import { useTranslation } from 'react-i18next';
import { useSetupIterationExperimentLayout } from '../../features/experiments/hooks';
import { ExperimentMeta, isExperimentMeta } from '../../features/experiments/types';
import { selectActiveTeamWidgets } from '../../features/widgets/selectors';
import { useNavigate } from '../../routes';
import { useMemoizedSelector } from '../../store';
import LinearLayout from '../LinearLayout';

export type SetupExperimentPopupProps = {
  orchestration: StoredFeatureOrchestration;
  visible: boolean;
  onClose: () => void;
};

const SetupExperimentPopup: React.FC<SetupExperimentPopupProps> = ({
  orchestration,
  visible,
  onClose,
}) => {
  const [value, setValue] = useState<ExperimentMeta>();
  const [valid, setValid] = useState(false);
  const widgets = useMemoizedSelector(selectActiveTeamWidgets, orchestration.team);

  const { t } = useTranslation();
  const { navigateWithSearch } = useNavigate();

  const onChange = useCallback<FormCallback<ExperimentMeta>>(({ valid, value }) => {
    setValid(valid);
    setValue(value);
  }, []);
  const widgetCandidates = useMemo(
    () =>
      widgets
        ?.filter(({ id }) => id !== orchestration.featureNudge.widgetRef)
        .filter((widget) => toNudge(orchestration, widget)),
    [orchestration, widgets]
  );

  const gotoExperimentPage = useCallback(() => {
    if (value) {
      const { type, widgetId } = value;
      const { id, team } = orchestration;
      const searchParams = new URLSearchParams();

      switch (type) {
        case NudgeExperimentTypes.Design:
          searchParams.set('type', type);
          searchParams.set('widgetId', widgetId);
          break;
        case NudgeExperimentTypes.Visibility: // Fall-through
        default:
          searchParams.set('type', type);
          break;
      }

      navigateWithSearch('/create/experiment/:team/:id', { team, id }, searchParams);
    }
  }, [orchestration, navigateWithSearch, value]);

  const layout = useSetupIterationExperimentLayout(widgetCandidates);

  return (
    <Modal visible={visible} handleCloseBtn={onClose}>
      <Sheets
        header={<ModalHeader />}
        footer={
          <ModalFooter renderBorder>
            <Button
              type="primary"
              disabled={!valid}
              text={t('global.action.continue')}
              onClick={gotoExperimentPage}
            />
            <Button type="secondary" text={t('global.action.cancel')} />
          </ModalFooter>
        }
      >
        <LinearLayout gap="m" padded>
          <Text headingSize="m" bodySize="m">
            {t('experiment.setup.title')}
          </Text>
          <Form config={layout} onChange={onChange} validationFn={isExperimentMeta} />
        </LinearLayout>
      </Sheets>
    </Modal>
  );
};

export default SetupExperimentPopup;
