import { Action, ButtonAction } from './action';
import { IconType } from './icon';

export type RGBColor = `rgb(${number}, ${number}, ${number})`;

export const VerticalAligns = {
  Top: 'top',
  Bottom: 'bottom',
} as const;
export type VerticalAlign = (typeof VerticalAligns)[keyof typeof VerticalAligns];

export const HorizontalAligns = {
  Left: 'left',
  Right: 'right',
} as const;
export type HorizontalAlign = (typeof HorizontalAligns)[keyof typeof HorizontalAligns];

export const Moods = {
  Positive: 'positive',
  Negative: 'negative',
  Neutral: 'neutral',
} as const;
export type Mood = (typeof Moods)[keyof typeof Moods];

export type ValueStatement = {
  mood?: Mood;
  icon?: IconType;
  statement: string;
};

export type ListValue = {
  statements: string[];
  ordered?: boolean;
};

export const AspectRatios = {
  Standard: 'standard',
  Square: 'square',
  Wide: 'wide',
} as const;
export type AspectRatio = (typeof AspectRatios)[keyof typeof AspectRatios];

export type Image = {
  imageSrc: string;
  aspectRatio: AspectRatio;
  altText?: string;
};

export type ModalHeader = Image;

export const WidgetTypes = {
  InlineMessage: 'inline_message',
  Message: 'message',
  Modal: 'modal',
  Reaction: 'reaction',
  Tab: 'tab',
  Teaser: 'teaser',
  Tip: 'tip',
  Toast: 'toast',
  Prompt: 'prompt',
} as const;
export type WidgetType = (typeof WidgetTypes)[keyof typeof WidgetTypes];

/**
 * An expandable menu has a root element that when pressed or hovered
 * will show the menu.
 */
export type ExpanderElement = {
  icon: IconType;
  text?: string;
  color?: RGBColor;
};

/**
 * The base widget type.
 */
type WidgetBase<T extends WidgetType> = {
  type: T;
  text: string;
};

/* *************************** */
/* Action properties
/* *************************** */
type WithAction = { action: Action };
type WithOptionalAction = Partial<WithAction>;
type WithButtonAction = { action: ButtonAction };
type WithButtonActions = { actions: ButtonAction[] };

/* *************************** */
/* Alignment properties
/* *************************** */
type WithHorizontalAlignment = { horizontalAlignment: HorizontalAlign };
type WithOptionalHorizontalAlignment = Partial<WithHorizontalAlignment>;

/* *************************** */
/* Text properties
/* *************************** */
type WithTitle = { title: string };
type WithValueStatementList = { valueStatements: ValueStatement[] };
type WithOptionalValueStatementList = Partial<WithValueStatementList>;

/* *************************** */
/* Color properties
/* *************************** */
type WithOptionalBackgroundColor = { backgroundColor?: RGBColor };

/* *************************** */
/* Color properties
/* *************************** */
type WithImage = { image: Image };

/* *************************** */
/* Toast properties
/* *************************** */
type WithinTimeDisabled = { withinTimeDisabled?: boolean };

/* *************************** */
/* Reaction properties
/* *************************** */
type WithReactions = { reactions: IconType[] };

/* *************************** */
/* Modal properties
/* *************************** */
type WithOptionalCTAText = { ctaText?: string[] };
type WithOptionalList = { list?: ListValue };
type WithOptionalSize = { size?: 'small' | 'medium' | 'large' };
type WithOptionalHeader = { header?: Image };
type WithOptionalFooterDivider = { footerDivider?: boolean };

/* *************************** */
/* Tab properties
/* *************************** */
type WithAccentuate = { accentuate: boolean };
type WithOptionalAccentuation = Partial<WithAccentuate>;

/* *************************** */
/* Inline message properties.
/* *************************** */
type Link = { href: string; label: string };
type WithLinkInText = { link: Link };
type WithOptionalLinkInText = Partial<WithLinkInText>;
type WithMessageVariant = { variant: 'informative' | 'positive' | 'negative' | 'cautionary' };
type WithOptionalMessageVariant = Partial<WithMessageVariant>;
type WithOptionalSubtle = { subtle?: boolean };

/* *************************** */
/* Widgets
/* *************************** */

/**
 * The message widget.
 */
export type MessageWidget = WidgetBase<'message'> & WithOptionalAction;

/**
 * The modal widget.
 */
export type ModalWidget = WidgetBase<'modal'> &
  WithButtonActions &
  WithOptionalBackgroundColor &
  WithTitle &
  WithOptionalCTAText &
  WithOptionalValueStatementList &
  WithOptionalList &
  WithOptionalHeader &
  WithOptionalHorizontalAlignment &
  WithOptionalSize &
  WithOptionalFooterDivider;

/**
 * The prompt widget.
 */
export type PromptWidget = WidgetBase<'prompt'> &
  WithTitle &
  WithButtonActions &
  WithOptionalValueStatementList &
  WithOptionalFooterDivider &
  WithOptionalSubtle;

/**
 * The reaction widget.
 */
export type ReactionWidget = WidgetBase<'reaction'> & WithReactions;

/**
 * The tab widget.
 */
export type TabWidget = WidgetBase<'tab'> & WithButtonAction & WithOptionalAccentuation;

/**
 * The tip widget.
 */
export type TipWidget = WidgetBase<'tip'>;

/**
 * The plain toast widget.
 */
export type ToastWidget = WidgetBase<'toast'> & WithAction & WithinTimeDisabled;

/**
 * The embedded teaser widget.
 */
export type TeaserWidget = WidgetBase<'teaser'> & WithImage & WithTitle & WithButtonAction;

/**
 * The embedded inline message widget.
 */
export type InlineMessageWidget = WidgetBase<'inline_message'> &
  WithTitle &
  WithOptionalLinkInText &
  WithOptionalSubtle &
  WithOptionalMessageVariant &
  WithAction;

/**
 * Union type of all widgets.
 */
export type Widget =
  | InlineMessageWidget
  | MessageWidget
  | ModalWidget
  | ReactionWidget
  | TabWidget
  | TeaserWidget
  | TipWidget
  | ToastWidget
  | PromptWidget;

/**
 * Union type of all widgets with an (optional) action.
 */
export type ActionWidget = Exclude<Widget, ReactionWidget | TipWidget | ModalWidget>;

/**
 * Union type of all overlay widgets.
 */
export type OverlayWidget = Exclude<Widget, EmbeddedWidget>;

/**
 * Union type of all embedded widgets.
 */
export type EmbeddedWidget = TeaserWidget | InlineMessageWidget;
