import Hyperlink from '@ingka/hyperlink';
import { toPath } from '@nfw/utils';
import classNames from 'classnames';
import { useNavigate } from '../../routes';
import { Route } from '../../routes/types';
import './index.scss';

export type PuffaHyperlinkProps = {
  route: Route;
  params?: Record<string, string | number>;
  children: React.ReactNode;
  subtle?: boolean;
};

const PuffaHyperlink: React.FC<PuffaHyperlinkProps> = ({
  route,
  params,
  children,
  subtle = false,
}) => {
  const { navigate } = useNavigate();

  const url = params ? toPath(route, params) : route;

  return (
    <Hyperlink
      className={classNames({ 'no-decoration': subtle })}
      subtle={subtle}
      button
      url={url}
      onClick={(event) => {
        event.preventDefault();
        navigate(route, params);
      }}
    >
      {children}
    </Hyperlink>
  );
};

export default PuffaHyperlink;
