import React, { useEffect, useState } from 'react';
import Text from '@ingka/text';
import { Widget } from '@nfw/nudge/types';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../components/Breadcrumb';
import DesignPage from '../../components/DesignPage';
import LinearLayout from '../../components/LinearLayout';
import PopUp from '../../components/PopUp';
import ProgressIndicator from '../../components/ProgressIndicator';
import WidgetForm from '../../components/WidgetForm';
import WidgetPreview from '../../components/WidgetPreview';
import { useGetTeamTranslations } from '../../features/i18n/hooks';
import { useCreateWidget } from '../../features/widgets/hooks';
import { InjectedTeamProps, withRoutedTeamPermissions } from '../../hocs';

const CreateWidgetPage: React.FC<InjectedTeamProps> = ({ team }) => {
  const [input, setInput] = useState({});
  const [widget, setWidget] = useState<Widget>();
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();
  const [popUpVisible, setPopUpVisible] = useState(false);
  const { create, isLoading, isError } = useCreateWidget(team);

  const { translations, isLoading: isLoadingTranslations } = useGetTeamTranslations(team);

  useEffect(() => {
    if (isError) {
      setPopUpVisible(true);
    }
  }, [isError]);

  // Add placeholder action to widget if non is defined so that the preview can be shown, will be overwritten by user input
  useEffect(() => {
    if (input['type'] === 'toast' && !input['action']) {
      const widgetWithPlaceholderAction = {
        ...input,
        action: { text: 'global.placeholder.text', type: 'dismiss' },
      } as Widget;
      setInput(widgetWithPlaceholderAction);
      setWidget(widgetWithPlaceholderAction);
    } else if ((input['type'] === 'prompt' || input['type'] === 'modal') && !input['actions']) {
      const widgetWithPlaceholderActions = {
        ...input,
        actions: [{ text: 'global.placeholder.text', type: 'dismiss', buttonType: 'primary' }],
      } as Widget;
      setInput(widgetWithPlaceholderActions);
      setWidget(widgetWithPlaceholderActions);
    }
  }, [input, widget]);

  return (
    <DesignPage
      canShowDesign={progress >= 1}
      compact
      designComponent={widget && <WidgetPreview widget={widget} translations={translations} />}
      loading={isLoadingTranslations}
      designOnly
    >
      <PopUp
        visible={popUpVisible}
        title={t('page.error.title')}
        body={t('form.widget.error')}
        close={() => setPopUpVisible(false)}
        actions={[{ type: 'primary', text: t('global.action.retry'), action: () => create(input) }]}
      />
      <LinearLayout gap="l">
        {translations && (
          <>
            <Breadcrumb crumbs={[{ name: t('global.overview'), to: '/?tab=widgets' }]} />
            <Text headingSize="l">{t('global.add_design')}</Text>
            <ProgressIndicator
              currentPage={progress + 1}
              steps={[
                t('component.progress_indicator.design_first'),
                t('component.progress_indicator.design_second'),
                t('component.progress_indicator.design_third'),
              ]}
            />
            <WidgetForm
              loading={isLoading}
              team={team}
              submit={create}
              onInput={setInput}
              onWidget={setWidget}
              onProgress={setProgress}
              translations={translations}
            />
          </>
        )}
      </LinearLayout>
    </DesignPage>
  );
};

export default withRoutedTeamPermissions(CreateWidgetPage, 'EDIT');
