import React from 'react';
import Canvas from '../Canvas';
import SplitPage, { SplitPageProps } from '../SplitPage';

export type DesignPageProps = Omit<SplitPageProps, 'design'> & {
  canShowDesign?: boolean;
  designComponent?: React.ReactElement;
  json?: object;
  designOnly?: boolean;
};

const DesignPage: React.FC<DesignPageProps> = ({
  canShowDesign = true,
  compact,
  loading = false,
  designOnly = false,
  children,
  designComponent,
  json,
}) => (
  <SplitPage
    compact={compact}
    loading={loading}
    design
    side={
      <Canvas
        canShowDesign={canShowDesign}
        component={designComponent}
        json={json}
        designOnly={designOnly}
      />
    }
  >
    {children}
  </SplitPage>
);

export default DesignPage;
