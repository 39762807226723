/* eslint-disable @typescript-eslint/no-this-alias */

export class Node<T> {
  /**
   * The value of the node
   */
  private readonly value: T;

  /**
   * Reference to the next node
   * */
  private next?: typeof this;

  /**
   * Constructor.
   *
   * @param value - the value of the node.
   */
  public constructor(value: T) {
    this.value = value;
  }

  /**
   * Get the value.
   *
   * @returns the value.
   */
  public getValue(): T {
    return this.value;
  }

  /**
   * Set the next node.
   *
   * @param node - the next node.
   *
   * @returns the reference to the next node.
   */
  public setNext(node: typeof this): typeof this {
    this.next = node;
    return node;
  }

  /**
   * Get the next node.
   *
   * @returns the next node, or undefined if not set.
   */
  public getNext(): typeof this | undefined {
    return this.next;
  }

  /**
   * Find the next value node with value.
   *
   * @param value - the value to find.
   *
   * @returns the first node with specified value, or undefined if not found.
   */
  public findNext(value: T): typeof this | undefined {
    return this.findNextBy((node) => node.value === value);
  }

  /**
   * Find next value by a deciding function callback.
   *
   * @param cb - the callback.
   *
   * @returns the first node where the callback returns true, otherwise undefined.
   */
  public findNextBy(cb: (node: typeof this) => boolean): typeof this | undefined {
    const visited: (typeof this)[] = [];
    let current: typeof this | undefined = this;
    let result: typeof this | undefined;

    do {
      if (cb(current)) {
        result = current;
      } else {
        visited.push(current);
        current = current.getNext();
      }
    } while (!result && current && !visited.includes(current));

    return result;
  }
}
