import { useCallback, useEffect, useState } from 'react';
import Button from '@ingka/button';
import Choice, { ChoiceItem } from '@ingka/choice';
import InlineMessage from '@ingka/inline-message';
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal';
import RadioButtonGroup from '@ingka/radio-button-group';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import Text from '@ingka/text';
import { useTranslation, Trans } from 'react-i18next';
import design from '../../assets/create/design.png';
import lightbulb from '../../assets/create/lightbulb.png';
import logic from '../../assets/create/Logic.png';
import { selectIdentifiedTeams } from '../../features/teams/selectors';
import { selectActiveTeamWidgets } from '../../features/widgets/selectors';
import { useNavigate } from '../../routes';
import { useMemoizedSelector } from '../../store';
import './index.scss';

export type NudgePickerProps = {
  open: boolean;
  close: () => void;
  isAllowed: boolean;
  teams: string[];
};

const NudgePicker: React.FC<NudgePickerProps> = ({ open, close, isAllowed, teams }) => {
  const { navigate } = useNavigate();
  const [context, setContext] = useState<string>();
  const [selectedTeam, setSelectedTeam] = useState<string>();
  const teamConfigurations = useMemoizedSelector(selectIdentifiedTeams, teams);
  const teamWidgets = useMemoizedSelector(selectActiveTeamWidgets, selectedTeam);
  const { t } = useTranslation();

  useEffect(() => {
    if (teams.length === 1) {
      setSelectedTeam(teams[0]);
    }
  }, [teams]);

  const createComponent = useCallback(
    (selection: { context?: string }) => {
      const { context } = selection;

      if (selectedTeam) {
        if (context === 'feature') {
          navigate('/create/nudge/:team', { team: selectedTeam });
        } else if (context === 'widget') {
          navigate('/create/design/:team', { team: selectedTeam });
        } else if (context === 'nudge_group') {
          navigate('/create/nudge-group/:team', { team: selectedTeam });
        }
      } else {
        setContext(context);
      }
    },
    [navigate, selectedTeam]
  );

  return (
    <Modal
      id="nudgeSelect"
      visible={open}
      handleCloseBtn={() => {
        setContext(undefined);
        close();
      }}
    >
      <Sheets
        size="small"
        header={<ModalHeader ariaCloseTxt={t('global.close')} />}
        footer={
          <ModalFooter renderBorder>
            <Button
              type="primary"
              text={t('component.nudge_picker.create')}
              disabled={!context || !selectedTeam}
              onClick={() => createComponent({ context })}
            />
          </ModalFooter>
        }
      >
        <ModalBody>
          <Text headingSize="m" className="title">
            {t('component.nudge_picker.title')}
          </Text>
          <Text bodySize="m" className="description">
            {t('component.nudge_picker.description')}
          </Text>
          {teamConfigurations.length > 1 && (
            <>
              <Text headingSize="xs" className="team-header">
                {t('component.nudge_picker.select_team')}
              </Text>
              <RadioButtonGroup
                list={teamConfigurations.map(({ id, name }) => ({
                  id,
                  label: name,
                  name,
                  value: id,
                  checked: selectedTeam === id,
                  onChange: () => {
                    setSelectedTeam(id);
                    setContext(undefined);
                  },
                }))}
              />
            </>
          )}
          {selectedTeam && (
            <>
              <Text headingSize="xs" className="choice-header">
                {t('global.choose_option')}
              </Text>
              <Choice>
                <ChoiceItem
                  title={t('component.nudge_picker.create_design')}
                  caption={t('component.nudge_picker.design_caption')}
                  id="nudge_design"
                  disabled={!isAllowed}
                  onClick={() => setContext('widget')}
                  image={{ src: design }}
                  className="choice-item"
                />
                <ChoiceItem
                  title={t('component.nudge_picker.create_nudge')}
                  caption={t('component.nudge_picker.nudge_caption')}
                  id="nudge"
                  disabled={!isAllowed || !teamWidgets || teamWidgets.length === 0}
                  onClick={() => setContext('feature')}
                  image={{ src: logic }}
                  className="choice-item"
                />
                <ChoiceItem
                  title={t('component.nudge_picker.create_nudge_group')}
                  caption={t('component.nudge_picker.nudge_group_caption')}
                  id="nudge_group"
                  disabled={!isAllowed || !teamWidgets || teamWidgets.length === 0}
                  onClick={() => setContext('nudge_group')}
                  image={{ src: lightbulb }}
                  className="choice-item"
                />
              </Choice>
              <InlineMessage
                variant="informative"
                subtle
                ssrIcon={informationCircle}
                body={
                  <Trans
                    t={t}
                    i18nKey={'component.nudge_picker.inline_message'}
                    values={{ nfw: 'The Nudging Framework' }}
                  />
                }
              />
            </>
          )}
        </ModalBody>
      </Sheets>
    </Modal>
  );
};

export default NudgePicker;
