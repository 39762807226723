import { useState } from 'react';
import Button from '@ingka/button';
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal';
import Text from '@ingka/text';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useOtherPermission } from '../../features/auth/hooks';
import { useGetNudgeGroupMetric, useGetNudgeMetric } from '../../features/orchestrations/hooks';
import PopUp, { ActionProps } from '../PopUp';
import ExpandableTextWrapper from '../TextWrapper';
import './index.scss';

export type NudgeMetricModalProps = {
  visible: boolean;
  close: () => void;
  orchestrationId?: string;
  groupId?: string;
};

const NudgeMetricModal: React.FC<NudgeMetricModalProps> = ({
  visible,
  close,
  orchestrationId,
  groupId,
}) => {
  const { t } = useTranslation();
  const [isInfoPromptVisible, setInfoPromptVisible] = useState(false);
  const dashboardPermission = useOtherPermission('dashboards');

  const { metric: nudgeMetric } = useGetNudgeMetric(
    orchestrationId ? orchestrationId : '',
    !orchestrationId || !visible
  );
  const { metric: groupMetric } = useGetNudgeGroupMetric(
    groupId ? groupId : '',
    !groupId || !visible
  );

  const metric = orchestrationId ? nudgeMetric : groupId ? groupMetric : undefined;

  return createPortal(
    <>
      <InformationPrompt
        close={() => setInfoPromptVisible(false)}
        visible={isInfoPromptVisible}
        id={orchestrationId ? orchestrationId : groupId}
        isGroup={!!groupId}
      />
      ,
      <Modal className="metric-theatre" visible={visible} handleCloseBtn={close}>
        <Sheets
          header={<ModalHeader title={`#${orchestrationId ? orchestrationId : groupId}`} />}
          footer={
            <ModalFooter stacked>
              <Button
                type="secondary"
                text={t('component.metrics_modal.more-info')}
                onClick={() => {
                  if (dashboardPermission) {
                    if (groupId) {
                      window.open(
                        `https://grafana.context.ingka.com/d/ddrj5mqdnzkzkm/nudges?orgId=142&var-nudgeGroup=${groupId}`
                      );
                    } else {
                      window.open(
                        `https://grafana.context.ingka.com/d/ddrj5mqdnzkzkm/nudges?orgId=142&var-nudge=${orchestrationId}`
                      );
                    }
                    close();
                  } else {
                    close();
                    setInfoPromptVisible(true);
                  }
                }}
              />
              <Button type="primary" text={t('global.close')} onClick={close} />
            </ModalFooter>
          }
        >
          <ModalBody className="modal-body">
            <Text headingSize="m">{t('component.metrics_modal.title')}</Text>
            <div className="intro">
              <ExpandableTextWrapper>{t('component.metrics_modal.body')}</ExpandableTextWrapper>
            </div>
            <MetricBox
              titleKey={t('component.metrics_modal.shows')}
              metricValue={metric?.shows}
              explanationKey={t('component.metrics_modal.shows_explanation')}
              key={'shows'}
              hasPrimaryCTA={false}
            />
            <MetricBox
              titleKey={t('component.metrics_modal.clicks')}
              metricValue={metric?.clicks}
              explanationKey={t('component.metrics_modal.clicks_explanation')}
              key={'clicks'}
              hasPrimaryCTA={true}
            />
            <MetricBox
              titleKey={t('component.metrics_modal.dismisses')}
              metricValue={metric?.dismisses}
              explanationKey={t('component.metrics_modal.dismisses_explanation')}
              key={'dismisses'}
              hasPrimaryCTA={false}
            />
            {metric?.blocked !== undefined && metric.blocked > 0 && (
              <MetricBox
                titleKey={t('component.metrics_modal.blocked')}
                metricValue={metric?.blocked}
                explanationKey={t('component.metrics_modal.blocked_explanation')}
                key={'blocked'}
                hasPrimaryCTA={false}
              />
            )}
            {metric?.notPicked !== undefined && metric.notPicked > 0 && (
              <MetricBox
                titleKey={t('component.metrics_modal.not_picked')}
                metricValue={metric?.notPicked}
                explanationKey={t('component.metrics_modal.not_picked_explanation')}
                key={'notPicked'}
                hasPrimaryCTA={false}
              />
            )}
          </ModalBody>
        </Sheets>
      </Modal>
    </>,
    document.body
  );
};

const InformationPrompt = ({ close, visible, id, isGroup }) => {
  const { t } = useTranslation();

  const actions: ActionProps[] = [];

  if (isGroup) {
    actions.push({
      type: 'primary',
      text: t('component.metrics_modal.more-info'),
      href: `https://grafana.context.ingka.com/d/ddrj5mqdnzkzkm/nudges?orgId=142&var-nudgeGroup=${id}`,
    });
  } else {
    actions.push({
      type: 'primary',
      text: t('component.metrics_modal.more-info'),
      href: `https://grafana.context.ingka.com/d/ddrj5mqdnzkzkm/nudges?orgId=142&var-nudge=${id}`,
    });
  }

  actions.push({
    type: 'secondary',
    text: t('global.close'),
    action: close,
  });

  return (
    <PopUp
      title={t('component.metrics_modal.title')}
      close={close}
      visible={visible}
      actions={actions}
      body={t('component.metrics_modal.info')}
    />
  );
};

// Custom metric box component.
const MetricBox = ({ titleKey, metricValue, explanationKey, hasPrimaryCTA }) => {
  const { t } = useTranslation();
  return (
    <div className="metric-box">
      {hasPrimaryCTA ? (
        <div className="click-container">
          <Text bodySize="l" className="metric-title">
            {titleKey}
          </Text>
          <span className="primary">
            <Text bodySize="m" className="cta">
              {t('component.metrics_modal.primary_cta')}
            </Text>
          </span>
        </div>
      ) : (
        <Text bodySize="l" className="metric-title">
          {t(titleKey)}
        </Text>
      )}
      <Text headingSize="l" className="metric">
        {metricValue ? metricValue.toLocaleString() : 'N/A'}
      </Text>
      <Text bodySize="m" className="info">
        {t(explanationKey)}
      </Text>
    </div>
  );
};

export default NudgeMetricModal;
