import { InputTypes, LayoutConfiguration, ValidationTypes } from '@nfw/form-types';
import { IkeaStore } from '@nfw/ikea/store';
import {
  ConditionType,
  ConditionTypes,
  DeviceTypes,
  IdentifiedUserTargets,
  Operands,
} from '@nfw/nudge/types';
import { TFunction } from 'i18next';

export const getConditionLayout = (
  t: TFunction,
  conditions: ConditionType[],
  stores: IkeaStore[]
): LayoutConfiguration => {
  const conditionExplanations = {
    [ConditionTypes.Authenticated]: t('form.feature.condition.authenticated_explanation'),
    [ConditionTypes.DeviceType]: t('form.feature.condition.mobiledevice_explanation'),
    [ConditionTypes.CartCount]: t('form.feature.condition.cart_count_explanation'),
    [ConditionTypes.CartItemAdded]: t('form.feature.condition.cart_item_explanation'),
    [ConditionTypes.CartLargeSum]: t('form.feature.condition.cart_large_sum_explanation'),
    [ConditionTypes.CartQuantity]: t('form.feature.condition.cart_qty_explanation'),
    [ConditionTypes.RewardBalance]: t('form.feature.condition.reward_balance_explanation'),
    [ConditionTypes.RewardCount]: t('form.feature.condition.reward_count_explanation'),
    [ConditionTypes.RewardRedeemable]: t('form.feature.condition.reward_redeemable_explanation'),
    [ConditionTypes.B2BCustomer]: t('form.feature.condition.b2b_explanation'),
    [ConditionTypes.Store]: t('form.feature.condition.store_explanation'),
    [ConditionTypes.PostCode]: t('form.feature.condition.post_code_explanation'),
    [ConditionTypes.Time]: t('form.feature.condition.time_explanation'),
    [ConditionTypes.Traffic]: t('form.feature.condition.traffic_explanation'),
  };
  const conditionOptions = conditions.map((condition) => ({
    name: condition,
    description: conditionExplanations[condition],
    value: condition,
  }));

  const opExplanations = {
    [Operands.And]: t('form.feature.condition.and_explanations'),
    [Operands.Or]: t('form.feature.condition.or_explanations'),
  };

  const opOptions = Object.entries(Operands).map(([name, value]) => ({
    name,
    description: opExplanations[value],
    value,
  }));

  const deviceExplanations = {
    [DeviceTypes.Mobile]: t('global.preview.mobile'),
    [DeviceTypes.Desktop]: t('global.preview.desktop'),
  };

  const deviceOptions = Object.entries(DeviceTypes).map(([name, value]) => ({
    name,
    value,
    description: deviceExplanations[value],
  }));

  return [
    {
      inputType: InputTypes.PillListbox,
      name: 'type',
      label: t('form.feature.condition.select_label'),
      includeSearch: false,
      btnLabel: t('form.general.select'),
      options: conditionOptions,
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.feature.condition.error'),
        },
      ],
    },
    {
      inputType: InputTypes.Divider,
    },
    {
      inputType: InputTypes.InlineMessage,
      variant: 'informative',
      body: t('form.feature.condition.authenticated_helpmessage'),
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.Authenticated],
    },
    {
      inputType: InputTypes.BooleanRadioGroup,
      label: t('form.feature.condition.authenticated_label'),
      name: 'value',
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.Authenticated],
      defaultValue: true,
      trueLabel: t('form.feature.condition.authenticated'),
      falseLabel: t('form.feature.condition.unauthenticated'),
    },
    {
      inputType: InputTypes.InlineMessage,
      subtle: true,
      variant: 'informative',
      body: t('form.feature.condition.authenticated_loyalty_help'),
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.Authenticated],
    },
    {
      inputType: InputTypes.Select,
      name: 'userTarget',
      label: t('form.feature.condition.authenticated_loyalty_select'),
      dependsOn: [
        { name: 'type', requiresValueOf: [ConditionTypes.Authenticated] },
        { name: 'value', requiresValueOf: [true] },
      ],
      defaultValue: IdentifiedUserTargets.All,
      hintText: t('form.feature.condition.authenticated_loyalty_hint'),
      options: [
        {
          name: t('form.feature.condition.authenticated_choice_all'),
          value: IdentifiedUserTargets.All,
        },
        {
          name: t('form.feature.condition.authenticated_choice_family'),
          value: IdentifiedUserTargets.Family,
        },
        {
          name: t('form.feature.condition.authenticated_choice_regular'),
          value: IdentifiedUserTargets.Regular,
        },
        {
          name: t('form.feature.condition.authenticated_choice_business'),
          value: IdentifiedUserTargets.Business,
        },
        {
          name: t('form.feature.condition.authenticated_choice_individual'),
          value: IdentifiedUserTargets.Individual,
        },
        {
          name: t('form.feature.condition.authenticated_choice_family_business'),
          value: IdentifiedUserTargets.FamilyBusiness,
        },
        {
          name: t('form.feature.condition.authenticated_choice_family_individual'),
          value: IdentifiedUserTargets.FamilyIndividual,
        },
        {
          name: t('form.feature.condition.authenticated_choice_regular_business'),
          value: IdentifiedUserTargets.RegularBusiness,
        },
        {
          name: t('form.feature.condition.authenticated_choice_regular_individual'),
          value: IdentifiedUserTargets.RegularIndividual,
        },
      ],
    },
    {
      inputType: InputTypes.InlineMessage,
      variant: 'informative',
      body: t('form.feature.condition.b2b_inline'),
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.B2BCustomer],
    },
    {
      inputType: InputTypes.InlineMessage,
      variant: 'informative',
      body: t('form.feature.condition.cart_large_sum_inline'),
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.CartLargeSum],
    },
    {
      inputType: InputTypes.InlineMessage,
      subtle: true,
      variant: 'informative',
      body: t('form.feature.condition.mobiledevice_helpmessage'),
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.DeviceType],
    },
    {
      inputType: InputTypes.Select,
      label: t('form.feature.condition.mobiledevice'),
      name: 'value',
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.DeviceType],
      options: deviceOptions,
      hintText: t('global.choose_option'),
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.general.field.error'),
        },
      ],
    },
    {
      inputType: InputTypes.Slider,
      label: t('form.feature.condition.reward_balance'),
      defaultValue: 1,
      min: 1,
      max: 500,
      unit: t('form.feature.condition.reward_unit'),
      step: 1,
      name: 'value',
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.RewardBalance],
    },
    {
      inputType: InputTypes.InlineFormControl,
      label: t('form.feature.condition.upper_limit'),
      name: 'limit',
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.RewardBalance],
      type: 'checkbox',
      configuration: [
        {
          inputType: InputTypes.Slider,
          label: t('form.feature.condition.upper_limit_label'),
          defaultValue: 1,
          min: 1,
          max: 500,
          unit: t('form.feature.condition.reward_unit'),
          step: 1,
          name: 'value',
        },
      ],
    },
    {
      inputType: InputTypes.Slider,
      label: t('form.feature.condition.reward_count'),
      defaultValue: 1,
      min: 1,
      max: 500,
      unit: t('form.feature.condition.reward_unit'),
      step: 1,
      name: 'value',
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.RewardCount],
    },
    {
      inputType: InputTypes.InlineFormControl,
      label: t('form.feature.condition.upper_limit'),
      name: 'limit',
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.RewardCount],
      type: 'checkbox',
      configuration: [
        {
          inputType: InputTypes.Slider,
          label: t('form.feature.condition.upper_limit_label'),
          defaultValue: 1,
          min: 1,
          max: 500,
          unit: t('form.feature.condition.reward_unit'),
          step: 1,
          name: 'value',
        },
      ],
    },
    {
      inputType: InputTypes.TextInput,
      name: 'value',
      label: t('form.feature.condition.reward_redeemable'),
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.RewardRedeemable],
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.general.field.error'),
        },
      ],
    },
    {
      inputType: InputTypes.Slider,
      label: t('form.feature.condition.cart_count'),
      defaultValue: 1,
      min: 1,
      max: 100,
      unit: t('form.feature.condition.cart_unique_unit'),
      step: 1,
      name: 'value',
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.CartCount],
    },
    {
      inputType: InputTypes.Slider,
      label: t('form.feature.condition.cart_quantity'),
      defaultValue: 1,
      min: 1,
      max: 150,
      unit: t('form.feature.condition.cart_unit'),
      step: 1,
      name: 'value',
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.CartQuantity],
    },
    {
      inputType: InputTypes.TextInputGroup,
      name: 'values',
      label: t('form.feature.condition.cart_item'),
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.CartItemAdded],
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.general.field.error'),
        },
      ],
    },
    {
      inputType: InputTypes.PillListbox,
      name: 'op',
      label: t('form.feature.condition.cart_item_and_or'),
      includeSearch: false,
      btnLabel: t('form.general.select'),
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.CartItemAdded],
      options: opOptions,
      defaultValue: Operands.And,
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.feature.condition.error'),
        },
      ],
    },
    {
      inputType: InputTypes.InlineMessage,
      body: t('form.feature.condition.rewards_inline'),
      variant: 'informative',
      dependsOn: 'type',
      subtle: true,
      requiresValueOf: [
        ConditionTypes.RewardBalance,
        ConditionTypes.RewardCount,
        ConditionTypes.RewardRedeemable,
      ],
    },
    {
      inputType: InputTypes.InlineMessage,
      body: t('form.feature.condition.cart_inline'),
      variant: 'informative',
      subtle: true,
      dependsOn: 'type',
      requiresValueOf: [
        ConditionTypes.CartCount,
        ConditionTypes.CartQuantity,
        ConditionTypes.CartItemAdded,
      ],
    },
    {
      inputType: InputTypes.CheckboxGroup,
      name: 'values',
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.Store],
      label: t('form.feature.condition.select_store'),
      options: stores.map((store) => ({
        id: store.id,
        label: Object.values(store.locales)[0],
        value: store.id,
      })),
    },
    {
      inputType: InputTypes.TextInputGroup,
      name: 'values',
      label: t('form.feature.condition.postal_code'),
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.PostCode],
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.general.field.error'),
        },
      ],
    },
    {
      inputType: InputTypes.InlineMessage,
      variant: 'informative',
      body: t('form.feature.condition.time_inline'),
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.Time],
    },
    {
      inputType: InputTypes.TextInput,
      name: 'startTime',
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.Time],
      label: t('form.feature.condition.time_start'),
      validations: [
        {
          type: ValidationTypes.Regexp,
          regexp: /^([01]\d|2[0-3]):([0-5]\d)$/,
          errorMessage: t('form.feature.condition.time_error'),
        },
      ],
    },
    {
      inputType: InputTypes.TextInput,
      name: 'endTime',
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.Time],
      label: t('form.feature.condition.time_end'),
      validations: [
        {
          type: ValidationTypes.Regexp,
          regexp: /^([01]\d|2[0-3]):([0-5]\d)$/,
          errorMessage: t('form.feature.condition.time_error'),
        },
      ],
    },
    {
      inputType: InputTypes.InlineMessage,
      variant: 'informative',
      body: t('form.feature.condition.traffic_inline'),
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.Traffic],
    },
    {
      inputType: InputTypes.Slider,
      name: 'value',
      dependsOn: 'type',
      requiresValueOf: [ConditionTypes.Traffic],
      label: t('form.feature.condition.traffic_label'),
      min: 1,
      max: 99,
      unit: '%',
      step: 1,
      defaultValue: 50,
    },
  ];
};
