import React, { useMemo, useState } from 'react';
import Button from '@ingka/button';
import { useTranslation } from 'react-i18next';
import JoinTeamTheatre from '../../components/JoinTeamTheatre';
import Page, { PageHeader } from '../../components/Page';
import TeamRequestModal from '../../components/TeamRequestModal';
import { useGetTeams } from '../../features/teams/hooks';
import Teams from './Teams';

const TeamPage: React.FC = () => {
  const [requestTeam, setRequestTeam] = useState(false);
  const [joinTeam, setJoinTeam] = useState(false);
  const { data: teams, isLoading } = useGetTeams();

  const { t } = useTranslation();
  const approvedTeams = useMemo(
    () => teams?.filter(({ status }) => status === 'approved'),
    [teams]
  );

  return (
    <>
      <TeamRequestModal open={requestTeam} onClose={() => setRequestTeam(false)} />
      <JoinTeamTheatre open={joinTeam} onClose={() => setJoinTeam(false)} />
      <Page
        padded
        loading={isLoading}
        crumbs={[
          { name: t('global.overview'), to: '/?tab=features' },
          { name: t('global.teams.all') },
        ]}
        header={
          <PageHeader
            title={t('global.teams.all')}
            caption={t('page.teams.caption')}
            actions={[
              <Button
                key="request"
                type="secondary"
                text={t('team.onboard.action.request.text')}
                onClick={() => setRequestTeam(true)}
              />,
              <Button
                key="join"
                type="primary"
                text={t('global.join.team')}
                onClick={() => setJoinTeam(true)}
              />,
            ]}
          />
        }
      >
        <Teams configurations={approvedTeams} />
      </Page>
    </>
  );
};

export default TeamPage;
