import React, { useState } from 'react';
import Button from '@ingka/button';
import Hyperlink from '@ingka/hyperlink';
import SSRIcon from '@ingka/ssr-icon';
import checkmarkCircle from '@ingka/ssr-icon/paths/checkmark-circle';
import circle from '@ingka/ssr-icon/paths/circle';
import incorrect from '@ingka/ssr-icon/paths/incorrect';
import Table, { TableBody, TableHeader } from '@ingka/table';
import Text from '@ingka/text';
import { TeamConfiguration } from '@nfw/contracts/self-service';
import { toDateString } from '@nfw/utils';
import { useTranslation } from 'react-i18next';
import ExperimentResultTheatre from '../../components/ExperimentResultTheatre';
import { TeamFilter } from '../../components/filters';
import ManageTeamRequestModal from '../../components/ManageTeamRequestModal';
import Page, { PageHeader } from '../../components/Page';
import UpdateFrameworkConfigModal from '../../components/UpdateFrameworkConfigModal';
import UpdateMarketConfigModal from '../../components/UpdateMarketConfigModal';
import { useGetTeams } from '../../features/teams/hooks';
import './index.scss';

const AdminPage: React.FC = () => {
  const [filteredTeams, setFilteredTeams] = useState<TeamConfiguration[]>([]);
  const [isMarketConfigOpen, setMarketConfigOpen] = useState(false);
  const [isFrameworkConfigOpen, setFrameworkConfigOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [experimentResultVisible, setExperimentResultVisible] = useState(false);
  const [selectedTeam, setTeam] = useState<TeamConfiguration>();
  const { t } = useTranslation();

  const { data, isLoading } = useGetTeams();

  return (
    <Page
      padded
      loading={isLoading}
      crumbs={[
        { name: t('global.overview'), to: '/?tab=features' },
        { name: t('component.header.admin') },
      ]}
      header={
        <PageHeader
          title={t('component.header.admin')}
          caption={t('global.request.team.description')}
          actions={[
            <Button
              key="experimentResult"
              type="primary"
              text={t('page.admin.experiment_history')}
              onClick={() => setExperimentResultVisible(true)}
            />,
            <Button
              key="updateFrameworkConfig"
              type="secondary"
              text={t('global.update_framework_config')}
              onClick={() => setFrameworkConfigOpen(true)}
            />,
            <Button
              key="updateMarketConfig"
              type="secondary"
              text={t('global.update_config')}
              onClick={() => setMarketConfigOpen(true)}
            />,
          ]}
        />
      }
    >
      {data && (
        <>
          <UpdateMarketConfigModal
            open={isMarketConfigOpen}
            onClose={() => setMarketConfigOpen(false)}
          />
          <UpdateFrameworkConfigModal
            open={isFrameworkConfigOpen}
            onClose={() => setFrameworkConfigOpen(false)}
          />
          <ExperimentResultTheatre
            visible={experimentResultVisible}
            close={() => setExperimentResultVisible(false)}
          />
          {selectedTeam && (
            <ManageTeamRequestModal
              visible={modalVisible}
              team={selectedTeam}
              close={() => setModalVisible(false)}
            />
          )}
          <TeamFilter data={data} onFilterChange={setFilteredTeams} />
          <Table fullWidth inset>
            <TableHeader sticky>
              <tr>
                <th>{t('global.team')}</th>
                <th>{t('global.administrator')}</th>
                <th>{t('team.onboard.date.received')}</th>
                <th>{t('global.status')}</th>
              </tr>
            </TableHeader>
            <TableBody>
              {filteredTeams.map((team) => (
                <tr key={team.id}>
                  <td className="team">
                    <Hyperlink
                      onClick={(event) => {
                        event.preventDefault();
                        setTeam(team);
                        setModalVisible(true);
                      }}
                    >
                      <b>{team.name}</b>
                    </Hyperlink>
                  </td>
                  <td>{team.creator}</td>
                  <td>{toDateString(team.created)}</td>
                  <td>
                    {team.status === 'rejected' && (
                      <div className="request-status">
                        <SSRIcon className="icon reject" paths={incorrect} />
                        <Text bodySize="m">{t('global.reject')}</Text>
                      </div>
                    )}
                    {team.status === 'approved' && (
                      <div className="request-status">
                        <SSRIcon className="icon approve" paths={checkmarkCircle} />
                        <Text bodySize="m"> {t('global.approve')}</Text>
                      </div>
                    )}
                    {team.status === 'pending' && (
                      <div className="request-status">
                        <SSRIcon className="icon open" paths={circle} />
                        <Text bodySize="m"> {t('global.open')}</Text>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </Page>
  );
};

export default AdminPage;
