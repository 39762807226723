import { useState } from 'react';
import Avatar from '@ingka/avatar';
import Button from '@ingka/button';
import certificate from '@ingka/ssr-icon/paths/certificate';
import contract from '@ingka/ssr-icon/paths/contract';
import dataChart from '@ingka/ssr-icon/paths/data-chart';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import network from '@ingka/ssr-icon/paths/network';
import people from '@ingka/ssr-icon/paths/people';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Logo from '../../assets/logo/logo.png';
import { usePermission } from '../../features/auth/hooks';
import { selectInitials, selectName } from '../../features/auth/selectors';
import { useNavigate } from '../../routes';
import { Routes } from '../../routes/types';
import ProfileSlider from '../ProfileSlider';
import PuffaHyperlink from '../PuffaHyperlink';
import SupportModal from '../SupportModal';
import './index.scss';

export const Header: React.FC = () => {
  const name = useSelector(selectName);
  const initials = useSelector(selectInitials);
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();
  const [isSupportVisible, setSupportVisible] = useState(false);

  const { navigate } = useNavigate();
  const { isTeamSuperAdmin: isSuperAdmin } = usePermission('MANAGE_TEAM');

  return (
    <>
      <ProfileSlider modalVisible={modalVisible} onClose={() => setModalVisible(false)} />
      <SupportModal visible={isSupportVisible} onClose={() => setSupportVisible(false)} />
      <div className="header-container">
        <PuffaHyperlink route="/home" subtle>
          <div className="header">
            <img src={Logo} height="32" alt="nfw-logo" />
            <div className="application-name">
              <Text bodySize="s" className="text-color">
                {t('global.app.name')}
              </Text>
              <Text className="text-color">
                <b>{t('global.app.system')}</b>
              </Text>
            </div>
          </div>
        </PuffaHyperlink>
        <div className="actions">
          <div>
            <Button
              type="tertiary"
              inverseTheme
              size="small"
              href={Routes.ViewTeams}
              onClick={(event) => {
                event.preventDefault();
                navigate(Routes.ViewTeams);
              }}
              key="teams"
              ssrIcon={people}
              text={t('global.teams.all')}
            />
            <Button
              type="tertiary"
              inverseTheme
              size="small"
              key="support"
              ssrIcon={informationCircle}
              onClick={() => setSupportVisible(true)}
              text={t('global.support')}
            />
            <Button
              type="tertiary"
              inverseTheme
              size="small"
              href={Routes.Collision}
              onClick={(event) => {
                event.preventDefault();
                navigate(Routes.Collision);
              }}
              key="collisions"
              ssrIcon={contract}
              text={t('page.nudge_collisions.header')}
            />
            <Button
              type="tertiary"
              inverseTheme
              size="small"
              href={Routes.NudgeOfFame}
              onClick={(event) => {
                event.preventDefault();
                navigate(Routes.NudgeOfFame);
              }}
              key="fame"
              ssrIcon={certificate}
              text={t('page.nudge_of_fame.header')}
            />
            {isSuperAdmin && (
              <>
                <Button
                  type="tertiary"
                  inverseTheme
                  size="small"
                  href={Routes.ViewRequests}
                  onClick={(event) => {
                    event.preventDefault();
                    navigate(Routes.ViewRequests);
                  }}
                  key="admin"
                  ssrIcon={network}
                  text={t('component.header.admin')}
                />
                <Button
                  type="tertiary"
                  inverseTheme
                  size="small"
                  href={Routes.HandleEvents}
                  onClick={(event) => {
                    event.preventDefault();
                    navigate(Routes.HandleEvents);
                  }}
                  key="default"
                  ssrIcon={dataChart}
                  text={t('component.header.default_events')}
                />
              </>
            )}
          </div>
          <Avatar
            id="profile"
            size="medium"
            screenReaderText={t('global.profile.welcome', { name })}
            text={initials}
            staticColour
            variant="secondary"
            onClick={() => setModalVisible(true)}
          />
        </div>
      </div>
    </>
  );
};
