import Text from '@ingka/text';
import { StoredWidget } from '@nfw/orchestration-types';
import { toDateString } from '@nfw/utils';
import { useTranslation } from 'react-i18next';
import { selectTeamName } from '../../features/teams/selectors';
import { useMemoizedSelector } from '../../store';
import DescribedItem from '../DescribedItem';
import LinearLayout from '../LinearLayout';

export type WidgetDescriptionProps = {
  widget: StoredWidget;
  detailsHeader: string;
  configurationHeader: string;
};

const WidgetDescription: React.FC<WidgetDescriptionProps> = ({
  widget,
  detailsHeader,
  configurationHeader,
}) => {
  const { type, text, creatorName, description, created } = widget;
  const teamName = useMemoizedSelector(selectTeamName, widget.team);
  const { t } = useTranslation();

  return (
    <>
      <LinearLayout gap="l" border padded>
        <Text headingSize="xs">{detailsHeader}</Text>
        <DescribedItem key={'created_by'} title={t('global.created_by')} text={creatorName} />
        <DescribedItem key={'team'} title={t('global.team')} text={teamName} />
        <DescribedItem key={'description'} title={t('global.description')} text={description} />
        <DescribedItem
          key={'created'}
          title={t('global.created')}
          text={toDateString(created, true)}
        />
      </LinearLayout>
      <LinearLayout gap="l" border padded>
        <Text headingSize="xs">{configurationHeader}</Text>
        <DescribedItem key={'component_type'} title={t('global.component_type')} text={type} />
        <DescribedItem key={'body'} title={t('form.widget.body_content')} text={text} />
        {type === 'modal' && (
          <>
            <DescribedItem key={'title'} title={t('form.widget.modal_title')} text={widget.title} />
            {widget.actions?.map((action, index) => (
              <DescribedItem
                key={`modalAction-${index}`}
                title={t('page.widget_details.action', { index: index + 1 })}
                text={action.type}
              />
            ))}
          </>
        )}
        {type === 'toast' && widget.action && (
          <DescribedItem
            key={'toastAction'}
            title={t('form.widget.actions.label')}
            text={widget.action.type}
          />
        )}
        {type === 'prompt' && (
          <>
            <DescribedItem key={'title'} title={t('form.widget.modal_title')} text={widget.title} />
            {widget.actions?.map((action, index) => (
              <DescribedItem
                key={`promptAction-${index}`}
                title={t('page.widget_details.action', { index: index + 1 })}
                text={action.type}
              />
            ))}
          </>
        )}
      </LinearLayout>
    </>
  );
};

export default WidgetDescription;
