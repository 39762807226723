import { Translations } from '@nfw/contracts/self-service';
import { InputTypes, LayoutConfiguration, ValidationTypes } from '@nfw/form-types';
import {
  ActionType,
  ActionTypes,
  ButtonTypes,
  IconType,
  IconTypes,
  WidgetTypes,
} from '@nfw/nudge/types';
import { TFunction } from 'i18next';

const getExplanation = (
  t: TFunction,
  key: string
): { translation: string; icon: IconType | undefined } => {
  switch (key) {
    case 'call':
      return { translation: t('form.widget.action.call.explanation'), icon: 'phone' };
    case 'goto':
      return { translation: t('form.widget.action.goto.explanation'), icon: 'link' };
    case 'goto_cart':
      return { translation: t('form.widget.action.goto_cart.explanation'), icon: 'shopping-bag' };
    case 'goto_external':
      return { translation: t('form.widget.action.goto_external.explanation'), icon: 'globe' };
    case 'goto_favourites':
      return { translation: t('form.widget.action.favorites.explanation'), icon: 'heart' };
    case 'login':
      return { translation: t('form.widget.action.login.explanation'), icon: 'lock' };
    case 'nudge':
      return { translation: t('form.widget.action.nudge.explanation'), icon: 'pairing' };
    case 'dismiss':
      return { translation: t('form.widget.action.dismiss.explanation'), icon: 'cross' };
    case 'positive':
      return {
        translation: t('form.widget.action.positive.explanation'),
        icon: 'thumbs-up',
      };
    case 'negative':
      return {
        translation: t('form.widget.action.negative.explanation'),
        icon: 'thumbs-down',
      };
    default:
      return { translation: '', icon: undefined };
  }
};

export const getActionLayout = (
  translations: Translations,
  t: TFunction,
  widgetType: string,
  actions: ActionType[]
): LayoutConfiguration => {
  const translationOptions = Object.entries(translations)
    .map(([key, value]) => ({ name: key, value: key, description: value }))
    .sort((a, b) => a.value.localeCompare(b.value));

  const list: LayoutConfiguration = [
    {
      inputType: InputTypes.PillListbox,
      name: 'type',
      label: t('form.widget.actions.label'),
      btnLabel: t('form.widget.action.label'),
      includeSearch: true,
      options: actions.map((value) => ({
        description: getExplanation(t, value).translation,
        name: value,
        value,
        icon: getExplanation(t, value).icon,
      })),
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.general.action.type.error'),
        },
      ],
    },
    {
      inputType: InputTypes.PillListbox,
      name: 'text',
      btnLabel: t('form.widget.select_key'),
      options: translationOptions,
      label: t('form.widget.action.cta'),
      includeSearch: false,
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.general.field.error'),
        },
      ],
    },
    {
      inputType: InputTypes.TextInput,
      name: 'path',
      label: t('form.widget.action.path.label'),
      helpMessage: t('form.widget.action.path.help', {
        path: 'https://www.ikea.com/xx/yy',
        interpolation: { escapeValue: false },
      }),
      dependsOn: 'type',
      requiresValueOf: [ActionTypes.GoTo],
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.general.field.error'),
        },
      ],
    },
    {
      inputType: InputTypes.TextInput,
      name: 'path',
      label: t('form.widget.action.loginpath.label'),
      helpMessage: t('form.widget.action.loginpath.help', {
        path: 'https://www.ikea.com/xx/yy',
        interpolation: { escapeValue: false },
      }),
      dependsOn: 'type',
      requiresValueOf: [ActionTypes.Login],
    },
    {
      inputType: InputTypes.TextInput,
      name: 'path',
      label: t('form.widget.action.external_path.label'),
      helpMessage: t('form.widget.action.external_path.help'),
      dependsOn: 'type',
      requiresValueOf: [ActionTypes.GoToExternal],
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.general.field.error'),
        },
      ],
    },
    {
      inputType: InputTypes.PillListbox,
      name: 'number',
      label: t('form.widget.action.number.label'),
      btnLabel: t('form.widget.select_key'),
      options: translationOptions,
      includeSearch: true,
      dependsOn: 'type',
      requiresValueOf: [ActionTypes.Call],
      helpMessage: t('form.widget.action.number.help'),
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.general.field.error'),
        },
      ],
    },
    {
      inputType: InputTypes.TextInput,
      name: 'nudgeId',
      label: t('form.widget.action.nudge.label'),
      helpMessage: t('form.widget.action.nudge.help'),
      dependsOn: 'type',
      requiresValueOf: [ActionTypes.Nudge],
      validations: [
        {
          type: ValidationTypes.Required,
          errorMessage: t('form.general.field.error'),
        },
      ],
    },
  ];

  if (widgetType === WidgetTypes.Modal || widgetType === WidgetTypes.Prompt) {
    list.push(
      {
        inputType: InputTypes.PillListbox,
        name: 'buttonType',
        label: t('form.widget.button-style'),
        btnLabel: t('form.widget.button-style'),
        includeSearch: false,
        options: Object.values(ButtonTypes).map((value) => ({ name: value, value })),
        validations: [
          {
            type: ValidationTypes.Required,
            errorMessage: t('form.general.action.type.error'),
          },
        ],
      },
      {
        inputType: InputTypes.InlineFormControl,
        name: 'icon',
        label: t('form.widget.icon.label'),
        type: 'switch',
        configuration: [
          {
            inputType: InputTypes.RadioGroup,
            name: 'iconPosition',
            label: t('form.widget.icon.label'),
            options: [
              { label: t('form.widget.icon.leading'), id: 'leading', value: 'leading' },
              { label: t('form.widget.icon.trailing'), id: 'trailing', value: 'trailing' },
            ],
          },
          {
            inputType: InputTypes.PillListbox,
            name: 'iconType',
            label: t('form.widget.action.icon.label'),
            btnLabel: t('global.choose_option'),
            includeSearch: false,
            options: Object.values(IconTypes).map((value) => ({ name: value, value, icon: value })),
          },
        ],
      }
    );
  }

  return list;
};
