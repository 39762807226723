import { useSelector } from 'react-redux';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import { selectFocusPortalEnabled } from '../features/app/selectors';
import AdminPage from '../pages/AdminPage';
import CollisionPage from '../pages/CollisionPage';
import CreateNudgeGroupPage from '../pages/CreateNudgeGroupPage';
import CreateOrchestrationPage from '../pages/CreateOrchestrationPage';
import CreateWidgetPage from '../pages/CreateWidgetPage';
import EditNudgeGroupPage from '../pages/EditNudgeGroupPage';
import EditOrchestrationPage from '../pages/EditOrchestrationPage';
import EditWidgetPage from '../pages/EditWidgetPage';
import ExperimentPage from '../pages/ExperimentPage';
import HandleEventsPage from '../pages/HandleEventsPage';
import NudgeGroupDetailsPage from '../pages/NudgeGroupDetailsPage';
import NudgeOfFamePage from '../pages/NudgeOfFamePage';
import OrchestrationDetailsPage from '../pages/OrchestrationDetailsPage';
import OverviewPage from '../pages/OverviewPage';
import TeamDetailsPage from '../pages/TeamDetailsPage';
import TeamPage from '../pages/TeamPage';
import MarketTeamTranslationPage from '../pages/TranslationPage/MarketTeamTranslationPage';
import ProductTeamTranslationPage from '../pages/TranslationPage/ProductTeamTranslationPage';
import WelcomePage from '../pages/WelcomePage';
import WidgetDetailsPage from '../pages/WidgetDetailsPage';
import ErrorBoundary from './ErrorBoundary';
import { Routes } from './types';

export * from './hooks';

const RootLayout: React.FC = () => {
  const enabled = useSelector(selectFocusPortalEnabled);

  return (
    <ErrorBoundary>
      <PageLayout focusPortal={enabled}>
        <Outlet />
      </PageLayout>
    </ErrorBoundary>
  );
};

export const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      {
        path: Routes.EditWidget,
        element: <EditWidgetPage />,
      },
      {
        path: Routes.CreateWidget,
        element: <CreateWidgetPage />,
      },
      {
        path: Routes.CreateOrchestration,
        element: <CreateOrchestrationPage />,
      },
      {
        path: Routes.CreateOrchestrationWithWidget,
        element: <CreateOrchestrationPage />,
      },
      {
        path: Routes.CopyOrchestration,
        element: <CreateOrchestrationPage />,
      },
      {
        path: Routes.EditOrchestration,
        element: <EditOrchestrationPage />,
      },
      {
        path: Routes.CreateExperiment,
        element: <ExperimentPage />,
      },
      {
        path: Routes.ViewTeams,
        element: <TeamPage />,
      },
      {
        path: Routes.ViewTeam,
        element: <TeamDetailsPage />,
      },
      {
        path: Routes.ViewOrchestration,
        element: <OrchestrationDetailsPage />,
      },
      {
        path: Routes.ViewWidget,
        element: <WidgetDetailsPage />,
      },
      {
        path: '/*',
        element: <WelcomePage />,
      },
      {
        path: Routes.HandleEvents,
        element: <HandleEventsPage />,
      },
      {
        path: Routes.Home,
        element: <OverviewPage />,
      },
      {
        path: Routes.ViewRequests,
        element: <AdminPage />,
      },
      {
        path: Routes.Collision,
        element: <CollisionPage />,
      },
      {
        path: Routes.NudgeOfFame,
        element: <NudgeOfFamePage />,
      },
      {
        path: Routes.ProductTeamTranslations,
        element: <ProductTeamTranslationPage />,
      },
      {
        path: Routes.MarketTeamTranslations,
        element: <MarketTeamTranslationPage />,
      },
      {
        path: Routes.CreateNudgeGroup,
        element: <CreateNudgeGroupPage />,
      },
      {
        path: Routes.EditNudgeGroupPage,
        element: <EditNudgeGroupPage />,
      },
      {
        path: Routes.ViewNudgeGroup,
        element: <NudgeGroupDetailsPage />,
      },
    ],
  },
]);
