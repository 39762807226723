import { useEffect, useMemo, useState } from 'react';
import InlineMessage from '@ingka/inline-message';
import ListView, { ListViewItem } from '@ingka/list-view';
import Loading, { LoadingBall } from '@ingka/loading';
import Modal, { ModalBody, ModalHeader, Sheets } from '@ingka/modal';
import Text from '@ingka/text';
import { Audit } from '@nfw/audit-types';
import { toDateString } from '@nfw/utils';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useGetAuditsQuery } from '../../features/audits/api';
import './index.scss';

export type AuditsProps = {
  id: string;
  modified?: number;
};

const getTitle = (audit: Audit, t: TFunction): string => {
  const { auditAction } = audit;

  switch (auditAction) {
    case 'create':
      return t('global.created');
    case 'update':
      return t('global.updated');
    case 'publish': {
      const { locale, success, environment } = audit;
      if (success) {
        return t('component.changelog.success', {
          locale: locale,
          environment: environment.toUpperCase(),
        });
      } else {
        return t('component.changelog.failure', {
          locale: locale,
          environment: environment.toUpperCase(),
        });
      }
    }
    case 'republish':
      return t('component.changelog.republish');
    case 'create_experiment':
      return t('component.changelog.confirmation', {
        locale: audit.audiences.join(', '),
      });
    case 'start_experiment':
      return t('component.changelog.experiment_started', {
        locale: audit.locale,
      });
    case 'update_experiment':
      return t('component.changelog.experiment_updated');
    case 'archive':
      return t('global.state.archived');
    case 'remove_experiment':
      return t('component.changelog.experiment_removed');
    case 'update_market_translation':
      return t('component.changelog.market_translation_updated');
    default:
      return '';
  }
};

const Audits: React.FC<AuditsProps> = ({ id, modified }) => {
  const [visible, setVisible] = useState(false);
  const { data, refetch, isError, isLoading } = useGetAuditsQuery({ id });
  const { t } = useTranslation();

  const sortedData = useMemo(() => {
    return (
      data
        ?.filter(({ name }) => name !== 'system' && !name.includes('@')) // Filtering our system updates
        .sort((a, b) => b.timestamp - a.timestamp) || []
    );
  }, [data]);

  useEffect(() => {
    if (visible) {
      refetch();
    }
  }, [refetch, visible]);

  return (
    <>
      <div className="modified-div">
        <Text bodySize="m">
          {t('global.last.modified', {
            changed:
              modified || sortedData[0]?.timestamp
                ? toDateString(sortedData[0]?.timestamp ?? modified, true)
                : 'N/A',
            latestEditor: sortedData[0]?.name,
            interpolation: { escapeValue: false },
          })}
        </Text>
        <Text className="changelog" bodySize="m" onClick={() => setVisible(true)}>
          {t('component.changelog.view_all')}
        </Text>
      </div>

      <Modal visible={visible} handleCloseBtn={() => setVisible(false)}>
        <Sheets
          footer={null}
          size="small"
          header={<ModalHeader ariaCloseTxt={t('global.close')} />}
        >
          <ModalBody>
            <Text tagName="h1" bodySize="xl" className="header">
              {t('component.changelog.title')}
            </Text>
            {isError && (
              <InlineMessage
                variant="negative"
                body={t('global.error.generic')}
                actions={[
                  {
                    text: t('global.action.retry'),
                    onClick: refetch,
                  },
                ]}
              />
            )}
            {isLoading && (
              <Loading text="">
                <LoadingBall size="large" />
              </Loading>
            )}
            <ListView size="large" id="audits">
              {sortedData.map((audit) => {
                const { timestamp, name } = audit;
                const title = getTitle(audit, t);

                const description = t('component.changelog.description', {
                  changed: toDateString(timestamp, true),
                  name,
                  interpolation: { escapeValue: false },
                });
                return (
                  <ListViewItem
                    key={timestamp}
                    id={`${timestamp}`}
                    title={<b>{title}</b>}
                    description={description}
                  />
                );
              })}
            </ListView>
          </ModalBody>
        </Sheets>
      </Modal>
    </>
  );
};

export default Audits;
