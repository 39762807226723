import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DetailCard from '../../../components/DetailCard';
import { OrchestrationFilter } from '../../../components/filters';
import LinearLayout from '../../../components/LinearLayout';
import StateIndicator from '../../../components/StateIndicator';
import { selectOverviewLayout } from '../../../features/app/selectors';
import { OverviewOrchestration } from '../../../features/app/types';
import { useMemoizedSelector } from '../../../store';

export type OrchestrationOverviewProps = {
  orchestrations?: OverviewOrchestration[];
};

const OrchestrationOverview: React.FC<OrchestrationOverviewProps> = ({ orchestrations = [] }) => {
  const layoutIndex = useMemoizedSelector(selectOverviewLayout, 'orchestrations');
  const { t } = useTranslation();
  const [filteredFeatures, setFilteredFeatures] = useState(orchestrations);

  return (
    <>
      <OrchestrationFilter
        data={orchestrations}
        onFilterChange={setFilteredFeatures}
        layoutIndex={layoutIndex}
      />
      <LinearLayout horizontal gap="xl" wrap>
        {filteredFeatures.map(
          ({
            id,
            name,
            creatorName,
            team,
            teamName,
            retailUnits,
            created,
            consolidatedStatus,
            isArchived,
          }) => (
            <DetailCard
              key={id}
              id={id}
              status={
                <StateIndicator isArchived={isArchived} consolidatedStatus={consolidatedStatus} />
              }
              title={name}
              team={teamName}
              info={retailUnits.map((ru) => t(`global.country.${ru}`)).join(', ')}
              creator={creatorName}
              created={created}
              navigateTo="/nudge/:team/:id"
              navigateToParams={{ team, id }}
            />
          )
        )}
      </LinearLayout>
    </>
  );
};

export default OrchestrationOverview;
