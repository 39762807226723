/**
 * The visibility types.
 */
export const VisibilityTypes = {
  /**
   * Always show the widget.
   */
  Always: 'always',
  /**
   * Only show the widget when anchor is visible in viewport.
   */
  Visible: 'visible',
  /**
   * Only show the widget when anchor is gone from viewport.
   */
  Gone: 'gone',
  /**
   * Only show the widget when anchor is under the viewport.
   */
  UnderViewport: 'under_viewport',
} as const;
export type VisibilityType = (typeof VisibilityTypes)[keyof typeof VisibilityTypes];

/**
 * The available exception types.
 */
export const VisibilityExceptionTypes = {
  DistanceGreaterThan: 'distance_gt',
  DistanceLessThan: 'distance_lt',
  VerticallyAligned: 'v_aligned',
  HorizontallyAligned: 'h_aligned',
  Intersecting: 'intersecting',
} as const;
export type VisibilityExceptionType =
  (typeof VisibilityExceptionTypes)[keyof typeof VisibilityExceptionTypes];

type VisibilityExceptionBase<S extends VisibilityExceptionType> = {
  type: S;
  show: boolean;
};

/**
 * The distance visibility. When nudge is within distance it will be triggered.
 */
export type DistanceVisibilityException = VisibilityExceptionBase<'distance_gt' | 'distance_lt'> & {
  distance: number;
};

/**
 * The align visibility exception. When nudge is aligned horizontally or vertically it will be triggered given that
 * it is in the range.
 */
export type AlignVisibilityException = VisibilityExceptionBase<'h_aligned' | 'v_aligned'> & {
  range: number;
};

/**
 * The intersecting visibility exception. When nudge is intersecting it will be triggered.
 */
export type IntersectingVisibilityException = VisibilityExceptionBase<'intersecting'> & {
  intersecting: boolean;
};

/**
 * The visibility exceptions.
 */
export type VisibilityException =
  | DistanceVisibilityException
  | AlignVisibilityException
  | IntersectingVisibilityException;

/**
 * The nudge visibility rule.
 */
export type NudgeVisibilityRule = {
  /**
   * Defines when to show the nudge.
   */
  when: VisibilityType;
  /**
   * A set of exceptions that are enforced for visibility.
   */
  exceptions?: VisibilityException[];
};
