import { LockPermissions, LockRequest, ReleaseRequest, Routes } from '@nfw/contracts/self-service';
import { api } from '../api';

export const resourcelockApi = api.injectEndpoints({
  endpoints: (builder) => ({
    acquire: builder.mutation<LockPermissions, LockRequest>({
      query: (body) => ({
        url: Routes.LockResource,
        method: 'PUT',
        body,
      }),
    }),
    release: builder.mutation<void, ReleaseRequest>({
      query: (body) => ({
        url: Routes.ReleaseResource,
        method: 'PUT',
        body,
      }),
    }),
  }),
});
