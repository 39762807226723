import Button from '@ingka/button';
import Modal, { ModalBody, ModalHeader, Sheets } from '@ingka/modal';
import linkOut from '@ingka/ssr-icon/paths/link-out';
import Text from '@ingka/text';
import { useTranslation, Trans } from 'react-i18next';
import { environment } from '../../environment';
import './index.scss';

export type SupportModalProps = {
  visible: boolean;
  onClose: () => void;
};

export const SupportModal: React.FC<SupportModalProps> = ({ visible, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal visible={visible} handleCloseBtn={onClose}>
      <Sheets footer={null} size="small" header={<ModalHeader ariaCloseTxt={t('global.close')} />}>
        <ModalBody>
          <Text className="support-header" headingSize="m">
            {t('global.support')}
          </Text>
          <div className="contact-method">
            <Text className="header" headingSize="xs">
              {t('component.support_modal.usage_guidelines_title')}
            </Text>
            <Text className="caption">{t('component.support_modal.usage_guidelines_caption')}</Text>
            <Button
              type="secondary"
              iconPosition="trailing"
              ssrIcon={linkOut}
              text={t('global.open')}
              href="https://confluence.build.ingka.ikea.com/x/_tY2NQ"
              newWindow
              size="small"
            />
          </div>
          <div className="contact-method">
            <Text className="header" headingSize="xs">
              Slack
            </Text>
            <Text className="caption">{t('component.support_modal.slack_caption')}</Text>
            <Button
              type="secondary"
              iconPosition="trailing"
              ssrIcon={linkOut}
              text={t('global.open')}
              href="https://ingka.slack.com/archives/C053MQE56BF"
              newWindow
              size="small"
            />
          </div>
          <div className="contact-method">
            <Text className="header" headingSize="xs">
              Confluence
            </Text>
            <Text tagName="p" className="caption">
              {t('component.support_modal.confluence_caption')}
            </Text>
            <Button
              type="secondary"
              iconPosition="trailing"
              ssrIcon={linkOut}
              text={t('global.open')}
              size="small"
              href="https://confluence.build.ingka.ikea.com/x/fybYKg"
              newWindow
            />
          </div>
          <div className="contact-method">
            <Text className="header" headingSize="xs">
              {t('global.email')}
            </Text>
            <Text tagName="p" className="caption">
              <Trans
                t={t}
                i18nKey={'component.support_modal.email_caption'}
                values={{ email: environment.supportEmail }}
              />
            </Text>
          </div>
        </ModalBody>
      </Sheets>
    </Modal>
  );
};

export default SupportModal;
