import { useEffect, useState } from 'react';
import { RetailUnit } from '@nfw/ikea/retail';
import { UserPermission } from '@nfw/permissions';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store';
import { queueToast } from '../app/actions';
import { useGetPermissionsQuery } from './api';

export type UserPermissionResponse = {
  isLoading: boolean;
  isAllowed: boolean;
  isTeamSuperAdmin: boolean;
  teams: string[];
};

export type TeamPermissionResponse = {
  /**
   * Function for setting the selected team.
   */
  setTeam: (team: string) => void;
  /**
   * Flag for if permissions are initialised.
   */
  isInitialised: boolean;
  /**
   * The retail units granted by the selected team.
   */
  retailUnits: RetailUnit[];
  /**
   * The user permissions for the team.
   */
  permissions: UserPermission[];
  /**
   * The selected team.
   */
  team: string | undefined;
};

/**
 * Hook for checking if user has permission in any of the teams.
 *
 * @param permission -  the permission to test against.
 *
 * @returns the user permission response.
 */
export const usePermission = (permission: UserPermission): UserPermissionResponse => {
  const { data: permissions } = useGetPermissionsQuery();
  const [isLoading, setIsLoading] = useState(true);
  const [isAllowed, setIsAllowed] = useState(false);
  const [isTeamSuperAdmin, setIsTeamSuperAdmin] = useState(false);
  const [teams, setTeams] = useState<string[]>([]);

  useEffect(() => {
    if (permissions) {
      const authenticatedTeams = Object.entries(permissions)
        .filter(([, { userPermissions: user }]) => user.includes(permission))
        .map(([key]) => key);

      setTeams(authenticatedTeams);
      setIsAllowed(authenticatedTeams.length > 0);
      setIsTeamSuperAdmin(authenticatedTeams.includes('master'));
      setIsLoading(false);
    }
  }, [permissions, permission]);

  return { isLoading, isTeamSuperAdmin, isAllowed, teams };
};

/**
 * Hook for checking if user has permission other than team permissions.
 *
 * @param permission -  the permission to test against.
 *
 * @returns true if the user has the correct permission.
 */
export const useOtherPermission = (permission: string) => {
  const { data: permissions, isLoading, isError } = useGetPermissionsQuery();
  const [isAllowed, setIsAllowed] = useState(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (isError) {
      dispatch(queueToast({ msg: t('global.error.generic') }));
    }
  }, [dispatch, isError, t]);

  useEffect(() => {
    if (permissions) {
      setIsAllowed(Object.keys(permissions).includes(permission));
    }
  }, [permissions, permission]);

  return { isLoading, isAllowed };
};

/**
 * Hook for using a permission related to a team.
 *
 * @param permission - the permission required.
 *
 * @returns the team permission response.
 */
export const useTeamPermission = (): TeamPermissionResponse => {
  const { data: teamPermissions } = useGetPermissionsQuery();
  const [retailUnits, setRetailUnits] = useState<RetailUnit[]>([]);
  const [team, setTeam] = useState<string>();
  const [permissions, setPermissions] = useState<UserPermission[]>([]);
  const [isInitialised, setInitialised] = useState(false);

  useEffect(() => {
    if (team && teamPermissions) {
      const permission = teamPermissions[team];

      if (permission) {
        setRetailUnits(permission.retailUnits);
        setPermissions(permission.userPermissions);
      }
      setInitialised(true);
    }
  }, [teamPermissions, team]);

  return {
    setTeam,
    team,
    isInitialised,
    permissions,
    retailUnits,
  };
};
