import { Action, ModalWidget, PromptWidget, Widget, WidgetTypes } from '@nfw/nudge/types';

export type Translations = {
  [key: string]: string;
};

/**
 * Add text translations to a widget.
 *
 * @param widget - the widget.
 * @param translations - the available translations.
 *
 * @returns true if the translations are successful, otherwise false.
 */
const addTextTranslation = (widget: Widget, translations: Translations): boolean => {
  let success = true;

  const text = translations[widget.text];

  if (text) {
    widget.text = text;
  } else {
    success = false;
  }

  return success;
};

/**
 * Add action translations.
 *
 * @param action - the action.
 * @param translations - the available translations.
 *
 * @returns true if the translations are successful, otherwise false.
 */
const addActionTranslation = (action: Action, translations: Translations): boolean => {
  let success = true;

  if (action.text) {
    const text = translations[action.text];

    if (text) {
      action.text = text;
    } else {
      success = false;
    }
  }

  return success;
};

/**
 * Add modal widget translations.
 *
 * @param widget - the modal widget.
 * @param translations - the available translations.
 *
 * @returns true if the translations are successful, otherwise false.
 */
const addModalTranslations = (widget: ModalWidget, translations: Translations): boolean => {
  let success = addTextTranslation(widget, translations);

  const title = translations[widget.title];

  if (widget.header) {
    /*
     * If no altText the image is consider to be decorative.
     * @link https://www.w3.org/WAI/tutorials/images/decorative/
     */
    widget.header.altText = widget.header.altText ? translations[widget.header.altText] : '';
  }

  if (title) {
    widget.title = title;
  } else {
    success = false;
  }
  if (widget.ctaText) {
    const ctaText = widget.ctaText.map((text) => {
      const translation = translations[text];

      if (translation) {
        return translation;
      } else {
        success = false;
        return text;
      }
    });
    widget.ctaText = ctaText;
  }

  if (widget.list) {
    const statements = widget.list.statements.map((text) => {
      const statement = translations[text];

      if (statement) {
        return statement;
      } else {
        success = false;
        return text;
      }
    });
    widget.list.statements = statements;
  }

  if (widget.valueStatements) {
    const valueStatements = widget.valueStatements.map((value) => {
      const statement = translations[value.statement];

      if (statement) {
        return { ...value, statement };
      } else {
        success = false;
      }
      return value;
    });
    widget.valueStatements = valueStatements;
  }

  if (widget.actions) {
    const actions = widget.actions.map((value) => {
      if (value.text) {
        const text = translations[value.text];

        if (text) {
          return { ...value, text };
        } else {
          success = false;
        }
      }

      return value;
    });

    widget.actions = actions;
  }

  return success;
};

/**
 * Add prompt widget translations.
 *
 * @param widget - the prompt widget.
 * @param translations - the available translations.
 *
 * @returns true if the translations are successful, otherwise false.
 */
const addPromptTranslations = (widget: PromptWidget, translations: Translations): boolean => {
  let success = addTextTranslation(widget, translations);

  const title = translations[widget.title];

  if (title) {
    widget.title = title;
  } else {
    success = false;
  }

  if (widget.actions) {
    const actions = widget.actions.map((value) => {
      if (value.text) {
        const text = translations[value.text];

        if (text) {
          return { ...value, text };
        } else {
          success = false;
        }
      }

      return value;
    });

    widget.actions = actions;
  }

  return success;
};

/**
 * Add translations to the widget.
 *
 * @param widget - the widget.
 * @param translations - the available translations.
 *
 * @returns true if the translations are successful, otherwise false.
 */
export const addWidgetTranslation = (widget: Widget, translations: Translations): boolean =>
  translateWidget(widget, translations)[1];

/**
 * Translate a widget.
 *
 * @param widget - the widget to translate.
 * @param translations - the translations to use.
 *
 * @returns the translated widget, and wether the translation was successful or not.
 */
export const translateWidget = <T extends Widget>(
  widget: T,
  translations?: Translations
): [T, boolean] => {
  let success = false;

  if (translations) {
    switch (widget.type) {
      case WidgetTypes.Modal:
        success = addModalTranslations(widget, translations);
        break;
      case WidgetTypes.Message:
      case WidgetTypes.Tab:
      case WidgetTypes.Toast:
        {
          success = addTextTranslation(widget, translations);
          if (success && widget.action) {
            success = addActionTranslation(widget.action, translations);
          }
        }
        break;
      case WidgetTypes.Reaction:
      case WidgetTypes.Tip:
        success = addTextTranslation(widget, translations);
        break;
      case WidgetTypes.Prompt:
        success = addPromptTranslations(widget, translations);
        break;
      default:
        throw new Error('Unknown widget');
    }
  }
  return [widget, success];
};
