export const ExceptionTypes = {
  StringMatch: 'string',
  Breadcrumb: 'breadcrumb',
} as const;
export type ExceptionType = (typeof ExceptionTypes)[keyof typeof ExceptionTypes];

type ExceptionBase<T extends ExceptionType> = {
  type: T;
};

/**
 * The page matcher exceptions. Can be either a inclusion filter or an exclusion
 * filter depending on the include child pages property.
 *
 * The matcher will simply check if the current subpath match any of the properties.
 */
export type StringMatcherException = ExceptionBase<'string'> & {
  startsWith: string[];
  endsWith: string[];
  includes: string[];
};

/**
 * The breadcrumb matcher exception.
 *
 * The breadcrumb matcher works with breadcrumb structure. Where for instance a root of /cat is the root
 * of all pip pages. The bread crumb would always contain the root of the catalogue.
 *
 * If the breadcrumbs can't be found on the page in question, all is filtered out.
 * This matcher exception is specific to the cat (catalogue), rooms and p (pip) pages.
 */
export type BreadcrumbMatcherException = ExceptionBase<'breadcrumb'> & {
  exceptions: string[];
};

/**
 * The union exception type.
 */
export type PageMatcherException = StringMatcherException | BreadcrumbMatcherException;

/**
 * Search param pair.
 */
export type SearchParam = {
  key: string;
  value: string;
};

/**
 * The page matcher for which a certain nudge and path is valid.
 */
export type PageMatcher = {
  includeChildPages: boolean;
  includeNamespacePage: boolean;
  exception?: PageMatcherException;
  searchparams?: SearchParam[];
};
