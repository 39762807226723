import { useState } from 'react';
import ListView, { ListViewItem } from '@ingka/list-view';
import { DefaultEvent } from '@nfw/contracts/experiment';
import { EventsFilter } from '../filters';
import LinearLayout from '../LinearLayout';

export type EventListProps = {
  events: DefaultEvent[];
  onSelect: (event: DefaultEvent) => void;
};

const EventList: React.FC<EventListProps> = ({ events, onSelect }) => {
  const [filteredEvents, setFilteredEvents] = useState(events);

  return (
    <LinearLayout gap="xl">
      <EventsFilter data={events} onFilterChange={setFilteredEvents} />
      <ListView id="events" size="large">
        {filteredEvents.map((event) => {
          const { id, name, project_name } = event;

          return (
            <ListViewItem
              key={id}
              inset
              emphasised
              title={name}
              description={`ID: ${id} - ${project_name}`}
              controlIcon="chevronRight"
              onClick={() => onSelect({ ...event })}
              control="navigational"
            />
          );
        })}
      </ListView>
    </LinearLayout>
  );
};

export default EventList;
