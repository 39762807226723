import { useEffect, useState } from 'react';
import { Input, InputType, ValidationFieldConfiguration, ValidationTypes } from '@nfw/form-types';
import { isDefined, isString } from '@nfw/utils';

export type ValidationResponse = [boolean, boolean, string | undefined];

/**
 * Validation hook.
 *
 * @param configuration - the configuration.
 * @param hidden - flag for if the component is hidden.
 * @param value - the current value.
 */
export const useValidation = <S extends InputType, T extends Input>(
  configuration: ValidationFieldConfiguration<S, T>,
  hidden: boolean,
  value?: T
): [boolean, boolean, string | undefined] => {
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    if (value) {
      setDirty(true);
    }
  }, [value]);

  useEffect(() => {
    if (hidden) {
      setDirty(false);
    }
  }, [hidden]);

  const validate = (): ValidationResponse => {
    const { validations } = configuration;

    let valid = true;
    let message: string | undefined;
    if (validations) {
      const failedValidation = validations.find((validation) => {
        switch (validation.type) {
          case ValidationTypes.Required:
            return !(
              (isDefined(value) && isString(value, { min: 1 })) ||
              (Array.isArray(value) && value.length > 0)
            );
          case ValidationTypes.Regexp:
            if (isString(value, { min: 1 })) {
              return !new RegExp(validation.regexp).test(value ?? '');
            }
            return false;
          default:
            return false;
        }
      });

      if (failedValidation) {
        valid = false;
        message = failedValidation.errorMessage;
      }
    }
    const shouldValidate = !!message && dirty;
    return [valid || hidden, shouldValidate, message];
  };

  return validate();
};
