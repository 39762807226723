/**
 * The conditions required to have a nudge part of the orchestration.
 */
export const ConditionTypes = {
  Authenticated: 'authenticated',
  B2BCustomer: 'b2b_customer',
  CartCount: 'cart_count',
  CartItemAdded: 'cartitem_added',
  CartLargeSum: 'cart_large_sum',
  CartQuantity: 'cart_quantity',
  DeviceType: 'device_type',
  PostCode: 'post_code',
  RewardBalance: 'reward_balance',
  RewardCount: 'reward_count',
  RewardRedeemable: 'reward_redeemable',
  Store: 'store',
  Time: 'time',
  Traffic: 'traffic',
} as const;
export type ConditionType = (typeof ConditionTypes)[keyof typeof ConditionTypes];

/**
 * Different Operand types.
 */
export const Operands = {
  And: 'and',
  Or: 'or',
} as const;
export type Operand = (typeof Operands)[keyof typeof Operands];

/**
 * Different Device types.
 */
export const DeviceTypes = {
  Mobile: 'mobile',
  Desktop: 'desktop',
} as const;
export type DeviceType = (typeof DeviceTypes)[keyof typeof DeviceTypes];

/**
 * The time interval type. The startTime and endTime are in the format 'HH:mm'.
 */
export type TimeInterval = {
  startTime: string;
  endTime: string;
};

/**
 * The condition base type.
 */
type ConditionBase<T extends ConditionType> = {
  type: T;
};

/**
 * Attached value type to a condition.
 */
type WithValue<S> = {
  value: S;
};

/**
 * Attached values type to a condition.
 */
type WithValues<S> = {
  values: S[];
};

/**
 * Attached values type to a condition.
 */
type WithOperand = {
  op: Operand;
};

/**
 * Attached a limit type to a condition.
 */
type WithOptionalLimit = {
  limit?: Limit;
};

/**
 * The limit type.
 */
export type Limit = {
  value: number;
};

/**
 * The different identified user targets.
 */
export const IdentifiedUserTargets = {
  All: 'all',
  /** Target all family members. */
  Family: 'family',
  /** Target all regular users. */
  Regular: 'regular',
  /** Target all business customers. */
  Business: 'business',
  /** Targets all individual customer types */
  Individual: 'individual',
  /** Target all family members who are also individual customers. */
  FamilyIndividual: 'family_individual',
  /** Target all regular users who are also individual customers. */
  RegularIndividual: 'regular_individual',
  /** Target all family members who are business customers. */
  FamilyBusiness: 'family_business',
  /** Target all business customers who are regular members. */
  RegularBusiness: 'regular_business',
} as const;
export type IdentifiedUserTarget =
  (typeof IdentifiedUserTargets)[keyof typeof IdentifiedUserTargets];

/**
 * The authenticated (logged in/out) condition.
 */
export type AuthenticatedCondition = ConditionBase<'authenticated'> &
  WithValue<boolean> & {
    userTarget?: IdentifiedUserTarget;
  };

/**
 * The business to business customer condition.
 */
export type B2BCustomerCondition = ConditionBase<'b2b_customer'>;

/**
 * The reward count condition.
 */
export type RewardCountCondition = ConditionBase<'reward_count'> &
  WithValue<number> &
  WithOptionalLimit;

/**
 * The reward count condition.
 */
export type RewardBalanceCondition = ConditionBase<'reward_balance'> &
  WithValue<number> &
  WithOptionalLimit;

/**
 * The reward exists condition.
 */
export type RewardRedeemableCondition = ConditionBase<'reward_redeemable'> & WithValue<string>;

/**
 * The cart count condition. The condition is fulfilled when the number of (unique) cart items is equal to
 * or more than the set value.
 */
export type CartCountCondition = ConditionBase<'cart_count'> & WithValue<number>;

/**
 * The cart quantity condition. The condition is fulfilled when the cart quantity is equal to
 * or more than the set value.
 */
export type CartQuantityCondition = ConditionBase<'cart_quantity'> & WithValue<number>;

/**
 * The cart item added condition. The condition is fulfilled when the specified cart item is in the cart.
 */
export type CartItemAddedCondition = ConditionBase<'cartitem_added'> &
  WithValues<string> &
  WithOperand;

/**
 * The large cart sum condition. The condition is fulfilled when the sum of the
 * products in the cart exceeds or is equal to the 'cartLargeSum'-configuration.
 */
export type CartLargeSumCondition = ConditionBase<'cart_large_sum'>;

/**
 * The mobile device condition. The condition is fulfilled when the device is either
 * a mobile device or not. Omitting this condition opens the nudge to any device type.
 */
export type DeviceTypeCondition = ConditionBase<'device_type'> & WithValue<DeviceType>;

/**
 * The store condition. The condition is fulfilled when the customers set store is
 * equal to the value.
 */
export type StoreCondition = ConditionBase<'store'> & WithValues<string>;

/**
 * The post code condition. The condition is fulfilled when the customers set post code is
 * equal to the value.
 */
export type PostCodeCondition = ConditionBase<'post_code'> & WithValues<string>;

/**
 * The time condition. The condition is fulfilled when the current time is within the set time interval.
 */
export type TimeCondition = ConditionBase<'time'> & TimeInterval;

/**
 * The traffic condition. The condition is fulfilled when the percentage of users
 * exposed to the nudge is equal to the value.
 */
export type TrafficCondition = ConditionBase<'traffic'> & WithValue<number>;

/**
 * Condition type.
 */
export type Condition =
  | AuthenticatedCondition
  | B2BCustomerCondition
  | CartCountCondition
  | CartItemAddedCondition
  | CartLargeSumCondition
  | CartQuantityCondition
  | DeviceTypeCondition
  | PostCodeCondition
  | RewardBalanceCondition
  | RewardCountCondition
  | RewardRedeemableCondition
  | StoreCondition
  | TimeCondition
  | TrafficCondition;
