import React, { useCallback, useEffect, useState } from 'react';
import Button from '@ingka/button';
import InlineMessage from '@ingka/inline-message';
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal';
import Text from '@ingka/text';
import { TeamRequest, isTeamRequest } from '@nfw/contracts/self-service';
import { Form, FormCallback } from '@nfw/ui/form';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectName } from '../../features/auth/selectors';
import { useTeamRequestConfirmation, useTeamRequestLayout } from '../../features/teams/hooks';
import './index.scss';

export type TeamRequestModalProps = {
  open: boolean;
  onClose: (isRequestSent: boolean) => void;
};

const TeamRequestModal: React.FC<TeamRequestModalProps> = ({ open, onClose }) => {
  const [request, setRequest] = useState<TeamRequest>();
  const name = useSelector(selectName);

  const { t } = useTranslation();

  const layout = useTeamRequestLayout(name);

  const onCloseInternal = () => {
    onClose(false);
  };

  const { create, isLoading, isSuccess, isError } = useTeamRequestConfirmation();

  useEffect(() => {
    if (isSuccess) {
      onClose(true);
    }
  }, [isSuccess, onClose]);

  const onSubmit = () => {
    if (request) {
      create(request);
    }
  };

  const onChangeInternal = useCallback<FormCallback<TeamRequest>>(({ value }) => {
    setRequest(value);
  }, []);

  return (
    <Modal visible={open} handleCloseBtn={onCloseInternal}>
      <Sheets
        header={<ModalHeader />}
        footer={
          <ModalFooter>
            <Button type="secondary" text={t('global.action.cancel')} />
            <Button
              disabled={!request}
              type="primary"
              loading={isLoading}
              text={t('global.action.save')}
              onClick={onSubmit}
            />
          </ModalFooter>
        }
      >
        <ModalBody>
          {isError && <InlineMessage variant="negative" body={t('global.error.generic')} />}
          <Text headingSize="m" className="space">
            {t('team.onboard.action.request.text')}
          </Text>
          <Text bodySize="m" className="space">
            {t('team.onboard.description')}
          </Text>
          <Form
            config={layout}
            onChange={onChangeInternal}
            validationFn={isTeamRequest}
            purify={DOMPurify.sanitize}
          />
        </ModalBody>
      </Sheets>
    </Modal>
  );
};

export default TeamRequestModal;
