import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const selectLocalState = (state: RootState) => state.auth;

export const selectCredentials = createSelector(selectLocalState, (state) => state.credentials);
export const selectToken = createSelector(selectCredentials, (credentials) => credentials?.token);
export const selectName = createSelector(selectCredentials, (credentials) => credentials?.name);
export const selectInitials = createSelector(selectName, (name) => {
  if (!name) return 'NA';
  const words = name.split(' ');
  return (words[0][0] + words[words.length - 1][0]).toUpperCase();
});
