import { createReducer } from '@reduxjs/toolkit';
import { setCredentials } from './actions';
import { AuthState } from './types';

const initialState: AuthState = {
  credentials: undefined,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setCredentials, (state, action) => {
    state.credentials = action.payload;
  });
});

export default reducer;
