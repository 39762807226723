import { useCallback, useEffect, useState } from 'react';
import { DefaultEvent, DefaultMetric, Metrics, isMetrics } from '@nfw/contracts/experiment';
import { Form, FormCallback } from '@nfw/ui/form';
import { useDefaultMetricsLayout } from '../../features/experiments/hooks';
import LinearLayout from '../LinearLayout';
import MetricModal from '../MetricModal';

export type DefaultMetricsFormProps = {
  event?: DefaultEvent;
  events: DefaultEvent[];
  defaultMetrics: DefaultMetric[];
  onMetrics: (metrics: Metrics) => void;
};

const DefaultMetricsForm: React.FC<DefaultMetricsFormProps> = ({
  event,
  events,
  defaultMetrics,
  onMetrics,
}) => {
  const [metrics, setMetrics] = useState<DefaultMetric[]>([]);
  const [latestMetrics, setLatestMetrics] = useState(metrics);
  const layout = useDefaultMetricsLayout(events, metrics);

  const onChangeInternal = useCallback<FormCallback<Metrics>>(
    ({ value }) => {
      if (value) {
        onMetrics(value);
        setLatestMetrics(value.metrics);
      }
    },
    [onMetrics]
  );

  const onMetric = useCallback(
    (metric: DefaultMetric) => {
      setMetrics(latestMetrics.concat(metric));
    },
    [latestMetrics]
  );

  useEffect(() => {
    setMetrics(defaultMetrics);
  }, [defaultMetrics]);

  return (
    <LinearLayout padded>
      <MetricModal event={event} onMetric={onMetric} />
      <Form config={layout} validationFn={isMetrics} onChange={onChangeInternal} />
    </LinearLayout>
  );
};

export default DefaultMetricsForm;
