import {
  DOMEventName,
  MessageNudgeTrigger,
  ModalNudgeTrigger,
  PromptNudgeTrigger,
  ReactionNudgeTrigger,
  TabNudgeTrigger,
  TeaserNudgeTrigger,
  TipNudgeTrigger,
  ToastNudgeTrigger,
  TriggerType,
  WidgetType,
  WidgetTypes,
} from '@nfw/nudge/types';

/*
 * These are all the defined trigger types for each nudge.
 *
 * DO NOT FORGET TO UPDATE WHEN CHANGING TRIGGER DEPENDENCIES IN nudge-types.
 */
const EmbeddedNudgeTriggerTypes: TeaserNudgeTrigger['type'][] = ['init'];
const MessageNudgeTriggerTypes: MessageNudgeTrigger['type'][] = [
  'init',
  'search_param',
  'idle',
  'dom_event',
  'cart_add',
  'cart_remove',
  'favourite_add',
  'favourite_remove',
  'store_change',
  'element_in_viewport',
];
const ModalNudgeTriggerTypes: ModalNudgeTrigger['type'][] = [
  'init',
  'search_param',
  'idle',
  'cart_add',
  'cart_remove',
  'favourite_add',
  'favourite_remove',
  'exit_intent',
  'episod_event',
  'regexp_matcher',
  'native',
  'store_change',
  'element_in_viewport',
];
const PromptNudgeTriggerTypes: PromptNudgeTrigger['type'][] = [
  'init',
  'search_param',
  'idle',
  'cart_add',
  'cart_remove',
  'favourite_add',
  'favourite_remove',
  'exit_intent',
  'episod_event',
  'regexp_matcher',
  'native',
  'store_change',
  'element_in_viewport',
];
const ReactionNudgeTriggerTypes: ReactionNudgeTrigger['type'][] = [
  'search_param',
  'episod_event',
  'cart_add',
  'cart_remove',
  'favourite_add',
  'favourite_remove',
  'exit_intent',
  'dom_event',
];
const TabNudgeTriggerTypes: TabNudgeTrigger['type'][] = [
  'init',
  'search_param',
  'idle',
  'cart_add',
  'cart_remove',
  'favourite_add',
  'favourite_remove',
  'store_change',
  'element_in_viewport',
];
const TipNudgeTriggerTypes: TipNudgeTrigger['type'][] = ['dom_event'];
const ToastNudgeTriggerTypes: ToastNudgeTrigger['type'][] = [
  'init',
  'search_param',
  'idle',
  'exit_intent',
  'episod_event',
  'cart_add',
  'cart_remove',
  'favourite_add',
  'favourite_remove',
  'regexp_matcher',
  'dom_event',
  'store_change',
  'element_in_viewport',
];

/**
 * The nudge dom event names applicable for each widget type.
 */
// DO NOT FORGET TO UPDATE WHEN CHANGING TRIGGER DEPENDENCIES IN nudge-types.
export const NudgeDOMEventNames: Record<WidgetType, DOMEventName[]> = {
  [WidgetTypes.InlineMessage]: [],
  [WidgetTypes.Teaser]: [],
  [WidgetTypes.Message]: ['click', 'focusin'],
  [WidgetTypes.Modal]: [],
  [WidgetTypes.Prompt]: [],
  [WidgetTypes.Reaction]: ['click', 'focusin', 'mouseover'],
  [WidgetTypes.Tab]: [],
  [WidgetTypes.Tip]: ['mouseover'],
  [WidgetTypes.Toast]: ['click', 'focusin', 'mouseover'],
};

/**
 * The trigger types suitable for a certain widget.
 */
export const NudgeTrigger: Record<WidgetType, TriggerType[]> = {
  [WidgetTypes.InlineMessage]: EmbeddedNudgeTriggerTypes,
  [WidgetTypes.Teaser]: EmbeddedNudgeTriggerTypes,
  [WidgetTypes.Message]: MessageNudgeTriggerTypes,
  [WidgetTypes.Modal]: ModalNudgeTriggerTypes,
  [WidgetTypes.Prompt]: PromptNudgeTriggerTypes,
  [WidgetTypes.Reaction]: ReactionNudgeTriggerTypes,
  [WidgetTypes.Tab]: TabNudgeTriggerTypes,
  [WidgetTypes.Tip]: TipNudgeTriggerTypes,
  [WidgetTypes.Toast]: ToastNudgeTriggerTypes,
};
