import { createAction } from '@reduxjs/toolkit';
import { Credentials } from './types';

export const ACTION_SET_CREDENTIALS = 'app/ACTION_SET_CREDENTIALS';
export const ACTION_CHECK_PERMISSSION = 'app/ACTION_CHECK_PERMISSION';

export const setCredentials = createAction<Credentials>(ACTION_SET_CREDENTIALS);
export type SetCredentialsAction = ReturnType<typeof setCredentials>;

export const checkPermission = createAction<string>(ACTION_CHECK_PERMISSSION);
export type CheckPermissionAction = ReturnType<typeof checkPermission>;
