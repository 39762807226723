import { createAction } from '@reduxjs/toolkit';
import { withMetaAndPayloadType, withPayloadType } from '../utils';
import { FilterValue, SortValue, Toast } from './types';

export const ACTION_QUEUE_TOAST = 'app/ACTION_QUEUE_TOAST';
export const ACTION_SHOW_TOAST = 'app/ACTION_SHOW_TOAST';
export const ACTION_ACKNOWLEDGE_TOAST = 'app/ACTION_ACKNOWLEDGE_TOAST';
export const ACTION_SET_TEAM = 'app/ACTION_SET_TEAM';
export const ACTION_TAB = 'app/ACTION_TAB';

export const ACTION_FOCUS_PORTAL = 'app/ACTION_FOCUS_PORTAL';

export const ACTION_UPDATE_FILTER = 'app/ACTION_UPDATE_FILTER';
export const ACTION_CLEAR_FILTER = 'app/ACTION_CLEAR_FILTER';

export const ACTION_SET_SORT = 'app/ACTION_SET_SORT';

export const ACTION_SET_OVERVIEW_LAYOUT = 'app/ACTION_SET_OVERVIEW_LAYOUT';
export const ACTION_DISMISS_EXPERIMENT_ITERATION_MESSAGE =
  'app/ACTION_DISMISS_EXPERIMENT_ITERATION_MESSAGE';

export const queueToast = createAction(ACTION_QUEUE_TOAST, withPayloadType<Toast>);
export type QueueToastAction = ReturnType<typeof queueToast>;

export const showToast = createAction(ACTION_SHOW_TOAST, withPayloadType<Toast>);
export type ShowToastAction = ReturnType<typeof showToast>;

export const acknowledgeToast = createAction(ACTION_ACKNOWLEDGE_TOAST);
export type AcknowledgeToastAction = ReturnType<typeof acknowledgeToast>;

export const setTab = createAction(ACTION_TAB, withPayloadType<string>);
export type TabAction = ReturnType<typeof setTab>;

export const enableFocusPortal = createAction<boolean>(ACTION_FOCUS_PORTAL);
export type EnableFocusPortalAction = ReturnType<typeof enableFocusPortal>;

export const updateFilter = createAction(
  ACTION_UPDATE_FILTER,
  withMetaAndPayloadType<boolean, FilterValue>
);
export type UpdateFilterAction = ReturnType<typeof updateFilter>;

export const clearFilter = createAction(ACTION_CLEAR_FILTER);
export type ClearFilterAction = ReturnType<typeof clearFilter>;

export const updateSort = createAction(ACTION_SET_SORT, withPayloadType<SortValue>);
export type UpdateSortAction = ReturnType<typeof updateSort>;

export const dismissExperimentIterationMessage = createAction(
  ACTION_DISMISS_EXPERIMENT_ITERATION_MESSAGE
);
export type DismissExperimentIterationMessageAction = ReturnType<
  typeof dismissExperimentIterationMessage
>;
