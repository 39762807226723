import { useCallback, useState } from 'react';
import Button from '@ingka/button';
import Combobox from '@ingka/combobox';
import FormField from '@ingka/form-field';
import InlineMessage from '@ingka/inline-message';
import { MenuItem } from '@ingka/list-box';
import Text from '@ingka/text';
import { DefaultEvent, Metrics } from '@nfw/contracts/experiment';
import { Namespace, Namespaces } from '@nfw/ikea/utils';
import { useTranslation } from 'react-i18next';
import DefaultMetricsForm from '../../components/DefaultMetricsForm';
import EventList from '../../components/EventList';
import FormFooter from '../../components/FormFooter';
import LinearLayout from '../../components/LinearLayout';
import SplitPage from '../../components/SplitPage';
import { useDefaultMetrics, useUpdateDefaultMetrics } from '../../features/experiments/hooks';
import { InjectedSuperAdminProps, withSuperAdmin } from '../../hocs';

const HandleEventsPage: React.FC<InjectedSuperAdminProps> = () => {
  const [event, setEvent] = useState<DefaultEvent>();
  const [metrics, setMetrics] = useState<Metrics>();
  const [namespace, setNamespace] = useState<Namespace>();

  const { defaultMetrics, isLoading: isLoadingMetrics } = useDefaultMetrics(namespace);
  const { events, isMetricsError, refetch, save, isSaving } = useUpdateDefaultMetrics(namespace);
  const { t } = useTranslation();

  const onSave = useCallback(() => {
    if (metrics) {
      save(metrics);
    }
  }, [save, metrics]);

  return (
    <SplitPage
      loading={!events || isLoadingMetrics}
      side={
        defaultMetrics &&
        events && (
          <DefaultMetricsForm
            event={event}
            events={events}
            defaultMetrics={defaultMetrics}
            onMetrics={setMetrics}
          />
        )
      }
    >
      <LinearLayout gap="xl">
        <Text tagName="h1" headingSize="m">
          {t('page.events.title')}
        </Text>
        {isMetricsError && (
          <InlineMessage
            variant="negative"
            body={t('global.error.generic')}
            actions={[
              {
                text: t('global.action.retry'),
                onClick: refetch,
              },
            ]}
          />
        )}
        <FormField>
          <Combobox
            label={t('page.handle_events.select_label')}
            labelId="namespace"
            listboxId="namespace"
            placeholder={t('page.handle_events.select_placeholder')}
          >
            {Object.values(Namespaces).map((namespace) => (
              <MenuItem
                id={namespace}
                key={namespace}
                onClick={() => setNamespace(namespace)}
                title={namespace}
              />
            ))}
          </Combobox>
        </FormField>
        {events && namespace && <EventList events={events} onSelect={setEvent} />}
        <FormFooter>
          <Button
            type="primary"
            onClick={onSave}
            text={t('global.action.save')}
            loading={isSaving}
            disabled={!metrics}
          />
        </FormFooter>
      </LinearLayout>
    </SplitPage>
  );
};

export default withSuperAdmin(HandleEventsPage);
