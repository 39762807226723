import { createAction } from '@reduxjs/toolkit';
import { withMetaAndPayloadType } from '../utils';

export const ACTION_SET_PUBLISHING = 'app/ACTION_SET_PUBLISHING';

export const setPublishing = createAction(
  ACTION_SET_PUBLISHING,
  withMetaAndPayloadType<boolean, string>
);
export type SetPublishingLoadingAction = ReturnType<typeof setPublishing>;
