import { useCallback } from 'react';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { api, authErrorHandler } from '../features/api';
import app from '../features/app';
import auth from '../features/auth';
import saga from '../features/configure';
import orchestration from '../features/orchestrations';

const appPersistConfig = {
  key: 'app',
  storage,
  whitelist: [
    'activeTab',
    'filterSelections',
    'overviewlayout',
    'experimentIterationMessageDismissed',
  ],
};

const reducer = combineReducers({
  app: persistReducer(appPersistConfig, app),
  auth,
  orchestration,
  [api.reducerPath]: api.reducer,
});

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer,
  middleware: (gdm) =>
    gdm({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(sagaMiddleware)
      .concat(api.middleware)
      .concat(authErrorHandler),
  devTools: process.env.NODE_ENV === 'development',
});
export const persistor = persistStore(store);

setupListeners(store.dispatch);
sagaMiddleware.run(saga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof reducer>;

/**
 * Selector type.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Selector = (state: RootState, ...args: any[]) => any;

/**
 * Function infer of params.
 */
type Params<T extends Selector> = T extends (state: RootState, ...args: infer P) => ReturnType<T>
  ? P
  : never;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useMemoizedSelector = <T extends Selector>(
  selector: T,
  ...args: Params<T>
): ReturnType<T> =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useSelector(useCallback((state: RootState) => selector(state, ...args), [selector, ...args]));
