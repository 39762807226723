import {
  DefaultEvent,
  DefaultMetric,
  Experiment,
  ExperimentRequest,
  ExperimentResultRecord,
  Metrics,
} from '@nfw/contracts/experiment';
import { Decision, Routes } from '@nfw/contracts/self-service';
import { toPath } from '@nfw/utils';
import { api } from '../api';
import { IdArg, NamespaceArg, OptionalIdArg, TeamIdArg } from '../api/types';

export const experimentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getExperimentMetrics: builder.query<DefaultMetric[], IdArg>({
      query: ({ id }) => toPath(Routes.ExperimentMetrics, { id }),
      providesTags: [{ type: 'experiment-metrics' }],
    }),
    getDefaultMetrics: builder.query<DefaultMetric[], NamespaceArg>({
      query: ({ namespace }) => toPath(Routes.DefaultMetrics, { namespace }),
      providesTags: [{ type: 'experiment-metrics' }],
    }),
    getMetrics: builder.query<Metrics, NamespaceArg>({
      query: ({ namespace }) => toPath(Routes.DefaultMetrics, { namespace }),
      providesTags: [{ type: 'metrics' }],
    }),
    createMetrics: builder.mutation<void, Metrics & NamespaceArg>({
      query: ({ namespace, metrics }) => ({
        url: toPath(Routes.DefaultMetrics, { namespace }),
        method: 'POST',
        body: { metrics },
      }),
      invalidatesTags: [{ type: 'metrics' }],
    }),
    getDefaultEvents: builder.query<DefaultEvent[], void>({
      query: () => Routes.ExperimentEvents,
      providesTags: [{ type: 'events' }],
    }),
    createExperiment: builder.mutation<void, TeamIdArg & { experiment: ExperimentRequest }>({
      query: ({ experiment, id, team }) => ({
        url: toPath(Routes.Experiment, { id, team }),
        method: 'POST',
        body: experiment,
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: 'experiment' },
        { type: 'deployment', id },
        { type: 'experiment-metrics' },
      ],
    }),
    deleteExperiment: builder.mutation<void, TeamIdArg>({
      query: ({ id, team }) => ({
        url: toPath(Routes.Experiment, { id, team }),
        method: 'DELETE',
        body: { orchestrationId: id },
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: 'experiment' },
        { type: 'deployment', id },
        { type: 'experiment-metrics' },
      ],
    }),
    getExperiment: builder.query<Experiment, TeamIdArg>({
      query: ({ id, team }) => toPath(Routes.Experiment, { id, team }),
      providesTags: [{ type: 'experiment' }],
    }),
    getExperimentReportUrl: builder.query<{ url: string }, IdArg>({
      query: ({ id }) => toPath(Routes.ExperimentReport, { id }),
    }),
    takeDecision: builder.mutation<void, TeamIdArg & Decision>({
      query: ({ id, team, value, motivation }) => ({
        url: toPath(Routes.ExperimentDecision, { id, team }),
        method: 'PUT',
        body: { value, motivation },
      }),
    }),
    getExperimentResultRecords: builder.query<ExperimentResultRecord[], OptionalIdArg>({
      query: ({ id }) => {
        const params = new URLSearchParams();

        if (id) {
          params.append('id', id);
        }

        return {
          url: Routes.ExperimentResults,
          params,
        };
      },
      providesTags: [{ type: 'experiment-results' }],
    }),
  }),
});

export const {
  useGetExperimentMetricsQuery,
  useLazyGetMetricsQuery,
  useGetDefaultEventsQuery,
  useCreateMetricsMutation,
  useCreateExperimentMutation,
  useDeleteExperimentMutation,
  useGetExperimentQuery,
  useTakeDecisionMutation,
  useGetDefaultMetricsQuery,
  useGetExperimentResultRecordsQuery,
} = experimentApi;
